import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useRouteMatch } from 'react-router-dom';
import { IRouterParams, routes } from '../routes';
import { CurrentCultureStateContext } from '../contexts';
import { useAsync } from 'react-async-hook';
import { CountryResponse, useMurexinPimContextApi } from '../api';
import { MenuLink } from './MenuLink';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
  },
  appBody: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    paddingLeft: theme.spacing(2),
  },
}));

interface IProps {
  children: React.ReactNode;
}

export function PIMLayout(props: IProps): JSX.Element {
  const classes = useStyles();
  const { setCurrentCulture } = React.useContext(CurrentCultureStateContext);
  const routeMatch = useRouteMatch<IRouterParams>({
    path: routes.countryBase,
    exact: false,
  });
  const api = useMurexinPimContextApi();

  const countryList = useAsync(() => {
    const result = api.countryService.countryServiceList();
    return result ?? [];
  }, []);

  const countryCode = routeMatch?.params?.country ?? undefined;

  useEffect(() => {
    let cultureCode = 'de';

    if (!countryList.loading && countryList.result) {
      const country = countryList.result.data.find(
        (countryEntry: CountryResponse) => countryEntry.code === countryCode
      );

      if (country) {
        cultureCode = country.defaultCultureCode ?? 'de';
      }
    }
    setCurrentCulture(cultureCode);
  }, [countryList.loading, countryList.result, countryCode, setCurrentCulture]);

  return (
    <div className={classes.wrapper}>
      <MenuLink />
      <div className={classes.appBody}>{props.children}</div>
    </div>
  );
}

import { Box, BoxProps, makeStyles } from '@material-ui/core';
import React, { useContext, useRef } from 'react';
import { NotificationContext } from '../../../contexts/NotificationContext';
import PIMButton, { PIMButtonColor } from '../PIMButton';

const useStyles = makeStyles(() => ({
  hidden: {
    display: 'none',
  },
}));

interface IProps extends Omit<BoxProps, 'children'> {
  accept?: string[];
  warningText?: string;
  text?: string;
  multipleFiles?: boolean;
  disabled?: boolean;
  color?: PIMButtonColor;
  onFilesSelected: (files: File[]) => void;
}

const acceptAll = ['*/*'];

export function FileSelectButton({
  onFilesSelected,
  accept = acceptAll,
  warningText,
  text,
  multipleFiles = false,
  disabled = false,
  color = 'primary',
  ...props
}: IProps): JSX.Element {
  const classes = useStyles();
  const inputRef = useRef<HTMLInputElement>(null);
  const notification = useContext(NotificationContext);
  const acceptRegex = accept.map((type) => {
    return new RegExp(type.replace(/\*/g, '[-\\w.+]+'));
  });

  // Must clear it so that when same file is again selected, change event is triggered
  function clearSelectedFile() {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  }

  function check(fileList: FileList | null) {
    if (!fileList || fileList.length === 0) {
      return;
    }

    const files = Array.from(fileList);
    for (const file of files) {
      const validType = acceptRegex.some((r) => r.test(file.type));

      if (!validType) {
        if (warningText) {
          notification.warning(warningText);
        }
        clearSelectedFile();
        return;
      }
    }

    onFilesSelected(files);
    clearSelectedFile();
  }

  return (
    <Box {...props}>
      <input
        disabled={disabled}
        ref={inputRef}
        accept={accept.join(', ')}
        type='file'
        id='select-file'
        className={classes.hidden}
        onChange={(e) => {
          check(e.target.files);
        }}
        multiple={multipleFiles}
      />
      <label htmlFor='select-file'>
        <PIMButton color={color} component='span' icon='add' disabled={disabled}>
          {text}
        </PIMButton>
      </label>
    </Box>
  );
}

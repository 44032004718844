import { Grid, makeStyles, Link, IconButton, InputAdornment } from '@material-ui/core';
import React, { ReactElement, useContext, useState } from 'react';
import { FieldArray, Form, Formik } from 'formik';
import {
  useMurexinPimContextApi,
  ArticleResponse,
  CountryPermission,
  ArrangementLayoutResponse,
} from '../../../api/MurexinPimApi';
import { NotificationContext } from '../../../contexts/NotificationContext';
import { useAsync, useAsyncCallback } from 'react-async-hook';
import { LinkIcon, ERPIcon, NonERPIcon } from '../../../shared/icons';
import { FileSelectButton } from '../../../shared/Components/FileSelectButton';
import { Theme } from '@mui/material';
import PIMFormikToggle from '../../../shared/Components/PIMFormikToggle';
import PIMButton from '../../../shared/Components/PIMButton';
import PIMFormikTextField from '../../../shared/Components/PIMFormikTextField';
import PIMFormikSelect from '../../../shared/Components/PIMFormikSelect';
import PIMInformationBox from '../../../shared/Components/PIMInformationBox';
import { CircleLoader } from '../../../utils/CircleLoader';
import * as Yup from 'yup';
import { AuthorizationContext } from '../../../auth';
import { IRouterParams } from '../../../routes';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PIMIconButton from '../../../shared/Components/PIMIconButton';
import MessageDialog from '../../../shared/Components/MessageDialog';

interface IProps {
  articleId: number;
  materialGroupId: number;
  articleResponse: ArticleResponse;
  countryCode: string;
  updateValue: (view: number, articleId?: number) => void;
  reload: () => void;
}

interface ArticleRequest {
  ArticleId: number;
  MaterialGroupId: number;
  ArticleDescription: string;
  ArticleNumber: string;
  EAN: string;
  ContainerType: string;
  ProductTypeCode: string;
  PalletNumberOfContainers: number | null;
  OverCartonNumberOfContainers: number | null;
  ArticleCountryNumber: string;
  DeletedArrangementLayoutIds: number[];
  AddedArrangementLayoutFiles: File[];
  IsGlobal: boolean;
}

interface ArticleFormData extends ArticleRequest {
  ArrangementLayouts: ArrangementLayoutResponse[];
}

interface ArticleCountryRequest {
  ArticleId: number;
  ArticleDescription: string;
  ProductTypeCode: string;
  ArticleCountryNumber: string;
  DeletedArrangementLayoutIds?: number[];
  AddedArrangementLayoutFiles?: File[];
}

interface ERPArticleRequest {
  ArticleId: number;
  ArticleCountryNumber: string;
  DeletedArrangementLayoutIds?: number[];
  AddedArrangementLayoutFiles?: File[];
  IsGlobal: boolean;
}

const MAX_ALLOWED_LAYOUTS = 5;

export function ArticleEditSidebar(props: IProps): ReactElement {
  const { articleId, materialGroupId, articleResponse, countryCode, updateValue, reload } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const api = useMurexinPimContextApi();
  const notification = React.useContext(NotificationContext);
  const { country } = useParams<IRouterParams>();
  const { hasCountryPermission } = useContext(AuthorizationContext);
  const isAuthorizedToWrite = hasCountryPermission(country, CountryPermission.Write);
  const readOnly = !isAuthorizedToWrite;
  const articleOwner = countryCode === articleResponse.countryCode;
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const editArticle = articleId > 0;
  const erpImport = articleResponse.erpImport;
  if (articleResponse.countryCode === null || articleResponse.countryCode === undefined) {
    articleResponse.countryCode = countryCode;
  }

  const productTypes = useAsync(async () => {
    const response = await api.articleService.articleServiceProductTypesDetail(countryCode);
    return response.data ?? [];
  }, [countryCode]);

  const mapArticleRequestToERPArticleRequest = (articleRequestModel: ArticleRequest) => {
    const erpArticleRequest: ERPArticleRequest = {
      ArticleId: articleRequestModel.ArticleId,
      ArticleCountryNumber: articleRequestModel.ArticleCountryNumber,
      DeletedArrangementLayoutIds: articleRequestModel.DeletedArrangementLayoutIds,
      AddedArrangementLayoutFiles: articleRequestModel.AddedArrangementLayoutFiles,
      IsGlobal: articleRequestModel.IsGlobal,
    };
    return erpArticleRequest;
  };

  const mapArticleRequestToArticleCountryRequest = (articleRequestModel: ArticleRequest) => {
    const articleCountryRequest: ArticleCountryRequest = {
      ArticleId: articleRequestModel.ArticleId,
      ArticleDescription: articleRequestModel.ArticleDescription,
      ProductTypeCode: articleRequestModel.ProductTypeCode,
      ArticleCountryNumber: articleRequestModel.ArticleCountryNumber,
      DeletedArrangementLayoutIds: articleRequestModel.DeletedArrangementLayoutIds,
      AddedArrangementLayoutFiles: articleRequestModel.AddedArrangementLayoutFiles,
    };
    return articleCountryRequest;
  };

  async function updateArticle(articleFormData: ArticleFormData) {
    // ArrangementLayouts needs to be removed from sent data to end point and
    // is therefore unused
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { ArrangementLayouts, ...articleRequestModel } = articleFormData;

    try {
      if ((articleOwner && !articleResponse.erpImport) || !editArticle) {
        if (articleRequestModel.ArticleNumber !== articleResponse.articleNumber) {
          articleRequestModel.ArticleNumber = countryCode + articleRequestModel.ArticleNumber;
        }
        await api.articleService.articleServiceArticleCreate(countryCode, articleId, articleRequestModel);
      } else if (articleOwner && articleResponse.erpImport) {
        const erpArticleRequest = mapArticleRequestToERPArticleRequest(articleRequestModel);
        await api.articleService.articleServiceErpArticleCreate(countryCode, articleId, erpArticleRequest);
      } else {
        const articleCountryRequest = mapArticleRequestToArticleCountryRequest(articleRequestModel);
        await api.articleService.articleServiceArticleCountryCreate(countryCode, articleId, articleCountryRequest);
      }

      updateValue(0, articleId);
      reload();
      notification.success(t('Speichern erfolgreich'));
    } catch (error) {
      notification.error(t('Fehler beim Speichern, im Zweifel im ERP ändern'));
    }
  }

  const onDeleteArticle = useAsyncCallback(async () => {
    setShowDeleteDialog(false);
    if (articleId > 0) {
      try {
        await api.articleService.articleServiceArticleDelete(countryCode, articleId);
        updateValue(0, 0);
        reload();
        notification.success(t('Löschen erfolgreich'));
      } catch (error) {
        notification.error(t('Fehler beim Löschen, im Zweifel im ERP ändern'));
      }
    }
  });

  const productTypeOptions =
    productTypes?.result?.map((productType) => ({
      key: productType.code,
      value: productType.code,
    })) ?? [];

  // TODO: no data for ContainerTypes available, list below is just for testing
  const containerTypeOptions = [
    { key: 'KE', value: t('Gebindeart_Abkürzung_KE') },
    { key: 'PS', value: t('Gebindeart_Abkürzung_PS') },
    { key: 'KTN', value: t('Gebindeart_Abkürzung_KTN') },
    { key: 'KKA', value: t('Gebindeart_Abkürzung_KKA') },
    { key: 'KFL', value: t('Gebindeart_Abkürzung_KFL') },
    { key: 'BDO', value: t('Gebindeart_Abkürzung_BDO') },
    { key: 'BFL', value: t('Gebindeart_Abkürzung_BFL') },
    { key: 'BHO', value: t('Gebindeart_Abkürzung_BHO') },
    { key: 'BKA', value: t('Gebindeart_Abkürzung_BKA') },
    { key: 'BLE', value: t('Gebindeart_Abkürzung_BLE') },
    { key: 'KDO', value: t('Gebindeart_Abkürzung_KDO') },
    { key: 'KTU', value: t('Gebindeart_Abkürzung_KTU') },
    { key: 'PPE', value: t('Gebindeart_Abkürzung_PPE') },
    { key: 'ROL', value: t('Gebindeart_Abkürzung_ROL') },
    { key: 'EH', value: t('Gebindeart_Abkürzung_EH') },
    { key: 'SET', value: t('Gebindeart_Abkürzung_SET') },
    { key: 'STK', value: t('Gebindeart_Abkürzung_STK') },
    { key: 'BFA', value: t('Gebindeart_Abkürzung_BFA') },
    { key: 'KFA', value: t('Gebindeart_Abkürzung_KFA') },
    { key: 'KHO', value: t('Gebindeart_Abkürzung_KHO') },
    { key: 'CON', value: t('Gebindeart_Abkürzung_CON') },
    { key: 'KS', value: t('Gebindeart_Abkürzung_KS') },
    { key: 'TB', value: t('Gebindeart_Abkürzung_TB') },
    { key: 'PAR', value: t('Gebindeart_Abkürzung_PAR') },
    { key: 'PKG', value: t('Gebindeart_Abkürzung_PKG') },
    { key: 'KG', value: t('Gebindeart_Abkürzung_KG') },
    { key: 'L', value: t('Gebindeart_Abkürzung_L') },
    { key: 'M', value: t('Gebindeart_Abkürzung_M') },
    { key: 'M2', value: t('Gebindeart_Abkürzung_M2') },
    { key: 'M3', value: t('Gebindeart_Abkürzung_M3') },
    { key: 'SAC', value: t('Gebindeart_Abkürzung_SAC') },
    { key: 'BB', value: t('Gebindeart_Abkürzung_BB') },
  ].sort();

  const articleNumberWithoutCountryCode =
    articleResponse.articleNumber !== null && articleResponse.articleNumber !== undefined
      ? articleResponse.articleNumber.substring(0, articleResponse.countryCode?.length ?? 0) ===
        articleResponse.countryCode
        ? articleResponse.articleNumber.substring(
            articleResponse.countryCode?.length ?? 0,
            articleResponse.articleNumber.length
          )
        : articleResponse.articleNumber
      : '';

  const initialValue: ArticleFormData = {
    ArticleId: articleResponse.id ?? 0,
    MaterialGroupId: materialGroupId,
    ArticleDescription: articleResponse.articleDescription ?? '',
    ArticleNumber: articleNumberWithoutCountryCode,
    EAN: articleResponse.ean ?? '',
    ContainerType: articleResponse.containerType ?? '',
    ProductTypeCode: articleResponse.productType?.code ?? '',
    PalletNumberOfContainers: articleResponse.palletNumberOfContainers ?? null,
    OverCartonNumberOfContainers: articleResponse.overCartonNumberOfContainers ?? null,
    ArticleCountryNumber: articleResponse.articleCountryNumber ?? '',
    DeletedArrangementLayoutIds: [],
    AddedArrangementLayoutFiles: [],
    ArrangementLayouts: articleResponse.arrangementLayouts ?? [],
    IsGlobal: articleResponse.isGlobal ?? true,
  };

  const eanCodeMaxLength = 13;
  const articleSchema = Yup.object().shape({
    ArticleDescription: Yup.string().required(t('Feld erforderlich')),
    ArticleNumber: Yup.string().matches(RegExp('^\\d+$'), t('Nur Ziffern erlaubt')).required(t('Feld erforderlich')),
    EAN: Yup.string()
      .matches(RegExp('^\\d+$'), t('Nur Ziffern erlaubt'))
      .max(eanCodeMaxLength, t('Feld maximal Stellen', { maxFieldLength: eanCodeMaxLength })),
    PalletNumberOfContainers: Yup.number()
      .min(1, t('Minimal erlaubter Wert ist', { minValue: 1 }))
      .max(999, t('Maximal erlaubter Wert ist', { maxValue: 999 }))
      .nullable(),
    OverCartonNumberOfContainers: Yup.number().typeError(t('Nur Ziffern erlaubt')).nullable(),
  });

  if (productTypes.loading) {
    return <CircleLoader open />;
  } else {
    return (
      <>
        <Grid container>
          <Grid item xs={12}>
            <Formik
              initialValues={initialValue}
              onSubmit={updateArticle}
              validationSchema={articleSchema}
              enableReinitialize>
              {({ values, isSubmitting, dirty, isValid, handleReset, handleSubmit, setFieldValue }) => (
                <Form>
                  <Grid container direction='row' alignItems='flex-start' className={classes.buttonContainer}>
                    {readOnly ? (
                      <PIMButton
                        className={classes.topButtons}
                        icon='close'
                        disabled={isSubmitting}
                        onClick={() => {
                          handleReset();
                          updateValue(0, 0);
                          reload();
                        }}>
                        {t('Schließen')}
                      </PIMButton>
                    ) : (
                      <>
                        <PIMButton
                          className={classes.topButtons}
                          icon='close'
                          disabled={isSubmitting}
                          onClick={() => {
                            notification.info(t('Daten zurücksetzen!'));
                            handleReset();
                            updateValue(0, 0);
                            reload();
                            history.push('0');
                          }}>
                          {t('Abbrechen')}
                        </PIMButton>
                        <PIMButton
                          color='primary'
                          icon='save'
                          className={classes.topButtons}
                          busy={isSubmitting}
                          disabled={isSubmitting || !dirty || !isValid}
                          onClick={() => {
                            handleSubmit();
                            history.push('0');
                          }}>
                          {t('Speichern')}
                        </PIMButton>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12} className={`${classes.titleContainer} ${classes.gridItem}`}>
                    <div className={classes.title}>
                      {readOnly
                        ? t('Artikel Details')
                        : editArticle
                        ? t('Artikel bearbeiten')
                        : t('Neuen Artikel hinzufügen')}
                    </div>
                    {erpImport ? <ERPIcon className={classes.erpIcon} /> : <NonERPIcon className={classes.erpIcon} />}
                  </Grid>
                  {erpImport ? (
                    <PIMInformationBox className={`${classes.titleContainer} ${classes.gridItem}`}>
                      <span>
                        {t('InfoAusERPTeil1')}
                        <br />
                        {t('InfoAusERPTeil2A')}
                      </span>
                    </PIMInformationBox>
                  ) : !articleOwner && editArticle ? (
                    <PIMInformationBox className={`${classes.titleContainer} ${classes.gridItem}`}>
                      <span>
                        {t('InfoAnderesLandTeil1')}
                        <br />
                        {t('InfoAnderesLandTeil2')}
                      </span>
                    </PIMInformationBox>
                  ) : null}
                  <Grid item xs={12}>
                    <FieldArray
                      name='ArrangementLayouts'
                      render={({ push, remove }) => {
                        const nothingToShowToReader = readOnly && values.ArrangementLayouts.length === 0;
                        return nothingToShowToReader ? null : (
                          <div className={classes.documents}>
                            <hr className={classes.documentsLine} />
                            {readOnly ? (
                              <div className={classes.subTitle}>{t('Gebindelayout Dokumente')}</div>
                            ) : (
                              <FileSelectButton
                                onFilesSelected={(files: File[]) => {
                                  push({
                                    articleId: values.ArticleId,
                                    countryCode,
                                    displayName: files[0].name,
                                    fileName: files[0].name,
                                  } as ArrangementLayoutResponse);
                                  setFieldValue('AddedArrangementLayoutFiles', [
                                    ...values.AddedArrangementLayoutFiles,
                                    files[0],
                                  ]);
                                }}
                                disabled={values.ArrangementLayouts.length >= MAX_ALLOWED_LAYOUTS}
                                color='secondary'
                                accept={['application/pdf']}
                                warningText={t('Nur PDFs sind erlaubt')}
                                text={t('Anordnungslayout')}
                              />
                            )}

                            {values.ArrangementLayouts?.map((layout: ArrangementLayoutResponse, index: number) => {
                              const isNewLayout = !layout.id;
                              const layoutDownloadLink = !layout.id
                                ? ''
                                : `/api/ArticleService/${countryCode}/arrangementLayout/${layout.id}`;

                              return (
                                <div key={index}>
                                  <PIMFormikTextField
                                    label={t('Gebindelayout Dokument', { documentNumber: index + 1 })}
                                    name={`ArrangementLayouts[${index}].displayName`}
                                    disabled
                                    fullWidth
                                  />
                                  <IconButton
                                    component={Link}
                                    disabled={isNewLayout}
                                    className={`${isNewLayout ? classes.disabled : ''} ${classes.iconOffset}`}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    href={layoutDownloadLink}>
                                    <LinkIcon />
                                  </IconButton>
                                  {readOnly ? null : (
                                    <PIMIconButton
                                      className={classes.iconOffset}
                                      disabled={readOnly}
                                      onClick={() => {
                                        if (!isNewLayout) {
                                          setFieldValue('DeletedArrangementLayoutIds', [
                                            ...values.DeletedArrangementLayoutIds,
                                            layout.id,
                                          ]);
                                        } else {
                                          const removedFile = values.AddedArrangementLayoutFiles.find(
                                            (file) => file.name === layout.fileName
                                          );
                                          const updatedFiles = values.AddedArrangementLayoutFiles.filter(
                                            (file) => file !== removedFile
                                          );
                                          setFieldValue('AddedArrangementLayoutFiles', updatedFiles);
                                        }
                                        remove(index);
                                      }}
                                      iconName='trash'
                                    />
                                  )}
                                </div>
                              );
                            })}
                            <hr className={classes.documentsLine} />
                          </div>
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.gridItem}>
                    <PIMFormikTextField
                      label={t('Artikelbezeichnung')}
                      name='ArticleDescription'
                      required
                      fullWidth
                      disabled={readOnly || (articleOwner && erpImport)}
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.gridItem}>
                    <PIMFormikTextField
                      label={t('Artikelnummer')}
                      name='ArticleNumber'
                      required
                      InputProps={{
                        startAdornment: <InputAdornment position='start'>{articleResponse.countryCode}</InputAdornment>,
                      }}
                      fullWidth
                      disabled={readOnly || erpImport || (!articleOwner && editArticle)}
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.gridItem}>
                    <PIMFormikTextField
                      label={t('EAN Code')}
                      name='EAN'
                      fullWidth
                      disabled={readOnly || erpImport || (!articleOwner && editArticle)}
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.gridItem}>
                    <PIMFormikSelect
                      name='ContainerType'
                      label={t('Gebindeart')}
                      options={containerTypeOptions}
                      formControlProps={{ fullWidth: true }}
                      disabled={readOnly || erpImport || (!articleOwner && editArticle)}
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.gridItem}>
                    <PIMFormikSelect
                      name='ProductTypeCode'
                      label={t('Produktart')}
                      options={productTypeOptions}
                      formControlProps={{ fullWidth: true }}
                      disabled={readOnly || (articleOwner && erpImport)}
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.gridItem}>
                    <PIMFormikTextField
                      label={t('Anzahl je Palette')}
                      name='PalletNumberOfContainers'
                      type='number'
                      fullWidth
                      disabled={readOnly || erpImport || (!articleOwner && editArticle)}
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.gridItem}>
                    <PIMFormikTextField
                      label={t('Überverpackung')}
                      name='OverCartonNumberOfContainers'
                      type='number'
                      fullWidth
                      disabled={readOnly || erpImport || (!articleOwner && editArticle)}
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.gridItem}>
                    <PIMFormikTextField
                      name='ArticleCountryNumber'
                      label={t('Zusätzliche Artikelnummer')}
                      fullWidth
                      disabled={readOnly}
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.gridItem}>
                    <PIMFormikToggle
                      name='IsGlobal'
                      label={t('Global verfügbar')}
                      disabled={readOnly || (!articleOwner && editArticle)}
                    />
                  </Grid>
                  {editArticle && !readOnly && !erpImport && articleOwner ? (
                    <>
                      <hr className={classes.line} />
                      <Grid item xs={12} className={`${classes.gridItem} ${classes.deleteButton}`}>
                        <PIMButton
                          color='warning'
                          icon={'trash'}
                          busy={onDeleteArticle.loading}
                          disabled={isSubmitting}
                          onClick={() => setShowDeleteDialog(true)}>
                          {t('Artikel löschen')}
                        </PIMButton>
                      </Grid>
                    </>
                  ) : null}
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
        <MessageDialog
          show={showDeleteDialog}
          messageText={t(`Möchten Sie diesen Artikel löschen?`)}
          title={t('Artikel löschen')}
          confirmColor='warning'
          confirmIcon='trash'
          confirmLabel={t('Löschen')}
          onClose={() => setShowDeleteDialog(false)}
          onCancel={() => setShowDeleteDialog(false)}
          onConfirm={onDeleteArticle.execute}
        />
      </>
    );
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  buttonContainer: {
    marginLeft: '40px',
    marginBottom: '24px',
    marginTop: '72px',
    width: '450px',
  },
  topButtons: { margin: theme.spacing(0, 3, 0, 0) },
  titleContainer: {
    marginBottom: '24px',
  },
  title: {
    fontSize: '20px;',
    font: 'Roboto',
    color: 'black',
    alignItems: 'center',
  },
  subTitle: {
    fontSize: '18px;',
    font: 'Roboto',
    color: 'black',
    alignItems: 'center',
  },
  documents: {
    '& .MuiFormControl-root': {
      width: 'calc(100% - 96px)',
    },
    margin: '24px 40px 0px',
  },
  documentsLine: {
    margin: '16px 0px',
  },
  iconOffset: {
    marginTop: '20px',
  },
  image: {
    marginTop: '20px',
    padding: '0 13px',
  },
  gridItem: {
    display: 'flex',
    marginLeft: '40px',
    marginRight: '40px',
  },
  disabled: { opacity: 0.5 },
  erpIcon: {
    height: '32px',
    marginLeft: '16px',
  },
  line: {
    margin: '16px 40px',
  },
  deleteButton: {
    marginTop: '24px',
    marginBottom: '40px',
  },
}));

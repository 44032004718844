import moment from 'moment';
import { CategoryResponse } from '../api';

class Utility {
  formatDateTimeUtc = (dateTime: Date): string => {
    return moment(dateTime).add(moment(dateTime).utcOffset(), 'minutes').format('DD.MM.YYYY HH:mm:ss');
  };

  formatDateTime = (dateTime: Date): string => {
    return moment(dateTime).format('DD.MM.YYYY HH:mm:ss');
  };

  formatDate = (dateTime: Date): string => {
    return moment(dateTime).format('DD.MM.YYYY');
  };

  // Based on following MDN entry. Note that NumberFormat is used in toLocalString.
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
  // For supported units see:
  // https://tc39.es/proposal-unified-intl-numberformat/section6/locales-currencies-tz_proposed_out.html#sec-issanctionedsimpleunitidentifier
  formatFileSize = (fileSize: number, locales?: string): string => {
    const byteDivisor = 1024;
    const byteUnits = ['byte', 'kilobyte', 'megabyte', 'gigabyte'];
    let unitIndex = 0;
    let displaySize = fileSize;

    while (displaySize > 1000 && unitIndex < byteUnits.length - 1) {
      displaySize = displaySize / byteDivisor;
      unitIndex++;
    }

    let fractionDigits = 0;

    if (displaySize < 100) {
      fractionDigits = displaySize < 10 ? 2 : 1;
    }

    const unit = byteUnits[unitIndex];
    return displaySize.toLocaleString(locales, {
      style: 'unit',
      unit,
      maximumFractionDigits: fractionDigits,
    });
  };

  getFileExtension = (fileName: string): string | undefined => {
    return fileName.split('.').pop();
  };

  // This is meant as best guess on content based on file extension.
  // Source for image types: https://developer.mozilla.org/en-US/docs/Web/Media/Formats/Image_types
  isImageFile = (fileName: string): boolean => {
    const imageFileExtensions = [
      'apng',
      'avif',
      'gif',
      'jpg',
      'jpeg',
      'jfif',
      'pjpeg',
      'pjp',
      'png',
      'svg',
      'webp',
      'bmp',
      'ico',
    ];
    const fileExtension = this.getFileExtension(fileName.toLocaleLowerCase());

    return !!fileExtension && imageFileExtensions.includes(fileExtension);
  };

  moveItemToStart = <T>(array: T[], findItem: (item: T) => boolean): void => {
    const itemIndex = array.findIndex(findItem);

    if (itemIndex > 0) {
      const [item] = array.splice(itemIndex, 1);
      array.unshift(item);
    }
  };

  sortOptionalString = <T>(array: T[], getString: (item: T) => string | null | undefined): void => {
    array.sort((a, b) => (getString(a) ?? '').localeCompare(getString(b) ?? ''));
  };

  sortCategoriesResponse = (categories: CategoryResponse[]): void => {
    this.sortOptionalString(categories, (c) => c.name);
    this.moveItemToStart(categories, (c) => c.name === 'General');
  };
}

export default new Utility();

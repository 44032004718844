import React, { useContext, useState } from 'react';
import { ProductHintRequest, ProductHintResponse, useMurexinPimContextApi } from '../../../../api';
import { useAsync } from 'react-async-hook';
import { useTranslation } from 'react-i18next';
import { Grid, makeStyles } from '@material-ui/core';
import { CircleLoader } from '../../../../utils/CircleLoader';
import { FieldArray, FieldArrayRenderProps, Form, Formik } from 'formik';
import { NotificationContext } from '../../../../contexts';
import { AdminBreadCrumbs } from '../../../../layouts/AdminBreadCrumbs/AdminBreadCrumbs';
import PIMButton from '../../../../shared/Components/PIMButton';
import PIMTextField from '../../../../shared/Components/PIMTextField';
import { TemplateItem } from '../TemplateItem';

export function ProductHint(): JSX.Element {
  const api = useMurexinPimContextApi();
  const notification = useContext(NotificationContext);
  const classes = useStyles();
  const { t } = useTranslation();
  const header = t('Produkthinweisvorlagen');
  const [searchText, setSearchText] = useState('');
  const [temporaryId, setTemporaryId] = useState(-1); // apply negative Id values to new and duplicated entries to be used as unique identifier

  const countriesResponse = useAsync(async () => {
    const countryListResponse = await api.countryService.countryServiceList();
    return countryListResponse.data ?? [];
  }, []);

  const productHints = useAsync(async () => {
    const result = await api.admin.adminProductHintList();
    return result?.data ?? [];
  }, []);

  const updateProductHints = async (values: ProductHintRequest[]) => {
    const titleValidationError = validateTitle(values);

    if (titleValidationError != null) {
      notification.error(titleValidationError);
      return;
    }

    try {
      const request = values.map((productHint: ProductHintRequest) => ({
        ...productHint,
        id: productHint.id !== undefined && productHint.id >= 0 ? productHint.id : 0,
      }));

      await api.admin.adminProductHintCreate(request);
      notification.success(t('Speichern erfolgreich'));
      await productHints.execute();
    } catch {
      notification.error(t('Speichern fehlgeschlagen'));
    }
  };

  const validateTitle = (values: ProductHintRequest[]) => {
    if (values.some((productHint) => productHint.title === '' || productHint.title === undefined)) {
      return t('Titelfeld erforderlich');
    } else if (values.some((productHint) => productHint.title && productHint.title.length > 250)) {
      return t('Titelfeld zu lang');
    } else if (new Set(values.map((s) => s.title)).size !== values.length) {
      return t('Titelfeld nicht eindeutig');
    } else {
      return null;
    }
  };

  const filterSearchResults = (values: ProductHintResponse[]) => {
    return searchText === ''
      ? values
      : values?.filter((hint) => {
          return (
            hint.title?.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) ||
            hint.content?.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
          );
        });
  };

  const getProductHintIndex = (values: ProductHintResponse[], hint: ProductHintResponse) =>
    values.findIndex((p: ProductHintResponse) => p.id === hint.id);

  const getUniqueTitle = (title: string, suffix: string, values: ProductHintResponse[]) => {
    const titleUnique = (s: string) => s === '' || !values.some((value) => value.title === s);

    let newTitle = `${title}${suffix}`;

    if (!titleUnique(newTitle)) {
      let index = 1;
      while (!titleUnique(newTitle.concat(` (${index})`))) {
        index += 1;
      }

      newTitle = newTitle.concat(` (${index})`);
    }

    return newTitle;
  };

  const addHint = (arrayHelpers: FieldArrayRenderProps) => {
    arrayHelpers.insert(0, {
      id: temporaryId,
      title: '',
      content: '',
    } as ProductHintResponse);
    setTemporaryId(temporaryId - 1);
  };

  const duplicateHint = (
    arrayHelpers: FieldArrayRenderProps,
    hint: ProductHintResponse,
    values: ProductHintResponse[]
  ) => {
    arrayHelpers.insert(0, {
      id: temporaryId,
      title: getUniqueTitle(hint.title ?? '', ` ${t('Kopie')}`, values),
      content: hint.content,
    });
    setTemporaryId(temporaryId - 1);
  };

  const deleteHint = (
    arrayHelpers: FieldArrayRenderProps,
    hint: ProductHintResponse,
    values: ProductHintResponse[]
  ) => {
    if (values) {
      const index = getProductHintIndex(values, hint);

      if (index >= 0) {
        arrayHelpers.remove(index);
      }
    }
  };

  function renderHints(arrayHelpers: FieldArrayRenderProps, values: ProductHintResponse[]): React.ReactNode {
    const filteredHints = filterSearchResults(values);

    return (
      <>
        {filteredHints?.map((hint: ProductHintResponse) => (
          <TemplateItem
            key={hint.id}
            duplicateItem={() => duplicateHint(arrayHelpers, hint, values)}
            deleteItem={() => deleteHint(arrayHelpers, hint, values)}
            textFieldPrefix={`productHints[${getProductHintIndex(values, hint)}]`}
          />
        ))}
      </>
    );
  }

  return (
    <>
      <AdminBreadCrumbs site={header} />
      <CircleLoader open={countriesResponse.loading || productHints.loading} />
      <Formik
        initialValues={{ productHints: productHints.result ?? [] }}
        onSubmit={async (formikValues) => await updateProductHints(formikValues.productHints as ProductHintRequest[])}
        enableReinitialize={true}>
        {(formik) => (
          <Form>
            <Grid container className={classes.Container}>
              <FieldArray
                name='productHints'
                render={(arrayHelpers) => (
                  <>
                    <Grid container item xs={12}>
                      <Grid item xs={6}>
                        <div className={classes.Title}>{header}</div>
                      </Grid>
                      <Grid container item xs={6} className={classes.ActionButtonRow}>
                        <PIMButton
                          className={classes.TopButtons}
                          icon='close'
                          type='reset'
                          disabled={!formik.dirty}
                          onClick={() => {
                            formik.resetForm();
                            notification.info(t('Daten zurückgesetzt'));
                          }}>
                          {t('Abbrechen')}
                        </PIMButton>
                        <PIMButton
                          color='primary'
                          icon='save'
                          className={classes.TopButtons}
                          busy={formik.isSubmitting}
                          disabled={!formik.dirty}
                          type='submit'>
                          {t('Speichern')}
                        </PIMButton>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <PIMButton
                        icon='add'
                        color='primary'
                        onClick={() => addHint(arrayHelpers)}
                        className={classes.Field}>
                        {t('Hinzufügen')}
                      </PIMButton>
                    </Grid>
                    <Grid container>
                      <PIMTextField
                        label={t('Suche')}
                        className={classes.SearchTextField}
                        fullWidth
                        onChange={(event) => {
                          setSearchText(event.target.value ?? '');
                        }}
                      />
                    </Grid>
                    <Grid container item xs={12} className={classes.Panel} spacing={3}>
                      {renderHints(arrayHelpers, formik.values.productHints)}
                    </Grid>
                  </>
                )}
              />
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}

const useStyles = makeStyles(() => ({
  Container: {
    marginTop: '40px',
    paddingLeft: '16px',
    paddingRight: '24px',
  },
  Title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '36px',
    marginBottom: '20px',
  },
  ActionButtonRow: {
    flexWrap: 'nowrap',
    justifyContent: 'flex-end',
    paddingBottom: '32px',
  },
  TopButtons: {
    marginLeft: '24px',
  },
  Panel: {
    background: 'white',
    padding: '16px 24px',
    margin: '16px 0px',
  },
  SearchTextField: {
    maxWidth: '600px',
    margin: '16px 24px 16px 0px',
  },
  Field: {
    margin: '16px 24px 16px 0px',
  },
}));

import React, { useState } from 'react';
import { createStyles, makeStyles, Grid } from '@material-ui/core';
import ListView from '../../../shared/Components/ListView';
import { GridColumns } from '@mui/x-data-grid';
import { useAsync } from 'react-async-hook';
import { DivisionCountryResponseModel, useMurexinPimContextApi } from '../../../api';
import PIMTextField from '../../../shared/Components/PIMTextField';
import { useTranslation } from 'react-i18next';
import { IRouterParams } from '../../../routes';
import { useParams } from 'react-router-dom';
import { CircleLoader } from '../../../utils/CircleLoader';
import { CountryAdminBreadCrumbs } from '../../../layouts/CountryAdminBreadCrumbs/CountryAdminBreadCrumbs';
import { DivisionDescriptionEditSidebar } from './DivisionDescriptionEditSidebar';

export function DivisionDescription(): JSX.Element {
  const classes = useStyles();
  const api = useMurexinPimContextApi();
  const [selectedDivisionCountry, setSelectedDivisionCountry] = useState<undefined | DivisionCountryResponseModel>();
  const [searchText, setSearchText] = useState<string>('');
  const { t } = useTranslation();
  const { country } = useParams<IRouterParams>();

  let divisionCountriesList: DivisionCountryResponseModel[] = [];

  const countryName = useAsync(async () => {
    const response = await api.countryService.countryServiceList();
    return response.data.find((c) => c.code === country)?.name;
  }, [country]);

  const getDivisionCountries = useAsync(async () => {
    const response = await api.divisionService.divisionServiceGetDivisionCountriesByCountryCodeDetail(country);
    return response.data ?? [];
  }, [country]);

  if (getDivisionCountries.result && getDivisionCountries.result.length > 0) {
    divisionCountriesList = getDivisionCountries.result;
  }

  const columnDefinition: GridColumns = [
    { field: 'id', headerName: '', hide: true },
    { field: 'divisionName', headerName: t('Sparten-Code'), width: 200 },
    { field: 'originalDivisionDescription', headerName: t('Spartenbezeichnung Original'), width: 400 },
    {
      field: 'divisionCountryDescription',
      headerName: `${t('Spartenbezeichnung')} ${countryName.result ?? country}`,
      width: 400,
    },
  ];

  if (searchText) {
    divisionCountriesList = divisionCountriesList.filter((item) => {
      return (
        item.divisionName?.toLowerCase().includes(searchText.toLowerCase()) ||
        item.originalDivisionDescription?.toLowerCase().includes(searchText.toLowerCase()) ||
        item.divisionCountryDescription?.toLowerCase().includes(searchText.toLowerCase())
      );
    });
  }

  return (
    <>
      <CircleLoader open={getDivisionCountries.loading} />
      <Grid container className={classes.Container}>
        <CountryAdminBreadCrumbs site={t('Spartenbezeichnungen')} />
        <Grid container item xs={12} className={classes.DivisionCountryPage}>
          <Grid item xs={selectedDivisionCountry !== undefined ? 8 : 12} className={classes.DivisionCountryGrid}>
            <div className={classes.Title}>{t('Spartenbezeichnungen')}</div>
            <Grid item xs={12}>
              <PIMTextField
                label={t('Element suchen')}
                className={classes.SearchTextField}
                fullWidth
                onChange={(event) => setSearchText(event.target.value ?? '')}
              />
            </Grid>
            <Grid item xs={12}>
              <ListView
                columns={columnDefinition}
                data={divisionCountriesList}
                onChangeSelection={(item) => {
                  setSelectedDivisionCountry(item);
                }}
                selectedItem={selectedDivisionCountry}
              />
            </Grid>
          </Grid>
          {selectedDivisionCountry && (
            <Grid item xs={4} className={classes.Sidebar}>
              <DivisionDescriptionEditSidebar
                countryCode={country}
                countryName={countryName.result ?? country}
                spartenModel={selectedDivisionCountry}
                onClose={() => {
                  setSelectedDivisionCountry(undefined);
                }}
                onSave={async () => {
                  await getDivisionCountries.execute();
                  setSelectedDivisionCountry(undefined);
                }}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    DivisionCountryPage: {
      padding: '24px 0px 0px 8px',
    },
    DivisionCountryGrid: {
      paddingRight: '24px',
    },
    SearchTextField: {
      marginBottom: '24px',
    },
    TitleRow: {
      fontSize: '36px',
      padding: '0 0 24px 0',
    },
    Sidebar: {
      minHeight: '100%',
      marginTop: '-100px',
    },
    Container: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      margin: '0px 0px 0px 0px',
    },
    Title: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '36px',
      marginBottom: '24px',
      lineHeight: '45px',
    },
  })
);

import React, { ReactElement } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    Tabpanel: {
      height: '100%',
      flex: 1,
    },
    Box: {
      padding: '0px',
      paddingLeft: '43px',
      height: '100%',
      width: '100%',
    },
  })
);

interface PIMTabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function PIMTabPanel(props: PIMTabPanelProps): ReactElement {
  const { children, value, index, ...other } = props;
  const classes = useStyles();

  return (
    <>
      {value === index && (
        <Grid container {...other} className={classes.Tabpanel}>
          {value === index && (
            <Box p={3} className={classes.Box}>
              {children}
            </Box>
          )}
        </Grid>
      )}
    </>
  );
}

import { Grid, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import { MaterialGroupTreeResponse } from '../../../../api';
import { ExpansionTrackingAccordion } from '../../../../shared/Components/Accordion';
import PIMAccordionDetails from '../../../../shared/Components/PIMAccordionDetails';
import PIMAccordionSummary from '../../../../shared/Components/PIMAccordionSummary';
import PIMCheckbox from '../../../../shared/Components/PIMCheckbox';
import { AccordionProductGroup } from './DynamicExport';

interface IProps {
  accordionProductGroups: AccordionProductGroup[];
  searchText: string;
  handleSelectProductGroup: (productGroup: AccordionProductGroup, checked: boolean) => void;
  handleSelectMaterialGroup: (
    productGroup: AccordionProductGroup,
    materialGroup: MaterialGroupTreeResponse,
    checked: boolean
  ) => void;
  materialGroupChecked: (materialGroup: MaterialGroupTreeResponse) => boolean;
}

export function ProductGroupAccordions(props: IProps): JSX.Element {
  const classes = useStyles();
  const {
    accordionProductGroups,
    searchText,
    handleSelectProductGroup,
    handleSelectMaterialGroup,
    materialGroupChecked,
  } = props;

  function renderProductGroupAccordions(): React.ReactNode {
    const productGroups = accordionProductGroups;
    const isSearching = searchText !== '';
    const isExpanded = isSearching ? productGroups.length > 0 : undefined;

    return productGroups.map((productGroup) => {
      return (
        <ExpansionTrackingAccordion
          key={productGroup.id}
          className={classes.ProductGroupAccordion}
          expanded={isExpanded}>
          <PIMAccordionSummary className={classes.AccordionSummary}>
            <PIMCheckbox
              key={productGroup.id}
              id={productGroup.name}
              color='primary'
              checked={productGroup.isChecked}
              indeterminate={productGroup.isIndeterminate}
              onClick={(e) => e.stopPropagation()}
              onChange={(_, checked: boolean) => handleSelectProductGroup(productGroup, checked)}
            />
            <Typography className={classes.Summary}>{productGroup.description}</Typography>
          </PIMAccordionSummary>
          <PIMAccordionDetails key={productGroup.id} className={classes.AccordionDetails}>
            {productGroup.materialGroups.map((materialGroup) => {
              return (
                <Grid container className={classes.MaterialGroupList} key={materialGroup.id}>
                  <PIMCheckbox
                    key={materialGroup.id}
                    color='primary'
                    checked={materialGroupChecked(materialGroup) ?? false}
                    onChange={(_, checked: boolean) => handleSelectMaterialGroup(productGroup, materialGroup, checked)}
                  />
                  <Typography className={classes.Summary}>{materialGroup.description}</Typography>
                </Grid>
              );
            })}
          </PIMAccordionDetails>
        </ExpansionTrackingAccordion>
      );
    });
  }

  return <>{renderProductGroupAccordions()}</>;
}

const useStyles = makeStyles(() => ({
  ProductGroupAccordion: {
    width: '100%',
    borderBottom: 'none',
    boxShadow: '0px 1px 0px 0px #f8f8f8',
    '&.MuiAccordion-root:before': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
    '& .Mui-expanded': {
      borderBottom: 'none',
      paddingTop: '2px',
      paddingBottom: '2px',
    },
  },
  MaterialGroupList: {
    height: '50px !important',
    minHeight: '50px !important',
    paddingLeft: '48px',
    boxShadow: '0px 1px 0px 0px #f8f8f8',
  },
  AccordionDetails: {
    display: 'grid',
    padding: 0,
    boxShadow: '0px 1px 0px 0px #f8f8f8',
  },
  AccordionSummary: {
    height: '50px !important',
    minHeight: '50px !important',
    boxShadow: '0px 1px 0px 0px #f8f8f8',
  },
  Summary: {
    fontSize: '14px',
    alignSelf: 'center',
  },
}));

import React from 'react';

export function SendIcon(): JSX.Element {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.25 15.75H2.25C1.85218 15.75 1.47064 15.592 1.18934 15.3107C0.908035 15.0294 0.75 14.6478 0.75 14.25V2.25C0.75 1.85218 0.908035 1.47064 1.18934 1.18934C1.47064 0.908035 1.85218 0.75 2.25 0.75H20.25C20.6478 0.75 21.0294 0.908035 21.3107 1.18934C21.592 1.47064 21.75 1.85218 21.75 2.25V11.25'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1.11804 1.26898L9.22904 7.46898C9.78155 7.97239 10.5021 8.25142 11.2495 8.25142C11.997 8.25142 12.7175 7.97239 13.27 7.46898L21.38 1.26898'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.25 12L23.25 16.5L17.25 21V18.75H12.75C12.3522 18.75 11.9706 18.592 11.6893 18.3107C11.408 18.0294 11.25 17.6478 11.25 17.25V15.75C11.25 15.3522 11.408 14.9706 11.6893 14.6893C11.9706 14.408 12.3522 14.25 12.75 14.25H17.25V12Z'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

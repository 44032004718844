import React from 'react';
import { Omit } from '@material-ui/types';
import { makeStyles, Theme, IconButton, IconButtonProps } from '@material-ui/core';
import { IconName, getIcon } from '../../icons';

interface Props {
  iconName?: IconName;
  busy?: boolean;
}

export function PIMIconButton(props: Props & Omit<IconButtonProps, keyof Props>): JSX.Element {
  const { className, busy, disabled, iconName, style, ...other } = props;
  const classes = useStyles(props);
  const iconComponent = getIcon(iconName);

  return (
    <div className={classes.wrapper} style={style}>
      <IconButton
        className={`${classes.iconButton} ${iconName === 'trash' ? classes.trashIcon : ''} ${className ?? ''}`}
        disabled={busy || disabled}
        {...other}>
        {iconComponent}
      </IconButton>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: 'relative',
    display: 'inline',
  },
  iconButton: { minHeight: '24px' },
  trashIcon: {
    '&.MuiIconButton-root:hover': {
      backgroundColor: theme.palette.warning.main,
    },
  },
}));

import { DocumentCategory, DocumentLevel } from '../api/MurexinPimApi';

export interface IDocumentCategory {
  id: DocumentCategory;
  name: string;
}

export const documentCategories: IDocumentCategory[] = [
  {
    id: DocumentCategory.TechnicalDataSheet,
    name: 'Technisches Merkblatt',
  },
  {
    id: DocumentCategory.ExternalLayout,
    name: 'Fremdaufmachung',
  },
  {
    id: DocumentCategory.SafetyDataSheet,
    name: 'Sicherheitsdatenblätter',
  },
  {
    id: DocumentCategory.DeclarationOfPerformance,
    name: 'Leistungserklärungen',
  },
  {
    id: DocumentCategory.InfoSheet,
    name: 'Infoblätter',
  },
  {
    id: DocumentCategory.TestCertificate,
    name: 'Prüfzeugnisse',
  },
  {
    id: DocumentCategory.PrintingData,
    name: 'Drucksorten',
  },
  {
    id: DocumentCategory.Documents,
    name: 'Dokumente',
  },
  {
    id: DocumentCategory.Pictures,
    name: 'Bilder',
  },
];

export interface IDocumentLevel {
  id: DocumentLevel;
  name: string;
}

export const documentLevels: IDocumentLevel[] = [
  {
    id: DocumentLevel.Division,
    name: 'Sparte',
  },
  {
    id: DocumentLevel.MaterialGroup,
    name: 'Warengruppe',
  },
  {
    id: DocumentLevel.ProductGroup,
    name: 'Produktgruppe',
  },
];

export enum DynamicExportEnum {
  MaterialGroup = 0,
  Article = 1,
}

export interface IDynamicExportType {
  id: DynamicExportEnum;
  name: string;
}

export const dynamicExportTypes: IDynamicExportType[] = [
  {
    id: DynamicExportEnum.MaterialGroup,
    name: 'Warengruppe',
  },
  {
    id: DynamicExportEnum.Article,
    name: 'Artikel',
  },
];

import * as React from 'react';
import { CardContent, Grid, makeStyles } from '@material-ui/core';
import { CountryPermission, MaterialGroupResponse, ProductGroupResponse } from '../../../api/MurexinPimApi';
import { NavLink, useParams } from 'react-router-dom';
import { routes } from '../../../routes/routes';
import { PIMCard } from '../../../shared/Components/PIMCard';
import PIMButton from '../../../shared/Components/PIMButton';
import { IRouterParams } from '../../../routes';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { AuthorizationContext } from '../../../auth';
import { IAdminMenuItem } from '../../../shared/interfaces';

interface IProps {
  handleOnAddMaterialGroup: () => void;
  materialGroupResponse: MaterialGroupResponse[];
  productGroup: ProductGroupResponse | undefined;
}

export function MaterialGrid(props: IProps): JSX.Element {
  const { handleOnAddMaterialGroup, materialGroupResponse, productGroup } = props;
  const classes = useStyles();
  const { country, divisionId, productId } = useParams<IRouterParams>();
  const { t } = useTranslation();
  const { hasCountryPermission } = useContext(AuthorizationContext);
  const isAuthorizedToCreate = hasCountryPermission(country, CountryPermission.CreateMaterialGroup);
  const isAuthorizedToEdit =
    hasCountryPermission(country, CountryPermission.Write) ||
    hasCountryPermission(country, CountryPermission.DeleteMaterialGroup) ||
    hasCountryPermission(country, CountryPermission.UpdateMaterialGroup);

  const showAdministrationPanel = isAuthorizedToCreate || isAuthorizedToEdit;

  const adminNav: IAdminMenuItem[] = [
    {
      label: t('Warengruppen'),
      sublabel: t('Bearbeiten und Löschen'),
      link: (countryCode: string) => routes.materialGroupDescriptionRoute(countryCode, divisionId, productId),
    },
  ];

  return (
    <Grid container>
      <Grid container item xs={12}>
        <Grid item xs={6} className={classes.MaterialGrid}>
          <Grid item xs={12}>
            <div className={classes.Title}>
              {productGroup?.productGroupCountryDescription ?? productGroup?.productGroupDescription}
            </div>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          {showAdministrationPanel && <div className={classes.Title}>{t('Administration')}</div>}
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={6} className={classes.MaterialGrid}>
          <Grid item xs={10}>
            {materialGroupResponse.map((material, key) => (
              <NavLink
                key={key}
                style={{ textDecoration: 'none' }}
                to={routes.articles.selected(country, divisionId, productId, material.id?.toString(), '0')}>
                <PIMCard>
                  <CardContent className={classes.CardContent}>
                    <span className={classes.CardTitle}>
                      {!!material.materialGroupTitle ? material.materialGroupTitle : material.materialGroupDescription}
                    </span>
                    <span className={classes.CardSubTitle}>{material.materialGroupName}</span>
                  </CardContent>
                </PIMCard>
              </NavLink>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={6} className={classes.MaterialGrid}>
          <Grid item xs={10}>
            {country !== 'AT' && isAuthorizedToCreate && (
              <PIMButton
                color='primary'
                icon='add'
                onClick={() => handleOnAddMaterialGroup()}
                className={classes.AddMaterialGroupButton}>
                {t('Materialgruppe hinzufügen')}
              </PIMButton>
            )}
            {isAuthorizedToEdit && adminNav ? (
              adminNav.map((item, index) => (
                <NavLink key={index} style={{ textDecoration: 'none' }} to={item.link(country)}>
                  <PIMCard>
                    <CardContent className={classes.CardContent}>
                      <span className={classes.CardTitle}>{item.label} </span>
                      <span className={classes.CardSubTitle}> {item.sublabel}</span>
                    </CardContent>
                  </PIMCard>
                </NavLink>
              ))
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  MaterialGrid: {
    paddingRight: '24px',
  },
  CardContent: {
    display: 'flex',
    flexDirection: 'column',
    margin: '8px 16px 8px 16px',
    padding: '0px',
    '&.MuiCardContent-root:last-child': {
      padding: '0px',
    },
  },
  CardTitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '20px',
    marginBottom: '4px',
    color: '#000000',
  },
  CardSubTitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontSize: '14px',
    fontWeight: 400,
    color: '#000000',
    opacity: '0.6',
  },
  Title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '36px',
    marginBottom: '24px',
  },
  AddMaterialGroupButton: {
    '& .MuiButton-root': {
      marginBottom: '20px',
    },
  },
}));

import { Grid, makeStyles } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { Form, Formik, FormikProps, FormikValues } from 'formik';
import PIMButton from '../PIMButton';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

interface ISidebarProps<T> {
  formikInitialValues: T;
  title?: string;
  cancelButtonText?: string;
  saveButtonText?: string;
  handleOnSubmit?: (formikValues: T) => Promise<void>;
  handleOnReset?: () => void;
  handleOnCancel?: () => void;
  validationSchema?: Yup.ObjectSchema;
  children?: ReactElement;
  render?: (formik: FormikProps<T>) => ReactElement;
}

export function PIMFormikSidebar<T extends FormikValues>(props: ISidebarProps<T>): ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();
  const { children, handleOnSubmit, handleOnReset, handleOnCancel, render } = props;

  const handleResetOnClick = (formik: FormikProps<T>) => {
    formik.handleReset();
    if (handleOnCancel) {
      handleOnCancel();
    }
  };

  return (
    <Grid container className={classes.Container}>
      <Formik
        initialValues={props.formikInitialValues}
        onSubmit={async (formikValues) => {
          if (handleOnSubmit && formikValues) {
            await handleOnSubmit(formikValues);
          }
        }}
        enableReinitialize={true}
        validationSchema={props.validationSchema}
        onReset={handleOnReset}>
        {(formik) => (
          <Form className={classes.Form}>
            <Grid item xs={12} className={classes.ActionButtonRow}>
              <PIMButton
                icon='close'
                className={classes.TopButtons}
                disabled={formik.isSubmitting}
                type='reset'
                onClick={() => handleResetOnClick(formik)}>
                {props.cancelButtonText ?? t('Schließen')}
              </PIMButton>
              <PIMButton
                color='primary'
                icon='save'
                busy={formik.isSubmitting}
                disabled={!formik.dirty || !formik.isValid}
                type='submit'>
                {props.saveButtonText ?? t('Speichern')}
              </PIMButton>
            </Grid>
            {props.title && (
              <Grid item xs={12} className={classes.Title}>
                <div>{props.title}</div>
              </Grid>
            )}
            <Grid item xs={12}>
              {render ? render(formik) : children}
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  Container: {
    backgroundColor: 'white',
    padding: '72px 40px 24px 40px',
    height: '100%',
  },
  ActionButtonRow: {
    marginBottom: '32px',
  },
  TopButtons: {
    marginRight: '24px',
  },
  Title: {
    marginBottom: '24px',
    fontSize: '20px',
    lineHeight: '120%',
    fontWeight: 400,
  },
  Form: {
    width: '100%',
  },
}));

import React from 'react';

export function DownIcon(): JSX.Element {
  return (
    <svg width='24' height='14' viewBox='0 0 24 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2.92629 0.884632L11.6966 9.64978C11.7364 9.68969 11.7837 9.72135 11.8358 9.74296C11.8878 9.76456 11.9436 9.77568 12 9.77568C12.0564 9.77568 12.1122 9.76456 12.1643 9.74296C12.2163 9.72135 12.2636 9.68969 12.3034 9.64978L21.0737 0.884632C21.2295 0.712379 21.4186 0.573565 21.6296 0.476614C21.8406 0.379663 22.0691 0.326597 22.3013 0.320636C22.5334 0.314675 22.7644 0.355944 22.9801 0.441936C23.1958 0.52793 23.3918 0.656854 23.5562 0.820887C23.7206 0.984921 23.8499 1.18064 23.9364 1.39618C24.0228 1.61171 24.0646 1.84256 24.0591 2.07472C24.0536 2.30688 24.0011 2.53551 23.9046 2.74674C23.8081 2.95796 23.6696 3.14738 23.4977 3.30349L13.8189 12.9823C13.5801 13.2213 13.2965 13.4109 12.9844 13.5402C12.6723 13.6695 12.3378 13.7361 12 13.7361C11.6622 13.7361 11.3277 13.6695 11.0156 13.5402C10.7035 13.4109 10.42 13.2213 10.1812 12.9823L0.502293 3.30349C0.33037 3.14738 0.191957 2.95796 0.0954552 2.74674C-0.00104713 2.53551 -0.053627 2.30688 -0.0590954 2.07472C-0.0645638 1.84256 -0.0228043 1.61171 0.0636463 1.39618C0.150097 1.18064 0.279438 0.984921 0.443821 0.820887C0.608204 0.656854 0.804199 0.52793 1.01992 0.441936C1.23563 0.355944 1.46657 0.314675 1.69872 0.320636C1.93087 0.326597 2.15939 0.379663 2.37041 0.476614C2.58142 0.573565 2.77055 0.712379 2.92629 0.884632Z'
        fill='currentColor'
      />
    </svg>
  );
}

import { Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import { MaterialGroupTreeResponse } from '../../../../api';
import { ExpansionTrackingAccordion } from '../../../../shared/Components/Accordion';
import PIMAccordionDetails from '../../../../shared/Components/PIMAccordionDetails';
import PIMAccordionSummary from '../../../../shared/Components/PIMAccordionSummary';
import PIMCheckbox from '../../../../shared/Components/PIMCheckbox';
import { AccordionDivision, AccordionProductGroup } from './DynamicExport';
import { ProductGroupAccordions } from './ProductGroupAccordions';

interface IProps {
  accordionDivisions: AccordionDivision[];
  searchText: string;
  handleSelectDivision: (division: AccordionDivision, checked: boolean) => void;
  handleSelectProductGroup: (
    division: AccordionDivision,
    productGroup: AccordionProductGroup,
    checked: boolean
  ) => void;
  handleSelectMaterialGroup: (
    division: AccordionDivision,
    productGroup: AccordionProductGroup,
    materialGroup: MaterialGroupTreeResponse,
    checked: boolean
  ) => void;
  materialGroupChecked: (materialGroup: MaterialGroupTreeResponse) => boolean;
}

export function DynamicExportAccordions(props: IProps): JSX.Element {
  const classes = useStyles();
  const {
    accordionDivisions,
    searchText,
    handleSelectDivision,
    handleSelectProductGroup,
    handleSelectMaterialGroup,
    materialGroupChecked,
  } = props;

  const getDivisionsFilterBySearchText = () => {
    if (searchText === '') return accordionDivisions;

    const searchValue = searchText.toLocaleLowerCase();
    const filteredDivisions = new Array<AccordionDivision>();

    accordionDivisions.forEach((division) => {
      const filteredProductGroups = new Array<AccordionProductGroup>();
      division.productGroups.forEach((pg) => {
        const filteredMaterialGroups = pg.materialGroups.filter((mg) =>
          mg.description?.toLocaleLowerCase().includes(searchValue)
        );
        if (filteredMaterialGroups.length > 0 || pg.description?.toLocaleLowerCase().includes(searchValue)) {
          filteredProductGroups.push({ ...pg, materialGroups: filteredMaterialGroups } as AccordionProductGroup);
        }
      });

      if (filteredProductGroups.length > 0 || division.description?.toLocaleLowerCase().includes(searchValue)) {
        filteredDivisions.push({ ...division, productGroups: filteredProductGroups } as AccordionDivision);
      }
    });

    return filteredDivisions;
  };

  function renderDivisionAccordions(): React.ReactNode {
    const isSearching = searchText !== '';
    const divisions = isSearching ? getDivisionsFilterBySearchText() : accordionDivisions;
    const isExpanded = isSearching ? divisions.length > 0 : undefined;

    return divisions.map((division) => {
      return (
        <ExpansionTrackingAccordion key={division.id} className={classes.DivisionAccordion} expanded={isExpanded}>
          <PIMAccordionSummary className={classes.AccordionSummary}>
            <PIMCheckbox
              key={division.id}
              color='primary'
              checked={division.isChecked ?? false}
              indeterminate={division.isIndeterminate ?? false}
              onClick={(e) => e.stopPropagation()}
              onChange={(_, checked: boolean) => {
                handleSelectDivision(division, checked);
              }}
            />
            <Typography className={classes.Summary}>{division.description}</Typography>
          </PIMAccordionSummary>
          <PIMAccordionDetails className={classes.AccordionDetails}>
            <ProductGroupAccordions
              accordionProductGroups={division.productGroups}
              searchText={searchText}
              handleSelectProductGroup={(pg, checked) => handleSelectProductGroup(division, pg, checked)}
              handleSelectMaterialGroup={(pg, mg, checked) => handleSelectMaterialGroup(division, pg, mg, checked)}
              materialGroupChecked={materialGroupChecked}
            />
          </PIMAccordionDetails>
        </ExpansionTrackingAccordion>
      );
    });
  }

  return <>{renderDivisionAccordions()}</>;
}

const useStyles = makeStyles(() => ({
  DivisionAccordion: {
    width: '100%',
    boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.25)',
    margin: '4px 0px',
    '&.MuiAccordion-root:before': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
    '& .Mui-expanded': {
      margin: '0px',
    },
    borderBottom: 'none',
  },
  AccordionSummary: {
    background: '#FFFFFF',
    height: '50px !important',
    minHeight: '50px !important',
  },
  AccordionDetails: {
    display: 'grid',
  },
  Summary: {
    fontSize: '20px',
    alignSelf: 'center',
  },
}));

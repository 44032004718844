import React, { ReactElement } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Card, IconButton, Theme, Link } from '@material-ui/core';

import PIMFormikTextField from '../../../../shared/Components/PIMFormikTextField';
import { LinkIcon } from '../../../../shared/icons';
import { routes } from '../../../../routes';
import { useHistory } from 'react-router-dom';
import PIMFormikSelect from '../../../../shared/Components/PIMFormikSelect';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    TextField: {
      '&.MuiTextField-root': {
        marginTop: '8px',
        marginBottom: '8px',
      },
      '& .MuiInputBase-input.Mui-disabled': {
        color: 'black',
      },
      '& .MuiFilledInput-underline.Mui-disabled:before': {
        borderBottomStyle: 'none',
      },
    },
    card: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    select: {
      '& .MuiFormControl-root': {
        width: 'calc(100% - 48px)',
      },
    },
    SelectWide: {
      '& .MuiFormControl-root': {
        width: '100%',
      },
    },
    linkButton: {
      marginTop: '20px',
    },
    makeHidden: {
      visibility: 'hidden',
    },
  })
);

interface IProps {
  readOnly?: boolean;
  authorizedToWrite: boolean;
  authorizedToWriteGlobal: boolean;
  onChange?: (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => void;
  productHintOptions?: {
    key: number | undefined;
    value: string | null | undefined;
  }[];
}

export function ProductHintAttributes(props: IProps): ReactElement {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const { readOnly, authorizedToWrite, authorizedToWriteGlobal, onChange, productHintOptions } = props;

  const handleOpenAdminProducthint = () => {
    history.push(routes.ProductHint(), null);
  };

  return (
    <>
      <Card className={classes.card}>
        {authorizedToWrite && (
          <div
            className={
              !readOnly
                ? authorizedToWriteGlobal
                  ? classes.select
                  : classes.SelectWide
                : (classes.select, classes.makeHidden)
            }>
            <PIMFormikSelect
              name='productHintResponses'
              id='test'
              label={
                productHintOptions?.length === 0
                  ? t('Keine Produkthinweisvorlagen vorhanden')
                  : t('Produkthinweisvorlagen')
              }
              hideRemoveSelection
              options={productHintOptions ?? []}
              disabled={productHintOptions?.length === 0}
              onChange={onChange}
            />
            {authorizedToWriteGlobal && (
              <IconButton className={classes.linkButton} onClick={handleOpenAdminProducthint} component={Link}>
                <LinkIcon />
              </IconButton>
            )}
          </div>
        )}
        <PIMFormikTextField
          label={t('Beschreibung')}
          name={'standardTxt'}
          className={classes.TextField}
          fullWidth
          multiline
          disabled={readOnly || !authorizedToWrite}
        />
      </Card>
    </>
  );
}

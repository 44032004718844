import React, { useContext } from 'react';
import { useAsync } from 'react-async-hook';
import { useParams } from 'react-router-dom';
import { CommentResponse, CountryPermission, useMurexinPimContextApi } from '../../api';
import { CommentList } from './CommentList';
import { AuthorizationContext, LoginContext } from '../../auth';
import { NotificationContext } from '../../contexts/NotificationContext';
import { CircleLoader } from '../../utils/CircleLoader';
import { useTranslation } from 'react-i18next';
import { IRouterParams } from '../../routes';

function sortDescending(dateStringA?: string, dateStringB?: string) {
  if (!dateStringA || !dateStringB) {
    return -1;
  }

  const dateA = new Date(dateStringA);
  const dateB = new Date(dateStringB);

  return dateB.getTime() - dateA.getTime();
}

export function CommentLoader(): JSX.Element {
  const { materialId: materialIdString, country } = useParams<IRouterParams>();
  const materialId = materialIdString !== undefined ? Number(materialIdString) : 0;
  const api = useMurexinPimContextApi();
  const { user } = useContext(LoginContext);
  const notification = useContext(NotificationContext);
  const { t } = useTranslation();
  const { hasCountryPermission } = useContext(AuthorizationContext);
  const isAuthorizedToWrite = hasCountryPermission(country, CountryPermission.Write);

  const comments = useAsync(async () => {
    const response = await api.comment.commentByMaterialIdDetail(materialId, country);
    const data = response.data ?? [];
    data.sort((ca, cb) => sortDescending(ca.dateTime, cb.dateTime));
    return data;
  }, [materialId]);

  async function deleteComment(id: number) {
    const deleteApi = await api.comment.commentDeactivateUpdate(id, country);

    if (deleteApi.status === 200) {
      await comments.execute();
    } else {
      notification.error(t('Löschen eines Kommentars fehlgeschlagen'));
    }
  }

  async function addComment(comment: CommentResponse) {
    comment.dateTime = '';
    comment.materialGroupId = materialId;
    comment.realName = user.firstName?.concat(' ', user.lastName ? user.lastName : '');
    const create = await api.comment.commentCreate(country, comment);

    if (create.status === 200) {
      await comments.execute();
    } else {
      notification.error(t('Hinzufügen eines Kommentars fehlgeschlagen'));
    }
  }

  if (comments.error) {
    notification.error(t('Kommentare konnten nicht geladen werden'));
  }

  if (comments.loading) {
    return <CircleLoader open />;
  } else {
    return (
      <CommentList
        list={comments.result ?? []}
        deleteComment={deleteComment}
        addComment={addComment}
        isAuthorizedToWrite={isAuthorizedToWrite}
      />
    );
  }
}

import React, { useState, useContext, useMemo } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { GridColDef, GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid';
import { useAsync, useAsyncCallback } from 'react-async-hook';
import { useParams } from 'react-router-dom';
import { CountryPermission, SealOfApprovalImageResponse, useMurexinPimContextApi } from '../../../api';
import { CircleLoader } from '../../../utils/CircleLoader';
import { CountryAdminBreadCrumbs } from '../../../layouts/CountryAdminBreadCrumbs/CountryAdminBreadCrumbs';
import { ISortInformation, SortImageListDropDown } from '../../../layouts/CustomImageList/SortImageListDropDown';
import { SealOfApprovalSideBar } from './SealOfApprovalSideBar';
import { NotificationContext } from '../../../contexts/NotificationContext';
import { useTranslation } from 'react-i18next';
import { AuthorizationContext } from '../../../auth';
import Utility from '../../../shared/Utility';
import { FileSelectButton } from '../../../shared/Components/FileSelectButton';
import MessageDialog from '../../../shared/Components/MessageDialog';
import { TileView } from '../../../shared/Components/TileView/TileView';
import ImageItem from '../../../shared/Components/ImageItem';
import { ApiImageUrlsBuilder } from '../../../utils/apiImageUrls';
import PIMIconButton from '../../../shared/Components/PIMIconButton';
import { ExpansionTrackingAccordion } from '../../../shared/Components/Accordion';
import PIMAccordionSummary from '../../../shared/Components/PIMAccordionSummary';
import PIMAccordionDetails from '../../../shared/Components/PIMAccordionDetails';
import ListView from '../../../shared/Components/ListView';
import { DataView } from '../../../shared/Enum/DataView';
import PIMTextField from '../../../shared/Components/PIMTextField';
import _ from 'lodash';

interface IParams {
  country: string;
}

export function SealOfApprovalList(): JSX.Element {
  const classes = useStyles();
  const api = useMurexinPimContextApi();
  const notification = useContext(NotificationContext);
  const { country } = useParams<IParams>();
  const [sealOfApprovals, setSealOfApprovals] = useState<SealOfApprovalImageResponse[]>([]);
  const [selectedSeal, setSelectedSeal] = useState<SealOfApprovalImageResponse>();
  const [sealsSearch, setSealsSearch] = useState('');
  const [addedSealFile, setAddedSealFile] = useState<File | null>(null);
  const [dataView, setDataView] = useState<DataView>(DataView.Grid);
  const { t } = useTranslation();
  const { hasCountryPermission } = useContext(AuthorizationContext);
  const isAuthorizedToWrite = hasCountryPermission(country, CountryPermission.AdministrationWrite);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const [sortOrder, setSortOrder] = useState<ISortInformation>({
    direction: 'asc',
    column: 'fileName',
  });

  const categoriesResponse = useAsync(async () => {
    const response = await api.category.categoryList();
    const updatedCategories = response.data ?? [];
    Utility.sortCategoriesResponse(updatedCategories);

    return updatedCategories;
  }, []);
  const categories = categoriesResponse.result ?? [];

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: ' ',
      // eslint-disable-next-line react/display-name
      renderCell: (params: GridRenderCellParams) => (
        <img
          className={classes.columnImage}
          src={ApiImageUrlsBuilder.buildSealOfApprovalAndNormImageUrl(country, (params.row.fullName as string) ?? '')}
          alt={(params.row.fileName as string) ?? ''}
        />
      ),
      minWidth: 50,
      width: 50,
      sortable: false,
      filterable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
    },
    {
      field: 'fileName',
      headerName: t('Dateiname'),
      flex: 1,
      minWidth: 200,
      sortable: false,
      filterable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
    },
    {
      field: 'updatedAt',
      type: 'dateTime',
      headerName: t('Zuletzt bearbeitet'),
      align: 'right',
      headerAlign: 'right',
      valueFormatter: (params: GridValueFormatterParams) =>
        params.value && Utility.formatDateTimeUtc(params.value as Date),
      width: 150,
      sortable: false,
      filterable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
    },
    {
      field: 'updatedBy',
      headerName: t('Bearbeiter'),
      minWidth: 120,
      width: 200,
      sortable: false,
      filterable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
    },
    {
      field: 'fileSize',
      type: 'number',
      headerName: t('Größe'),
      valueFormatter: (params: GridValueFormatterParams) =>
        params.value && Utility.formatFileSize(params.value as number),
      width: 100,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      filterable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
    },
    {
      field: '',
      headerName: '',
      sortable: false,
      filterable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      align: 'center',
      width: 80,
      // eslint-disable-next-line react/display-name
      renderCell: () => (
        <PIMIconButton
          className={classes.deleteButton}
          size='small'
          onClick={() => setShowDeleteDialog(true)}
          iconName='trash'
        />
      ),
    },
  ];

  const sealOfApprovalsResponse = useAsync(async () => {
    let seals: SealOfApprovalImageResponse[] = [];

    try {
      const response = await api.sealOfApprovalAndNorm.sealOfApprovalAndNormImagesDetail(country);
      seals = response.data ?? [];
    } catch {
      notification.error(t('Fehler beim Laden der Daten.'));
    }

    // Intentionally not using sealOfApprovalsResponse, since we want to show currently loaded items
    // while waiting for response from backend
    setSealOfApprovals(seals);
    clearSelectedSeal();

    return seals;
  }, [country]);

  const sealsCategoriesMap = useMemo(() => {
    const sealsCategoriesArray = _.chain(sealOfApprovals)
      .orderBy([sortOrder.column], [sortOrder.direction])
      .groupBy((s) => s.category?.name)
      .map((value, key) => [key, value] as [string, SealOfApprovalImageResponse[]])
      .value();
    const updatedSealsCategoriesMap = new Map<string, SealOfApprovalImageResponse[]>(sealsCategoriesArray);
    return updatedSealsCategoriesMap;
  }, [sealOfApprovals, sortOrder]);

  function clearSelectedSeal() {
    setAddedSealFile(null);
    setSelectedSeal(undefined);
  }

  function handleAddNewSealOfApprovalFile(addedFiles: File[]) {
    if (addedFiles.length !== 1) {
      return;
    }

    const addedFile = addedFiles[0];
    setSelectedSeal(undefined);
    setAddedSealFile(addedFile);
  }

  const handleSealSelectionChange = (newSelectedSeal: SealOfApprovalImageResponse | undefined) => {
    if (selectedSeal?.id === newSelectedSeal?.id) {
      clearSelectedSeal();
    } else {
      setSelectedSeal(newSelectedSeal);
    }
  };

  const onDeleteSelectedSealOfApproval = useAsyncCallback(async () => {
    const fileName = selectedSeal?.fileName;
    const category = selectedSeal?.category;

    setShowDeleteDialog(false);

    if (fileName && category?.name) {
      try {
        await api.sealOfApprovalAndNorm.sealOfApprovalAndNormSealOfApprovalDelete(country, fileName, category.name);
        notification.success(t('Datei wurde erfolgreich gelöscht'));
        setSelectedSeal(undefined);
        await sealOfApprovalsResponse.execute();
      } catch {
        notification.error(t('Der Löschvorgang ist fehlgeschlagen'));
      }
    }
  });

  const CategoryAccordions = categories.map((category) => {
    let categorySeals = sealsCategoriesMap.get(category.name ?? '') ?? [];
    const isSearching = !!sealsSearch;

    if (isSearching) {
      const searchValue = sealsSearch.toLocaleLowerCase();
      categorySeals = categorySeals.filter((s) => s.fileName?.toLocaleLowerCase().includes(searchValue));
    }

    const hasSeals = categorySeals.length > 0;
    const isExpanded = isSearching ? hasSeals : undefined;

    return (
      <ExpansionTrackingAccordion
        key={category.id}
        hidden={isSearching && !hasSeals}
        disabled={!hasSeals}
        expanded={isExpanded}
        TransitionProps={{ unmountOnExit: true }}>
        <PIMAccordionSummary>
          {category.name} ({categorySeals.length})
        </PIMAccordionSummary>
        <PIMAccordionDetails>
          {dataView === DataView.List ? (
            <ListView
              data={categorySeals}
              selectedItem={selectedSeal}
              onChangeSelection={handleSealSelectionChange}
              columns={columns}
            />
          ) : (
            <TileView>
              {categorySeals.map((seal: SealOfApprovalImageResponse) => {
                const updatedAt = seal.updatedAt ? new Date(seal.updatedAt) : new Date();

                return (
                  <ImageItem
                    description={seal.fileName ?? ''}
                    isClickable
                    showSubDescription
                    showSelected={selectedSeal?.id === seal.id}
                    subDescription={Utility.formatDateTime(updatedAt)}
                    key={seal.id}
                    imageSrc={ApiImageUrlsBuilder.buildSealOfApprovalAndNormImageUrl(country, seal.fullName ?? '')}
                    onClickItem={() => handleSealSelectionChange(seal)}
                    showDeleteButton={isAuthorizedToWrite}
                    onClickDelete={() => {
                      setSelectedSeal(seal);
                      setShowDeleteDialog(true);
                    }}
                  />
                );
              })}
            </TileView>
          )}
        </PIMAccordionDetails>
      </ExpansionTrackingAccordion>
    );
  });

  return (
    <>
      <CircleLoader
        open={sealOfApprovalsResponse.loading || categoriesResponse.loading || onDeleteSelectedSealOfApproval.loading}
      />
      <Grid container>
        <Grid item xs={8} className={classes.mainView}>
          <Grid container>
            <Grid item xs={12} container justifyContent='space-between'>
              <CountryAdminBreadCrumbs site={t('Prüfsiegel')} />
              <Grid item className={classes.icons}>
                <PIMIconButton
                  iconName='listView'
                  className={classes.dataViewButton}
                  disabled={dataView === DataView.List}
                  onClick={() => setDataView(DataView.List)}
                />
                <PIMIconButton
                  iconName='gridView'
                  className={classes.dataViewButton}
                  disabled={dataView === DataView.Grid}
                  onClick={() => setDataView(DataView.Grid)}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.filterArea}>
              <div className={classes.Title}>{t('Prüfsiegel')}</div>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={5}>
                  <PIMTextField
                    label={`${t('Filter')}`}
                    onChange={(e) => setSealsSearch(e.target.value ?? '')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3}>
                  <SortImageListDropDown
                    possibleColumns={['fileName', 'fileSize', 'updatedAt', 'updatedBy']}
                    currentSortDirection={sortOrder}
                    selectSortDirection={(selectedOrder) => setSortOrder(selectedOrder)}
                    className={classes.sortedDropdown}
                  />
                </Grid>
                <Grid item xs={4}>
                  {isAuthorizedToWrite && (
                    <FileSelectButton
                      className={classes.addButton}
                      accept={['image/*']}
                      text={t('Prüfsiegel hinzufügen')}
                      onFilesSelected={handleAddNewSealOfApprovalFile}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {CategoryAccordions}
        </Grid>
        {(addedSealFile || selectedSeal) && (
          <Grid item xs={4} className={classes.actionArea}>
            <SealOfApprovalSideBar
              country={country}
              file={addedSealFile}
              selectedSeal={selectedSeal}
              reload={async () => {
                // TODO: fix this with close sidebar request
                await sealOfApprovalsResponse.execute();
              }}
              resetFile={clearSelectedSeal}
              categories={categories}
              isAuthorizedToWrite={isAuthorizedToWrite}
              actionDelete={() => setShowDeleteDialog(true)}
            />
          </Grid>
        )}
      </Grid>
      <MessageDialog
        show={showDeleteDialog}
        title={t('Wollen Sie dieses Prüfsiegel löschen?')}
        messageText={t(`WarnungElementAusZugewiesenenWarengruppenEntfernt`)}
        confirmColor='warning'
        confirmIcon='trash'
        confirmLabel={t('Löschen')}
        onClose={() => setShowDeleteDialog(false)}
        onCancel={() => setShowDeleteDialog(false)}
        onConfirm={onDeleteSelectedSealOfApproval.execute}
      />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  actionArea: {
    minHeight: '100%',
    backgroundColor: '#ffffff',
    overflowY: 'auto',
    paddingTop: '44px',
  },
  icons: {
    marginTop: '16px',
    paddingRight: '34px',
  },
  filterArea: {
    marginTop: '10px',
    marginBottom: '20px',
    width: '100%',
  },
  sortedDropdown: {
    width: '100%',
    marginTop: '0',
  },
  addButton: {
    width: '100%',
    minWidth: '300px',
    margin: '0',
    padding: '0',
    float: 'right',
  },
  deleteButton: {
    marginRight: '2px',
  },
  dataViewButton: {
    opacity: '0.5',
    '&.Mui-disabled': {
      opacity: '1',
    },
  },
  columnImage: {
    height: 32,
    width: 32,
  },
  mainView: { padding: theme.spacing(0, 2, 0, 0) },
  Title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '36px',
    marginBottom: '24px',
    lineHeight: '45px',
  },
}));

import React, { useState } from 'react';
import { FormControl, InputLabel, makeStyles, createStyles, MenuItem, Select } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export interface IProps {
  possibleColumns: string[];
  currentSortDirection: ISortInformation;
  selectSortDirection: (selectedValue: ISortInformation) => void;
  className?: string;
}

export interface ISortInformation {
  direction: 'asc' | 'desc';
  column: 'fileName' | 'updatedBy' | 'updatedAt' | 'defaultImage';
}

const useStyles = makeStyles(() =>
  createStyles({
    dropDown: {
      margin: '16px 0px 0px 0px',
      display: 'flex',
      width: 'auto',
      '& .MuiFormLabel-root.Mui-focused': {
        color: 'black',
      },
      '& .MuiFilledInput-underline:after': {
        borderBottom: '1px solid black',
      },
      '& .MuiFilledInput-root': {
        backgroundColor: '#f2f2f2',
        margin: '0px 16px 0px 0px',
      },
      '& .MuiSelect-root': { fontSize: 16, background: '#F2F2F2', boxShadow: 'inset 0px -1px 0px #8C8C8C' },
    },
  })
);

export function SortImageListDropDown(props: IProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();

  const [selectedValues, setSelectedValues] = useState<number | undefined>(undefined);
  const possibleSortValues: ISortInformation[] = [];
  const sortTranslations = {
    fileName: t('Dateiname'),
    updatedBy: t('Bearbeiter'),
    updatedAt: t('Zuletzt bearbeitet'),
    defaultImage: t('Standardbild'),
  };

  props.possibleColumns.forEach((d) => {
    if (d === 'fileName' || d === 'updatedBy' || d === 'updatedAt' || d === 'defaultImage') {
      possibleSortValues.push({ column: d, direction: 'asc' });
      possibleSortValues.push({ column: d, direction: 'desc' });
    }
  });
  if (selectedValues === undefined) {
    const defaultValue = possibleSortValues.find(
      (d) => d.column === props.currentSortDirection.column && d.direction === props.currentSortDirection.direction
    );

    if (defaultValue) {
      setSelectedValues(possibleSortValues.indexOf(defaultValue));
    } else {
      setSelectedValues(0);
    }
  }
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedValues(event.target.value as number);
    props.selectSortDirection(possibleSortValues[event.target.value as number]);
  };

  // TODO: Refeactor Control: Id should not be used because in a dom only an id with the same name can only exists once
  return (
    <>
      <FormControl className={`${classes.dropDown} ${props.className ?? ''}`} variant='filled' fullWidth>
        <InputLabel id='demo-simple-select-filled-label'>{t('Sortierreihenfolge')}</InputLabel>
        <Select
          className={classes.dropDown}
          labelId='demo-simple-select-filled-label'
          id='demo-simple-select-filled'
          value={selectedValues ? selectedValues : 0}
          onChange={handleChange}>
          {possibleSortValues.map((item, index) => (
            <MenuItem key={index} value={index}>
              {sortTranslations[item.column] ?? item.column}, {item.direction === 'asc' ? 'A>Z' : 'Z>A'}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}

import React from 'react';

export function CloseIcon(): JSX.Element {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_2493_7375)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M23.7131 0.286922C24.0956 0.669477 24.0956 1.28972 23.7131 1.67227L1.67227 23.7131C1.28971 24.0956 0.66947 24.0956 0.286916 23.7131C-0.0956386 23.3305 -0.0956386 22.7103 0.286916 22.3277L22.3277 0.286922C22.7103 -0.0956319 23.3305 -0.0956319 23.7131 0.286922Z'
          fill='black'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M0.286916 0.286922C0.66947 -0.0956319 1.28971 -0.0956319 1.67227 0.286922L23.7131 22.3277C24.0956 22.7103 24.0956 23.3305 23.7131 23.7131C23.3305 24.0956 22.7103 24.0956 22.3277 23.7131L0.286916 1.67227C-0.0956386 1.28972 -0.0956386 0.669477 0.286916 0.286922Z'
          fill='black'
        />
      </g>
      <defs>
        <clipPath id='clip0_2493_7375'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}

import * as React from 'react';
import { isFunction } from 'lodash';
import { useI18n } from '../i18n/useI18n';

interface ICurrentCultureState {
  currentCulture: string;
  setCurrentCulture: (value: string) => void;
  reloadCurrentCultureTranslation: () => void;
}

interface ICurrentCultureStateProviderProps {
  children: React.ReactNode | ((context: ICurrentCultureState) => React.ReactNode);
}

const CurrentCultureStateContext = React.createContext<ICurrentCultureState>({} as ICurrentCultureState);

export function CurrentCultureStateProvider(props: ICurrentCultureStateProviderProps): JSX.Element {
  const { children } = props;
  const [currentCulture, setCurrentCulture] = React.useState<string>('de');

  const { reloadCurrentCultureTranslation } = useI18n(currentCulture);

  const contextValue = {
    currentCulture,
    setCurrentCulture,
    reloadCurrentCultureTranslation,
  };

  return (
    <CurrentCultureStateContext.Provider value={contextValue}>
      {isFunction(children) ? children(contextValue) : children}
    </CurrentCultureStateContext.Provider>
  );
}

export { CurrentCultureStateContext };

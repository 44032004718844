import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes, CountryRoute, CountryAdministrationRoute } from '../../routes';
import { AdminPictogramList } from '../CountryAdmin/AdminPictogramList/AdminPictogramList';
import { DisplayImagesList } from '../CountryAdmin/DisplayImages/DisplayImagesList';
import MaterialGroupView from '../Article/ArticleDetailed/MaterialGroupView';
import DivisionList from '../Division/list/DivisionList';
import { Home } from '../HomePage/Home';
import MaterialList from '../Material/list/MaterialList';
import ProductList from '../Product/list/ProductList';
import { SealOfApprovalList } from '../CountryAdmin/SealOfApproval/SealOfApprovalList';
import { DivisionDescription } from '../Division/list/DivisionDescription';
import { ProductGroupDescription } from '../Product/list/ProductGroupDescription';
import { MaterialGroupDescription } from '../Material/list/MaterialGroupDescription';

export function HomeRoute(): JSX.Element {
  return (
    <>
      <Route exact path={routes.home} component={Home} />
      <CountryAdministrationRoute exact path={routes.pictogramRoute()} component={AdminPictogramList} />
      <CountryAdministrationRoute exact path={routes.displayImage()} component={DisplayImagesList} />
      <CountryAdministrationRoute exact path={routes.sealOfApproval()} component={SealOfApprovalList} />
      <CountryAdministrationRoute exact path={routes.divisionDescriptionRoute()} component={DivisionDescription} />
      <CountryAdministrationRoute
        exact
        path={routes.productGroupDescriptionRoute()}
        component={ProductGroupDescription}
      />
      <CountryAdministrationRoute
        exact
        path={routes.materialGroupDescriptionRoute()}
        component={MaterialGroupDescription}
      />
      <Switch>
        <CountryRoute exact path={routes.division()} component={DivisionList} />
        <CountryRoute exact path={routes.product()} component={ProductList} />
        <CountryRoute exact path={routes.material()} component={MaterialList} />
        <CountryRoute exact path={routes.articles.selected()} component={MaterialGroupView} />
      </Switch>
    </>
  );
}

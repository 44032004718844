import React from 'react';

export function ImagePlaceholderIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <rect width='32' height='32' fill='#FFDD00' />
      <path
        d='M4 24H10.2241L11.3494 14.6851L14.0514 24H17.8087L20.9113 14.6851L21.7759 24H28L25.0882 7H18.5399L15.9332 15.9303L13.4856 7H6.86729L4 24Z'
        fill='black'
      />
    </svg>
  );
}

import * as React from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { Form, Formik, FormikProps } from 'formik';
import { CategoryResponse, SealOfApprovalImageResponse, useMurexinPimContextApi } from '../../../api';
import { useState } from 'react';
import { NotificationContext } from '../../../contexts/NotificationContext';
import { useTranslation } from 'react-i18next';
import PIMButton from '../../../shared/Components/PIMButton';
import { ApiImageUrlsBuilder } from '../../../utils/apiImageUrls';
import PIMFormikTextField from '../../../shared/Components/PIMFormikTextField';
import PIMFormikSelect from '../../../shared/Components/PIMFormikSelect';

interface IProps {
  selectedSeal?: SealOfApprovalImageResponse;
  country: string;
  file: File | null;
  reload: () => void;
  resetFile: () => void;
  categories: CategoryResponse[];
  isAuthorizedToWrite: boolean;
  actionDelete: () => void;
}

export function SealOfApprovalSideBar(props: IProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const api = useMurexinPimContextApi();
  const notification = React.useContext(NotificationContext);
  const [categoryName, setCategoryName] = useState<string | null>();
  const categoryOptions = props.categories.map((c) => ({
    key: c.id,
    value: c.name,
  }));

  function saveApproval() {
    if (!props.file) return;
    return api.sealOfApprovalAndNorm
      .sealOfApprovalAndNormSealOfApprovalCreate(props.country, {
        File: props.file,
        FullName: props.file.name,
        FileName: props.file.name,
        Category: categoryName ?? 'General',
      })
      .then(() => {
        notification.success(t('Speichern erfolgreich'));
        props.reload();
      })
      .catch(() => {
        notification.error(t('Speichern fehlgeschlagen'));
        props.reload();
      });
  }

  const updateCategory = () => {
    return api.sealOfApprovalAndNorm
      .sealOfApprovalAndNormSealOfApprovalUpdate(props.country, {
        fileName: props.selectedSeal?.fileName,
        oldCategoryName: props.selectedSeal?.category?.name,
        newCategoryName: categoryName,
      })
      .then(() => {
        notification.success(t('Speichern erfolgreich'));
        props.reload();
      })
      .catch(() => {
        notification.error(t('Speichern fehlgeschlagen'));
        props.reload();
      });
  };

  const handleChangeCategory = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
    formik: FormikProps<any>
  ) => {
    const selectedCategoryId = e.target.value as number;
    const selectedCategory = props.categories.find((c) => c.id === selectedCategoryId);
    setCategoryName(selectedCategory?.name);
    formik.setFieldValue('category.id', selectedCategoryId);
  };

  return (
    <>
      {props.selectedSeal && props.categories ? (
        <div className={classes.actionArea}>
          <Grid container className={classes.whiteBackground}>
            <Grid item xs={12} className={classes.gridItem}>
              <Formik
                initialValues={props.selectedSeal}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                }}
                enableReinitialize>
                {(formik) => (
                  <Form>
                    <div className={classes.topButtons}>
                      {props.isAuthorizedToWrite && (
                        <>
                          <PIMButton
                            className={classes.buttonSeperator}
                            icon='close'
                            onClick={() => {
                              formik.resetForm();
                              props.resetFile();
                            }}>
                            {t('Abbrechen')}
                          </PIMButton>
                          <PIMButton
                            color='primary'
                            icon='save'
                            className={classes.topButtons}
                            busy={formik.isSubmitting}
                            disabled={!formik.dirty}
                            onClick={() => updateCategory()}>
                            {t('Speichern')}
                          </PIMButton>
                        </>
                      )}
                    </div>
                    <Grid container>
                      <Grid item xs={12} className={classes.gridItem}>
                        <PIMFormikTextField name='fileName' label={t('Dateiname')} disabled fullWidth />
                        <PIMFormikSelect
                          name='category.id'
                          label={t('Kategorie')}
                          hideRemoveSelection
                          options={categoryOptions}
                          formControlProps={{ fullWidth: true }}
                          onChange={(e) => handleChangeCategory(e, formik)}
                        />
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
              <Grid item xs={9} className={classes.gridItem}>
                <Box textAlign='center'>
                  <div className={classes.paddingImage}>
                    <img
                      className={classes.width}
                      src={ApiImageUrlsBuilder.buildSealOfApprovalAndNormImageUrl(
                        props.country,
                        props.selectedSeal?.fullName ?? ''
                      )}
                      alt={props.selectedSeal?.fileName ?? ''}
                    />
                  </div>
                </Box>
              </Grid>
              <hr className={classes.separatorLine} />
              <PIMButton
                color='warning'
                icon='trash'
                onClick={() => {
                  props.actionDelete();
                }}>
                {t('Prüfsiegel löschen')}
              </PIMButton>
            </Grid>
          </Grid>
        </div>
      ) : (
        <div className={classes.actionArea}>
          <Grid container className={classes.whiteBackground}>
            <Grid item xs={12} className={classes.gridItem}>
              <Formik
                initialValues={{ fileName: props.file?.name }}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                }}
                enableReinitialize={true}>
                {(formik) => (
                  <Form>
                    <div>
                      {props.isAuthorizedToWrite && (
                        <>
                          <PIMButton
                            className={classes.buttonSeperator}
                            icon='close'
                            onClick={() => {
                              formik.resetForm();
                              props.resetFile();
                            }}>
                            {t('Abbrechen')}
                          </PIMButton>
                          <PIMButton
                            color='primary'
                            icon='save'
                            className={classes.topButtons}
                            busy={formik.isSubmitting}
                            disabled={!formik.dirty}
                            onClick={() => saveApproval()}>
                            {t('Speichern')}
                          </PIMButton>
                        </>
                      )}
                    </div>
                    <Grid container>
                      <Grid item xs={12} className={classes.gridItem}>
                        <PIMFormikTextField name='fileName' label={t('Dateiname')} disabled fullWidth />
                        <PIMFormikSelect
                          name='category.id'
                          label={t('Kategorie')}
                          hideRemoveSelection
                          options={categoryOptions}
                          formControlProps={{ fullWidth: true }}
                          onChange={(e) => handleChangeCategory(e, formik)}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.gridItem}>
                      {props.file ? (
                        <Box textAlign='center'>
                          <div className={classes.paddingImage}>
                            <img
                              src={URL.createObjectURL(props.file)}
                              alt={props.file.name}
                              className={classes.width}
                            />
                          </div>
                        </Box>
                      ) : null}
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
}

const useStyles = makeStyles(() => ({
  actionArea: {
    minHeight: '100%',
  },
  paddingImage: {
    paddingTop: '20px',
  },
  width: {
    width: '100%',
  },
  gridItem: {
    paddingLeft: '10px',
    paddingRight: '10px',
    width: '100%',
    maxWidth: '100%',
  },
  whiteBackground: {
    backgroundColor: 'white',
    padding: '30px 0',
  },
  separatorLine: {
    margin: '32px 0',
  },
  topButtons: {
    marginBottom: '16px',
    marginRight: '24px',
    paddingLeft: '10px',
  },
  buttonSeperator: {
    marginRight: '24px',
  },
}));

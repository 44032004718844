import React from 'react';

export function NonEditable(): JSX.Element {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path
          d='M22.19 1.81C21.8494 1.47081 21.4449 1.20247 20.9999 1.02048C20.555 0.838501 20.0784 0.746478 19.5977 0.749732C19.117 0.752987 18.6417 0.851453 18.1993 1.03944C17.7568 1.22744 17.356 1.50123 17.02 1.845L2.52 16.345L0.75 23.25L7.655 21.479L22.155 6.979C22.4989 6.64313 22.7727 6.24244 22.9608 5.80008C23.1488 5.35771 23.2473 4.88246 23.2506 4.4018C23.2539 3.92114 23.1618 3.44459 22.9798 2.99973C22.7977 2.55486 22.5293 2.15049 22.19 1.81V1.81Z'
          stroke='black'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M16.606 2.25999L21.74 7.39399'
          stroke='black'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M2.521 16.344L7.66 21.474'
          stroke='black'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <rect x='1.41418' width='32.3127' height='2' rx='1' transform='rotate(45 1.41418 0)' fill='black' />
      </g>
    </svg>
  );
}

import React, { ReactElement, useContext } from 'react';
import { createStyles, makeStyles, Grid } from '@material-ui/core';
import PIMButton from '../../../../shared/Components/PIMButton';
import { Form, Formik, FieldArray } from 'formik';
import PIMFormikTextField from '../../../../shared/Components/PIMFormikTextField';
import {
  CultureDefinition,
  LocalizationDefinition,
  LocalizationTextModel,
  useMurexinPimContextApi,
} from '../../../../api';
import { useAsync } from 'react-async-hook';
import { CircleLoader } from '../../../../utils/CircleLoader';
import { NotificationContext } from '../../../../contexts/NotificationContext';
import { useTranslation } from 'react-i18next';

interface IProps {
  localizationDefinition: LocalizationDefinition;
  textCode: string;
  sectionCode: string;
  onClose?: () => void;
  onSave?: () => void;
}

export function LocalizationSidebar(props: IProps): ReactElement {
  const classes = useStyles();
  const api = useMurexinPimContextApi();
  const notification = useContext(NotificationContext);
  const { t } = useTranslation();
  const { localizationDefinition, textCode, sectionCode } = props;

  const translationsResponse = useAsync(async () => {
    const response = await api.localization.localizationTextList({ sectionCode, textCode });
    return response.data;
  }, [sectionCode, textCode]);

  const updateTransalation = async (data: LocalizationTextModel) => {
    try {
      await api.localization.localizationTextPartialUpdate(data);
      notification.info('Update successfull');
      await translationsResponse.execute();
      if (props.onSave) props.onSave();
    } catch {
      notification.warning('Update failed');
    }
  };

  const translations =
    translationsResponse.result ??
    ({
      textCode: '',
      sectionCode: '',
      translations: {},
    } as LocalizationTextModel);

  if (localizationDefinition.cultureDefinitions) {
    localizationDefinition.cultureDefinitions.forEach((cultureDefinition: CultureDefinition) => {
      if (translations.translations[cultureDefinition.cultureCode] === undefined) {
        translations.translations[cultureDefinition.cultureCode] = '';
      }
    });
  }
  if (translationsResponse.loading) {
    return <CircleLoader open={true} />;
  } else {
    return (
      <Grid className={classes.Container}>
        <Formik
          initialValues={translations}
          onSubmit={updateTransalation}
          onReset={() => {
            notification.info('Reset Data!');
            if (props.onClose) props.onClose();
          }}
          enableReinitialize={true}>
          {(formik) => (
            <Form>
              <Grid item xs={12} className={classes.ActionButtonRow}>
                <PIMButton icon='close' className={classes.TopButtons} disabled={formik.isSubmitting} type='reset'>
                  {t('Abbrechen')}
                </PIMButton>
                <PIMButton
                  color='primary'
                  icon='save'
                  busy={formik.isSubmitting}
                  disabled={!formik.dirty}
                  type='submit'>
                  {t('Speichern')}
                </PIMButton>
              </Grid>
              <Grid item xs={12} className={classes.TitleRow}>
                <div>{t('Daten bearbeiten')}</div>
              </Grid>
              <Grid item xs={12} className={classes.TranslationTextRow}>
                <PIMFormikTextField label={t('Übersetzungsschlüssel')} name='textCode' disabled fullWidth />
                <FieldArray
                  name='translations'
                  render={() => (
                    <div>
                      {localizationDefinition.cultureDefinitions
                        ? localizationDefinition.cultureDefinitions.map((cultureDefinition: CultureDefinition) => {
                            return (
                              <PIMFormikTextField
                                key={cultureDefinition.cultureCode}
                                label={t(cultureDefinition.cultureCode)}
                                name={`translations.${cultureDefinition.cultureCode}`}
                                fullWidth
                                multiline
                              />
                            );
                          })
                        : null}
                    </div>
                  )}
                />
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    );
  }
}

const useStyles = makeStyles(() =>
  createStyles({
    Container: {
      padding: '72px 40px 24px 40px',
    },
    ActionButtonRow: {
      marginBottom: '24px',
    },
    TopButtons: {
      marginRight: '24px',
    },
    TitleRow: {},
    TranslationTextRow: {},
  })
);

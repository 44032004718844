import React from 'react';

interface IProps {
  className?: string;
}

export function AddLinkIcon(props: IProps): JSX.Element {
  const { className } = props;

  return (
    <svg
      className={className}
      width='26'
      height='16'
      viewBox='0 0 26 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.717 5.79347C12.6774 7.6625 12.6505 10.659 10.7288 12.4994C10.7252 12.5031 10.7209 12.5072 10.717 12.511L8.51198 14.611C6.56718 16.4631 3.4031 16.4629 1.4586 14.611C-0.486199 12.7591 -0.486199 9.74534 1.4586 7.89347L2.67614 6.73391C2.99901 6.42641 3.55506 6.63078 3.57172 7.06535C3.59299 7.61916 3.69726 8.17556 3.88968 8.71287C3.95484 8.89481 3.90828 9.09606 3.76555 9.232L3.33613 9.64097C2.41653 10.5168 2.38768 11.9428 3.29823 12.8272C4.21777 13.7203 5.72918 13.7256 6.65577 12.8431L8.86077 10.7435C9.78579 9.8625 9.78192 8.43856 8.86077 7.56128C8.73933 7.44585 8.61701 7.35616 8.52146 7.2935C8.45386 7.24929 8.39805 7.19065 8.35847 7.12226C8.3189 7.05386 8.29665 6.9776 8.29351 6.89956C8.28052 6.56935 8.40337 6.22907 8.67735 5.96813L9.36819 5.31016C9.54934 5.13763 9.83353 5.11644 10.0436 5.25607C10.2842 5.41605 10.5095 5.59588 10.717 5.79347ZM15.3414 1.38904C13.3969 -0.46289 10.2328 -0.46314 8.28803 1.38904L6.08303 3.48904C6.07909 3.49279 6.07483 3.49685 6.07122 3.5006C4.14952 5.34097 4.12258 8.3375 6.08303 10.2065C6.2905 10.4041 6.51581 10.5839 6.75638 10.7439C6.96644 10.8835 7.25066 10.8623 7.43179 10.6898L8.12262 10.0318C8.39661 9.7709 8.51946 9.43062 8.50646 9.1004C8.50332 9.02237 8.48108 8.94611 8.4415 8.87771C8.40192 8.80932 8.34611 8.75068 8.27851 8.70647C8.18297 8.64381 8.06064 8.55412 7.9392 8.43869C7.01805 7.56141 7.01418 6.13747 7.9392 5.25651L10.1442 3.15682C11.0708 2.27435 12.5822 2.27967 13.5017 3.17276C14.4123 4.05713 14.3835 5.48319 13.4638 6.359L13.0344 6.76797C12.8917 6.90391 12.8451 7.10516 12.9103 7.2871C13.1027 7.82441 13.207 8.38081 13.2282 8.93462C13.245 9.36918 13.801 9.57356 14.1238 9.26606L15.3414 8.1065C17.2862 6.25466 17.2862 3.24092 15.3414 1.38904Z'
        fill='black'
      />
      <path
        d='M24.5998 11.1429H21.8998V8.57143C21.8998 8.25589 21.6311 8 21.2998 8H20.6998C20.3685 8 20.0998 8.25589 20.0998 8.57143V11.1429H17.3998C17.0685 11.1429 16.7998 11.3988 16.7998 11.7143V12.2857C16.7998 12.6013 17.0685 12.8571 17.3998 12.8571H20.0998V15.4286C20.0998 15.7441 20.3685 16 20.6998 16H21.2998C21.6311 16 21.8998 15.7441 21.8998 15.4286V12.8571H24.5998C24.9311 12.8571 25.1998 12.6013 25.1998 12.2857V11.7143C25.1998 11.3988 24.9311 11.1429 24.5998 11.1429Z'
        fill='black'
      />
    </svg>
  );
}

import React from 'react';
import { makeStyles } from '@material-ui/styles';

interface IProps {
  children: React.ReactNode;
}

const useStyles = makeStyles(() => ({
  imageList: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: '4px',
  },
}));

export function TileView(props: IProps): JSX.Element {
  const classes = useStyles();

  const { children } = props;

  return <div className={classes.imageList}>{children}</div>;
}

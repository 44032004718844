import React, { BaseSyntheticEvent } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import { ImagePlaceholderIcon } from '../../../shared/icons';
import PIMIconButton from '../PIMIconButton';

interface IProps {
  className?: string;
  imageSrc: string;
  description?: string | null;
  subDescription?: string | null;
  showSubDescription?: boolean | null;
  showDeleteButton?: boolean | null;
  onClickDelete?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => void;
  isSelected?: boolean | null;
  isClickable?: boolean | null;
  onClickItem?: (event: React.MouseEvent<HTMLDivElement> | undefined) => void;
  showSelected?: boolean | null;
  showPlaceholder?: boolean | null;
}

const useStyles = makeStyles((theme: Theme) => ({
  imageItem: {
    display: 'flex',
    flexDirection: 'column',
    margin: '8px 4px',
    width: '172px',
    borderRadius: '4px',
    overflow: 'hidden',
    background: '#FFFFFF',
    height: '215px',
    position: 'relative',

    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#F2F2F2',
  },
  imageContain: {
    display: 'block',
    width: '172px',
    height: '172px',
    objectFit: 'contain',
  },
  imageCover: {
    display: 'block',
    width: '172px',
    height: '172px',
    objectFit: 'cover',
  },
  textBlock: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
  },
  description: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '12px',
    whiteSpace: 'nowrap',
    paddingLeft: '8px',
    paddingRight: '8px',
    flex: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  maindescription: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  subdescription: {
    overflow: 'hidden',
    textIverflow: 'ellipsis',
    fontWeight: 'normal',
    fontSize: '9px',
  },
  isSelected: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  Selected: {
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
    border: '2px',
    margin: `calc(${theme.spacing(1)}px - 2px)`,
  },
  ShowSelected: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
  },
  DeleteButton: {
    marginRight: '2px',
  },
  DeleteIcon: {
    width: '16px',
    height: '16px',
    marginTop: '-2px',
  },
}));

export function ImageItem(props: IProps): JSX.Element {
  const classes = useStyles();

  const handleOnClickItem = (event: React.MouseEvent<HTMLDivElement> | undefined) => {
    if (props.isClickable === true && props.onClickItem) {
      props.onClickItem(event);
    }
  };

  const handleOnDeleteItem = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (props.onClickDelete) {
      props.onClickDelete(event);
      // Prevent bubbling of event to item click
      event.stopPropagation();
    }
  };

  const { imageSrc, description, subDescription, showSubDescription, showDeleteButton, isSelected, showSelected } =
    props;

  const imageClassNames: string[] = [classes.imageItem];
  if (showSelected) {
    imageClassNames.push(classes.ShowSelected);
  }

  return (
    <div className={imageClassNames.join(' ')} onClick={handleOnClickItem}>
      <div>
        {props.showPlaceholder ? (
          <ImagePlaceholderIcon className={classes.imageCover} />
        ) : (
          <img
            src={imageSrc}
            onLoad={(event: BaseSyntheticEvent) => {
              const img = event.target as HTMLImageElement;
              img.className = Math.abs(img.height / img.width - 1) >= 0.15 ? classes.imageContain : classes.imageCover;
              return;
            }}
          />
        )}
      </div>
      {isSelected === true ? (
        <div className={classes.isSelected}>
          <CheckCircle color='primary' />
        </div>
      ) : null}
      <div className={classes.textBlock}>
        {showSubDescription === true ? (
          <div className={classes.description}>
            <div className={classes.maindescription}>{description}</div>
            <div className={classes.subdescription}>{subDescription}</div>
          </div>
        ) : (
          <div className={classes.description}>{description}</div>
        )}
        {showDeleteButton === true ? (
          <div>
            <PIMIconButton
              className={classes.DeleteButton}
              size='small'
              onClick={handleOnDeleteItem}
              iconName='trash'
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}

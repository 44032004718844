import { Grid, makeStyles, CardContent } from '@material-ui/core';
import React, { useEffect } from 'react';
import { CircleLoader } from '../../../utils/CircleLoader';
import { routes } from '../../../routes/routes';
import { PIMCard } from '../../../shared/Components/PIMCard';
import { useHistory, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ContainerLayoutSideBar } from '../Export/ContainerLayoutExport/ContainerLayoutSideBar';

interface IMenuItem {
  title: string;
  description: string;
  items: ISubMenuItem[];
  link?: string;
}

const AdminTabTypes = { LINK: 1, SIDEBAR: 2 };

export type ISubMenuItem = {
  title: string;
  description: string;
  type: number;
  link?: string;
};

export function AdminMenu(): JSX.Element {
  const [selectedItem, setSelectedItem] = React.useState<IMenuItem | null>(null);
  const [selectedSubmenuItem, setSelectedSubmenuItem] = React.useState<ISubMenuItem | null>(null);
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const sideBarItems: IMenuItem[] = [
    {
      title: t('Export'),
      description: t('Export Untertitel'),
      items: [
        {
          title: t('Gebindelayout'),
          description: t('Gebindelayout Untertitel'),
          type: AdminTabTypes.SIDEBAR,
        },
        {
          title: t('Dynamischer Export'),
          description: t('Dynamischer Export Untertitel'),
          type: AdminTabTypes.LINK,
          link: routes.DynamicExport(),
        },
      ],
    },
    {
      title: t('Info Mail'),
      description: t('Info Mail Untertitel'),
      items: [],
      link: routes.MailingList(),
    },
    {
      title: t('Dokumente'),
      description: t('Dokumente Untertitel'),
      items: [],
      link: routes.DocumentsManagement(),
    },
    {
      title: t('Konfiguration'),
      description: t('Konfiguration Untertitle'),
      items: [
        {
          title: t('Allgemeine Hinweise'),
          description: t('Allgemeine Hinweise Untertitle'),
          type: AdminTabTypes.LINK,
          link: routes.GeneralHint(),
        },
        {
          title: t('Produkthinweise'),
          description: t('Produkthinweise Untertitle'),
          type: AdminTabTypes.LINK,
          link: routes.ProductHint(),
        },
        {
          title: t('Untergrundvorlagen'),
          description: t('Untergrund Untertitle'),
          type: AdminTabTypes.LINK,
          link: routes.Substrate(),
        },
        {
          title: t('Internationalisierung'),
          description: t('Internationalisierung Untertitle'),
          type: AdminTabTypes.LINK,
          link: routes.AdminConfigurationLocalization(),
        },
      ],
    },
  ];

  const handleChangeMenuItem = (item: IMenuItem) => {
    if (item.link) {
      history.push(item.link);
    } else {
      setSelectedItem(item);
    }
  };

  const handleChangeSubmenuItem = (item: ISubMenuItem) => {
    setSelectedSubmenuItem(selectedSubmenuItem === item ? null : item);
  };

  useEffect(() => {
    setSelectedSubmenuItem(null);
  }, [selectedItem]);

  return (
    <Grid container className={classes.Container}>
      <Grid item xs={4}>
        <Grid item xs={10}>
          <div className={classes.Title}>{t('Administration')}</div>
          {sideBarItems ? (
            sideBarItems
              .sort((l, r) => l.title?.localeCompare(r.title))
              .map((item, index) => {
                return (
                  <PIMCard
                    key={index}
                    className={classes.PimCard}
                    isSelected={selectedItem ? selectedItem?.title === item.title : false}
                    onClick={() => handleChangeMenuItem(item)}>
                    <CardContent className={classes.CardContent}>
                      <span className={classes.CardTitle}>{item.title}</span>
                      <span className={classes.CardSubTitle}>{item.description}</span>
                    </CardContent>
                  </PIMCard>
                );
              })
          ) : (
            <CircleLoader open={true} />
          )}
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Grid item xs={10}>
          <div className={classes.Title}>{selectedItem?.title}</div>
          {selectedItem && selectedItem.items.length > 0 ? (
            <>
              {selectedItem?.items
                .sort((l, r) => l.title?.localeCompare(r.title))
                .map((item, index) => {
                  if (item.type === AdminTabTypes.LINK) {
                    return (
                      <NavLink key={index} style={{ textDecoration: 'none' }} to={item.link}>
                        <PIMCard key={index} className={classes.PimCard}>
                          <CardContent className={classes.CardContent}>
                            <span className={classes.CardTitle}>{item.title}</span>
                            <span className={classes.CardSubTitle}>{item.description}</span>
                          </CardContent>
                        </PIMCard>
                      </NavLink>
                    );
                  } else if (item.type === AdminTabTypes.SIDEBAR) {
                    return (
                      <PIMCard
                        key={index}
                        className={classes.PimCard}
                        isSelected={selectedSubmenuItem ? selectedSubmenuItem?.title === item.title : false}
                        onClick={() => handleChangeSubmenuItem(item)}>
                        <CardContent className={classes.CardContent}>
                          <span className={classes.CardTitle}>{item.title}</span>
                          <span className={classes.CardSubTitle}>{item.description}</span>
                        </CardContent>
                      </PIMCard>
                    );
                  }
                })}
            </>
          ) : null}
        </Grid>
      </Grid>
      <Grid item xs={4} className={classes.Sidebar}>
        {selectedSubmenuItem?.title === t('Gebindelayout') && <ContainerLayoutSideBar />}
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  AdminSideNavGrid: {
    paddingRight: '24px',
  },
  Sidebar: {
    marginTop: '-76px',
    minHeight: '100%',
  },
  PimCard: {
    cursor: 'pointer',
  },
  CardContent: {
    display: 'flex',
    flexDirection: 'column',
    margin: '8px 16px 8px 16px',
    padding: '0px',
    '&.MuiCardContent-root:last-child': {
      padding: '0px',
    },
  },
  CardTitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '20px',
    marginBottom: '4px',
    color: '#000000',
  },
  CardSubTitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontSize: '14px',
    fontWeight: 400,
    color: '#000000',
    opacity: '0.6',
  },
  Title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '36px',
    marginBottom: '24px',
    marginTop: '16px',
  },
  Container: {
    height: '100%',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    paddingLeft: '16px',
  },
}));

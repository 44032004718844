import React, { useContext } from 'react';
import { createStyles, Grid, makeStyles } from '@material-ui/core';
import { routes } from '../../../../routes/routes';
import { useHistory, useParams } from 'react-router-dom';
import {
  SubstrateMaterialGroupResponse,
  SubstrateMaterialGroupRequest,
  useMurexinPimContextApi,
  CountryPermission,
  GlobalPermission,
} from '../../../../api';
import { useAsync } from 'react-async-hook';
import { Form, Formik } from 'formik';
import { CircleLoader } from '../../../../utils/CircleLoader';
import { NotificationContext } from '../../../../contexts/NotificationContext';
import PIMButton from '../../../../shared/Components/PIMButton';
import SubstrateAttributes from './SubstrateAttributes';
import { IRouterParams } from '../../../../routes/IRouterParams';
import { useTranslation } from 'react-i18next';
import { AuthorizationContext } from '../../../../auth/AuthorizationContext';
import { ProductManagementProps } from '../../ProductManagement/ProductManagement';
import useFormikSaveAndClose from '../../../../utils/customHooks/useFormikSaveAndClose';

interface IProps {
  countrySelectionComponent: React.ReactNode;
  translationCountryCode: string;
}

export function Substrate(props: IProps & ProductManagementProps): React.ReactElement {
  const { country, materialId } = useParams<IRouterParams>();
  const { onDirtyChanged, cleanUpForm, countrySelectionComponent, translationCountryCode, saveOnCloseRef } = props;
  const materialGroupId = Number(materialId);
  const history = useHistory();
  const classes = useStyles();
  const api = useMurexinPimContextApi();
  const notification = React.useContext(NotificationContext);
  const { hasCountryPermission, hasGlobalPermission } = useContext(AuthorizationContext);
  const isAuthorizedToWrite = hasCountryPermission(country, CountryPermission.Write);
  const isAuthorizedToWriteGlobal = hasGlobalPermission(GlobalPermission.AdministrationWrite);
  const showTranslationPanel = !!translationCountryCode && isAuthorizedToWrite;
  const { t } = useTranslation();
  const formikRef = useFormikSaveAndClose<SubstrateMaterialGroupResponse>(saveOnCloseRef);

  const handleOpenAdminSubstrate = () => {
    history.push(routes.Substrate(), null);
  };

  const substrateOptions = useAsync(async () => {
    const response = await api.admin.adminSubstrateDetail(country);
    const options =
      response?.data?.map((s) => ({
        key: s.id,
        value: s.title,
        content: s.content,
      })) ?? [];

    return options;
  }, [country]);

  const substrateMaterialGroupResponse = useAsync(async () => {
    const response = await api.materialGroupService.materialGroupServiceSubstrateMaterialGroupDetail(
      country,
      materialGroupId
    );
    return response.data ?? {};
  }, [country, materialId]);

  const substrateMaterialGroupResponseTranslation = useAsync(async () => {
    if (!!translationCountryCode) {
      const response = await api.materialGroupService.materialGroupServiceSubstrateMaterialGroupDetail(
        translationCountryCode,
        materialGroupId
      );
      return response.data ?? {};
    }
    return {};
  }, [translationCountryCode, materialId]);

  async function updateData(response: SubstrateMaterialGroupResponse) {
    const request: SubstrateMaterialGroupRequest = {
      id: response.id,
      requirementStandard: response.requirementStandard,
      preprocessing: response.preprocessing,
    };

    try {
      await api.materialGroupService.materialGroupServiceSubstrateMaterialGroupCreate(
        country,
        materialGroupId,
        request
      );

      void substrateMaterialGroupResponse.execute();
      cleanUpForm();
      notification.success(t('Speichern erfolgreich'));
    } catch (error) {
      notification.error(t('Speichern fehlgeschlagen'));
      throw error;
    }
  }

  if (substrateOptions.loading) {
    return <CircleLoader open={true} />;
  } else {
    return (
      <>
        <CircleLoader
          open={substrateMaterialGroupResponse.loading || substrateMaterialGroupResponseTranslation.loading}
        />
        <Formik
          enableReinitialize={!substrateMaterialGroupResponse.loading}
          innerRef={formikRef}
          initialValues={substrateMaterialGroupResponse.result as SubstrateMaterialGroupResponse}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            void updateData(values);
          }}>
          {({ isSubmitting, dirty, handleReset, handleSubmit, setFieldValue, handleChange }) => (
            <Form
              className={classes.Container}
              onChange={(e) => {
                onDirtyChanged();
                handleChange(e);
              }}>
              <Grid container item xs={showTranslationPanel ? 12 : 8}>
                <Grid item xs={12} className={classes.ActionButtonRow}>
                  {isAuthorizedToWrite && (
                    <>
                      {countrySelectionComponent}
                      <PIMButton
                        className={classes.TopButtons}
                        icon='close'
                        disabled={isSubmitting || !dirty}
                        onClick={() => {
                          notification.info(t('Daten zurückgesetzt'));
                          handleReset();
                        }}>
                        {t('Abbrechen')}
                      </PIMButton>
                      <PIMButton
                        color='primary'
                        icon='save'
                        className={classes.TopButtons}
                        busy={isSubmitting}
                        disabled={!dirty}
                        onClick={() => handleSubmit()}>
                        {t('Speichern')}
                      </PIMButton>
                    </>
                  )}
                </Grid>
                <Grid container item spacing={2}>
                  <Grid item xs={showTranslationPanel ? 6 : 12}>
                    <SubstrateAttributes
                      substrateOptions={substrateOptions?.result ?? []}
                      authorizedToWrite={isAuthorizedToWrite}
                      authorizedToWriteGlobal={isAuthorizedToWriteGlobal}
                      openAdminSubstrate={handleOpenAdminSubstrate}
                      onChange={(
                        e: React.ChangeEvent<{
                          name?: string | undefined;
                          value: unknown;
                        }>
                      ) => {
                        setFieldValue(
                          'requirementStandard',
                          substrateOptions?.result?.find((s) => s.key === e.target.value)?.content
                        );
                        handleChange(e);
                      }}
                    />
                  </Grid>
                  {!!showTranslationPanel && (
                    <Grid item xs={6}>
                      <Formik
                        enableReinitialize={!substrateMaterialGroupResponseTranslation.loading}
                        initialValues={
                          substrateMaterialGroupResponseTranslation.result as SubstrateMaterialGroupResponse
                        }
                        onSubmit={() => {
                          return;
                        }}>
                        <SubstrateAttributes
                          readonly
                          authorizedToWrite={isAuthorizedToWrite}
                          authorizedToWriteGlobal={isAuthorizedToWriteGlobal}
                        />
                      </Formik>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

const useStyles = makeStyles(() =>
  createStyles({
    ActionButtonRow: {
      display: 'flex',
      alignContent: 'flex-end',
      justifyContent: 'flex-end',
      marginBottom: '32px',
      marginTop: '-80px',
    },
    TopButtons: {
      marginLeft: '24px',
    },
    Container: {
      marginRight: '24px',
    },
  })
);

import React, { useState, useContext } from 'react';
import { Grid, AccordionActions, makeStyles, Theme } from '@material-ui/core';
import ListView from '../../../shared/Components/ListView';
import { GridColumns, GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid';
import Utility from '../../../shared/Utility';
import EditDocumentsSideBar from './EditDocumentsSideBar';
import { FileSelectButton } from '../../../shared/Components/FileSelectButton';
import { ExpansionTrackingAccordion } from '../../../shared/Components/Accordion';
import PIMAccordionSummary from '../../../shared/Components/PIMAccordionSummary';
import PIMAccordionDetails from '../../../shared/Components/PIMAccordionDetails';
import { useAsync, useAsyncCallback } from 'react-async-hook';
import {
  CountryPermission,
  DocumentCategory,
  DocumentLevel,
  DocumentResponse,
  useMurexinPimContextApi,
} from '../../../api/MurexinPimApi';
import { useParams } from 'react-router-dom';
import { CircleLoader } from '../../../utils/CircleLoader';
import PIMButton from '../../../shared/Components/PIMButton';
import PIMTextField from '../../../shared/Components/PIMTextField';
import { ImagePlaceholderIcon, SelectedIcon, NonEditable } from '../../../shared/icons';
import { NotificationContext } from '../../../contexts/NotificationContext';
import { useTranslation } from 'react-i18next';
import { AuthorizationContext } from '../../../auth';
import { ApiImageUrlsBuilder } from '../../../utils/apiImageUrls';
import { IRouterParams } from '../../../routes';
import { documentCategories } from '../../../shared/DocumentTranslations';

export interface UpdateDocumentRequest {
  id: number;
  fileName: string;
  category: DocumentCategory;
  isExported: boolean;
  newFile?: File;
  documentLevel: DocumentLevel;
}

export function DocumentsAndLeafletsMainPanel(): JSX.Element {
  const api = useMurexinPimContextApi();
  const classes = useStyles();
  const { t } = useTranslation();
  const { country, materialId } = useParams<IRouterParams>();
  const materialGroupId = Number(materialId);
  const notification = useContext(NotificationContext);
  const { hasCountryPermission } = useContext(AuthorizationContext);
  const isAuthorizedToWrite = hasCountryPermission(country, CountryPermission.Write);
  const isExportEnabledForCategory = (category: DocumentCategory): boolean => {
    return category !== DocumentCategory.ExternalLayout;
  };

  const renderColumns = (showExport: boolean): GridColumns => {
    const columns: GridColumns = [
      { field: 'id', headerName: '', hide: true },
      {
        // NOTE: Field 'image' is not present in data
        field: 'image',
        headerName: ' ',
        renderCell: (params: GridRenderCellParams): React.ReactNode => {
          const document = params.row as DocumentResponse;
          if (!document || !document.fileName || !Utility.isImageFile(document.fileName)) {
            return <ImagePlaceholderIcon className={classes.columnImage} />;
          } else {
            return (
              <img
                className={classes.columnImage}
                src={ApiImageUrlsBuilder.buildEditDocumentEditSidebarIconUrlByCountryAndDocumentId(
                  country,
                  document.id
                )}
              />
            );
          }
        },
        minWidth: 50,
        width: 50,
        sortable: false,
        filterable: false,
        hideSortIcons: true,
        disableColumnMenu: true,
      },
      {
        field: 'fileName',
        headerName: t('Dateiname'),
        flex: 1,
        minWidth: 200,
        sortable: false,
        filterable: false,
        hideSortIcons: true,
        disableColumnMenu: true,
      },
      {
        field: 'updatedAt',
        type: 'dateTime',
        headerName: t('Zuletzt bearbeitet'),
        align: 'right',
        headerAlign: 'right',
        valueFormatter: (params: GridValueFormatterParams) =>
          params.value && Utility.formatDateTimeUtc(params.value as Date),
        width: 150,
        sortable: false,
        filterable: false,
        hideSortIcons: true,
        disableColumnMenu: true,
      },
      {
        field: 'updatedBy',
        headerName: t('Bearbeiter'),
        minWidth: 120,
        width: 200,
        sortable: false,
        filterable: false,
        hideSortIcons: true,
        disableColumnMenu: true,
      },
      {
        field: 'fileSize',
        type: 'number',
        headerName: t('Größe'),
        valueFormatter: (params: GridValueFormatterParams) =>
          params.value && Utility.formatFileSize(params.value as number),
        width: showExport ? 80 : 280,
        headerAlign: 'left',
        align: 'left',
        sortable: false,
        filterable: false,
        hideSortIcons: true,
        disableColumnMenu: true,
      },
      {
        field: 'isExported',
        type: 'boolean',
        hide: !showExport,
        headerName: t('Export zur Webseite'),
        width: 200,
        align: 'center',
        sortable: false,
        filterable: false,
        hideSortIcons: true,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams): React.ReactNode => {
          if (params.value === true) {
            return <SelectedIcon />;
          } else {
            return <></>;
          }
        },
      },
      {
        field: 'documentLevel',
        type: 'boolean',
        hide: !showExport,
        headerName: t('Editierbar'),
        width: 120,
        align: 'center',
        sortable: false,
        filterable: false,
        hideSortIcons: true,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams): React.ReactNode => {
          if (params.value === DocumentLevel.MaterialGroup) {
            return <></>;
          } else {
            return (
              <div className={classes.NonEditableIcon}>
                <NonEditable />
              </div>
            );
          }
        },
      },
    ];

    return columns;
  };

  const [documentsSearch, setDocumentsSearch] = useState('');
  const [selectedDocument, setSelectedDocument] = useState<UpdateDocumentRequest | null>(null);
  const [exportedTechnicalDataSheet, setExportedTechnicalDataSheet] = useState<DocumentResponse | null>(null);

  const groupedDocumentsResponse = useAsync(async () => {
    setSelectedDocument(null);
    const response = await api.document.documentMaterialGroupDetail(country, materialGroupId);
    const groupedDocuments = response.data ?? [];
    const technicalDataSheetGroup = groupedDocuments.find((gd) => gd.category === DocumentCategory.TechnicalDataSheet);
    const exportedDataSheet = technicalDataSheetGroup?.documents.find((d) => d.isExported) ?? null;
    setExportedTechnicalDataSheet(exportedDataSheet);

    return groupedDocuments;
  }, [country, materialGroupId]);

  const onGenerateTechnicalDataSheet = useAsyncCallback(async () => {
    try {
      await api.tmbGenerator.tmbGeneratorMaterialgroupGenerateworddocumentCreate(country, materialGroupId);
      notification.success(t('Ein neues technisches Merkblatt wurde erstellt'));
      await groupedDocumentsResponse.execute();
    } catch (error) {
      notification.error(t('Bei der Erstellung ist ein Fehler aufgetreten'));
    }
  });

  const onGenerateExternalLayoutDataSheet = useAsyncCallback(async () => {
    try {
      await api.tmbGenerator.tmbGeneratorMaterialgroupGenerateexternallayoutworddocumentCreate(
        country,
        materialGroupId
      );
      notification.success(t('Ein neues Fremdaufmachungsmerkblatt wurde erstellt'));
      await groupedDocumentsResponse.execute();
    } catch (error) {
      notification.error(t('Bei der Erstellung ist ein Fehler aufgetreten'));
    }
  });

  function handleAddNewDocument(files: File[]) {
    const newDocument: UpdateDocumentRequest = {
      id: -1,
      fileName: files[0].name,
      category: DocumentCategory.Documents,
      isExported: false,
      newFile: files[0],
      documentLevel: DocumentLevel.MaterialGroup,
    };
    setSelectedDocument(newDocument);
  }

  async function handleDocumentEditCompleted(hasChanges: boolean) {
    setSelectedDocument(null);

    if (hasChanges) {
      await groupedDocumentsResponse.execute();
    }
  }

  const CategoryAccordions = documentCategories.map((category) => {
    const documentGroup = groupedDocumentsResponse.result?.find((gd) => gd.category === category.id);
    const isSearching = !!documentsSearch;
    let documents = documentGroup?.documents ?? [];

    if (isSearching) {
      const searchValue = documentsSearch.toLocaleLowerCase();
      documents = documents.filter((d) => d.fileName.toLocaleLowerCase().includes(searchValue));
    }

    const hasDocuments = documents.length > 0;
    const isTechnicalDataSheetCategory = category.id === DocumentCategory.TechnicalDataSheet;
    const isExternalLayoutDataSheetCategory = category.id === DocumentCategory.ExternalLayout;
    const isEnabled =
      hasDocuments || ((isTechnicalDataSheetCategory || isExternalLayoutDataSheetCategory) && !isSearching);
    const isExpanded = isSearching ? hasDocuments : undefined;
    const categorySummary = `${t(category.name)} (${documents.length})`;

    return (
      <ExpansionTrackingAccordion
        hidden={!hasDocuments && isSearching}
        disabled={!isEnabled}
        expanded={isExpanded}
        key={category.id}>
        <PIMAccordionSummary>{categorySummary}</PIMAccordionSummary>
        {isTechnicalDataSheetCategory && isAuthorizedToWrite && (
          <AccordionActions>
            <PIMButton
              onClick={onGenerateTechnicalDataSheet.execute}
              busy={onGenerateTechnicalDataSheet.loading}
              icon='addFile'>
              {t('Technisches Merkblatt erstellen')}
            </PIMButton>
          </AccordionActions>
        )}
        {isExternalLayoutDataSheetCategory && isAuthorizedToWrite && (
          <AccordionActions>
            <PIMButton
              onClick={onGenerateExternalLayoutDataSheet.execute}
              busy={onGenerateExternalLayoutDataSheet.loading}
              icon='addFile'>
              {t('Fremdaufmachungsmerkblatt erstellen')}
            </PIMButton>
          </AccordionActions>
        )}
        {hasDocuments && (
          <PIMAccordionDetails>
            <ListView
              columns={renderColumns(isExportEnabledForCategory(category.id))}
              data={documents}
              onChangeSelection={(item) => setSelectedDocument(item ?? null)}
              selectedItem={selectedDocument ?? undefined}
            />
          </PIMAccordionDetails>
        )}
      </ExpansionTrackingAccordion>
    );
  });

  return (
    <>
      <CircleLoader open={groupedDocumentsResponse.loading} />
      <Grid container className={classes.Container}>
        <Grid item xs={8} className={classes.mainView}>
          <Grid container alignItems='center' className={classes.searchContainer}>
            <PIMTextField
              className={classes.searchField}
              label={t('Suche')}
              onChange={(e) => setDocumentsSearch(e.target.value ?? '')}
            />
            {isAuthorizedToWrite && (
              <FileSelectButton
                text={t('Dokument hinzufügen')}
                onFilesSelected={handleAddNewDocument}
                className={classes.buttonMargin}
              />
            )}
          </Grid>
          {CategoryAccordions}
        </Grid>
        {selectedDocument && (
          <Grid item xs={4} className={classes.Sidebar}>
            <EditDocumentsSideBar
              isAuthorizedToWrite={isAuthorizedToWrite}
              document={selectedDocument}
              exportedTechnicalDataSheet={exportedTechnicalDataSheet}
              documentCategories={documentCategories}
              materialGroupId={materialGroupId}
              country={country}
              isExportEnabledForCategory={isExportEnabledForCategory}
              onUpdateCompleted={handleDocumentEditCompleted}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  buttonMargin: {
    marginLeft: '16px',
  },
  searchField: {
    flex: 1,
  },
  mainView: { padding: theme.spacing(0, 2, 0, 0) },
  searchContainer: { padding: theme.spacing(0, 0, 2, 0) },
  columnImage: {
    height: 32,
    width: 32,
  },
  Container: {
    height: '100%',
  },
  Sidebar: {
    minHeight: '100%',
    marginTop: '-231px',
    zIndex: 1101,
    backgroundColor: 'white',
  },
  NonEditableIcon: {
    opacity: '0.6',
    display: 'flex',
  },
}));

import React, { ReactElement, useContext } from 'react';
import { createStyles, Grid, makeStyles } from '@material-ui/core';
import { CountryPermission, PrepareRequestModel, useMurexinPimContextApi } from '../../../../api';
import { NotificationContext } from '../../../../contexts/NotificationContext';
import { useParams } from 'react-router-dom';
import { useAsync } from 'react-async-hook';
import { CircleLoader } from '../../../../utils/CircleLoader';
import { Form, Formik } from 'formik';
import PIMButton from '../../../../shared/Components/PIMButton';
import PrepareAttributes from './PrepareAttributes';
import { IRouterParams } from '../../../../routes/IRouterParams';
import { useTranslation } from 'react-i18next';
import { AuthorizationContext } from '../../../../auth/AuthorizationContext';
import { ProductManagementProps } from '../../ProductManagement/ProductManagement';
import useFormikSaveAndClose from '../../../../utils/customHooks/useFormikSaveAndClose';

const useStyles = makeStyles(() =>
  createStyles({
    ActionButtonGrid: {
      display: 'flex',
      alignContent: 'flex-end',
      justifyContent: 'flex-end',
      marginBottom: '32px',
      marginTop: '-80px',
    },
    ActionButton: {
      marginLeft: '24px',
    },
    Container: {
      marginRight: '24px',
    },
  })
);

interface IProps {
  countrySelectionComponent: React.ReactNode;
  translationCountryCode: string;
}

export function Prepare(props: IProps & ProductManagementProps): ReactElement {
  const classes = useStyles();
  const { country, materialId } = useParams<IRouterParams>();
  const materialGroupId = Number(materialId);
  const { onDirtyChanged, cleanUpForm, countrySelectionComponent, translationCountryCode, saveOnCloseRef } = props;
  const api = useMurexinPimContextApi();
  const notification = React.useContext(NotificationContext);
  const { hasCountryPermission } = useContext(AuthorizationContext);
  const isAuthorizedToWrite = hasCountryPermission(country, CountryPermission.Write);
  const showTranslationPanel = !!translationCountryCode && isAuthorizedToWrite;
  const { t } = useTranslation();
  const formikRef = useFormikSaveAndClose<PrepareRequestModel>(saveOnCloseRef);

  const prepare = useAsync(async () => {
    const response = await api.materialGroupService.materialGroupServiceMaterialGroupPrepareDetail(
      country,
      materialGroupId
    );
    const prepareResponseModel = response.data ?? {};
    return prepareResponseModel;
  }, [country, materialGroupId]);

  const prepareTranslation = useAsync(async () => {
    if (!!translationCountryCode) {
      const response = await api.materialGroupService.materialGroupServiceMaterialGroupPrepareDetail(
        translationCountryCode,
        materialGroupId
      );
      return response.data ?? {};
    }
    return {};
  }, [translationCountryCode, materialGroupId]);

  async function updateData(data: PrepareRequestModel) {
    try {
      await api.materialGroupService.materialGroupServiceMaterialGroupPrepareCreate(country, materialGroupId, data);
      notification.success(t('Speichern erfolgreich'));
      cleanUpForm();
      await prepare.execute();
    } catch (error) {
      notification.success(t('Speichern erfolgreich'));
      throw error;
    }
  }

  return (
    <>
      <CircleLoader open={prepare.loading || prepareTranslation.loading} />
      <Formik
        enableReinitialize={!prepare.loading}
        innerRef={formikRef}
        initialValues={prepare.result ? prepare.result : {}}
        onSubmit={updateData}>
        {(formik) => (
          <Form
            className={classes.Container}
            onChange={(e) => {
              formik.handleChange(e);
              onDirtyChanged();
            }}>
            <Grid container item xs={showTranslationPanel ? 12 : 8}>
              <Grid item xs={12} className={classes.ActionButtonGrid}>
                {isAuthorizedToWrite && (
                  <>
                    {countrySelectionComponent}
                    <PIMButton
                      icon='close'
                      className={classes.ActionButton}
                      disabled={formik.isSubmitting || prepare.loading || !formik.dirty}
                      onClick={() => formik.handleReset()}>
                      {t('Abbrechen')}
                    </PIMButton>
                    <PIMButton
                      icon='save'
                      color='primary'
                      type='submit'
                      className={classes.ActionButton}
                      disabled={!formik.dirty || prepare.loading}
                      busy={formik.isSubmitting}>
                      {t('Speichern')}
                    </PIMButton>
                  </>
                )}
              </Grid>
              <Grid container item spacing={2}>
                <Grid item xs={showTranslationPanel ? 6 : 12}>
                  <PrepareAttributes authorizedToWrite={isAuthorizedToWrite} />
                </Grid>
                {!!showTranslationPanel && (
                  <Grid item xs={6}>
                    <Formik
                      enableReinitialize={!prepareTranslation.loading}
                      initialValues={prepareTranslation.result ? prepareTranslation.result : {}}
                      onSubmit={() => {
                        return;
                      }}>
                      <PrepareAttributes readonly />
                    </Formik>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}

import React, { useContext } from 'react';
import { createStyles, Grid, makeStyles } from '@material-ui/core';
import {
  WorksafetyRequestModel,
  useMurexinPimContextApi,
  WorksafetyResponseModel,
  CountryPermission,
} from '../../../../api';
import { useParams } from 'react-router';
import { useAsync } from 'react-async-hook';
import { CircleLoader } from '../../../../utils/CircleLoader';
import { Form, Formik } from 'formik';
import { NotificationContext } from '../../../../contexts/NotificationContext';
import PIMButton from '../../../../shared/Components/PIMButton';
import { IRouterParams } from '../../../../routes';
import { WorkSafetyAttributes } from './WorkSafetyAttributes';
import { useTranslation } from 'react-i18next';
import { AuthorizationContext } from '../../../../auth';
import useFormikSaveAndClose from '../../../../utils/customHooks/useFormikSaveAndClose';
import { ProductManagementProps } from '../../ProductManagement/ProductManagement';

const useStyles = makeStyles(() =>
  createStyles({
    Container: {
      marginRight: '24px',
    },
    ActionButtonGrid: {
      display: 'flex',
      alignContent: 'flex-end',
      justifyContent: 'flex-end',
      marginBottom: '32px',
      marginTop: '-80px',
    },
    ActionButton: {
      marginLeft: '24px',
    },
  })
);

interface IProps {
  countrySelectionComponent: React.ReactNode;
  translationCountryCode: string;
}

export function WorkSafety(props: IProps & ProductManagementProps): React.ReactElement {
  const { country, materialId } = useParams<IRouterParams>();
  const materialGroupId = Number(materialId);
  const classes = useStyles();
  const { t } = useTranslation();
  const api = useMurexinPimContextApi();
  const notification = React.useContext(NotificationContext);
  const { translationCountryCode, countrySelectionComponent, saveOnCloseRef, onDirtyChanged } = props;
  const showTranslationPanel = !!translationCountryCode;
  const { hasCountryPermission } = useContext(AuthorizationContext);
  const isAuthorizedToWrite = hasCountryPermission(country, CountryPermission.Write);
  const formikRef = useFormikSaveAndClose<WorksafetyRequestModel>(saveOnCloseRef);

  const workSafetyMaterialGroupResponse = useAsync(async () => {
    const response = await api.materialGroupService.materialGroupServiceMaterialGroupWorkSafetyDetail(
      country,
      materialGroupId
    );
    return response.data ?? {};
  }, [country, materialGroupId]);

  const workSafetyMaterialGroupResponseTranslation = useAsync(async () => {
    if (!!translationCountryCode) {
      const response = await api.materialGroupService.materialGroupServiceMaterialGroupWorkSafetyDetail(
        translationCountryCode,
        materialGroupId
      );

      return response.data ?? {};
    }

    return {};
  }, [translationCountryCode, materialGroupId]);

  const generalHint = useAsync(async () => {
    const response = await api.admin.adminGeneralHintDetail(country);
    return response.data ?? {};
  }, [country]);

  const generalHintTranslation = useAsync(async () => {
    const response = await api.admin.adminGeneralHintDetail(translationCountryCode);
    return response.data ?? {};
  }, [translationCountryCode]);

  async function updateData(data: WorksafetyRequestModel) {
    try {
      await api.materialGroupService.materialGroupServiceMaterialGroupWorkSafetyCreate(country, materialGroupId, data);
      notification.success(t('Speichern erfolgreich'));
      props.cleanUpForm();
      await workSafetyMaterialGroupResponse.execute();
    } catch (error) {
      notification.error(t('Speichern fehlgeschlagen'));
      throw error;
    }
  }

  return (
    <>
      <CircleLoader
        open={workSafetyMaterialGroupResponse.loading || workSafetyMaterialGroupResponseTranslation.loading}
      />
      <Formik
        enableReinitialize={!workSafetyMaterialGroupResponse.loading && !generalHint.loading}
        innerRef={formikRef}
        initialValues={workSafetyMaterialGroupResponse.result as WorksafetyResponseModel}
        onSubmit={updateData}>
        {(formik) => (
          <Form
            className={classes.Container}
            onChange={(e) => {
              onDirtyChanged();
              formik.handleChange(e);
            }}>
            <Grid container item xs={showTranslationPanel ? 12 : 8}>
              <Grid className={classes.ActionButtonGrid} item xs={12}>
                {isAuthorizedToWrite && countrySelectionComponent}
                {isAuthorizedToWrite && (
                  <>
                    <PIMButton
                      className={classes.ActionButton}
                      disabled={formik.isSubmitting || workSafetyMaterialGroupResponse.loading || !formik.dirty}
                      icon='close'
                      onClick={() => formik.handleReset()}>
                      {t('Abbrechen')}
                    </PIMButton>
                    <PIMButton
                      className={classes.ActionButton}
                      icon='save'
                      disabled={!formik.dirty || workSafetyMaterialGroupResponse.loading}
                      busy={formik.isSubmitting}
                      color='primary'
                      type='submit'>
                      {t('Speichern')}
                    </PIMButton>
                  </>
                )}
              </Grid>
              <Grid container item spacing={2}>
                <Grid item xs={showTranslationPanel ? 6 : 12}>
                  <WorkSafetyAttributes generalHint={generalHint.result} readOnly={!isAuthorizedToWrite} />
                </Grid>
                {showTranslationPanel && (
                  <Grid item xs={6}>
                    <Formik
                      enableReinitialize={
                        !workSafetyMaterialGroupResponseTranslation.loading && !generalHintTranslation.loading
                      }
                      initialValues={workSafetyMaterialGroupResponseTranslation.result as WorksafetyRequestModel}
                      onSubmit={() => {
                        return;
                      }}>
                      <WorkSafetyAttributes readOnly generalHint={generalHintTranslation?.result} />
                    </Formik>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}

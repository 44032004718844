import React, { ReactElement } from 'react';
import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';
import Radio, { RadioProps } from '@mui/material/Radio';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  Radio: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    '&.Mui-disabled': {
      opacity: 0.5,
    },
    '& .MuiFormControlLabel-label.Mui-disabled': {
      color: '#000000 !important',
    },
  },
}));

type PIMFormikRadioProps = RadioProps & Omit<FormControlLabelProps, 'control'>;

export function PIMFormikRadio(props: PIMFormikRadioProps): ReactElement {
  const classes = useStyles();

  return (
    <FormControlLabel
      control={
        <Radio
          className={classes.Radio}
          icon={
            <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <circle cx='10' cy='10' r='9' stroke='#737373' strokeWidth='2' />
            </svg>
          }
          checkedIcon={
            <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z'
                fill='#AA8A08'
              />
              <circle cx='10' cy='10' r='4.5' fill='#FFDD00' stroke='#AA8A08' />
            </svg>
          }
          inputProps={{ 'aria-label': 'decorative checkbox' }}
          {...props}
        />
      }
      {...props}
      className={[classes.Radio, props.className].join(' ')}
    />
  );
}

import React, { useState } from 'react';
import { useAsync } from 'react-async-hook';
import { Grid, makeStyles, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { AdminDocumentResponse, DocumentCategory, useMurexinPimContextApi } from '../../../api';
import { AdminBreadCrumbs } from '../../../layouts/AdminBreadCrumbs/AdminBreadCrumbs';
import PIMSelect from '../../../shared/Components/PIMSelect';
import PIMTextField from '../../../shared/Components/PIMTextField';
import { GridColumns, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { SelectedIcon } from '../../../shared/icons';
import ListView from '../../../shared/Components/ListView';
import Utility from '../../../shared/Utility';
import { documentCategories, documentLevels } from '../../../shared/DocumentTranslations';
import { DocumentSidebar } from './DocumentSidebar';

interface IExportBoolean {
  label: string;
  value: number;
}

export default function Documents(): JSX.Element {
  const { t } = useTranslation();
  const api = useMurexinPimContextApi();
  const classes = useStyles();
  const header = t('Dokumente');
  const [searchText, setSearch] = useState('');
  const [selectedCountryCode, setSelectedCountryCode] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [selectedLevel, setSelectedLevel] = useState('');
  const [selectedDetail, setSelectedDetails] = useState('');
  const [selectedExport, setSelectedExport] = useState<number | string>('');
  const [selectedDocument, setSelectedDocument] = useState<AdminDocumentResponse | undefined>(undefined);

  const renderColumns = (): GridColumns => {
    const columns: GridColumns = [
      { field: 'id', headerName: '', hide: true },
      {
        field: 'fileName',
        headerName: t('Dateiname'),
        flex: 4,
        minWidth: 400,
        sortable: false,
        filterable: false,
        hideSortIcons: true,
        disableColumnMenu: true,
      },
      {
        field: 'documentCategory',
        headerName: t('Typ'),
        flex: 2,
        sortable: false,
        filterable: false,
        hideSortIcons: true,
        disableColumnMenu: true,
        valueGetter: (params: GridValueGetterParams) =>
          params.value && t(documentCategories.find((c) => c.id === params.value)?.name ?? ''),
      },
      {
        field: 'countryName',
        headerName: t('Land'),
        flex: 1,
        sortable: false,
        filterable: false,
        hideSortIcons: true,
        disableColumnMenu: true,
      },
      {
        field: 'documentLevel',
        headerName: t('Ebene'),
        flex: 2,
        sortable: false,
        filterable: false,
        hideSortIcons: true,
        disableColumnMenu: true,
        valueGetter: (params: GridValueGetterParams) =>
          params.value && t(documentLevels.find((c) => c.id === params.value)?.name ?? ''),
      },
      {
        field: 'detailDescription',
        headerName: t('Detail'),
        flex: 3,
        sortable: false,
        filterable: false,
        hideSortIcons: true,
        disableColumnMenu: true,
      },

      {
        field: 'createdAt',
        headerName: t('Uploaddatum'),
        type: 'datetime',
        width: 150,
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        filterable: false,
        hideSortIcons: true,
        disableColumnMenu: true,
        valueGetter: (params: GridValueGetterParams) => params.value && Utility.formatDate(params.value as Date),
      },
      {
        field: 'isExported',
        type: 'boolean',
        headerName: t('Für Export'),
        width: 150,
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        filterable: false,
        hideSortIcons: true,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams): React.ReactNode => {
          if (params.value === true) {
            return <SelectedIcon />;
          } else {
            return <></>;
          }
        },
      },
    ];
    return columns;
  };

  const isExported: IExportBoolean[] = [
    { label: t('Ja'), value: 0 },
    { label: t('Nein'), value: 1 },
  ];

  const isExportEnabledForCategory = (category: DocumentCategory): boolean => {
    return category !== DocumentCategory.ExternalLayout;
  };

  const documentsResponse = useAsync(async () => {
    const result = await api.admin.adminDocumentAdminList();
    return result?.data ?? [];
  }, []);

  const details = useAsync(async () => {
    setSelectedDetails('');
    const result = await api.admin.adminDocumentAdminDetailsList();
    return result?.data ?? [];
  }, []);

  const countries = useAsync(async () => {
    const countryListResponse = await api.countryService.countryServiceList();
    return countryListResponse.data ?? [];
  }, []);

  const filteredDocuments = () => {
    let documents = documentsResponse?.result ?? [];
    const searchTextList = searchText.trim().toLocaleLowerCase().split(' ');

    documents = documents.filter((document: AdminDocumentResponse) => {
      let filtersInclude =
        (selectedType === '' || document.documentCategory === selectedType) &&
        (selectedCountryCode === '' || document.countryCode === selectedCountryCode) &&
        (selectedLevel === '' || document.documentLevel === selectedLevel) &&
        (selectedExport === '' || document.isExported === (selectedExport === 0)) &&
        (selectedDetail === '' || document.detailName === selectedDetail);

      if (filtersInclude) {
        filtersInclude = searchTextList.every((searchTerm: string) => {
          const includesSearchTerm =
            searchTerm === '' ||
            document.fileName?.toLocaleLowerCase().includes(searchTerm) ||
            document.detailDescription?.toLocaleLowerCase().includes(searchTerm);

          return includesSearchTerm;
        });
      }
      return filtersInclude;
    });
    return documents;
  };

  const openSidebar = (document: AdminDocumentResponse | undefined) => {
    setSelectedDocument(document);
  };

  const reloadDocuments = async () => {
    await documentsResponse.execute();
  };

  const closeSidebar = () => {
    setSelectedDocument(undefined);
  };

  return (
    <>
      <AdminBreadCrumbs site={header} />
      <Grid container className={classes.Container}>
        <Grid item xs={selectedDocument !== undefined ? 8 : 12} className={classes.MainPanel}>
          <Grid item xs={6}>
            <div className={classes.Title}>{header}</div>
          </Grid>
          <Grid container item xs={6} className={classes.ActionButtonRow}>
            {/* TODO in a follow up story */}
            {/* <PIMButton
              icon='add'
              color='primary'
              onClick={() => {
                addDocument();
              }}
              className={classes.TopButtons}>
              {t('Hinzufügen')}
            </PIMButton>  */}
          </Grid>
          <Grid container item spacing={2}>
            <Grid container item spacing={2}>
              <Grid item xs={4}>
                <PIMTextField
                  label={t('Filter')}
                  value={searchText}
                  fullWidth
                  onChange={(event) => {
                    setSearch(event.target.value ?? '');
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <PIMSelect
                  label={t('Typ')}
                  removeSelectionText={t('Auswahl entfernen')}
                  value={selectedType}
                  disabled={documentCategories.length === 0}
                  shrinkLabel={selectedType !== ''}
                  className={classes.Select}
                  onChange={(e) => {
                    setSelectedType(e.target.value as string);
                  }}>
                  {documentCategories.map((d) => (
                    <MenuItem key={d.id} value={d.id}>
                      {d.name}
                    </MenuItem>
                  ))}
                </PIMSelect>
              </Grid>
              <Grid item xs={4}>
                <PIMSelect
                  label={t('Land')}
                  removeSelectionText={t('Auswahl entfernen')}
                  value={selectedCountryCode}
                  disabled={countries?.result?.length === 0}
                  shrinkLabel={selectedCountryCode !== ''}
                  className={classes.Select}
                  onChange={(e) => {
                    setSelectedCountryCode(e.target.value as string);
                  }}>
                  {countries?.result?.map((d) => (
                    <MenuItem key={d.code} value={d.code ?? ''}>
                      {d.name}
                    </MenuItem>
                  ))}
                </PIMSelect>
              </Grid>
            </Grid>
            <Grid container item spacing={2}>
              <Grid item xs={4}>
                <PIMSelect
                  label={t('Ebene')}
                  removeSelectionText={t('Auswahl entfernen')}
                  value={selectedLevel}
                  disabled={documentLevels.length === 0}
                  shrinkLabel={selectedLevel !== ''}
                  className={classes.Select}
                  onChange={(e) => {
                    setSelectedLevel(e.target.value as string);
                  }}>
                  {documentLevels.map((d) => (
                    <MenuItem key={d.id} value={d.id}>
                      {d.name}
                    </MenuItem>
                  ))}
                </PIMSelect>
              </Grid>
              <Grid item xs={4}>
                <PIMSelect
                  label={t('Detail')}
                  removeSelectionText={t('Auswahl entfernen')}
                  value={selectedDetail}
                  disabled={details?.result?.length === 0}
                  className={classes.Select}
                  shrinkLabel={selectedDetail !== ''}
                  onChange={(e) => {
                    setSelectedDetails(e.target.value as string);
                  }}>
                  {details?.result?.map((d) => (
                    <MenuItem key={d.detailName} value={d.detailName}>
                      {d.detailDescription}
                    </MenuItem>
                  ))}
                </PIMSelect>
              </Grid>
              <Grid item xs={4}>
                <PIMSelect
                  label={t('Export zur Website')}
                  removeSelectionText={t('Auswahl entfernen')}
                  value={selectedExport}
                  disabled={countries?.result?.length === 0}
                  shrinkLabel={selectedExport !== ''}
                  className={classes.Select}
                  onChange={(e) => {
                    setSelectedExport((e.target?.value as number) ?? '');
                  }}>
                  {isExported.map((d) => (
                    <MenuItem key={d.value} value={d.value}>
                      {d.label}
                    </MenuItem>
                  ))}
                </PIMSelect>
              </Grid>
            </Grid>
          </Grid>
          <Grid container className={classes.DocumentsGrid}>
            <ListView
              loading={documentsResponse.loading}
              columns={renderColumns()}
              data={filteredDocuments()}
              noResultsMessage={t('Keine Ergebnisse gefunden.')}
              onChangeSelection={(document) => openSidebar(document)}
            />
          </Grid>
        </Grid>
        {selectedDocument && (
          <Grid item xs={4} className={classes.Sidebar}>
            <DocumentSidebar
              document={selectedDocument}
              countries={countries.result ?? []}
              details={details.result ?? []}
              reloadDocuments={reloadDocuments}
              onCloseSidebar={closeSidebar}
              isExportEnabledForCategory={isExportEnabledForCategory}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}

const useStyles = makeStyles(() => ({
  Container: {
    marginTop: '40px',
    paddingLeft: '16px',
    height: '100%',
  },
  MainPanel: {
    paddingRight: '24px',
  },
  Sidebar: {
    marginTop: '-116px',
    minHeight: '100%',
  },
  DocumentsGrid: {
    margin: '24px 0px',
  },
  Title: {
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '36px',
    marginBottom: '20px',
  },
  Select: { width: '100%' },
  ActionButtonRow: {
    flexWrap: 'nowrap',
    justifyContent: 'flex-end',
    marginBottom: '24px',
  },
  TopButtons: {
    marginLeft: '24px',
  },
}));

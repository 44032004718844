import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core';
import { FormikCheckbox, FormikCheckboxProps } from '@dccs/react-formik-mui';

const useStyles = makeStyles(() => ({
  checkBox: {
    '&.Mui-disabled': {
      opacity: 0.3,
    },
  },
  label: {
    '& .MuiTypography-body1': {
      fontSize: 18,
      lineHeight: '120%',
    },
  },
}));

export function PIMFormikToggle(props: FormikCheckboxProps): ReactElement {
  const classes = useStyles();
  const { className = '', inputProps, formControlProps, formControlLabelProps, ...rest } = props;

  return (
    <FormikCheckbox
      className={`${classes.checkBox} ${className}`}
      icon={
        <svg width='58' height='32' viewBox='0 0 58 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <rect x='2.5' y='0.5' width='55' height='31' rx='15.5' fill='#8F8F8F' fillOpacity='0.4' stroke='#737373' />
          <circle cx='16' cy='16' r='15' fill='#8F8F8F' stroke='#737373' strokeWidth='2' />
        </svg>
      }
      checkedIcon={
        <svg width='58' height='32' viewBox='0 0 58 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <rect x='0.5' y='0.5' width='55' height='31' rx='15.5' fill='#FFDD00' fillOpacity='0.2' stroke='#AA8A08' />
          <circle cx='39.5881' cy='16' r='15' fill='#FFDD00' stroke='#AA8A08' strokeWidth='2' />
        </svg>
      }
      inputProps={{ 'aria-label': 'decorative checkbox', ...inputProps }}
      formControlProps={{ ...formControlProps }}
      formControlLabelProps={{ labelPlacement: 'end', className: classes.label, ...formControlLabelProps }}
      useField
      {...rest}
    />
  );
}

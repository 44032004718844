import { Card, createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import PIMFormikTextField from '../../../../shared/Components/PIMFormikTextField';

interface IProps {
  readonly?: boolean;
  authorizedToWrite?: boolean;
}

export default function DescriptionAttributes(props: IProps): ReactElement {
  const classes = useStyles();
  const { readonly, authorizedToWrite } = props;
  const { t } = useTranslation();
  const disabled = readonly || !authorizedToWrite;
  return (
    <Card className={classes.Card}>
      <PIMFormikTextField
        label={t('Werkzeugtext')}
        name='toolText'
        className={classes.TextField}
        fullWidth
        multiline
        disabled={disabled}
      />
      <PIMFormikTextField
        label={t('Anmischen')}
        name='mixing'
        className={classes.TextField}
        fullWidth
        multiline
        disabled={disabled}
      />
      <PIMFormikTextField
        label={t('Verarbeiten')}
        name='process'
        className={classes.TextField}
        fullWidth
        multiline
        disabled={disabled}
      />
      <PIMFormikTextField
        label={t('Verarbeiten (Kurztext)')}
        name='processingShort'
        className={classes.TextField}
        fullWidth
        multiline
        disabled={disabled}
      />
    </Card>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    TextField: {
      '&.MuiTextField-root': {
        marginTop: '8px',
        marginBottom: '8px',
      },
    },
    Card: {
      padding: theme.spacing(2),
    },
  })
);

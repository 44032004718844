import React, { useState } from 'react';
import { createStyles, makeStyles, Grid } from '@material-ui/core';
import ListView from '../../../shared/Components/ListView';
import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid';
import { useAsync } from 'react-async-hook';
import { MaterialGroupResponse, useMurexinPimContextApi } from '../../../api';
import PIMTextField from '../../../shared/Components/PIMTextField';
import { useTranslation } from 'react-i18next';
import { IRouterParams } from '../../../routes';
import { useParams } from 'react-router-dom';
import { CircleLoader } from '../../../utils/CircleLoader';
import { CountryAdminBreadCrumbs } from '../../../layouts/CountryAdminBreadCrumbs/CountryAdminBreadCrumbs';
import { SelectedIcon } from '../../../shared/icons';
import { MaterialGroupSidebar } from './MaterialGroupSidebar';
import { SidebarMode } from '../../../shared/Enum';

type MaterialGroupResponseModel = Omit<MaterialGroupResponse, 'id'> & {
  id: number;
};

export function MaterialGroupDescription(): JSX.Element {
  const classes = useStyles();
  const api = useMurexinPimContextApi();
  const [selectedMaterialGroupCountry, setSelectedMaterialGroupCountry] =
    useState<undefined | MaterialGroupResponseModel>();
  const [searchText, setSearchText] = useState<string>('');
  const { t } = useTranslation();
  const { country, productId } = useParams<IRouterParams>();

  const countryName = useAsync(async () => {
    const response = await api.countryService.countryServiceList();
    return response.data.find((c) => c.code === country)?.name;
  }, [country]);

  const productGroupResponse = useAsync(async () => {
    const response = await api.productService.productServiceGetByIdDetail(country, Number(productId));
    return response.data ?? {};
  }, []);

  const materialGroupCountryList = useAsync(async () => {
    const response = await api.materialGroupService.materialGroupServiceByProductIdDetail(country, Number(productId));
    return response.data ?? [];
  }, [country]);

  const columnDefinition: GridColumns = [
    { field: 'id', headerName: '', hide: true },
    { field: 'materialGroupName', headerName: t('Warengruppencode'), width: 200 },
    { field: 'materialGroupDescription', headerName: `${t('Warengruppenbezeichnung Original')}`, width: 400 },
    {
      field: 'materialGroupTitle',
      headerName: `${t('Warengruppenbezeichnung')} ${countryName.result ?? country}`,
      width: 400,
    },
    {
      field: 'erpImport',
      type: 'boolean',
      hide: false,
      headerName: t('Freie Warengruppe'),
      width: 200,
      align: 'center',
      sortable: false,
      filterable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams): React.ReactNode => {
        if (params.value === false) {
          return <SelectedIcon />;
        } else {
          return <></>;
        }
      },
    },
  ];

  const getMaterialGroupCountryList = (): MaterialGroupResponseModel[] => {
    let result = materialGroupCountryList.result ?? [];
    if (searchText) {
      result = result.filter((item: MaterialGroupResponse) => {
        return (
          item.materialGroupName?.toLowerCase().includes(searchText.toLowerCase()) ||
          item.materialGroupDescription?.toLowerCase().includes(searchText.toLowerCase()) ||
          item.materialGroupTitle?.toLowerCase().includes(searchText.toLowerCase())
        );
      });
    }
    return result as MaterialGroupResponseModel[];
  };

  if (materialGroupCountryList.loading || productGroupResponse.loading) return <CircleLoader open={true} />;
  else
    return (
      <Grid container className={classes.Container}>
        <CountryAdminBreadCrumbs site={t('Warengruppen bearbeiten')} />
        <Grid container item xs={12} className={classes.DivisionCountryPage}>
          <Grid item xs={8} className={classes.DivisionCountryGrid}>
            <div className={classes.Title}>{t('Warengruppen bearbeiten')}</div>
            <Grid item xs={12}>
              <PIMTextField
                label={t('Element suchen')}
                className={classes.SearchTextField}
                fullWidth
                onChange={(event) => setSearchText(event.target.value ?? '')}
              />
            </Grid>
            <Grid item xs={12}>
              <ListView
                columns={columnDefinition}
                data={getMaterialGroupCountryList()}
                onChangeSelection={(item) => {
                  return item?.id === selectedMaterialGroupCountry?.id
                    ? setSelectedMaterialGroupCountry(undefined)
                    : setSelectedMaterialGroupCountry(item);
                }}
                selectedItem={selectedMaterialGroupCountry}
              />
            </Grid>
          </Grid>
          {selectedMaterialGroupCountry && (
            <Grid item xs={4} className={classes.Sidebar}>
              <MaterialGroupSidebar
                mode={SidebarMode.Update}
                handleOnCancel={() => {
                  setSelectedMaterialGroupCountry(undefined);
                }}
                materialGroup={selectedMaterialGroupCountry}
                productGroupName={productGroupResponse.result?.productGroupName ?? ''}
                countryName={countryName.result ?? country}
                handleOnClose={async () => {
                  await materialGroupCountryList.execute();
                  setSelectedMaterialGroupCountry(undefined);
                }}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    );
}

const useStyles = makeStyles(() =>
  createStyles({
    DivisionCountryPage: {
      padding: '24px 0px 0px 8px',
    },
    DivisionCountryGrid: {
      paddingRight: '24px',
      minHeight: '100%',
    },
    SearchTextField: {
      marginBottom: '24px',
    },
    TitleRow: {
      fontSize: '36px',
      padding: '0 0 24px 0',
    },
    Sidebar: {
      minHeight: '100%',
      marginTop: '-100px',
    },
    Container: {
      height: '100%',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      margin: '0px 0px 0px 0px',
    },
    Title: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '36px',
      marginBottom: '24px',
      lineHeight: '45px',
    },
  })
);

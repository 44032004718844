import React from 'react';

interface IProps {
  className?: string;
}

export function SearchIcon(props: IProps): JSX.Element {
  const { className } = props;

  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}>
      <mask id='mask0_4961_8246' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'>
        <rect width='24' height='24' fill='white' />
      </mask>
      <g mask='url(#mask0_4961_8246)'>
        <ellipse
          cx='10.3894'
          cy='10.3887'
          rx='8.056'
          ry='8.056'
          transform='rotate(-23.025 10.3894 10.3887)'
          stroke='black'
        />
        <path d='M16.0854 16.0844L22.3335 22.3333' stroke='black' />
      </g>
    </svg>
  );
}

import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { createStyles, makeStyles, Grid, IconButton } from '@material-ui/core';
import { NotificationContext } from '../../../../contexts/NotificationContext';
import {
  CategoryResponse,
  SealOfApprovalAndNormMaterialGroupResponse,
  useMurexinPimContextApi,
  SealOfApprovalResponse,
  SealOfApprovalAndNormMaterialGroupRequest,
  CountryPermission,
} from '../../../../api';
import { CircleLoader } from '../../../../utils/CircleLoader';
import { SealOfApprovalAndNormSidebar } from './SealOfApprovalAndNormSidebar';
import { Form, Formik, FormikProps, FieldArrayRenderProps, FieldArray } from 'formik';
import { DataView } from '../../../../shared/Enum/DataView';
import { ListViewIcon, GridViewIcon } from '../../../../shared/icons';
import PIMButton from '../../../../shared/Components/PIMButton';
import { useAsync } from 'react-async-hook';
import { SealOfApprovalAndNormAttributes } from './SealOfApprovalAndNormAttributes';
import { useTranslation } from 'react-i18next';
import { AuthorizationContext } from '../../../../auth';
import Utility from '../../../../shared/Utility';
import useFormikSaveAndClose from '../../../../utils/customHooks/useFormikSaveAndClose';
import { ProductManagementProps } from '../../ProductManagement/ProductManagement';

interface IProps {
  countrySelectionComponent: React.ReactNode;
  translationCountryCode: string;
}

interface IParams {
  country: string;
  divisionId: string;
  productId: string;
  materialId: string;
  articleId?: string;
}

export interface SealOfApproval {
  id: string;
  category: CategoryTranslationResponse;
  fileName: string;
  fullName: string;
  isUsed: boolean;

  createdAt?: Date;
  createdBy?: string;

  updatedAt?: Date;
  updatedBy?: string;
}

export interface CategoryTranslationResponse extends CategoryResponse {
  isExpanded?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    Form: {
      height: '100%',
    },
    Container: {
      height: '100%',
      alignItems: 'flex-start',
      flexWrap: 'nowrap',
      margin: '0px',
    },
    DataViewRow: {
      justifyContent: 'flex-end',
      marginTop: '-155px',
    },
    DataViewButton: {
      opacity: '0.5',
      marginRight: '8px',
      '&.Mui-disabled': {
        opacity: '1',
      },
    },
    TopButtons: {
      marginLeft: '24px',
    },
    SaveButton: {
      marginRight: '16px',
      marginLeft: '24px',
    },
    Sidebar: {
      minHeight: '100%',
      marginTop: '-231px',
      zIndex: 1101,
      backgroundColor: 'white',
    },
    ActionButtonRow: {
      justifyContent: 'flex-end',
      marginTop: '27px',
      paddingBottom: '16px',
    },
    FullHeight: {
      minHeight: '100%',
    },
    ExtraMargin: {
      marginTop: '100px',
    },
  })
);

export function SealOfApprovalAndNorm(props: IProps & ProductManagementProps): JSX.Element {
  const classes = useStyles();
  const notification = useContext(NotificationContext);
  const { country, materialId } = useParams<IParams>();
  const materialGroupId = Number(materialId);
  const api = useMurexinPimContextApi();
  const [selectedSeal, setSelectedSeal] = useState<SealOfApproval | undefined>(undefined);

  const [dataView, setDataView] = useState<DataView>(DataView.Grid);
  const { translationCountryCode, countrySelectionComponent, saveOnCloseRef, onDirtyChanged, cleanUpForm } = props;
  const { hasCountryPermission } = useContext(AuthorizationContext);
  const isAuthorizedToWrite = hasCountryPermission(country, CountryPermission.Write);
  const showTranslationPanel = !!translationCountryCode && !selectedSeal && isAuthorizedToWrite;
  const { t } = useTranslation();
  const formikRef = useFormikSaveAndClose<SealOfApprovalAndNormMaterialGroupResponse>(saveOnCloseRef);
  const [categories, setCategories] = useState<CategoryTranslationResponse[]>([]);

  const onClickCategory = (clickedCategory: CategoryTranslationResponse, expanded: boolean) => {
    const updatedCategory = { ...clickedCategory, isExpanded: expanded };
    const updatedCategories = categories.map((c) => (c === clickedCategory ? updatedCategory : c));
    setCategories(updatedCategories);
  };

  const categoriesResponse = useAsync(async () => {
    const response = await api.category.categoryList();
    const categoriesList = response.data ?? [];
    Utility.sortCategoriesResponse(categoriesList);
    setCategories(categoriesList);
  }, []);

  const sealOfApprovalImagesResponse = useAsync(async () => {
    const response = await api.sealOfApprovalAndNorm.sealOfApprovalAndNormImagesDetail(country);
    return response.data ?? [];
  }, [country]);

  const sealOfApprovalImagesTranslationResponse = useAsync(async () => {
    if (!!translationCountryCode) {
      const response = await api.sealOfApprovalAndNorm.sealOfApprovalAndNormImagesDetail(translationCountryCode);
      return response.data ?? [];
    }
    return [];
  }, [translationCountryCode]);

  const sealsOfApprovalResponse = useAsync(async () => {
    const response = await api.sealOfApprovalAndNorm.sealOfApprovalAndNormMaterialGroupDetail(country, materialGroupId);
    return response.data ?? {};
  }, [country, materialGroupId]);

  const sealsOfApprovalTranslationResponse = useAsync(async () => {
    if (!!translationCountryCode) {
      const response = await api.sealOfApprovalAndNorm.sealOfApprovalAndNormMaterialGroupDetail(
        translationCountryCode,
        materialGroupId
      );
      return response.data ?? {};
    }
    return {};
  }, [translationCountryCode, materialGroupId]);

  const isLoaded = (): boolean => {
    return (
      !categoriesResponse.loading &&
      !sealOfApprovalImagesResponse.loading &&
      !sealOfApprovalImagesTranslationResponse.loading &&
      !sealsOfApprovalResponse.loading &&
      !sealsOfApprovalTranslationResponse.loading
    );
  };

  const handleFormOnChange = (
    event: React.FormEvent<HTMLFormElement> | undefined,
    formik: FormikProps<SealOfApprovalAndNormMaterialGroupResponse | {}>
  ) => {
    formik.handleChange(event);
    onDirtyChanged();
  };

  const handleClickSealOfApproval = (sealOfApproval: SealOfApproval | undefined) => {
    if (selectedSeal !== undefined && selectedSeal.id === sealOfApproval?.id) {
      setSelectedSeal(undefined);
    } else {
      setSelectedSeal(sealOfApproval);
    }
  };

  const onChangeSealOfApproval = (formikArrayHelpers: FieldArrayRenderProps, sealOfApproval: SealOfApproval) => {
    const sealOfApprovalValues =
      (formikArrayHelpers.form.values as SealOfApprovalAndNormMaterialGroupResponse).sealOfApprovals ?? [];

    if (sealOfApprovalValues !== null && sealOfApprovalValues !== undefined) {
      const index = sealOfApprovalValues.findIndex(
        (s: SealOfApprovalResponse) => s.fullName === sealOfApproval.fullName
      );

      if (sealOfApproval.isUsed) {
        if (index < 0) {
          formikArrayHelpers.push(sealOfApproval);
        }
      } else {
        if (index >= 0) {
          formikArrayHelpers.remove(index);
        }
      }
    }
  };

  const getRequestModel = (
    values: SealOfApprovalAndNormMaterialGroupResponse
  ): SealOfApprovalAndNormMaterialGroupRequest => {
    const sealOfApprovalAndNormMaterialGroupRequest: SealOfApprovalAndNormMaterialGroupRequest = {
      countryCode: country,
      materialGroupId,
    };

    if (values !== null && values !== undefined) {
      sealOfApprovalAndNormMaterialGroupRequest.id = values.id;
      sealOfApprovalAndNormMaterialGroupRequest.norm = values.norm;

      if (values.sealOfApprovals !== null && values.sealOfApprovals !== undefined) {
        sealOfApprovalAndNormMaterialGroupRequest.sealOfApprovals = values.sealOfApprovals.map(
          (sealOfApproval: SealOfApprovalResponse) => {
            return {
              fullName: sealOfApproval.fullName,
            };
          }
        );
      }
    }

    return sealOfApprovalAndNormMaterialGroupRequest;
  };

  const saveSealOfApprovalByMaterialGroup = async (values: SealOfApprovalAndNormMaterialGroupResponse) => {
    const sealOfApprovalAndNormMaterialGroupRequest = getRequestModel(values);

    try {
      await api.sealOfApprovalAndNorm.sealOfApprovalAndNormMaterialGroupCreate(
        country,
        materialGroupId,
        sealOfApprovalAndNormMaterialGroupRequest
      );
      setSelectedSeal(undefined);
      await sealOfApprovalImagesResponse.execute();
      await sealsOfApprovalResponse.execute();
      cleanUpForm();
      notification.success(t('Hochladen erfolgreich'));
    } catch (error) {
      notification.error(t('Hochladen fehlgeschlagen'));
    }
  };

  return (
    <>
      <CircleLoader open={!isLoaded()} />
      <Formik
        initialValues={sealsOfApprovalResponse.result !== undefined ? sealsOfApprovalResponse.result : {}}
        innerRef={formikRef}
        onSubmit={saveSealOfApprovalByMaterialGroup}
        enableReinitialize={true}>
        {(formik) => (
          <Form
            className={classes.Form}
            onChange={(event: React.FormEvent<HTMLFormElement> | undefined) => handleFormOnChange(event, formik)}>
            <FieldArray
              name='sealOfApprovals'
              render={(arrayHelpers) => (
                <Grid container className={classes.Container}>
                  <Grid container item xs={12} className={classes.FullHeight}>
                    <Grid item xs={showTranslationPanel && !selectedSeal ? 12 : 8} className={classes.FullHeight}>
                      <Grid container item xs={12} className={classes.DataViewRow}>
                        <IconButton
                          className={classes.DataViewButton}
                          disabled={dataView === DataView.List}
                          onClick={() => setDataView(DataView.List)}>
                          <ListViewIcon />
                        </IconButton>
                        <IconButton
                          className={classes.DataViewButton}
                          disabled={dataView === DataView.Grid}
                          onClick={() => setDataView(DataView.Grid)}>
                          <GridViewIcon />
                        </IconButton>
                      </Grid>
                      {isAuthorizedToWrite && (
                        <Grid container item xs={12} className={classes.ActionButtonRow}>
                          {countrySelectionComponent}
                          <PIMButton
                            className={classes.TopButtons}
                            icon='close'
                            disabled={formik.isSubmitting || !formik.dirty || isLoaded() === false}
                            onClick={() => {
                              notification.info(t('Daten zurückgesetzt'));
                              formik.handleReset();
                              setSelectedSeal(undefined);
                            }}>
                            {t('Abbrechen')}
                          </PIMButton>
                          <PIMButton
                            color='primary'
                            icon='save'
                            className={classes.SaveButton}
                            busy={formik.isSubmitting}
                            disabled={formik.isSubmitting || !formik.dirty || isLoaded() === false}
                            type='submit'>
                            {t('Speichern')}
                          </PIMButton>
                        </Grid>
                      )}
                      <Grid
                        container
                        item
                        className={
                          isAuthorizedToWrite ? classes.FullHeight : `${classes.FullHeight} ${classes.ExtraMargin}`
                        }>
                        <Grid item xs={showTranslationPanel && !selectedSeal ? 6 : 12}>
                          <SealOfApprovalAndNormAttributes
                            country={country}
                            categoriesResponse={categories}
                            sealOfApprovalImagesResponse={sealOfApprovalImagesResponse.result ?? []}
                            sealOfApprovalAndNormData={formik.values}
                            dataView={dataView}
                            authorizedToWrite={isAuthorizedToWrite}
                            selectedSeal={selectedSeal}
                            onClickCategory={onClickCategory}
                            onClickSealOfApproval={(sealOfApproval) => handleClickSealOfApproval(sealOfApproval)}
                          />
                        </Grid>
                        {!!showTranslationPanel && !selectedSeal && (
                          <Grid item xs={6}>
                            <Formik
                              enableReinitialize={
                                !sealsOfApprovalTranslationResponse.loading &&
                                !sealOfApprovalImagesTranslationResponse.loading
                              }
                              initialValues={sealsOfApprovalTranslationResponse.result ?? {}}
                              onSubmit={() => {
                                return;
                              }}>
                              {(formikTranslation) => (
                                <SealOfApprovalAndNormAttributes
                                  country={translationCountryCode}
                                  categoriesResponse={categories}
                                  sealOfApprovalImagesResponse={sealOfApprovalImagesTranslationResponse.result ?? []}
                                  sealOfApprovalAndNormData={formikTranslation.values}
                                  dataView={dataView}
                                  readOnly
                                  showOnlyUsedSeals={true}
                                  onClickCategory={onClickCategory}
                                />
                              )}
                            </Formik>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    {selectedSeal && (
                      <Grid item xs={4} className={classes.Sidebar}>
                        <SealOfApprovalAndNormSidebar
                          sealOfApproval={selectedSeal}
                          authorizedToWrite={isAuthorizedToWrite}
                          onCloseSidebar={() => {
                            setSelectedSeal(undefined);
                          }}
                          onChange={(sealOfApproval: SealOfApproval) =>
                            onChangeSealOfApproval(arrayHelpers, sealOfApproval)
                          }
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}
            />
          </Form>
        )}
      </Formik>
    </>
  );
}

import React from 'react';
import { SearchMaterialGroupResponse, useMurexinPimContextApi } from '../../../../api';
import Box from '@mui/material/Box';
import { createStyles, makeStyles } from '@material-ui/core';
import { ApiImageUrlsBuilder } from '../../../../utils/apiImageUrls';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import { useAsync } from 'react-async-hook';
import PIMTextField from '../../../../shared/Components/PIMTextField';
import { useTranslation } from 'react-i18next';

export interface SearchMaterialGroupDropdownProps<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends Omit<
    AutocompleteProps<SearchMaterialGroupResponse, Multiple, DisableClearable, FreeSolo>,
    'label' | 'options' | 'renderOption' | 'renderInput'
  > {
  country: string;
  hideCountryCode?: boolean;
  hideProductName?: boolean;
  selectText?: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      fontFamily: 'Roboto',
      fontSize: '12px',
      fontWeight: 'normal',
      width: '100%',
    },
    text: {
      margin: '0px 10px',
    },
    textDescription: {
      flex: 1,
      margin: '0px 10px',
    },
    image: {
      display: 'block',
      maxWidth: '32px',
      maxHeight: '32px',
    },
    imageWrapper: {
      width: '32px',
      height: '32px',
    },
  })
);

export function SearchMaterialGroupDropdown<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>(props: SearchMaterialGroupDropdownProps<Multiple, DisableClearable, FreeSolo>): JSX.Element {
  const classes = useStyles();
  const pimApi = useMurexinPimContextApi();
  const { country, hideCountryCode, hideProductName, selectText, ...rest } = props;
  const { t } = useTranslation();

  const renderSearchMaterialGroupOption = (
    renderProps: any,
    materialGroup: SearchMaterialGroupResponse
  ): React.ReactNode => {
    return (
      <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...renderProps}>
        <div className={classes.row}>
          <div className={classes.imageWrapper}>
            {materialGroup.imageStoreMaterialGroupId !== null &&
              materialGroup.imageStoreMaterialGroupId !== undefined && (
                <img
                  className={classes.image}
                  src={ApiImageUrlsBuilder.buildMaterialGroupImageUrl(country, materialGroup.imageStoreMaterialGroupId)}
                />
              )}
          </div>
          {!hideCountryCode && <div className={classes.text}>{country}</div>}
          <div className={classes.textDescription}>
            {!!materialGroup.materialGroupTitle
              ? materialGroup.materialGroupTitle
              : materialGroup.materialGroupDescription}
          </div>
          {!hideProductName && <div className={classes.text}>{materialGroup.productName}</div>}
        </div>
      </Box>
    );
  };

  const materialGroupList = useAsync(async () => {
    const response = await pimApi.materialGroupService.materialGroupServiceSearchDetail(props.country);
    return response.data ?? [];
  }, [country]);

  return (
    <>
      <Autocomplete
        renderInput={(params) => (
          <PIMTextField {...params} label={selectText ?? t('Warengruppe suchen und hinzufügen')} />
        )}
        renderOption={(renderProps, option) => renderSearchMaterialGroupOption(renderProps, option)}
        {...rest}
        loading={materialGroupList.loading}
        options={materialGroupList.result ?? []}
        getOptionLabel={(option) =>
          !!option.materialGroupTitle ? option.materialGroupTitle : option.materialGroupDescription ?? ''
        }
      />
    </>
  );
}

import React, { ReactElement, useContext, useState } from 'react';
import { CountryPermission, TechnicalDetailsResponseModel, useMurexinPimContextApi } from '../../../../api';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useAsync } from 'react-async-hook';
import { useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { CircleLoader } from '../../../../utils/CircleLoader';
import { Grid } from '@material-ui/core';
import { TechnicalDetailAttributes } from './TechnicalDetailAttributes';
import PIMButton from '../../../../shared/Components/PIMButton';
import { IRouterParams } from '../../../../routes';
import { NotificationContext } from '../../../../contexts/NotificationContext';
import { useTranslation } from 'react-i18next';
import { AuthorizationContext } from '../../../../auth';
import { ProductManagementProps } from '../../ProductManagement/ProductManagement';
import useFormikSaveAndClose from '../../../../utils/customHooks/useFormikSaveAndClose';

interface IProps {
  countrySelectionComponent: React.ReactNode;
  translationCountryCode: string;
}

export interface ITechnicalDetailAccordions {
  general?: boolean;
  BAT?: boolean;
  FLT?: boolean;
  FAT?: boolean;
  ESS?: boolean;
  KLT?: boolean;
}

export function TechnicalDetail(props: IProps & ProductManagementProps): ReactElement {
  const api = useMurexinPimContextApi();
  const { country, materialId } = useParams<IRouterParams>();
  const materialGroupId = Number(materialId);
  const classes = useClassess();
  const notification = React.useContext(NotificationContext);
  const { onDirtyChanged, cleanUpForm, translationCountryCode, countrySelectionComponent, saveOnCloseRef } = props;
  const showTranslationPanel = !!translationCountryCode;
  const [expandedAccordions, setExpandedAccordions] = useState<ITechnicalDetailAccordions>({});
  const { t } = useTranslation();
  const { hasCountryPermission } = useContext(AuthorizationContext);
  const isAuthorizedToWrite = hasCountryPermission(country, CountryPermission.Write);

  const formikRef = useFormikSaveAndClose<TechnicalDetailsResponseModel>(saveOnCloseRef);

  const technicalDetails = useAsync(async () => {
    const technicalDetailResponse =
      await api.materialGroupService.materialGroupServiceMaterialGroupTechnicalDetailsDetail(country, materialGroupId);
    return technicalDetailResponse.data ?? {};
  }, [country, materialGroupId]);

  const technicalDetailsTranslation = useAsync(async () => {
    if (!!translationCountryCode) {
      const technicalDetailResponse =
        await api.materialGroupService.materialGroupServiceMaterialGroupTechnicalDetailsDetail(
          translationCountryCode,
          materialGroupId
        );

      return technicalDetailResponse.data ?? {};
    }

    return {};
  }, [translationCountryCode, materialGroupId]);

  async function updateData(values: TechnicalDetailsResponseModel) {
    try {
      await api.materialGroupService.materialGroupServiceMaterialGroupTechnicalDetailsCreate(
        country,
        materialGroupId,
        values
      );
      await technicalDetails.execute();
      cleanUpForm();
      notification.success(t('Speichern erfolgreich'));
    } catch (error) {
      notification.error(t('Speichern fehlgeschlagen'));
      throw error;
    }
  }

  const handleSetExpandedAccordions = (expanded: ITechnicalDetailAccordions) => {
    setExpandedAccordions(expanded);
  };

  return (
    <>
      <CircleLoader open={technicalDetails.loading || technicalDetailsTranslation.loading} />
      <Formik
        enableReinitialize={!technicalDetails.loading}
        innerRef={formikRef}
        initialValues={technicalDetails.result as TechnicalDetailsResponseModel}
        onSubmit={updateData}>
        {({ isSubmitting, dirty, handleChange }) => (
          <Form
            className={classes.Container}
            onChange={(e) => {
              onDirtyChanged();
              handleChange(e);
            }}>
            <Grid container item xs={showTranslationPanel ? 12 : 8}>
              <Grid className={classes.ActionButtonGrid} item xs={12}>
                {isAuthorizedToWrite && countrySelectionComponent}
                {isAuthorizedToWrite && (
                  <>
                    <PIMButton
                      className={classes.ActionButton}
                      disabled={isSubmitting || technicalDetails.loading || !dirty}
                      icon='close'
                      type='reset'>
                      {t('Abbrechen')}
                    </PIMButton>
                    <PIMButton
                      className={classes.ActionButton}
                      icon='save'
                      disabled={!dirty || technicalDetails.loading}
                      busy={isSubmitting}
                      color='primary'
                      type='submit'>
                      {t('Speichern')}
                    </PIMButton>
                  </>
                )}
              </Grid>
              <Grid container item spacing={2}>
                <Grid item xs={showTranslationPanel ? 6 : 12}>
                  <TechnicalDetailAttributes
                    originCountry={true}
                    onDirtyChanged={props.onDirtyChanged}
                    onExpandAccordion={handleSetExpandedAccordions}
                    expandedAccordions={expandedAccordions}
                    authorizedToWrite={isAuthorizedToWrite}
                  />
                </Grid>
                {showTranslationPanel && !technicalDetailsTranslation.loading && (
                  <Grid item xs={6}>
                    <Formik
                      initialValues={technicalDetailsTranslation.result as TechnicalDetailsResponseModel}
                      onSubmit={() => {
                        return;
                      }}>
                      <TechnicalDetailAttributes
                        originCountry={false}
                        onDirtyChanged={props.onDirtyChanged}
                        expandedAccordions={expandedAccordions}
                        onExpandAccordion={handleSetExpandedAccordions}
                        authorizedToWrite={isAuthorizedToWrite}
                      />
                    </Formik>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}

const useClassess = makeStyles(() =>
  createStyles({
    Container: {
      marginRight: '24px',
    },
    ActionButtonGrid: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'flex-end',
      paddingBottom: '32px',
      marginTop: '-80px',
    },
    ActionButton: {
      marginLeft: '24px',
    },
  })
);

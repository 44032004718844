import React from 'react';

export function DownloadIcon(): JSX.Element {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_2552_52601)'>
        <path d='M11.5 19.248V1.248' stroke='black' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path
          d='M7.75 15.498L11.5 19.248L15.25 15.498'
          stroke='black'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M0.25 23.748H22.75' stroke='black' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0_2552_52601'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}

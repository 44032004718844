import React, { ReactElement } from 'react';
import { makeStyles, FormControlLabel, FormControlLabelProps, Checkbox, CheckboxProps } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  Toggle: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    marginTop: '16px',
    marginBottom: '16px',
    '&.Mui-disabled': {
      opacity: 0.5,
    },
    '& .MuiTypography-body1': {
      fontSize: 18,
      lineHeight: '120%',
    },
    '& .MuiFormControlLabel-label.Mui-disabled': {
      color: '#000000 !important',
    },
    '& .MuiSwitch-track': {
      display: 'none',
    },
  },
}));

type PIMToggleProps = CheckboxProps & Omit<FormControlLabelProps, 'control'>;

export function PIMToggle(props: PIMToggleProps): ReactElement {
  const classes = useStyles();
  const { inputProps, label, ...rest } = props;
  return (
    <FormControlLabel
      control={
        <Checkbox
          className={classes.Toggle}
          color='primary'
          icon={
            <svg width='58' height='32' viewBox='0 0 58 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <rect
                x='2.5'
                y='0.5'
                width='55'
                height='31'
                rx='15.5'
                fill='#8F8F8F'
                fillOpacity='0.4'
                stroke='#737373'
              />
              <circle cx='16' cy='16' r='15' fill='#8F8F8F' stroke='#737373' strokeWidth='2' />
            </svg>
          }
          checkedIcon={
            <svg width='58' height='32' viewBox='0 0 58 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <rect
                x='0.5'
                y='0.5'
                width='55'
                height='31'
                rx='15.5'
                fill='#FFDD00'
                fillOpacity='0.2'
                stroke='#AA8A08'
              />
              <circle cx='39.5881' cy='16' r='15' fill='#FFDD00' stroke='#AA8A08' strokeWidth='2' />
            </svg>
          }
          inputProps={{ 'aria-label': 'decorative checkbox', ...inputProps }}
          {...rest}
        />
      }
      label={label}
      labelPlacement='end'
      className={[classes.Toggle, props.className].join(' ')}
    />
  );
}

import React, { useCallback, useContext } from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom';
import { routes } from './routes';
import { AuthorizationContext, IAuthorization } from '../auth/AuthorizationContext';

interface IAuthorizationRouteProps {
  isAuthorized: (authorization: IAuthorization, routeProps: RouteComponentProps) => boolean;
}

export type SimplifiedRouteProps = Omit<RouteProps, 'children'>;

export function AuthorizedRoute({
  isAuthorized,
  component,
  render,
  ...rest
}: SimplifiedRouteProps & IAuthorizationRouteProps): JSX.Element {
  const authorization = useContext(AuthorizationContext);
  const renderComponent = useCallback(
    (routeProps: RouteComponentProps) => {
      if (!isAuthorized(authorization, routeProps)) {
        return <Redirect to={routes.home} />;
      }

      if (component) return <>{component && React.createElement(component, routeProps)}</>;

      return render && render(routeProps);
    },
    [component, render, isAuthorized, authorization]
  );

  return <Route {...rest} render={renderComponent} />;
}

import React from 'react';

export function SelectedIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M20.1695 10C20.1695 15.5229 15.6544 20 10.0847 20C4.51508 20 0 15.5229 0 10C0 4.47714 4.51508 0 10.0847 0C15.6544 0 20.1695 4.47714 20.1695 10ZM8.91825 15.2949L16.4005 7.87556C16.6546 7.62363 16.6546 7.21512 16.4005 6.96319L15.4804 6.05081C15.2263 5.79883 14.8143 5.79883 14.5602 6.05081L8.45817 12.1015L5.60927 9.27657C5.3552 9.02464 4.94323 9.02464 4.68912 9.27657L3.76901 10.189C3.51494 10.4409 3.51494 10.8494 3.76901 11.1013L7.9981 15.2949C8.25221 15.5469 8.66414 15.5469 8.91825 15.2949Z'
        fill='#FFDD00'
      />
    </svg>
  );
}

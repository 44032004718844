import React from 'react';
import { Omit } from '@material-ui/types';
import { Button, makeStyles, Theme, ButtonProps, CircularProgress } from '@material-ui/core';
import { IconName, getIcon } from '../../icons';

interface Props {
  icon?: IconName;
  color?: PIMButtonColor;
  // Was not sure how to add component property like it was done in MUI button.
  // There seems to be a more complex type tree.
  component?: 'span' | 'button';
  busy?: boolean;
}

export type PIMButtonColor = 'primary' | 'secondary' | 'warning';

export function PIMButton(props: Props & Omit<ButtonProps, keyof Props>): JSX.Element {
  // color is not used directly but must be removed from props so props can be spread on Button
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { color, className = '', busy, disabled, icon, style, ...other } = props;
  const classes = useStyles(props);
  const iconComponent = getIcon(icon);

  return (
    <div className={`${classes.wrapper} ${className}`} style={style}>
      <Button className={classes.root} disabled={busy || disabled} endIcon={iconComponent} {...other} />
      {busy && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  );
}

function getButtonBackground(props: Props, theme: Theme) {
  switch (props.color) {
    case 'primary':
      return theme.palette.primary.main;
    case 'warning':
      return theme.palette.warning.main;
    case 'secondary':
    default:
      return theme.palette.secondary.main;
  }
}

function getButtonColor(props: Props, theme: Theme) {
  switch (props.color) {
    case 'primary':
      return theme.palette.primary.contrastText;
    case 'warning':
      return theme.palette.warning.contrastText;
    case 'secondary':
    default:
      return theme.palette.secondary.contrastText;
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '18px',
    background: (props: Props) => getButtonBackground(props, theme),
    border: 0,
    borderRadius: 4,
    color: (props: Props) => getButtonColor(props, theme),
    height: 56,
    padding: '16px 24px',
    '&:hover': {
      background: (props: Props) => getButtonBackground(props, theme),
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
    },
    '&.MuiButton-root.Mui-disabled': {
      opacity: 0.5,
      color: (props: Props) => getButtonColor(props, theme),
    },
  },
  buttonProgress: {
    color: 'black',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    position: 'relative',
    display: 'inline',
  },
}));

import React, { ReactElement } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Tab, { TabProps } from '@mui/material/Tab';

export function PIMTab(props: TabProps): ReactElement {
  const classes = useStyles();

  return <Tab className={classes.Tab} {...props} />;
}

const useStyles = makeStyles(() =>
  createStyles({
    Tabs: {
      '& .MuiTabs-indicator': {
        borderRight: '4px solid #000000',
        width: '0px',
      },
    },
    Tab: {
      '&.MuiTab-root': {
        color: '#000000',
        fontFamily: 'Roboto',
        fontWeight: 'normal',
        fontSize: '18px',
        textTransform: 'none',
        height: '32px',
        lineHeight: '20px',
        maxWidth: '100%',
        alignItems: 'flex-end',
        opacity: '0.7',
        letterSpacing: 'unset',
        textAlign: 'end',
        '&.Mui-selected': {
          backgroundColor: '#FFDD00',
          color: '#000000',
          opacity: '1',
        },
      },
    },
  })
);

import * as React from 'react';
import { CardContent, makeStyles, Grid } from '@material-ui/core';
import { CountryPermission, useMurexinPimContextApi } from '../../../api/MurexinPimApi';
import { useAsync } from 'react-async-hook';
import { NavLink, useParams } from 'react-router-dom';
import { routes } from '../../../routes/routes';
import { CircleLoader } from '../../../utils/CircleLoader';
import { PIMCard } from '../../../shared/Components/PIMCard';
import { useTranslation } from 'react-i18next';
import { AuthorizationContext } from '../../../auth';
import { useContext } from 'react';

interface IParams {
  country: string;
}

interface IAdminMenuItem {
  label: string;
  sublabel: string;
  link: (countryCode: string) => string;
}

export function DivisionDataGrid(): React.ReactElement {
  const { country } = useParams<IParams>();
  const classes = useStyles();
  const { t } = useTranslation();
  const api = useMurexinPimContextApi();
  const { hasCountryPermission } = useContext(AuthorizationContext);
  const hasAdminReadAuthorization = hasCountryPermission(country, CountryPermission.AdministrationRead);

  const adminNav: IAdminMenuItem[] = [
    {
      label: t('Anzeigebilder'),
      sublabel: t('Anzeigebilder Untertitel'),
      link: (countryCode: string) => routes.displayImage(countryCode),
    },
    {
      label: t('Prüfsiegel'),
      sublabel: t('Prüfsiegel Untertitel'),
      link: (countryCode: string) => routes.sealOfApproval(countryCode),
    },
    {
      label: t('Piktogramme'),
      sublabel: t('Piktogramme Untertitel'),
      link: (countryCode: string) => routes.pictogramRoute(countryCode),
    },
    {
      label: t('Spartenbezeichnungen'),
      sublabel: t('Bearbeiten'),
      link: (countryCode: string) => routes.divisionDescriptionRoute(countryCode),
    },
  ];

  const divisionResponse = useAsync(async () => {
    const response = await api.divisionService.divisionServiceDetail(country);
    return response.data ?? [];
  }, [country]);

  if (divisionResponse.loading) {
    return <CircleLoader open={true} />;
  } else {
    return (
      <Grid container>
        <Grid container item xs={8}>
          <Grid item xs={6} className={classes.DivisionGrid}>
            <Grid item xs={12}>
              <div className={classes.Title}>{t('Division Übersicht')}</div>
            </Grid>
          </Grid>
          <Grid item xs={6} className={classes.DivisionGrid}>
            {hasAdminReadAuthorization && <div className={classes.Title}>{t('Administration')}</div>}
          </Grid>
        </Grid>
        <Grid container item xs={8}>
          <Grid item xs={6} className={classes.DivisionGrid}>
            <Grid item xs={10}>
              {divisionResponse.result?.map((division, key) => (
                <NavLink
                  key={key}
                  style={{ textDecoration: 'none' }}
                  to={routes.product(country, division.id?.toString())}>
                  <PIMCard color={division.divisionColor ?? undefined}>
                    <CardContent className={classes.CardContent}>
                      <span className={classes.CardTitle}>
                        {division.divisionDescription != null ? division.divisionDescription : division.divisionName}
                      </span>
                      <span className={classes.CardSubTitle}> {division.divisionName}</span>
                    </CardContent>
                  </PIMCard>
                </NavLink>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={6} className={classes.DivisionGrid}>
            {hasAdminReadAuthorization && (
              <Grid item xs={10}>
                {adminNav ? (
                  adminNav.map((item, index) => (
                    <NavLink key={index} style={{ textDecoration: 'none' }} to={item.link(country)}>
                      <PIMCard>
                        <CardContent className={classes.CardContent}>
                          <span className={classes.CardTitle}>{item.label} </span>
                          <span className={classes.CardSubTitle}> {item.sublabel}</span>
                        </CardContent>
                      </PIMCard>
                    </NavLink>
                  ))
                ) : (
                  <></>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const useStyles = makeStyles(() => ({
  DivisionGrid: {
    paddingRight: '24px',
  },
  CardContent: {
    display: 'flex',
    flexDirection: 'column',
    margin: '8px 16px 8px 16px',
    padding: '0px',
    '&.MuiCardContent-root:last-child': {
      padding: '0px',
    },
  },
  CardTitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '20px',
    marginBottom: '4px',
    color: '#000000',
  },
  CardSubTitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontSize: '14px',
    fontWeight: 400,
    color: '#000000',
    opacity: '0.6',
  },
  Title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '36px',
    marginBottom: '24px',
  },
}));

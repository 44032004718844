import React, { ReactElement } from 'react';
import { AccordionSummary, AccordionSummaryProps, makeStyles } from '@material-ui/core';
import { RightIcon } from '../../icons';

export function PIMAccordionSummary(props: AccordionSummaryProps): ReactElement {
  const classes = useStyles();
  const { className = '', ...rest } = props;

  return (
    <AccordionSummary className={`${classes.accordionSummary} ${className}`} expandIcon={<RightIcon />} {...rest} />
  );
}

const useStyles = makeStyles(() => ({
  accordionSummary: {
    fontSize: 20,
    alignItems: 'center',
    height: 48,
    '&.Mui-expanded': {
      minHeight: 'unset',
      borderBottom: '1px solid rgb(0 0 0 / 25%)',
    },
    '& .MuiAccordionSummary-expandIcon': {
      color: '#828282',
    },
    '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      margin: 0,
    },
  },
}));

import React from 'react';
import { AdminMenu } from '../AdminMenu/AdminMenu';
import { AdminBreadCrumbs } from '../../../layouts/AdminBreadCrumbs/AdminBreadCrumbs';

function AdminList(): JSX.Element {
  return (
    <>
      <AdminBreadCrumbs site={null} />
      <AdminMenu />
    </>
  );
}

export default AdminList;

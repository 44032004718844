import React, { useState, useMemo } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import { GridColDef, GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid';
import { useAsync, useAsyncCallback } from 'react-async-hook';
import { useParams } from 'react-router-dom';
import { CountryPermission, ImageStoreResponse, useMurexinPimContextApi } from '../../../api';
import { CircleLoader } from '../../../utils/CircleLoader';
import { DisplayImageSideBar } from './DisplayImageSideBar';
import { CountryAdminBreadCrumbs } from '../../../layouts/CountryAdminBreadCrumbs/CountryAdminBreadCrumbs';
import { ISortInformation, SortImageListDropDown } from '../../../layouts/CustomImageList/SortImageListDropDown';
import { ApiImageUrlsBuilder } from '../../../utils/apiImageUrls';
import ImageItem from '../../../shared/Components/ImageItem';
import TileView from '../../../shared/Components/TileView';
import { useTranslation } from 'react-i18next';
import { AuthorizationContext } from '../../../auth';
import { FileSelectButton } from '../../../shared/Components/FileSelectButton';
import Utility from '../../../shared/Utility';
import MessageDialog from '../../../shared/Components/MessageDialog';
import { NotificationContext } from '../../../contexts';
import PIMIconButton from '../../../shared/Components/PIMIconButton';
import { DataView } from '../../../shared/Enum/DataView';
import PIMTextField from '../../../shared/Components/PIMTextField';
import _ from 'lodash';
import ListView from '../../../shared/Components/ListView';

interface IParams {
  country: string;
}

export function DisplayImagesList(): JSX.Element {
  const api = useMurexinPimContextApi();
  const notification = React.useContext(NotificationContext);
  const { country } = useParams<IParams>();
  const [displayImages, setDisplayImages] = useState<ImageStoreResponse[]>([]);
  const [selectedDisplayImage, setSelectedDisplayImage] = useState<ImageStoreResponse>();
  const [imagesSearch, setImagesSearch] = React.useState<string>('');
  const [dataView, setDataView] = useState<DataView>(DataView.Grid);
  const [addedDisplayImageFile, setAddedDisplayImageFile] = useState<File | null>(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const { t } = useTranslation();
  const { hasCountryPermission } = React.useContext(AuthorizationContext);
  const isAuthorizedToWrite = hasCountryPermission(country, CountryPermission.AdministrationWrite);

  function checkIsMaterialGroupAssigned(selectedMaterialGroupId: number): boolean {
    const isAssigned =
      displayImages
        .filter((d) => d !== selectedDisplayImage)
        .some((d) => d.materialGroupId === selectedMaterialGroupId) ?? false;

    return isAssigned;
  }

  const [sortOrder, setSortOrder] = useState<ISortInformation>({
    direction: 'asc',
    column: 'fileName',
  });

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: ' ',
      // eslint-disable-next-line react/display-name
      renderCell: (params: GridRenderCellParams) => (
        <img
          className={classes.columnImage}
          src={params.value ? ApiImageUrlsBuilder.buildMaterialGroupImageUrl(country, params.value as number) : ''}
          alt={(params.row.fileName as string) ?? ''}
        />
      ),
      minWidth: 50,
      width: 50,
      sortable: false,
      filterable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
    },
    {
      field: 'fileName',
      headerName: t('Dateiname'),
      flex: 1,
      minWidth: 200,
      sortable: false,
      filterable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
    },
    {
      field: 'updatedAt',
      type: 'dateTime',
      headerName: t('Zuletzt bearbeitet'),
      align: 'right',
      headerAlign: 'right',
      valueFormatter: (params: GridValueFormatterParams) =>
        params.value && Utility.formatDateTimeUtc(params.value as Date),
      width: 150,
      sortable: false,
      filterable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
    },
    {
      field: 'updatedBy',
      headerName: t('Bearbeiter'),
      minWidth: 120,
      width: 200,
      sortable: false,
      filterable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
    },
    {
      field: 'fileSize',
      type: 'number',
      headerName: t('Größe'),
      valueFormatter: (params: GridValueFormatterParams) =>
        params.value && Utility.formatFileSize(params.value as number),
      width: 100,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      filterable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
    },
    {
      field: 'defaultImage',
      headerName: t('Standardbild'),
      // eslint-disable-next-line react/display-name
      renderCell: (params: GridRenderCellParams) => <>{params.value ? <CheckCircle color='primary' /> : null}</>,
      width: 130,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      filterable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
    },
    {
      field: '',
      headerName: '',
      sortable: false,
      filterable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      align: 'center',
      width: 80,
      // eslint-disable-next-line react/display-name
      renderCell: () => (
        <PIMIconButton
          className={classes.deleteButton}
          size='small'
          onClick={() => setShowDeleteDialog(true)}
          iconName='trash'
        />
      ),
    },
  ];

  const classes = useStyles();

  const displayImagesResponse = useAsync(async () => {
    let updatedDisplayImages: ImageStoreResponse[] = [];

    try {
      const response = await api.materialGroup.materialGroupImageStoreLoadAllImagesDataDetail(country);
      updatedDisplayImages = response.data ?? [];
    } catch {
      notification.error(t('Fehler beim Laden der Daten.'));
    }

    // Intentionally not using sealOfApprovalsResponse, since we want to show currently loaded items
    // while waiting for response from backend
    setDisplayImages(updatedDisplayImages);
    clearSelectedImage();
  }, [country]);

  const sortedDisplayImages = useMemo(() => {
    return _.chain(displayImages).orderBy([sortOrder.column], [sortOrder.direction]).value();
  }, [displayImages, sortOrder]);
  let displayedImages = sortedDisplayImages;

  if (imagesSearch) {
    const searchValue = imagesSearch.toLocaleLowerCase();
    displayedImages = displayedImages.filter((p) => p.fileName?.toLocaleLowerCase().includes(searchValue));
  }

  function clearSelectedImage() {
    setAddedDisplayImageFile(null);
    setSelectedDisplayImage(undefined);
  }

  const handleDisplayImageSelectionChange = (newSelectedImage: ImageStoreResponse | undefined) => {
    if (selectedDisplayImage?.id === newSelectedImage?.id) {
      clearSelectedImage();
    } else {
      setSelectedDisplayImage(newSelectedImage);
    }
  };

  function handleAddNewDisplayImageFile(addedFiles: File[]) {
    if (addedFiles.length !== 1) {
      return;
    }

    const addedFile = addedFiles[0];
    setSelectedDisplayImage(undefined);
    setAddedDisplayImageFile(addedFile);
  }

  const onDeleteSelectedDisplayImage = useAsyncCallback(async () => {
    const imageId = selectedDisplayImage?.id;
    setShowDeleteDialog(false);

    if (imageId) {
      try {
        await api.materialGroup.materialGroupImageStoreImageDelete(country, imageId);
        notification.success(t('Datei wurde erfolgreich gelöscht'));
        clearSelectedImage();
        await displayImagesResponse.execute();
      } catch {
        notification.error(t('Der Löschvorgang ist fehlgeschlagen'));
      }
    }
  });

  return (
    <>
      <CircleLoader open={displayImagesResponse.loading || onDeleteSelectedDisplayImage.loading} />
      <Grid container>
        <Grid item xs={8} className={classes.mainView}>
          <Grid container>
            <Grid item xs={12} container justifyContent='space-between'>
              <CountryAdminBreadCrumbs site={t('Anzeigebilder')} />
              <Grid item className={classes.icons}>
                <PIMIconButton
                  iconName='listView'
                  className={classes.dataViewButton}
                  disabled={dataView === DataView.List}
                  onClick={() => setDataView(DataView.List)}
                />
                <PIMIconButton
                  iconName='gridView'
                  className={classes.dataViewButton}
                  disabled={dataView === DataView.Grid}
                  onClick={() => setDataView(DataView.Grid)}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.filterArea}>
              <div className={classes.Title}>{t('Anzeigebilder')}</div>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <PIMTextField
                    label={`${t('Filter')}`}
                    onChange={(e) => setImagesSearch(e.target.value ?? '')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3}>
                  <SortImageListDropDown
                    possibleColumns={['fileName', 'fileSize', 'updatedAt', 'updatedBy', 'defaultImage']}
                    currentSortDirection={sortOrder}
                    selectSortDirection={(selectedOrder) => setSortOrder(selectedOrder)}
                    className={classes.sortedDropdown}
                  />
                </Grid>
                <Grid item xs={4}>
                  {isAuthorizedToWrite && (
                    <FileSelectButton
                      className={classes.addButton}
                      accept={['image/*']}
                      text={t('Anzeigebild hinzufügen')}
                      onFilesSelected={handleAddNewDisplayImageFile}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              {dataView === DataView.List ? (
                <ListView
                  data={displayedImages}
                  selectedItem={selectedDisplayImage}
                  onChangeSelection={handleDisplayImageSelectionChange}
                  columns={columns}
                />
              ) : (
                <TileView>
                  {displayedImages.map((displayImage: ImageStoreResponse) => {
                    const updatedAt = displayImage.updatedAt ? new Date(displayImage.updatedAt) : new Date();

                    return (
                      <ImageItem
                        description={displayImage.fileName ?? ''}
                        isClickable
                        showSubDescription
                        showSelected={selectedDisplayImage?.id === displayImage.id}
                        subDescription={Utility.formatDateTime(updatedAt)}
                        key={displayImage.id}
                        imageSrc={ApiImageUrlsBuilder.buildMaterialGroupImageUrl(country, displayImage.id)}
                        onClickItem={() => handleDisplayImageSelectionChange(displayImage)}
                        showDeleteButton={isAuthorizedToWrite}
                        onClickDelete={() => {
                          setSelectedDisplayImage(displayImage);
                          setShowDeleteDialog(true);
                        }}
                      />
                    );
                  })}
                </TileView>
              )}
            </Grid>
          </Grid>
        </Grid>
        {(addedDisplayImageFile || selectedDisplayImage) && (
          <Grid item xs={4} className={classes.actionArea}>
            <DisplayImageSideBar
              isAuthorizedToWrite={isAuthorizedToWrite}
              country={country}
              newItem={false}
              file={addedDisplayImageFile}
              selectedPicture={selectedDisplayImage ?? null}
              reload={async () => {
                // TODO: fix this with close sidebar request
                await displayImagesResponse.execute();
              }}
              resetFile={clearSelectedImage}
              checkIsMaterialGroupAssigned={checkIsMaterialGroupAssigned}
              actionDelete={() => setShowDeleteDialog(true)}
            />
          </Grid>
        )}
      </Grid>
      <MessageDialog
        show={showDeleteDialog}
        title={t('Wollen Sie dieses Anzeigebild löschen?')}
        messageText={t(`WarnungElementAusZugewiesenenWarengruppenEntfernt`)}
        confirmColor='warning'
        confirmIcon='trash'
        confirmLabel={t('Löschen')}
        onClose={() => setShowDeleteDialog(false)}
        onCancel={() => setShowDeleteDialog(false)}
        onConfirm={onDeleteSelectedDisplayImage.execute}
      />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  actionArea: {
    minHeight: '100%',
    backgroundColor: '#ffffff',
    overflowY: 'auto',
    paddingTop: '72px',
  },
  icons: {
    marginTop: '16px',
    paddingRight: '34px',
  },
  filterArea: {
    marginTop: '10px',
    marginBottom: '20px',
    width: '100%',
  },
  sortedDropdown: {
    width: '100%',
    marginTop: '0',
  },
  addButton: {
    width: '100%',
    minWidth: '300px',
    margin: '0',
    padding: '0',
    float: 'right',
  },
  deleteButton: {
    marginRight: '2px',
  },
  dataViewButton: {
    opacity: '0.5',
    '&.Mui-disabled': {
      opacity: '1',
    },
  },
  columnImage: {
    height: 32,
    width: 32,
  },
  mainView: { padding: theme.spacing(0, 2, 0, 0) },
  Title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '36px',
    marginBottom: '24px',
    lineHeight: '45px',
  },
}));

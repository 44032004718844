import { useTranslation } from 'react-i18next';
import { TranslationDictionary, LocalizationSectionCode, useMurexinPimContextApi } from '../api';
import { useAsync } from 'react-async-hook';

interface I18nState {
  reloadCurrentCultureTranslation: () => void;
}

export function useI18n(currentCulture: string): I18nState {
  const { i18n } = useTranslation();

  const api = useMurexinPimContextApi();

  useAsync(() => {
    return i18n.changeLanguage(currentCulture);
  }, [i18n, currentCulture]);

  const translationsResponse = useAsync(async () => {
    const response = await api.localization.localizationTranslationListList({ cultureCode: currentCulture });
    const translations: TranslationDictionary = response.data ?? {};
    i18n.removeResourceBundle(currentCulture, LocalizationSectionCode.STATIC);
    i18n.addResourceBundle(
      currentCulture,
      LocalizationSectionCode.STATIC,
      translations?.texts ? translations?.texts[LocalizationSectionCode.STATIC] : []
    );
    return response.data ?? {};
  }, [currentCulture]);

  const reloadCurrentCultureTranslation = async () => {
    await translationsResponse.execute();
  };

  return { reloadCurrentCultureTranslation };
}

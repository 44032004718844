import React, { useState } from 'react';
import { Grid, makeStyles, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-async-hook';
import { CircleLoader } from '../../../../utils/CircleLoader';
import PIMButton from '../../../../shared/Components/PIMButton';
import { ApiImageUrlsBuilder } from '../../../../utils/apiImageUrls';
import PIMTextField from '../../../../shared/Components/PIMTextField';
import PIMSelect from '../../../../shared/Components/PIMSelect';
import { MaterialGroupResponse, useMurexinPimContextApi } from '../../../../api';

export function ContainerLayoutSideBar(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const api = useMurexinPimContextApi();
  const [fileName, setFileName] = useState<string>(t('Gebindelayout'));
  const [divisionId, setDivisionId] = useState<string>('');
  const [productGroupId, setProductId] = useState<string>('');
  const [materialGroupId, setMaterialGroupId] = useState<string>('');

  const sortByMaterialGroupName = (a: MaterialGroupResponse | null, b: MaterialGroupResponse | null) => {
    return (a?.materialGroupName ?? 0) > (b?.materialGroupName ?? 0) ? 1 : -1;
  };

  const sortByMaterialGroupDescription = (a: MaterialGroupResponse | null, b: MaterialGroupResponse | null) => {
    return (a?.materialGroupDescription ?? 0) > (b?.materialGroupDescription ?? 0) ? 1 : -1;
  };

  const divisionsResponse = useAsync(async () => {
    const response = await api.divisionService.divisionServiceDetail('AT');
    return response.data ?? [];
  }, []);

  const productGroupsResponse = useAsync(async () => {
    const response = await api.admin.adminContainerLayoutExportProductGroupsList();
    return (
      response.data?.sort((a, b) => ((a?.productGroupDescription ?? 0) > (b?.productGroupDescription ?? 0) ? 1 : -1)) ??
      []
    );
  }, []);

  const materialGroupsResponse = useAsync(async () => {
    const response = await api.admin.adminContainerLayoutExportMaterialGroupsList();
    return response.data ?? [];
  }, []);

  const materialGroups = productGroupId
    ? materialGroupsResponse?.result?.filter((x) => x.productGroupId === Number(productGroupId))
    : materialGroupsResponse?.result;

  const handleOnChangeMaterialGroupCode = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    const selectedMaterialGroup = materialGroupsResponse.result?.find((mg) => mg.id === e.target.value);
    const selectedProductGroup = productGroupsResponse.result?.find(
      (mg) => mg.id === selectedMaterialGroup?.productGroupId
    );

    setDivisionId((selectedProductGroup?.divisionId ?? '') as string);
    setProductId((selectedMaterialGroup?.productGroupId ?? '') as string);
    setMaterialGroupId(e.target.value as string);
  };

  return (
    <>
      {divisionsResponse.loading && productGroupsResponse.loading && materialGroupsResponse.loading ? (
        <CircleLoader open />
      ) : null}
      <Grid container className={classes.Container}>
        <Grid item xs={12} className={`${classes.gridItem} ${classes.TitleGridItem}`}>
          <div className={classes.Title}>{t('Export Gebindelayout')}</div>
        </Grid>
        <Grid container direction='row'>
          <Grid item xs={12} className={classes.gridItem}>
            <PIMTextField
              label={t('Dateiname')}
              defaultValue={fileName}
              className={classes.Dropdown}
              fullWidth
              onChange={(
                e: React.ChangeEvent<{
                  name?: string | undefined;
                  value: unknown;
                }>
              ) => {
                setFileName(e.target.value as string);
              }}
            />
          </Grid>
          <Grid item xs={12} className={classes.gridItem}>
            <PIMSelect
              label={t('Sparte')}
              defaultValue={divisionId}
              value={divisionId}
              hideRemoveSelection
              className={classes.Dropdown}
              onChange={(
                e: React.ChangeEvent<{
                  name?: string | undefined;
                  value: unknown;
                }>
              ) => {
                setMaterialGroupId('');
                setProductId('');
                setDivisionId(e.target.value as string);
              }}>
              {divisionsResponse.result?.map((d) => (
                <MenuItem key={d.id ?? ''} value={d.id ?? ''}>
                  {d.divisionDescription}
                </MenuItem>
              ))}
            </PIMSelect>
          </Grid>
          <Grid item xs={12} className={classes.gridItem}>
            <PIMSelect
              label={t('Produktgruppe')}
              defaultValue={productGroupId}
              value={productGroupId}
              hideRemoveSelection
              className={classes.Dropdown}
              disabled={!divisionId}
              onChange={(
                e: React.ChangeEvent<{
                  name?: string | undefined;
                  value: unknown;
                }>
              ) => {
                setMaterialGroupId('');
                setProductId(e.target.value as string);
              }}>
              {productGroupsResponse.result
                ?.filter((p) => p.divisionId === Number(divisionId))
                .map((d) => (
                  <MenuItem key={d.id ?? ''} value={d.id ?? ''}>
                    {d.productGroupDescription}
                  </MenuItem>
                ))}
            </PIMSelect>
          </Grid>
          <Grid item xs={12} className={classes.gridItem}>
            <PIMSelect
              label={t('Warengruppe')}
              hideRemoveSelection
              className={classes.Dropdown}
              defaultValue={materialGroupId}
              value={materialGroupId}
              disabled={!divisionId || !productGroupId}
              onChange={(
                e: React.ChangeEvent<{
                  name?: string | undefined;
                  value: unknown;
                }>
              ) => {
                setMaterialGroupId(e.target.value as string);
              }}>
              {materialGroups?.sort(sortByMaterialGroupDescription).map((d) => (
                <MenuItem key={d.id ?? ''} value={d.id ?? ''}>
                  {d.materialGroupDescription}
                </MenuItem>
              ))}
            </PIMSelect>
          </Grid>
          <Grid item xs={12} className={classes.gridItem}>
            <PIMSelect
              name='MaterialGroup'
              label={t('Warengruppencode')}
              hideRemoveSelection
              className={classes.Dropdown}
              defaultValue={materialGroupId}
              value={materialGroupId}
              onChange={handleOnChangeMaterialGroupCode}>
              {materialGroups?.sort(sortByMaterialGroupName).map((d) => (
                <MenuItem key={d.id ?? ''} value={d.id ?? ''}>
                  {d.materialGroupName}
                </MenuItem>
              ))}
            </PIMSelect>
          </Grid>
          <Grid item xs={12} className={classes.gridItem}>
            <PIMButton
              icon='download'
              color='primary'
              type='submit'
              className={classes.Button}
              disabled={!materialGroupId}
              href={ApiImageUrlsBuilder.buildContainerLayoutExportFileUrl(Number(materialGroupId), fileName + '.txt')}>
              {t('TXT exportieren')}
            </PIMButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

const useStyles = makeStyles(() => ({
  Title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '24px',
    marginTop: '72px',
    marginBottom: '20px',
  },
  TitleGridItem: {
    flexBasis: '0',
  },
  gridItem: {
    display: 'flex',
    marginLeft: '40px',
    marginRight: '40px',
    width: '100%',
  },
  Container: {
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
    height: '100%',
    padding: '20px 40px 0px 40px',
    background: 'white',
  },
  Button: {
    marginTop: '12px',
    marginRight: '0px',
    marginLeft: 'auto',
  },
  Dropdown: {
    width: '100%',
    marginTop: '12px',
    marginBottom: '12px',
  },
}));

import * as React from 'react';
import { routes } from '../../routes/routes';
import AdminList from './AdminList/AdminList';
import { GlobalAdministrationRoute } from '../../routes';
import LocalizationPage from './Configuration/Localization';
import { MailingList } from './MailingList/MailingList';
import { GeneralHint } from './Configuration/GeneralHint/GeneralHint';
import { Substrate } from './Configuration/Substrate/Substrate';
import { ProductHint } from './Configuration/ProductHint/ProductHint';
import Documents from './Documents/Documents';
import { DynamicExport } from './Export/DynamicExport/DynamicExport';

export function AdminRoutes(): JSX.Element {
  return (
    <>
      <GlobalAdministrationRoute exact path={routes.adminRoute} component={AdminList} />
      <GlobalAdministrationRoute exact path={routes.GeneralHint()} component={GeneralHint} />
      <GlobalAdministrationRoute exact path={routes.Substrate()} component={Substrate} />
      <GlobalAdministrationRoute exact path={routes.ProductHint()} component={ProductHint} />
      <GlobalAdministrationRoute exact path={routes.MailingList()} component={MailingList} />
      <GlobalAdministrationRoute exact path={routes.AdminConfigurationLocalization()} component={LocalizationPage} />
      <GlobalAdministrationRoute exact path={routes.DocumentsManagement()} component={Documents} />
      <GlobalAdministrationRoute exact path={routes.DynamicExport()} component={DynamicExport} />
    </>
  );
}

import React, { useContext, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Grid,
  ListItem,
  makeStyles,
  MenuItem,
  Theme,
  MenuProps,
} from '@material-ui/core';
import { useAsync, useAsyncCallback } from 'react-async-hook';
import { useTranslation } from 'react-i18next';
import {
  ArticleExportRequest,
  DivisionTreeResponse,
  MaterialGroupAttributeType,
  MaterialGroupExportRequest,
  MaterialGroupTreeResponse,
  ProductGroupTreeResponse,
  useMurexinPimContextApi,
} from '../../../../api';
import { NotificationContext } from '../../../../contexts';
import { AdminBreadCrumbs } from '../../../../layouts/AdminBreadCrumbs/AdminBreadCrumbs';
import PIMButton from '../../../../shared/Components/PIMButton';
import PIMTextField from '../../../../shared/Components/PIMTextField';
import { CircleLoader } from '../../../../utils/CircleLoader';
import PIMSelect from '../../../../shared/Components/PIMSelect';
import { DynamicExportEnum, dynamicExportTypes } from '../../../../shared/DocumentTranslations';
import { DynamicExportAccordions as DivisionAccordions } from './DivisionAccordions';
import fileDownload from 'js-file-download';

export interface AccordionProductGroup extends ProductGroupTreeResponse {
  isChecked?: boolean;
  isIndeterminate?: boolean;
}
export interface AccordionDivision extends DivisionTreeResponse {
  isChecked?: boolean;
  isIndeterminate?: boolean;
  productGroups: AccordionProductGroup[];
}

export function DynamicExport(): JSX.Element {
  const api = useMurexinPimContextApi();
  const classes = useStyles();
  const notification = useContext(NotificationContext);
  const { t } = useTranslation();
  const header = t('Dynamischer Export');

  // Purposedly setting filename in frontend to avoid building file name from headers content disposition
  const articleExportFilename = 'Artikel Export.csv';
  const materialGroupExportFilename = 'Warengruppen Export.csv';

  const [searchText, setSearchText] = useState('');
  const [exportType, setExportType] = useState(DynamicExportEnum.MaterialGroup);
  const [selectedCountryCode, setSelectedCountryCode] = useState('AT');
  const [selectedMaterialGroups, setSelectedMaterialGroups] = useState<MaterialGroupTreeResponse[]>([]);
  const [selectedProductTypes, setSelectedProductTypes] = useState<string[]>(['ST']);
  const [selectedAttributes, setSelectedAttributes] = useState<MaterialGroupAttributeType[]>([
    MaterialGroupAttributeType.DivisionName,
    MaterialGroupAttributeType.DivisionDescription,
    MaterialGroupAttributeType.ProductGroupName,
    MaterialGroupAttributeType.ProductGroupDescription,
    MaterialGroupAttributeType.MaterialGroupName,
    MaterialGroupAttributeType.MaterialGroupDescription,
    MaterialGroupAttributeType.DescriptionProductDescriptionShort,
    MaterialGroupAttributeType.TechnicalDetailConsumption,
  ]);
  const [accordionDivisions, setAccordionDivisions] = useState<AccordionDivision[]>([]);

  const selectMenuProps: Partial<MenuProps> = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
    style: { maxHeight: 400 },
  };

  const countries = useAsync(async () => {
    const countryListResponse = await api.countryService.countryServiceList();
    return countryListResponse.data ?? [];
  }, []);

  const divisionTreeResponse = useAsync(async () => {
    setSelectedMaterialGroups([]);
    const divisionTree = await api.admin.adminDynamicExportDivisionDetail(selectedCountryCode);
    const divisions = divisionTree.data ?? [];
    setAccordionDivisions(
      divisions.map(
        (d) =>
          ({
            ...d,
            isChecked: false,
            isIndeterminate: false,
            productGroups: d.productGroups.map(
              (pg) =>
                ({
                  ...pg,
                  isChecked: false,
                  isIndeterminate: false,
                } as AccordionProductGroup)
            ),
          } as AccordionDivision)
      )
    );
    return divisions;
  }, [selectedCountryCode]);

  const productTypeResponse = useAsync(async () => {
    const productTypes = await api.articleService.articleServiceProductTypesDetail(selectedCountryCode);
    return productTypes.data ?? [];
  }, []);

  const materialGroupAttributesResponse = useAsync(async () => {
    const materialGroupAttributes = await api.admin.adminDynamicExportMaterialGroupAttributeList();
    return materialGroupAttributes.data ?? [];
  }, []);

  const exportCSV = useAsyncCallback(async () => {
    try {
      if (exportType === DynamicExportEnum.MaterialGroup) await exportMaterialGroup();
      else await exportArticle();
      notification.success(t('Export erfolgreich'));
    } catch {
      notification.error(t('Export fehlgeschlagen'));
    }
  });

  const exportMaterialGroup = async () => {
    const materialGroupExportRequest = {
      countryCode: selectedCountryCode,
      materialGroupIds: selectedMaterialGroups.map((mg) => mg.id),
      attributes: selectedAttributes.length !== 0 ? selectedAttributes : null,
      productTypeCodes: selectedProductTypes,
    } as MaterialGroupExportRequest;
    const result = await api.admin.adminDynamicExportMaterialGroupFileCreate(materialGroupExportRequest);
    fileDownload(await result.blob(), materialGroupExportFilename);
  };

  const exportArticle = async () => {
    const articleExportRequest = {
      countryCode: selectedCountryCode,
      materialGroupIds: selectedMaterialGroups.map((mg) => mg.id),
      productTypeCodes: selectedProductTypes,
    } as ArticleExportRequest;
    const result = await api.admin.adminDynamicExportArticleFileCreate(articleExportRequest);
    fileDownload(await result.blob(), articleExportFilename);
  };

  const handleSelectDivision = (division: AccordionDivision, checked: boolean) => {
    division.isChecked = checked;
    division.productGroups.forEach((productGroup) => handleSelectProductGroup(division, productGroup, checked));
  };

  const handleSelectProductGroup = (
    division: AccordionDivision,
    productGroup: AccordionProductGroup,
    productGroupChecked: boolean
  ) => {
    const materialGroups = selectedMaterialGroups;

    productGroup.materialGroups.forEach((materialGroup) => {
      const index = selectedMaterialGroups.indexOf(materialGroup);

      if (!productGroupChecked && index >= 0) {
        materialGroups.splice(index, 1);
      }
      if (productGroupChecked && index === -1) {
        materialGroups.push(materialGroup);
      }
    });

    setSelectedMaterialGroups(materialGroups);
    updateAccordionDivisions(division, productGroup, productGroupChecked);
  };

  const handleSelectMaterialGroup = (
    division: AccordionDivision,
    productGroup: AccordionProductGroup,
    materialGroup: MaterialGroupTreeResponse,
    materialGroupChecked: boolean
  ) => {
    const index = selectedMaterialGroups.indexOf(materialGroup);
    const materialGroups = selectedMaterialGroups;

    if (!materialGroupChecked && index >= 0) {
      materialGroups.splice(index, 1);
    }
    if (materialGroupChecked && index === -1) {
      materialGroups.push(materialGroup);
    }

    const productGroupChecked = productGroup.materialGroups.every((mg) => selectedMaterialGroups.includes(mg));
    setSelectedMaterialGroups(materialGroups);
    updateAccordionDivisions(division, productGroup, productGroupChecked);
  };

  const updateAccordionDivisions = (
    division: AccordionDivision,
    productGroup: AccordionProductGroup,
    isCheckedProductGroup: boolean
  ) => {
    const isIndeterminateProductGroup = isCheckedProductGroup
      ? false
      : productGroup.materialGroups.some((mg) => selectedMaterialGroups.includes(mg));

    productGroup.isChecked = isCheckedProductGroup;
    productGroup.isIndeterminate = isIndeterminateProductGroup;

    const isCheckedDivision = division.productGroups.every((pg) => pg.isChecked);
    const isIndeterminateDivision = isCheckedDivision
      ? false
      : division.productGroups.some((pg) => pg.isIndeterminate || pg.isChecked);

    division.isChecked = isCheckedDivision;
    division.isIndeterminate = isIndeterminateDivision;

    setAccordionDivisions([...accordionDivisions]);
  };

  const mapAttributeToLabelKey = (attribute: MaterialGroupAttributeType): string => {
    if (materialGroupAttributesResponse.result === undefined) return '';
    const index = materialGroupAttributesResponse.result.findIndex((mar) => mar.type === attribute);
    if (index === undefined || index < 0) return '';
    return materialGroupAttributesResponse.result[index].labelKey;
  };

  return (
    <>
      <AdminBreadCrumbs site={header} />
      <CircleLoader
        open={
          countries.loading ||
          divisionTreeResponse.loading ||
          productTypeResponse.loading ||
          materialGroupAttributesResponse.loading
        }
      />
      <Grid container className={classes.Container}>
        <Grid container>
          <Grid item xs={6}>
            <div className={classes.Title}>{header}</div>
          </Grid>
          <Grid container item xs={6} className={classes.ActionButtonRow} alignItems='center'>
            <PIMSelect
              label={t('Land')}
              hideRemoveSelection
              value={countries.loading ? '' : selectedCountryCode}
              disabled={countries?.result?.length === 0}
              shrinkLabel={selectedCountryCode !== ''}
              onChange={(e) => {
                setSelectedCountryCode(e.target.value as string);
              }}>
              {countries?.result?.map((d) => (
                <MenuItem key={d.code} value={d.code ?? ''}>
                  {d.name}
                </MenuItem>
              ))}
            </PIMSelect>
            <PIMButton
              color='primary'
              icon='download'
              className={classes.ExportButton}
              disabled={selectedMaterialGroups.length === 0}
              busy={exportCSV.loading}
              type='submit'
              onClick={exportCSV.execute}>
              {t('CSV exportieren')}
            </PIMButton>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid container item xs={6} alignContent='flex-start'>
            <PIMSelect
              label={t('Export')}
              value={exportType}
              hideRemoveSelection
              className={classes.Select}
              onChange={(e) => setExportType(e.target.value as DynamicExportEnum)}>
              {dynamicExportTypes?.map((type) => (
                <MenuItem key={type.id} value={type.id}>
                  {t(type.name)}
                </MenuItem>
              ))}
            </PIMSelect>
            <PIMTextField
              label={t('Suche')}
              className={classes.SearchTextField}
              fullWidth
              onChange={(event) => {
                setSearchText(event.target.value ?? '');
              }}
            />
            <DivisionAccordions
              accordionDivisions={accordionDivisions}
              searchText={searchText}
              handleSelectDivision={handleSelectDivision}
              handleSelectProductGroup={handleSelectProductGroup}
              handleSelectMaterialGroup={handleSelectMaterialGroup}
              materialGroupChecked={(mg) => selectedMaterialGroups.includes(mg)}
            />
          </Grid>
          <Grid container item xs={6} alignContent='flex-start'>
            <PIMSelect
              name='articleTypes'
              label={t('Produktarten auswählen')}
              value={selectedProductTypes}
              shrinkLabel={false}
              renderValue={() => null}
              hideRemoveSelection
              multiple
              className={classes.Select}
              MenuProps={selectMenuProps}
              onChange={(e) => setSelectedProductTypes(e.target.value as string[])}>
              {productTypeResponse.result?.map((productType) => (
                <MenuItem key={productType.code} value={productType.code ?? ''}>
                  {productType.code}
                </MenuItem>
              ))}
            </PIMSelect>
            <Accordion expanded={true} className={classes.Accordion}>
              <AccordionSummary className={classes.AccordionSummary}>{t('Produktarten')}</AccordionSummary>
              <AccordionDetails className={classes.AccordionDetails}>
                <Grid container>
                  {selectedProductTypes.map((productType) => {
                    return (
                      <ListItem key={productType} className={classes.ListItem}>
                        <Chip
                          label={productType}
                          onDelete={() =>
                            setSelectedProductTypes(selectedProductTypes.filter((pt) => pt !== productType))
                          }
                        />
                      </ListItem>
                    );
                  })}
                </Grid>
              </AccordionDetails>
            </Accordion>
            {exportType === DynamicExportEnum.MaterialGroup && (
              <>
                <PIMSelect
                  name='articleTypes'
                  label={t('Attribute auswählen')}
                  value={selectedAttributes}
                  shrinkLabel={false}
                  renderValue={() => null}
                  multiple
                  className={classes.Select}
                  hideRemoveSelection
                  MenuProps={selectMenuProps}
                  onChange={(e) => setSelectedAttributes(e.target.value as MaterialGroupAttributeType[])}>
                  {materialGroupAttributesResponse.result?.map((attribute) => (
                    <MenuItem key={attribute.type} value={attribute.type}>
                      {t(attribute.labelKey)}
                    </MenuItem>
                  ))}
                </PIMSelect>
                <Accordion expanded={true} className={classes.Accordion}>
                  <AccordionSummary className={classes.AccordionSummary}>{t('Attribute')}</AccordionSummary>
                  <AccordionDetails className={classes.AccordionDetails}>
                    <Grid container>
                      {selectedAttributes.map((attribute) => {
                        return (
                          <ListItem key={attribute} className={classes.ListItem}>
                            <Chip
                              label={t(mapAttributeToLabelKey(attribute))}
                              onDelete={() => setSelectedAttributes(selectedAttributes.filter((a) => a !== attribute))}
                            />
                          </ListItem>
                        );
                      })}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  Container: {
    marginTop: '40px',
    paddingLeft: '16px',
    paddingRight: '24px',
  },
  Title: {
    fontWeight: 300,
    fontSize: '36px',
    marginBottom: '20px',
  },
  ActionButtonRow: {
    justifyContent: 'flex-end',
    paddingBottom: '32px',
  },
  ExportButton: {
    marginLeft: '24px',
  },
  SearchTextField: {
    margin: '0px 0px 8px 0px',
  },
  Select: {
    marginBottom: '8px',
    width: '100%',
  },
  Accordion: {
    width: '100%',
    margin: theme.spacing(1, 0),
    '&.Mui-expanded': {
      margin: theme.spacing(1, 0),
    },
    boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.25)',
    '&.MuiAccordion-root:before': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
    '& .Mui-expanded': {
      margin: '0px',
    },
    borderBottom: 'none',
  },
  AccordionSummary: {
    background: '#FFFFFF',
    height: '50px !important',
    minHeight: '50px !important',
    pointerEvents: 'none',
    fontSize: 20,
    alignItems: 'center',
    '&.Mui-expanded': {
      minHeight: 'unset',
      borderBottom: '1px solid rgb(0 0 0 / 25%)',
    },
    '& .MuiAccordionSummary-expandIcon': {
      color: '#828282',
    },
    '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      margin: 0,
    },
  },
  AccordionDetails: {
    padding: '8px 16px',
  },
  ListItem: {
    padding: '8px',
    width: 'fit-content',
  },
}));

import React, { ReactElement } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PIMFormikSidebar from '../../../shared/Components/PIMFormikSidebar';
import * as Yup from 'yup';
import PIMButton from '../../../shared/Components/PIMButton';
import PIMFormikTextField from '../../../shared/Components/PIMFormikTextField';
import {
  CountryResponse,
  CreateMailRequest,
  MailResponse,
  UpdateMailRequest,
  useMurexinPimContextApi,
} from '../../../api';
import { NotificationContext } from '../../../contexts';
import PIMFormikSelect from '../../../shared/Components/PIMFormikSelect';

interface IProps {
  mail: MailResponse;
  countries: CountryResponse[];
  isAuthorizedToWrite: boolean;
  actionDelete: (deleteMail: MailResponse) => void;
  actionCancel: () => void;
  actionSubmit: () => void;
}

const COUNTRY_NAME_FOR_GENERAL_ENTRY = 'GENERAL';

export function MailingListSidebar(props: IProps): ReactElement {
  const classes = useStyles();
  const api = useMurexinPimContextApi();
  const { t } = useTranslation();
  const notification = React.useContext(NotificationContext);
  const { mail, countries, isAuthorizedToWrite, actionDelete, actionCancel, actionSubmit } = props;

  const countryTypeOptions = [...countries]?.map((country) => ({
    key: country.code,
    value: country.name,
  }));

  const validationSchema = Yup.object().shape({
    eMail: Yup.string().required(t('Feld erforderlich')).email(t('E-Mail ist ungultig')),
  });

  const handleOnSubmit = async (values: MailResponse) => {
    try {
      if (values.id && mail?.id) {
        const updatedMail: UpdateMailRequest = {
          id: Number(values.id),
          eMail: values.eMail,
          countryCode: values.countryCode === COUNTRY_NAME_FOR_GENERAL_ENTRY ? null : (values.countryCode as string),
        };
        await api.mail.mailUpdate(mail?.id, updatedMail);
      } else {
        const createMail: CreateMailRequest = {
          ...values,
          countryCode: values.countryCode === COUNTRY_NAME_FOR_GENERAL_ENTRY ? null : values.countryCode,
        };
        await api.mail.mailCreate(createMail);
      }
      notification.success(t('Speichern erfolgreich'));
      actionSubmit();
    } catch (error) {
      notification.error(t('Speichern fehlgeschlagen'));
    }
  };

  return (
    <PIMFormikSidebar
      title={props.mail?.id ? t('E-Mail Adresse bearbeiten') : t('Neue E-Mail Adresse hinzufügen')}
      cancelButtonText={t('Abbrechen')}
      formikInitialValues={mail}
      validationSchema={validationSchema}
      handleOnSubmit={handleOnSubmit}
      handleOnCancel={actionCancel}>
      {mail ? (
        <>
          <PIMFormikSelect
            name='countryCode'
            label={t('E-Mail Verteiler')}
            hideRemoveSelection
            options={countryTypeOptions}
            formControlProps={{ fullWidth: true, className: classes.CountrySelect }}
          />
          <PIMFormikTextField name='eMail' label={t('E-Mail Adresse')} type='text' fullWidth />
          {props.mail?.id && (
            <>
              <hr className={classes.line} />
              <PIMButton
                color='warning'
                icon={'trash'}
                onClick={() => actionDelete(mail)}
                disabled={!isAuthorizedToWrite}>
                {t('E-Mail löschen')}
              </PIMButton>
            </>
          )}
        </>
      ) : (
        <div>{t('Mail Adresse auswählen!')}</div>
      )}
    </PIMFormikSidebar>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    line: {
      margin: '16px 0px 24px 0px',
    },
    CountrySelect: {
      '&.MuiFormControl-root': {
        marginTop: '0px',
      },
    },
  })
);

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, makeStyles } from '@material-ui/core';
import PIMFormikTextField from '../../../shared/Components/PIMFormikTextField';
import PIMIconButton from '../../../shared/Components/PIMIconButton';
import MessageDialog from '../../../shared/Components/MessageDialog';

interface IProps {
  duplicateItem: () => void;
  deleteItem: () => void;
  textFieldPrefix: string;
}

export function TemplateItem(props: IProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const { duplicateItem, deleteItem, textFieldPrefix } = props;
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  return (
    <Grid container spacing={2}>
      <Grid item xs className={classes.Title}>
        <PIMFormikTextField
          name={`${textFieldPrefix}.title`}
          label={t('Titel')}
          variant='filled'
          type='text'
          fullWidth
        />
      </Grid>
      <Grid item xs>
        <PIMFormikTextField
          multiline
          name={`${textFieldPrefix}.content`}
          label={t('Inhalt')}
          variant='filled'
          type='text'
          fullWidth
        />
      </Grid>
      <Grid item>
        <PIMIconButton size='small' onClick={() => duplicateItem()} iconName='duplicate' className={classes.Button} />
      </Grid>
      <Grid item>
        <PIMIconButton
          size='small'
          onClick={() => setShowDeleteDialog(true)}
          iconName='trash'
          className={classes.Button}
        />
      </Grid>
      <MessageDialog
        show={showDeleteDialog}
        messageText={t('Wollen Sie den Eintrag wirklich löschen?')}
        title={t('Eintrag löschen')}
        confirmColor='warning'
        confirmIcon='trash'
        confirmLabel={t('Löschen')}
        onClose={() => setShowDeleteDialog(false)}
        onCancel={() => setShowDeleteDialog(false)}
        onConfirm={() => {
          deleteItem();
          setShowDeleteDialog(false);
        }}
      />
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  Button: {
    marginTop: '32px',
  },
  Title: {
    minWidth: '300px',
    maxWidth: '500px',
  },
}));

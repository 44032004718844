import React, { useEffect } from 'react';
import { useAsync } from 'react-async-hook';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { UserMenu } from './UserMenu';
import { Toolbar } from '@material-ui/core';
import { routes } from './../routes/routes';
import MurexinLogo from '../images/MurexinLogo.svg';
import { useMurexinPimContextApi, GlobalPermission, CountryPermission, LocalizationSectionCode } from '../api';
import { NotificationContext } from '../contexts/NotificationContext';
import { AuthorizationContext } from '../auth';
import { IRouterParams } from '../routes';
import { CircleLoader } from '../utils/CircleLoader';
import { useTranslation } from 'react-i18next';

export function MenuLink(): JSX.Element {
  const notification = React.useContext(NotificationContext);
  const { hasGlobalPermission, hasCountryPermission } = React.useContext(AuthorizationContext);
  const classes = useStyles();
  const history = useHistory();
  const api = useMurexinPimContextApi();
  const [value, setValue] = React.useState('home');
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const germanTranslation = i18n.getFixedT('de', LocalizationSectionCode.STATIC);
  const routeMatch = useRouteMatch<IRouterParams>({
    path: routes.countryBase,
    exact: false,
  });

  useEffect(() => {
    if (routeMatch !== null) {
      setValue(routeMatch.params.country);
    }
  }, [routeMatch]);

  useEffect(() => {
    if (location.pathname === '/administration') {
      setValue('administration');
    } else if (location.pathname === '/') {
      setValue('home');
    }
  }, [location]);

  const goToAdmin = () => {
    history.push(routes.adminRoute);
  };

  const goHome = () => {
    history.push(routes.home);
  };

  const goToCountry = (code: string | undefined | null) => {
    if (code !== null && code !== undefined) {
      history.push(routes.division(code));
    } else {
      notification.error(t('Ländercode nicht gefunden'));
    }
  };

  const countriesResponse = useAsync(async () => {
    const countryListResponse = await api.countryService.countryServiceList();
    return countryListResponse.data ?? [];
  }, []);

  if (countriesResponse.loading) {
    return <CircleLoader open />;
  } else
    return (
      <div>
        <AppBar position='static' elevation={0}>
          <Toolbar classes={{ root: classes.ToolbarBackground }}>
            <Tabs value={value} TabIndicatorProps={{ className: classes.TabIndicator }} className={classes.Toolbar}>
              <Tab
                label={germanTranslation('Menü')}
                onClick={goHome}
                className={`${classes.Tab} ${classes.TabBorderRight} ${classes.MenuTab}`}
                value=''
              />
              {countriesResponse.result &&
                countriesResponse.result
                  .filter((country) => hasCountryPermission(country.code ?? '', CountryPermission.Read))
                  .map((country) => (
                    <Tab
                      label={country.name}
                      key={country.code}
                      className={classes.Tab}
                      onClick={() => goToCountry(country.code)}
                      value={country.code}
                    />
                  ))}
              {hasGlobalPermission(GlobalPermission.AdministrationRead) && (
                <Tab
                  label={germanTranslation('Administration')}
                  className={`${classes.Tab} ${classes.TabBorderLeft}`}
                  onClick={goToAdmin}
                  value='administration'
                />
              )}
              <UserMenu />
              <Tab
                label={<img src={MurexinLogo} />}
                className={`${classes.LogoTab} ${classes.Tab}`}
                onClick={goHome}
                value='home'
              />
            </Tabs>
          </Toolbar>
        </AppBar>
      </div>
    );
}

const useStyles = makeStyles((theme: Theme) => ({
  TabIndicator: {
    backgroundColor: 'black',
    height: '10px',
    top: '45px',
  },
  Toolbar: {
    width: '100vw',
  },
  ToolbarBackground: {
    backgroundColor: theme.palette.primary.main,
    padding: '0',
    minHeight: '0',
    color: 'black',
    '& .MuiToolbar-root': {
      display: 'block',
    },
  },
  Tab: {
    minWidth: 'max-content',
    lineHeight: 'normal',
    paddingRight: 0,
    paddingLeft: 0,
    opacity: 1,
    '& .MuiTab-wrapper': {
      paddingLeft: '12px',
      paddingRight: '12px',
      textTransform: 'none',
      fontSize: '16px',
      fontWeight: '400',
    },
  },
  LogoTab: {
    marginRight: '24px',
    '& .MuiTab-wrapper': {
      paddingRight: '0px',
      paddingLeft: '0px',
      marginRight: '0px',
    },
  },
  MenuTab: {
    marginLeft: '24px',
  },
  TabBorderRight: {
    '& .MuiTab-wrapper': {
      borderRight: '1px solid #000000',
    },
  },
  TabBorderLeft: {
    '& .MuiTab-wrapper': {
      borderLeft: '1px solid #000000',
    },
  },
}));

import React from 'react';

export function DuplicateIcon(): JSX.Element {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M21.75 9.75H8.25C7.42157 9.75 6.75 10.4216 6.75 11.25V20.25C6.75 21.0784 7.42157 21.75 8.25 21.75H21.75C22.5784 21.75 23.25 21.0784 23.25 20.25V11.25C23.25 10.4216 22.5784 9.75 21.75 9.75Z'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.25 6.75V3.75C17.25 3.35218 17.092 2.97064 16.8107 2.68934C16.5294 2.40804 16.1478 2.25 15.75 2.25H2.25C1.85218 2.25 1.47064 2.40804 1.18934 2.68934C0.908035 2.97064 0.75 3.35218 0.75 3.75V12.75C0.75 13.1478 0.908035 13.5294 1.18934 13.8107C1.47064 14.092 1.85218 14.25 2.25 14.25H3.75'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

import { Card, createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { ReactElement } from 'react';
import TileView from '../../../../shared/Components/TileView';
import ImageItem from '../../../../shared/Components/ImageItem';
import Utility from '../../../../shared/Utility';
import { Pictogram } from './Pictograms';
import { ApiImageUrlsBuilder } from '../../../../utils/apiImageUrls';

interface IProps {
  readonly?: boolean;
  handleClickPictogram?: (pictogram: Pictogram) => void;
  selectedItem?: Pictogram;
  countryCode: string;
  pictograms: Pictogram[];
}

export default function PictogramAttributes(props: IProps): ReactElement {
  const classes = useStyles();
  const { readonly, countryCode, handleClickPictogram, pictograms, selectedItem } = props;

  return (
    <Card className={classes.Card}>
      <TileView>
        {pictograms.map((pictogramResponse: Pictogram) => {
          let updatedAt = new Date();
          if (pictogramResponse.updatedAt !== undefined) updatedAt = new Date(pictogramResponse.updatedAt);

          // Using file name as key, since currently pictogram file names are unique and
          // have no other ID available
          return (
            <ImageItem
              description={pictogramResponse.fileName}
              showSubDescription={true}
              showSelected={selectedItem?.fileName === pictogramResponse.fileName}
              subDescription={Utility.formatDateTime(updatedAt)}
              isClickable={!readonly}
              isSelected={pictogramResponse.isUsed}
              onClickItem={() => {
                if (handleClickPictogram) handleClickPictogram(pictogramResponse);
              }}
              key={pictogramResponse.fileName}
              imageSrc={ApiImageUrlsBuilder.buildPictogramImageUrl(countryCode, pictogramResponse.fileName)}
            />
          );
        })}
      </TileView>
    </Card>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    Card: {
      padding: theme.spacing(2),
      boxShadow: 'none',
      marginRight: '16px',
    },
  })
);

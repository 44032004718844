import React, { ReactElement } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { MaterialGrid } from './MaterialGrid';
import { useMurexinPimContextApi } from '../../../api';
import { useParams } from 'react-router-dom';
import { useAsync } from 'react-async-hook';
import { IRouterParams } from '../../../routes';
import { CircleLoader } from '../../../utils/CircleLoader';
import { MaterialGroupSidebar } from './MaterialGroupSidebar';
import { SidebarMode } from '../../../shared/Enum';

function MaterialList(): ReactElement {
  const classes = useStyles();
  const { country, productId } = useParams<IRouterParams>();

  const api = useMurexinPimContextApi();
  const [openSidebar, setOpenSidebar] = React.useState<boolean>(false);

  const productGroupResponse = useAsync(async () => {
    const response = await api.productService.productServiceGetByIdDetail(country, Number(productId));

    return response.data ?? {};
  }, []);

  const materialGroupResponse = useAsync(async () => {
    const response = await api.materialGroupService.materialGroupServiceByProductIdDetail(country, Number(productId));
    return response.data ?? [];
  }, [country, productId]);

  if (materialGroupResponse.loading || productGroupResponse.loading) {
    return <CircleLoader open={true} />;
  } else {
    return (
      <Grid container className={classes.Container}>
        <Grid item xs={8}>
          <MaterialGrid
            materialGroupResponse={materialGroupResponse.result ?? []}
            handleOnAddMaterialGroup={() => setOpenSidebar(true)}
            productGroup={productGroupResponse.result}
          />
        </Grid>
        <Grid item xs={4} className={classes.Sidebar}>
          {country !== 'AT' && openSidebar && (
            <MaterialGroupSidebar
              mode={SidebarMode.Create}
              handleOnCancel={() => setOpenSidebar(false)}
              productGroupName={productGroupResponse.result?.productGroupName ?? ''}
              handleOnClose={async () => {
                await materialGroupResponse.execute();
                setOpenSidebar(false);
              }}
            />
          )}
        </Grid>
      </Grid>
    );
  }
}

const useStyles = makeStyles(() => ({
  Sidebar: {
    minHeight: '100%',
    marginTop: '-92px',
  },
  Container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    padding: '16px 0px 0px 16px',
  },
}));

export default MaterialList;

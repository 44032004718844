import { Card, createStyles, makeStyles, Theme } from '@material-ui/core';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { AdvantageResponse, DescriptionResponseModel } from '../../../../api';
import PIMFormikCheckbox from '../../../../shared/Components/PIMFormikCheckbox';
import PIMFormikTextField from '../../../../shared/Components/PIMFormikTextField';

interface IProps {
  readonly?: boolean;
  authorizedToWrite: boolean;
}

export default function DescriptionAttributes(props: IProps): ReactElement {
  const classes = useStyles();
  const { readonly, authorizedToWrite } = props;
  const { t } = useTranslation();
  const disabled = readonly || !authorizedToWrite;
  const formikContext = useFormikContext();
  const advantages = (formikContext.values as DescriptionResponseModel)?.advantage;
  const maxAdvantageDisplayOrder = 5;
  const minAdvantageDisplayOrder = 1;

  const getAdvantageNameByOrder = (displayOrder: number) =>
    advantages?.find((a) => a.displayOrder === displayOrder)?.advantageName ?? '';

  const handleOnChangeAdvantage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    displayOrder: number,
    formikArrayHelpers: FieldArrayRenderProps
  ) => {
    if (event.target.validity.valid || event.target.value === '') {
      const advantageName = event.target.value;
      const index = advantages?.findIndex((a: AdvantageResponse) => a.displayOrder === displayOrder);
      if (index === undefined) return;

      const advantage = { advantageName, displayOrder } as AdvantageResponse;

      if (index < 0) {
        formikArrayHelpers.push(advantage);
      } else {
        if (advantageName === '') {
          formikArrayHelpers.remove(index);
        } else {
          formikArrayHelpers.replace(index, advantage);
        }
      }
    }
  };

  function renderAdvantages(formikArrayHelpers: FieldArrayRenderProps) {
    const advantagesList = [];

    for (let displayOrder = minAdvantageDisplayOrder; displayOrder <= maxAdvantageDisplayOrder; displayOrder++) {
      advantagesList.push(
        <PIMFormikTextField
          key={displayOrder}
          label={t(`Produktvorteil ${displayOrder}`)}
          name={`Produktvorteil ${displayOrder}`}
          value={getAdvantageNameByOrder(displayOrder)}
          onChange={(event) => handleOnChangeAdvantage(event, displayOrder, formikArrayHelpers)}
          className={classes.Textfield}
          fullWidth
          multiline
          disabled={disabled}
        />
      );
    }
    return advantagesList;
  }

  return (
    <>
      <Card className={classes.Card}>
        <div className={!readonly ? `${classes.Checkbox}` : `${classes.Checkbox} ${classes.CheckboxHidden}`}>
          <PIMFormikCheckbox
            color='primary'
            value='newProduct'
            name={'newProduct'}
            label={t('Neues Produkt')}
            disabled={disabled}
          />
        </div>
        <PIMFormikTextField
          label={t('Produktbezeichnung')}
          name={'productTitle'}
          className={`${classes.Textfield} ${classes.TopTextfieldMargin}`}
          fullWidth
          multiline
          disabled={disabled}
        />
        <FieldArray name='advantage' render={(arrayHelpers) => renderAdvantages(arrayHelpers)} />
        <PIMFormikTextField
          label={t('Produktbeschreibung lang')}
          name={'productDescriptionLong'}
          className={classes.Textfield}
          fullWidth
          multiline
          disabled={disabled}
        />
        <PIMFormikTextField
          label={t('Produktbeschreibung kurz')}
          name={'productDescriptionShort'}
          className={classes.Textfield}
          fullWidth
          multiline
          disabled={disabled}
        />
      </Card>
    </>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    Textfield: {
      paddingBottom: '5px',
      '& .MuiFormLabel-root.Mui-focused': {
        color: 'black',
      },
    },
    TopTextfieldMargin: {
      marginTop: '0px',
    },
    Checkbox: {
      '& .MuiFormControl-marginNormal': {
        marginTop: '16px',
        marginBottom: '16px',
      },
      '& .MuiFormControl-root': {
        alignItems: 'flex-end',
        width: '100%',
        display: 'flex',
      },
      '& .MuiFormControlLabel-root': {
        marginRight: '0px',
      },
    },
    CheckboxHidden: {
      visibility: 'hidden',
    },
    Card: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  })
);

import React, { useState } from 'react';
import { useAsync } from 'react-async-hook';
import { QuickLinkResponse, useMurexinPimContextApi } from '../../api';
export interface IBreadCrumb {
  reload: () => void;
  quickLinks: QuickLinkResponse[];
}
export const BreadCrumbContext = React.createContext<IBreadCrumb>({
  reload: () => false,
  quickLinks: [],
});

interface IProps {
  children: React.ReactElement;
}

export function BreadCrumbProvider(props: IProps): JSX.Element {
  const api = useMurexinPimContextApi();
  const [quicklinks, setQuicklinks] = useState<QuickLinkResponse[]>([]);

  useAsync(() => api.quickLink.quickLinkList().then((d) => setQuicklinks(d.data)), []);

  async function reload() {
    await api.quickLink.quickLinkList().then((d) => setQuicklinks(d.data));
  }

  return (
    <BreadCrumbContext.Provider
      value={{
        reload: () => reload(),
        quickLinks: quicklinks,
      }}>
      {props.children}
    </BreadCrumbContext.Provider>
  );
}

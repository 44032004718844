import React, { useContext, useState } from 'react';
import { Divider, IconButton, Link, makeStyles, Grid, Theme } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { LinkIcon } from '../../../shared/icons';
import { UpdateDocumentRequest } from './DocumentsAndLeafletsMainPanel';
import { useMurexinPimContextApi, DocumentResponse, DocumentCategory, DocumentLevel } from '../../../api/MurexinPimApi';
import { NotificationContext } from '../../../contexts/NotificationContext';
import MessageDialog from '../../../shared/Components/MessageDialog';
import { useAsyncCallback } from 'react-async-hook';
import PIMButton from '../../../shared/Components/PIMButton';
import PIMFormikToggle from '../../../shared/Components/PIMFormikToggle';
import PIMFormikTextField from '../../../shared/Components/PIMFormikTextField';
import PIMFormikSelect from '../../../shared/Components/PIMFormikSelect';
import { useTranslation } from 'react-i18next';
import { ApiImageUrlsBuilder } from '../../../utils/apiImageUrls';
import PIMInformationBox from '../../../shared/Components/PIMInformationBox';
import { IDocumentCategory } from '../../../shared/DocumentTranslations';

interface IProps {
  isAuthorizedToWrite: boolean;
  document: UpdateDocumentRequest;
  exportedTechnicalDataSheet: DocumentResponse | null;
  documentCategories: IDocumentCategory[];
  country: string;
  materialGroupId: number;
  isExportEnabledForCategory: (category: DocumentCategory) => boolean;
  onUpdateCompleted: (hasChanges: boolean) => void;
}

export default function EditDocumentsSideBar(props: IProps): JSX.Element {
  const {
    isAuthorizedToWrite,
    document,
    exportedTechnicalDataSheet,
    documentCategories,
    country,
    materialGroupId,
    isExportEnabledForCategory,
    onUpdateCompleted,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  // TODO: on dirty warning when switching
  const categoryOptions = documentCategories.map((c) => ({
    key: c.id,
    value: c.name,
  }));
  const api = useMurexinPimContextApi();
  const notification = useContext(NotificationContext);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showChangeExportedDialog, setChangeExportedDialog] = useState(false);

  const onDeleteDocument = useAsyncCallback(async () => {
    setShowDeleteDialog(false);
    try {
      await api.document.documentDelete(country, document.id);
      notification.success(t('Dokument wurde gelöscht'));
      onUpdateCompleted(true);
    } catch (error) {
      notification.error(t('Der Löschvorgang ist fehlgeschlagen'));
    }
  });

  const readOnly = document.documentLevel !== DocumentLevel.MaterialGroup;

  async function handleOnSubmit(submittedDocument: UpdateDocumentRequest) {
    try {
      if (!submittedDocument.newFile) {
        const updateDocument = {
          fileName: submittedDocument.fileName,
          category: submittedDocument.category,
          isExported: submittedDocument.isExported,
        };
        await api.document.documentUpdate(country, submittedDocument.id, updateDocument);
        notification.success(t('Einstellungen wurden geändert'));
      } else {
        const createDocument = {
          File: submittedDocument.newFile,
          Category: submittedDocument.category,
          IsExported: submittedDocument.isExported,
          DetailId: materialGroupId,
          DocumentLevel: DocumentLevel.MaterialGroup,
        };
        await api.document.documentCreate(country, createDocument);
        notification.success(t('Ein Dokument wurde hinzugefügt'));
      }

      onUpdateCompleted(true);
    } catch (error) {
      const message = submittedDocument.newFile
        ? t('Das Hinzufügen ist fehlgeschlagen')
        : t('Einstellungen konnten nicht aktualisiert werden');
      notification.error(message);
    }
  }

  function beforeOnSubmit(submittedDocument: UpdateDocumentRequest, handleSubmit: () => void) {
    const isChangingExportedTechnicalDataSheet =
      !!exportedTechnicalDataSheet &&
      submittedDocument.id !== exportedTechnicalDataSheet.id &&
      submittedDocument.category === DocumentCategory.TechnicalDataSheet &&
      submittedDocument.isExported;

    if (isChangingExportedTechnicalDataSheet) {
      setChangeExportedDialog(true);
    } else {
      handleSubmit();
    }
  }

  return (
    <Grid className={classes.Container}>
      <Formik initialValues={document} onSubmit={handleOnSubmit} enableReinitialize>
        {({ handleReset, handleSubmit, values, isSubmitting, dirty }) => (
          <>
            <Form>
              <Grid container direction='row' alignItems='flex-start' className={classes.buttonContainer}>
                <PIMButton
                  className={classes.topButtons}
                  icon='close'
                  disabled={isSubmitting || onDeleteDocument.loading}
                  onClick={() => {
                    handleReset();
                    onUpdateCompleted(false);
                  }}>
                  {isAuthorizedToWrite && !readOnly ? t('Abbrechen') : t('Schließen')}
                </PIMButton>
                {isAuthorizedToWrite && !readOnly && (
                  <>
                    <PIMButton
                      color='primary'
                      icon='save'
                      className={classes.topButtons}
                      busy={isSubmitting}
                      disabled={(!dirty && !document.newFile) || onDeleteDocument.loading}
                      onClick={() => beforeOnSubmit(values, handleSubmit)}>
                      {t('Speichern')}
                    </PIMButton>
                  </>
                )}
              </Grid>
              {readOnly && <PIMInformationBox>{t('HinweisNichtEditbaresDokument')}</PIMInformationBox>}
              <PIMFormikSelect
                disabled={!isAuthorizedToWrite || readOnly}
                name='category'
                id='test'
                label={t('Kategorie')}
                hideRemoveSelection
                options={categoryOptions}
                formControlProps={{ fullWidth: true }}
              />
              <Grid container alignItems='center'>
                <PIMFormikTextField
                  name='fileName'
                  id='fileName'
                  label={t('Dateiname')}
                  className={classes.fileNameField}
                  type='text'
                  disabled
                />
                <IconButton
                  disabled={!!document.newFile}
                  className={classes.linkButton}
                  component={Link}
                  target='_blank'
                  rel='noopener noreferrer'
                  href={ApiImageUrlsBuilder.buildEditDocumentEditSidebarIconUrlByCountryAndDocumentId(
                    country,
                    document.id
                  )}>
                  <LinkIcon />
                </IconButton>
              </Grid>
              {isExportEnabledForCategory(values.category) && (
                <PIMFormikToggle
                  name={'isExported'}
                  label={t('Exportieren zur Website')}
                  disabled={!isAuthorizedToWrite || readOnly}
                />
              )}

              {isAuthorizedToWrite && !document.newFile && !readOnly && (
                <>
                  <Divider variant='fullWidth' className={classes.divider} />
                  <PIMButton
                    color='warning'
                    icon='trash'
                    busy={onDeleteDocument.loading}
                    disabled={isSubmitting}
                    onClick={() => setShowDeleteDialog(true)}>
                    {t('Dokument löschen')}
                  </PIMButton>
                </>
              )}
            </Form>
            <MessageDialog
              show={showChangeExportedDialog}
              messageText={t(`FrageInformationZumExport`, { filename: document.fileName })}
              title={t('Information zum Export')}
              confirmLabel={t('Freigeben')}
              onClose={() => setChangeExportedDialog(false)}
              onCancel={() => setChangeExportedDialog(false)}
              onConfirm={() => {
                setChangeExportedDialog(false);
                handleSubmit();
              }}
            />
          </>
        )}
      </Formik>
      <MessageDialog
        show={showDeleteDialog}
        messageText={t(`FrageDokumentLöschen`, { filename: document.fileName })} // Möchten Sie das Dokument {{filename}} löschen?
        title={t('Dokument löschen')}
        confirmColor='warning'
        confirmIcon='trash'
        confirmLabel={t('Löschen')}
        onClose={() => setShowDeleteDialog(false)}
        onCancel={() => setShowDeleteDialog(false)}
        onConfirm={onDeleteDocument.execute}
      />
    </Grid>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  linkButton: { '&.Mui-disabled': { opacity: 0.5 } },
  topButtons: { margin: theme.spacing(0, 3, 0, 0) },
  buttonContainer: { margin: theme.spacing(0, 0, 2) },
  divider: { margin: theme.spacing(2, 0, 4) },
  fileNameField: {
    flex: 1,
    padding: theme.spacing(0, 2, 0, 0),
  },
  stretch: { flex: 1 },
  Container: {
    margin: '0px',
    padding: '72px 40px 24px 40px',
  },
}));

import React from 'react';

export function LinkIcon(): JSX.Element {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M23.1 9.89334H12.051C11.0741 9.89334 10.1372 10.2814 9.44646 10.9722C8.75571 11.6629 8.36764 12.5998 8.36764 13.5767'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.1533 6.94666L23.1 9.89332L20.1533 12.84'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.2067 5.47333C17.2067 5.08258 17.0514 4.70783 16.7751 4.43153C16.4988 4.15523 16.1241 4 15.7333 4H2.47335C2.0826 4 1.70785 4.15523 1.43155 4.43153C1.15524 4.70783 1.00002 5.08258 1.00002 5.47333V18.7333C1.00002 19.1241 1.15524 19.4988 1.43155 19.7751C1.70785 20.0514 2.0826 20.2066 2.47335 20.2066H15.7333C16.1241 20.2066 16.4988 20.0514 16.7751 19.7751C17.0514 19.4988 17.2067 19.1241 17.2067 18.7333V14.3133'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

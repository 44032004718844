import React, { ReactElement, useState } from 'react';
import { createStyles, makeStyles, Grid } from '@material-ui/core';
import { AdminBreadCrumbs } from '../../../../layouts/AdminBreadCrumbs/AdminBreadCrumbs';
import ListView from '../../../../shared/Components/ListView';
import { GridColumns, GridColDef } from '@mui/x-data-grid';
import { useAsync } from 'react-async-hook';
import {
  useMurexinPimContextApi,
  LocalizationTextModel,
  CultureDefinition,
  LocalizationDefinition,
} from '../../../../api';
import { CircleLoader } from '../../../../utils/CircleLoader';
import { LocalizationSidebar } from './LocalizationSidebar';
import PIMTextField from '../../../../shared/Components/PIMTextField';
import { useTranslation } from 'react-i18next';
import { CurrentCultureStateContext } from '../../../../contexts/CurrentCultureStateContext';

interface TranslationGridType {
  [key: string]: string | number;
  id: number;
  sectionCode: string;
  textCode: string;
}

export function LocalizationPage(): ReactElement {
  const classes = useStyles();
  const api = useMurexinPimContextApi();
  const [selectedTranslation, setSelectedTranslation] = useState<TranslationGridType | undefined>(undefined);
  const [searchText, setSearchText] = useState<string>('');
  const { reloadCurrentCultureTranslation } = React.useContext(CurrentCultureStateContext);

  const { t } = useTranslation();

  const localizationDefinition = useAsync(async () => {
    const response = await api.localization.localizationDefinitionList();
    return response.data ?? [];
  }, []);

  const translations = useAsync(async () => {
    const response = await api.localization.localizationTextListList();
    return response.data ?? [];
  }, []);

  const columnDefinition: GridColumns = [
    { field: 'id', headerName: '', hide: true },
    { field: 'textCode', headerName: t('Übersetzungsschlüssel'), width: 250 },
  ];

  if (localizationDefinition.result && localizationDefinition.result.cultureDefinitions) {
    localizationDefinition.result.cultureDefinitions.forEach((cultureDefinition) => {
      const column: GridColDef = {
        field: cultureDefinition.cultureCode,
        headerName: t(cultureDefinition.cultureCode),
        width: 250,
      };
      columnDefinition.push(column);
    });
  }

  const getFilteredBySearchText = (
    data: TranslationGridType[],
    cultureDefinitions: CultureDefinition[] | undefined | null
  ): TranslationGridType[] => {
    const searchTextList = searchText.trim().toLocaleLowerCase().split(' ');

    let filteredData: TranslationGridType[];

    if (cultureDefinitions && searchText !== '') {
      filteredData = data.filter((translation: TranslationGridType) => {
        let ret = false;
        ret = searchTextList.every((searchTerm: string) => {
          let exists = false;
          if (!exists) {
            exists = translation.textCode.toLocaleLowerCase().includes(searchTerm);
          }
          if (!exists) {
            cultureDefinitions.forEach((cultureDefinition: CultureDefinition) => {
              if (!exists) {
                const translationText = translation[cultureDefinition.cultureCode] as string;
                exists = translationText.toLocaleLowerCase().includes(searchTerm);
              }
            });
          }
          return exists;
        });
        return ret;
      });
    } else {
      filteredData = data;
    }

    return filteredData;
  };

  const getTranslationData = (
    localizationTextList: LocalizationTextModel[] | undefined,
    cultureDefinitions: CultureDefinition[] | undefined | null
  ): TranslationGridType[] => {
    let data: TranslationGridType[] = [];
    if (localizationTextList && cultureDefinitions) {
      data = localizationTextList.map((localizationText: LocalizationTextModel, index) => {
        const translation: TranslationGridType = {
          id: index,
          sectionCode: localizationText.sectionCode,
          textCode: localizationText.textCode,
        };
        cultureDefinitions.forEach((cultureDefinition: CultureDefinition) => {
          if (localizationText.translations[cultureDefinition.cultureCode] === undefined) {
            translation[cultureDefinition.cultureCode] = '';
          } else {
            translation[cultureDefinition.cultureCode] = localizationText.translations[cultureDefinition.cultureCode];
          }
        });
        return translation;
      });
    }

    return getFilteredBySearchText(data, cultureDefinitions);
  };

  if (localizationDefinition.loading) {
    return <CircleLoader open={true} />;
  } else {
    return (
      <Grid container>
        <AdminBreadCrumbs site={t('Internationalisierung')} />
        <Grid container item xs={12} className={classes.LocalizationPage}>
          <Grid item xs={selectedTranslation !== undefined ? 8 : 12} className={classes.LocalizationGrid}>
            <div className={classes.Title}>{t('Internationalisierung')}</div>
            <Grid item xs={12}>
              <PIMTextField
                label={t('Element suchen')}
                className={classes.SearchTextField}
                fullWidth
                onChange={(event) => setSearchText(event.target.value ?? '')}
              />
            </Grid>
            <Grid item xs={12}>
              <ListView
                columns={columnDefinition}
                data={getTranslationData(translations.result, localizationDefinition?.result?.cultureDefinitions)}
                onChangeSelection={(item) => setSelectedTranslation(item ?? undefined)}
                selectedItem={selectedTranslation ?? undefined}
              />
            </Grid>
          </Grid>
          {selectedTranslation !== undefined ? (
            <Grid item xs={4} className={classes.Sidebar}>
              <LocalizationSidebar
                localizationDefinition={localizationDefinition.result as LocalizationDefinition}
                sectionCode={selectedTranslation.sectionCode}
                textCode={selectedTranslation.textCode}
                onClose={() => {
                  setSelectedTranslation(undefined);
                }}
                onSave={async () => {
                  setSelectedTranslation(undefined);
                  await translations.execute();
                  reloadCurrentCultureTranslation();
                }}
              />
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    );
  }
}

const useStyles = makeStyles(() =>
  createStyles({
    LocalizationPage: {
      padding: '24px 0px 0px 8px',
      minHeight: '100%',
    },
    LocalizationGrid: {
      paddingRight: '24px',
    },
    SearchTextField: {
      width: '688px',
      marginBottom: '24px',
    },
    Sidebar: {
      marginTop: '-100px',
      minHeight: '100%',
      background: 'white',
    },
    Title: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '36px',
      marginBottom: '24px',
    },
  })
);

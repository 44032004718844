import React from 'react';
import { CommentGrid } from './CommentGrid';
import { AddingComment } from './AddingComment';
import { CommentResponse } from '../../api';

interface IProps {
  isAuthorizedToWrite: boolean;
  list: CommentResponse[] | null;
  deleteComment: (id: number) => void;
  addComment: (comment: CommentResponse) => void;
}

export function CommentList(props: IProps): JSX.Element {
  return (
    <div>
      {props.isAuthorizedToWrite && <AddingComment addComment={props.addComment} />}
      <CommentGrid
        comments={props.list}
        deleteComment={props.deleteComment}
        isAuthorizedToWrite={props.isAuthorizedToWrite}
      />
    </div>
  );
}

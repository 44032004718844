import React, { useContext } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import Card from '@mui/material/Card';

import { ArticleResponse, CountryPermission } from '../../../api/MurexinPimApi';
import { GlobalIcon, LocalIcon, SuccessIcon, ErrorIcon } from '../../../shared/icons';
import PIMButton from '../../../shared/Components/PIMButton';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { IRouterParams } from '../../../routes';
import { AuthorizationContext } from '../../../auth';

import { useHistory } from 'react-router-dom';

interface IProps {
  updateValue: (view: number, articleId?: number) => void;
  articleList: ArticleResponse[] | null | undefined;
  readOnly?: boolean;
  erpImport?: boolean;
}

export function ArticleGrid(props: IProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const { updateValue, articleList, erpImport } = props;
  const history = useHistory();
  const { country, articleId } = useParams<IRouterParams>();
  const { hasCountryPermission } = useContext(AuthorizationContext);
  const isAuthorizedToWrite = hasCountryPermission(country, CountryPermission.Write);
  const readOnly = props.readOnly || !isAuthorizedToWrite;
  const productTypes = ['ST', 'SP', 'IND', 'FA', 'EX', 'PC'];

  function click(id: number) {
    history.push(id.toString());
    updateValue(2, id);
  }

  function sortArticles(article1: ArticleResponse, article2: ArticleResponse): number {
    if (
      article1 &&
      article2 &&
      article1.productType &&
      article2.productType &&
      article1.productType.code &&
      article2.productType.code
    ) {
      if (!productTypes.includes(article1.productType.code)) {
        return 1;
      }

      if (!productTypes.includes(article2.productType.code)) {
        return -1;
      }

      if (article1.articleNumber && article2.articleNumber) {
        if (article1.productType.name === article2.productType.name) {
          return Number(article1.articleNumber) - Number(article2.articleNumber);
        } else {
          return productTypes.indexOf(article1.productType.code) - productTypes.indexOf(article2.productType.code);
        }
      }
    }

    return 0;
  }

  return (
    <>
      {readOnly || erpImport ? null : (
        <Grid container xs={12}>
          <Grid xs={8} />
          <Grid xs={4}>
            <PIMButton
              className={`${classes.flexAlignRight} ${classes.addArticleButton}`}
              icon='add'
              color='primary'
              onClick={() => click(0)}>
              {t('Neuen Artikel hinzufügen')}
            </PIMButton>
          </Grid>
        </Grid>
      )}
      {articleList
        ? articleList
            .sort((article1, article2) => sortArticles(article1, article2))
            .map((article, key) => (
              <Card
                className={[
                  classes.ArticleCard,
                  article.isActive === false ? 'Inactive' : null,
                  article.id === Number(articleId) && article.id !== 0 ? 'Selected' : null,
                ].join(' ')}
                component={'div'}
                variant='outlined'
                key={key}
                onClick={() => {
                  click(Number(article.id));
                }}>
                <Grid container className={classes.ArticleGrid} spacing={2}>
                  <Grid item xs>
                    <div
                      className={[classes.ArticleDescription, article.isActive === false ? 'Inactive' : null].join(
                        ' '
                      )}>
                      {article.articleDescription}
                    </div>
                    <div className={classes.SubTitleArticleDescription}>
                      <div>
                        {article.articleNumber} · {article.ean}
                      </div>
                      {!article.isActive ? <div className={classes.InactiveText}>{t('inaktiv')}</div> : null}
                    </div>
                  </Grid>
                  <Grid item className={classes.GridItem}>
                    <div className={[classes.Title, article.isActive === false ? 'Inactive' : null].join(' ')}>
                      {article.containerType ? t(`Gebindeart_Abkürzung_${article.containerType}`) : '-'}
                    </div>
                    <div className={classes.SubTitle}>{t('Gebindeart')}</div>
                  </Grid>
                  <Grid item className={classes.GridItem}>
                    <div className={[classes.Title, article.isActive === false ? 'Inactive' : null].join(' ')}>
                      {article.productType?.code ?? '-'}
                    </div>
                    <div className={classes.SubTitle}>{t('Produktart')}</div>
                  </Grid>
                  <Grid item xs='auto' className={classes.GridItem}>
                    <div className={[classes.Title, article.isActive === false ? 'Inactive' : null].join(' ')}>
                      {article.palletNumberOfContainers ?? '-'}
                    </div>
                    <div className={classes.SubTitle}>{t('je Palette')}</div>
                  </Grid>
                  <Grid item xs='auto' className={classes.GridItem}>
                    <div className={[classes.Title, article.isActive === false ? 'Inactive' : null].join(' ')}>
                      {article.overCartonNumberOfContainers && article.overCartonNumberOfContainers !== 0
                        ? article.overCartonNumberOfContainers
                        : '-'}
                    </div>
                    <div className={classes.SubTitle}>{t('Überverpackung')}</div>
                  </Grid>
                  <Grid item xs='auto' className={classes.ERP}>
                    <div className={[classes.Images, article.isActive === false ? 'Inactive' : null].join(' ')}>
                      {article.erpImport ? (
                        <SuccessIcon className={classes.ERPIcon} />
                      ) : (
                        <ErrorIcon className={classes.ERPIcon} />
                      )}
                    </div>
                    <div className={classes.SubTitle}>{article.erpImport ? t('ERP') : t('Non-ERP')}</div>
                  </Grid>
                  <Grid item xs='auto'>
                    <div className={[classes.Images, article.isActive === false ? 'Inactive' : null].join(' ')}>
                      {article.isGlobal ? <GlobalIcon /> : <LocalIcon />}
                    </div>
                  </Grid>
                </Grid>
              </Card>
            ))
        : null}
    </>
  );
}
const useStyles = makeStyles((theme) => ({
  ArticleCard: {
    padding: '8px 16px',
    marginBottom: '8px',
    marginRight: '-47px',
    cursor: 'pointer',
    '&.MuiPaper-outlined': {
      border: 'none',
      borderRadius: '0px',
    },
    '&.Inactive': {
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
    },
    '&.Selected': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  ArticleGrid: {
    alignItems: 'center',
  },
  ArticleDescription: {
    fontFamily: 'Roboto',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: '#000000',
    margin: '0px 0px 4px 0px',
    '&.Inactive': {
      opacity: '0.38',
    },
  },
  Title: {
    fontFamily: 'Roboto',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: '#000000',
    margin: '0px 0px 4px 0px',
    textAlign: 'center',
    '&.Inactive': {
      opacity: '0.38',
    },
  },
  SubTitleArticleDescription: {
    opacity: '0.5',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: '#000000',
    display: 'flex',
    flexDirection: 'row',
  },
  SubTitle: {
    opacity: '0.65',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    textAlign: 'center',
    color: '#000000',
  },
  InactiveText: {
    margin: '0px 0px 0px 8px',
  },
  Images: {
    margin: '0px 8px 0px 8px',
    '&.Inactive': {
      opacity: '0.38',
    },
    display: 'flex',
    justifyContent: 'center',
  },
  GridItem: {
    margin: '0px 4px 0px 4px',
  },
  ERPIcon: {
    height: '22.5px',
  },
  flexAlignRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  ERP: {
    width: '86px',
    height: '50px',
  },
  addArticleButton: {
    marginTop: '-32px',
    marginBottom: '32px',
    marginRight: '-47px',
  },
}));

import { createStyles, Grid, makeStyles } from '@material-ui/core';
import React, { ReactElement, useContext } from 'react';
import {
  AdvantageResponse,
  CountryPermission,
  DescriptionResponseModel,
  useMurexinPimContextApi,
} from '../../../../api';
import { NotificationContext } from '../../../../contexts/NotificationContext';
import { useParams } from 'react-router-dom';
import { useAsync } from 'react-async-hook';
import { CircleLoader } from '../../../../utils/CircleLoader';
import { Form, Formik } from 'formik';
import PIMButton from '../../../../shared/Components/PIMButton';
import DescriptionAttributes from './DescriptionAttributes';
import { IRouterParams } from '../../../../routes';
import { AuthorizationContext } from '../../../../auth';
import { useTranslation } from 'react-i18next';
import { ProductManagementProps } from '../../ProductManagement/ProductManagement';
import useFormikSaveAndClose from '../../../../utils/customHooks/useFormikSaveAndClose';

interface IProps {
  countrySelectionComponent: React.ReactNode;
  translationCountryCode: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    ActionButtonRow: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'flex-end',
      paddingBottom: '32px',
      marginTop: '-80px',
    },
    TopButtons: {
      marginLeft: '24px',
    },
    Container: {
      marginRight: '24px',
    },
  })
);

export default function Description(props: IProps & ProductManagementProps): ReactElement {
  const classes = useStyles();
  const api = useMurexinPimContextApi();
  const notification = React.useContext(NotificationContext);
  const { country, materialId } = useParams<IRouterParams>();

  const { onDirtyChanged, cleanUpForm, countrySelectionComponent, translationCountryCode, saveOnCloseRef } = props;
  const formikRef = useFormikSaveAndClose<DescriptionResponseModel>(saveOnCloseRef);

  const materialGroupId = Number(materialId);
  const showTranslationPanel = !!translationCountryCode;
  const { t } = useTranslation();
  const { hasCountryPermission } = useContext(AuthorizationContext);
  const isAuthorizedToWrite = hasCountryPermission(country, CountryPermission.Write);

  const descriptionAttributes = useAsync(async () => {
    const descriptionResponse = await api.materialGroupService.materialGroupServiceDescriptionDetail(
      country,
      materialGroupId
    );

    if (!descriptionResponse.data) {
      descriptionResponse.data = {} as DescriptionResponseModel;
    }

    if (!descriptionResponse.data.advantage) {
      descriptionResponse.data.advantage = new Array<AdvantageResponse>();
    }

    return descriptionResponse.data;
  }, [country, materialGroupId]);

  const descriptionAttributesTranslation = useAsync(async () => {
    if (!!translationCountryCode) {
      const response = await api.materialGroupService.materialGroupServiceDescriptionDetail(
        translationCountryCode,
        materialGroupId
      );
      return response.data ?? {};
    }
    return {};
  }, [translationCountryCode, materialGroupId]);

  async function updateData(values: DescriptionResponseModel) {
    try {
      await api.materialGroupService.materialGroupServiceDescriptionCreate(country, materialGroupId, values);

      await descriptionAttributes.execute();
      cleanUpForm();
      notification.success(t('Speichern erfolgreich'));
    } catch (error) {
      notification.error(t('Speichern fehlgeschlagen'));
      throw error;
    }
  }

  return (
    <>
      <CircleLoader open={descriptionAttributes.loading || descriptionAttributesTranslation.loading} />
      <Formik
        enableReinitialize={!descriptionAttributes.loading}
        innerRef={formikRef}
        initialValues={descriptionAttributes.result as DescriptionResponseModel}
        onSubmit={updateData}>
        {({ isSubmitting, dirty, handleReset, handleSubmit, handleChange }) => (
          <Form
            className={classes.Container}
            onChange={(e) => {
              onDirtyChanged();
              handleChange(e);
            }}>
            <Grid container item xs={showTranslationPanel ? 12 : 8}>
              <Grid item xs={12} className={classes.ActionButtonRow}>
                {isAuthorizedToWrite && countrySelectionComponent}
                {isAuthorizedToWrite && (
                  <>
                    <PIMButton
                      className={classes.TopButtons}
                      icon='close'
                      disabled={isSubmitting || !dirty || !isAuthorizedToWrite}
                      onClick={() => {
                        notification.info(t('Daten zurückgesetzt'));
                        handleReset();
                      }}>
                      {t('Abbrechen')}
                    </PIMButton>
                    <PIMButton
                      color='primary'
                      icon='save'
                      className={classes.TopButtons}
                      busy={isSubmitting}
                      disabled={!dirty || !isAuthorizedToWrite}
                      onClick={() => handleSubmit()}>
                      {t('Speichern')}
                    </PIMButton>
                  </>
                )}
              </Grid>
              <Grid container item spacing={2}>
                <Grid item xs={showTranslationPanel ? 6 : 12}>
                  <DescriptionAttributes authorizedToWrite={isAuthorizedToWrite} />
                </Grid>
                {!!showTranslationPanel && (
                  <Grid item xs={6}>
                    <Formik
                      enableReinitialize={!descriptionAttributesTranslation.loading}
                      initialValues={descriptionAttributesTranslation.result as DescriptionResponseModel}
                      onSubmit={() => {
                        return;
                      }}>
                      <DescriptionAttributes readonly authorizedToWrite={isAuthorizedToWrite} />
                    </Formik>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}

import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';
import { InformationIcon } from '../../icons';
import { createStyles, makeStyles } from '@material-ui/core';

interface PIMInformationBoxProps {
  children: React.ReactNode;
  className?: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    Box: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: 'rgba(10, 157, 213, 0.1)',
      border: '1px solid #0A9DD5',
      padding: '16px',
      borderRadius: '4px',
    },
    InformationIcon: {
      marginRight: '16px',
      alignSelf: 'flex-start',
    },
    InformationText: {
      fontFamily: 'Roboto',
      fontSize: '16px',
      fontStyle: 'normal',
    },
  })
);

export function PIMInformationBox(props: PIMInformationBoxProps): ReactElement {
  const classes = useStyles();

  return (
    <Box className={[classes.Box, props.className].join(' ')}>
      <div className={classes.InformationIcon}>
        <InformationIcon />
      </div>
      <div className={classes.InformationText}>{props.children}</div>
    </Box>
  );
}

import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  })
);

interface IProps {
  open: boolean;
}

export function CircleLoader(props: IProps): JSX.Element {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(open);
  };

  return (
    <div>
      <Backdrop className={classes.backdrop} open={props.open} onClick={handleClose}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </div>
  );
}

import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PIMButton from '../shared/Components/PIMButton';

interface IProps {
  handleCancel: () => void;
  handleClose: () => void;
  handleSaveAndClose: () => void;
  open: boolean;
  fullScreen?: boolean;
  saveText: string;
  title: string;
}

export function SaveChangesDialog(props: IProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Dialog
      fullScreen={props.fullScreen}
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby='responsive-dialog-title'
      className={classes.dialog}>
      <DialogTitle id='responsive-dialog-title'>
        <span className={classes.title}>{props.title}</span>
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.font}>
          <span className={classes.font}>{props.saveText}</span>
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.ActionButtons}>
        <PIMButton color='secondary' onClick={props.handleCancel} autoFocus>
          {t('Abbrechen')}
        </PIMButton>
        <PIMButton color='secondary' onClick={props.handleClose} autoFocus>
          {t('Ohne speichern verlassen')}
        </PIMButton>
        <PIMButton onClick={props.handleSaveAndClose} color='primary' icon='save' autoFocus>
          {t('Speichern')}
        </PIMButton>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles(() => ({
  dialog: {
    padding: '32px',
    '& .MuiDialog-paperWidthSm': {
      padding: '32px',
    },
  },
  title: {
    fontSize: '28px',
    fontWeight: 400,
  },
  font: {
    color: 'black',
    fontSize: '18px',
  },
  ActionButtons: {
    margin: 0,
    justifyContent: 'space-evenly',
  },
}));

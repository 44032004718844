import React from 'react';
import { useMurexinPimContextApi, PerfectSystemMaterialGroupResponseModel } from '../../../../api';
import { createStyles, makeStyles, Grid, Theme } from '@material-ui/core';
import { useAsync } from 'react-async-hook';
import { CircleLoader } from '../../../../utils/CircleLoader';
import { useParams } from 'react-router-dom';
import { IRouterParams } from '../../../../routes';
import PIMButton from '../../../../shared/Components/PIMButton';
import { useTranslation } from 'react-i18next';

interface IProps {
  isAuthorizedToWrite?: boolean;
  perfectSystemMaterialGroup: PerfectSystemMaterialGroupResponseModel;
  onClickMaterialGroupLink: (divisionId: number, productGroupId: number, materialGroupId: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    Container: {
      flexDirection: 'column',
      flexWrap: 'nowrap',
      alignItems: 'flex-start',
      height: '100%',
      padding: '20px 40px 0px 40px',
    },
    HeaderRow: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      marginBottom: '16px',
    },
    Title: {
      flex: '1',
      fontSize: '19px',
      fontWeight: 'normal',
      fontStyle: 'normal',
      alignSelf: 'center',
      marginRight: '16px',
      wordBreak: 'break-all',
    },
    OpenLinkButton: {
      padding: '16px 24px',
      justifySelf: 'flex-end',
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    OpenLinkButtonText: {
      fontSize: '18px',
      fontWeight: 'normal',
      fontStyle: 'normal',
      marginRight: '16px',
    },
    DescriptionRow: {},
    DescriptionTitle: {
      fontSize: '12px',
      fontWeight: 'normal',
      fontStyle: 'normal',
      color: 'rgba(0, 0, 0, 0.6)',
      marginRight: '16px',
    },
    Description: {
      fontSize: '16px',
      fontWeight: 'normal',
      fontStyle: 'normal',
      color: '#000000',
      lineHeight: '20px',
      wordBreak: 'break-word',
    },
  })
);

export function PerfectSystemSidebar(props: IProps): JSX.Element {
  const classes = useStyles();
  const api = useMurexinPimContextApi();
  const { country } = useParams<IRouterParams>();
  const { t } = useTranslation();

  const { perfectSystemMaterialGroup, onClickMaterialGroupLink } = props;

  const materialGroupDescriptionResult = useAsync(async () => {
    const response = await api.materialGroupService.materialGroupServiceDescriptionDetail(
      country,
      Number(perfectSystemMaterialGroup.materialGroupId),
      {}
    );
    const descriptionResponseModel = response.data ?? {};
    return descriptionResponseModel;
  }, [perfectSystemMaterialGroup.materialGroupId, country]);

  return (
    <>
      {materialGroupDescriptionResult === undefined || materialGroupDescriptionResult.loading ? (
        <CircleLoader open={true} />
      ) : (
        <Grid item xs={12} className={classes.Container} spacing={2}>
          <div className={classes.HeaderRow}>
            <div className={classes.Title}>{perfectSystemMaterialGroup.materialGroup?.materialGroupDescription}</div>
            <PIMButton
              icon='link'
              color='primary'
              onClick={() =>
                onClickMaterialGroupLink(
                  perfectSystemMaterialGroup.materialGroup?.divisionId ?? 0,
                  perfectSystemMaterialGroup.materialGroup?.productGroupId ?? 0,
                  perfectSystemMaterialGroup.materialGroupId ?? 0
                )
              }>
              {t('Öffnen')}
            </PIMButton>
          </div>
          <div className={classes.DescriptionRow}>
            <div className={classes.DescriptionTitle}>{t('Produktbeschreibung lang')}</div>
            <div className={classes.Description}>{materialGroupDescriptionResult.result?.productDescriptionLong}</div>
          </div>
        </Grid>
      )}
    </>
  );
}

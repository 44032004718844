import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles } from '@material-ui/core';
import PIMButton, { PIMButtonColor } from '../PIMButton';
import { IconName } from '../../icons';
import { useTranslation } from 'react-i18next';

interface IProps {
  show: boolean;
  messageText: string;
  title?: string;
  confirmColor?: PIMButtonColor;
  confirmIcon?: IconName;
  onClose?: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void;
  confirmLabel?: string;
  onConfirm: () => void;
  cancelLabel?: string;
  onCancel?: () => void;
}

export function MessageDialog(props: IProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    show,
    title,
    messageText,
    confirmColor = 'primary',
    confirmIcon = 'save',
    onClose,
    confirmLabel = t('Bestätigen'),
    onConfirm,
    cancelLabel = t('Abbrechen'),
    onCancel,
  } = props;

  return (
    <Dialog fullScreen={false} open={show} onClose={onClose} className={classes.dialog}>
      <DialogTitle>
        <span className={classes.title}>{title}</span>
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.messageText}>
          <span>{messageText}</span>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {onCancel && (
          <PIMButton autoFocus onClick={onCancel} className={classes.cancelButton}>
            {cancelLabel}
          </PIMButton>
        )}
        <PIMButton autoFocus={!onCancel} onClick={onConfirm} color={confirmColor} icon={confirmIcon}>
          {confirmLabel}
        </PIMButton>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles(() => ({
  dialog: {
    padding: '32px',
    '& .MuiDialog-paperWidthSm': {
      padding: '32px',
    },
  },
  title: {
    color: '#000000',
    fontSize: '28px',
  },
  messageText: {
    color: '#000000',
    fontSize: '16px',
  },
  cancelButton: {
    marginRight: '16px',
  },
}));

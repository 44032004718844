import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { createStyles, Divider, Grid, IconButton, Link, makeStyles, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PIMFormikSidebar from '../../../shared/Components/PIMFormikSidebar';
import PIMFormikTextField from '../../../shared/Components/PIMFormikTextField';
import PIMFormikSelect from '../../../shared/Components/PIMFormikSelect';
import {
  AdminDocumentResponse,
  CountryResponse,
  DocumentCategory,
  DocumentDetailOptionResponse,
  DocumentLevel,
  GlobalPermission,
  useMurexinPimContextApi,
} from '../../../api';
import { NotificationContext } from '../../../contexts';
import { documentCategories, documentLevels } from '../../../shared/DocumentTranslations';
import { ApiImageUrlsBuilder } from '../../../utils/apiImageUrls';
import { LinkIcon } from '../../../shared/icons';
import PIMFormikToggle from '../../../shared/Components/PIMFormikToggle';
import PIMTextField from '../../../shared/Components/PIMTextField';
import Utility from '../../../shared/Utility';
import PIMButton from '../../../shared/Components/PIMButton';
import MessageDialog from '../../../shared/Components/MessageDialog';
import { useAsyncCallback } from 'react-async-hook';
import { AuthorizationContext } from '../../../auth';
import { FormikProps } from 'formik';

interface IProps {
  document: AdminDocumentResponse;
  countries: CountryResponse[];
  details: DocumentDetailOptionResponse[];
  reloadDocuments: () => void;
  onCloseSidebar: () => void;
  isExportEnabledForCategory: (category: DocumentCategory) => boolean;
}

type DropdownOption = {
  key: string;
  value: string;
};

export function DocumentSidebar(props: IProps): ReactElement {
  const classes = useStyles();
  const api = useMurexinPimContextApi();
  const notification = useContext(NotificationContext);
  const { t } = useTranslation();
  const { countries, document, details, reloadDocuments, isExportEnabledForCategory, onCloseSidebar } = props;
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [documentLevel, setDocumentLevel] = useState(document.documentLevel);
  const [documentCategory, setDocumentCategory] = useState<DocumentCategory | undefined>(undefined);
  const { hasGlobalPermission } = useContext(AuthorizationContext);
  const isAuthorizedToWriteGlobalPM = hasGlobalPermission(GlobalPermission.AdministrationWrite);
  const isAuthorizedToWriteAdmin = hasGlobalPermission(GlobalPermission.AdminProductAndMaterialGroupWrite);

  const documentDetailOptions: DropdownOption[] = details
    .filter((d) => d.documentLevel === documentLevel)
    .map((detail) => ({
      key: detail.detailName,
      value: detail.detailDescription,
    }));

  useEffect(() => {
    setDocumentCategory(document.documentCategory);
    setDocumentLevel(document.documentLevel);
  }, [document]);

  const countryTypeOptions = [...countries]?.map((country) => ({
    key: country.code,
    value: country.name ?? '',
  }));

  const documentLevelOptions: DropdownOption[] = [...documentLevels]?.map((level) => ({
    key: level.id,
    value: level.name,
  }));

  const documentCategoryOptions: DropdownOption[] = [...documentCategories]?.map((category) => ({
    key: category.id,
    value: category.name,
  }));

  const onChangeDocumentCategory = (
    newDocumentCategory: DocumentCategory,
    formik: FormikProps<AdminDocumentResponse>
  ) => {
    if (documentCategory !== newDocumentCategory) {
      formik.setFieldValue('documentCategory', newDocumentCategory);
    }

    setDocumentCategory(newDocumentCategory);
  };

  const onChangeDocumentLevel = (newDocumentLevel: DocumentLevel, formik: FormikProps<AdminDocumentResponse>) => {
    if (documentLevel !== newDocumentLevel) {
      formik.setFieldValue('detailName', document.documentLevel === newDocumentLevel ? document.detailName : null);
      formik.setFieldValue('documentLevel', newDocumentLevel);
    }
    setDocumentLevel(newDocumentLevel);
  };

  const handleOnSubmit = async (values: AdminDocumentResponse) => {
    const detailNameRequiredValidationError = values.detailName ? null : t('Detailfeld erforderlich');

    if (detailNameRequiredValidationError != null) {
      notification.error(detailNameRequiredValidationError);
      return;
    }

    try {
      await api.admin.adminDocumentAdminUpdate(String(document.id), {
        ...values,
        detailId: details.find((d) => d.detailName === values.detailName)?.detailId,
        isExported: values.documentCategory === DocumentCategory.ExternalLayout ? false : values.isExported,
      });
      notification.success(t('Speichern erfolgreich'));
      onCloseSidebar();
      reloadDocuments();
    } catch (error) {
      notification.error(t('Speichern fehlgeschlagen'));
    }
  };

  const onDeleteDocument = useAsyncCallback(async () => {
    setShowDeleteDialog(false);
    try {
      await api.admin.adminDocumentAdminDelete(document.id);
      notification.success(t('Dokument wurde gelöscht'));
      onCloseSidebar();
      reloadDocuments();
    } catch (error) {
      notification.error(t('Der Löschvorgang ist fehlgeschlagen'));
    }
  });

  return (
    <PIMFormikSidebar
      title={t('Daten bearbeiten')}
      cancelButtonText={t('Abbrechen')}
      formikInitialValues={document}
      handleOnSubmit={handleOnSubmit}
      handleOnCancel={onCloseSidebar}
      render={(formik) => (
        <>
          <Grid container alignItems='center'>
            <PIMFormikTextField
              name='fileName'
              id='fileName'
              label={t('Dateiname')}
              className={classes.FileNameField}
              fullWidth
              type='text'
              disabled
            />
            <IconButton
              className={classes.LinkButton}
              component={Link}
              target='_blank'
              rel='noopener noreferrer'
              href={ApiImageUrlsBuilder.builAdminDocumentSidebarIconUrlByDocumentId(document.id)}>
              <LinkIcon />
            </IconButton>
          </Grid>
          <PIMFormikSelect
            disabled={!isAuthorizedToWriteGlobalPM}
            name='documentCategory'
            label={t('Typ')}
            hideRemoveSelection
            options={documentCategoryOptions}
            onChange={(event) => {
              onChangeDocumentCategory(event.target.value as DocumentCategory, formik);
            }}
            formControlProps={{ fullWidth: true }}
          />
          <PIMFormikSelect
            disabled={!isAuthorizedToWriteGlobalPM}
            name='countryCode'
            label={t('Land')}
            hideRemoveSelection
            options={countryTypeOptions}
            formControlProps={{ fullWidth: true }}
          />
          <PIMFormikSelect
            disabled={!isAuthorizedToWriteGlobalPM}
            name='documentLevel'
            label={t('Ebene')}
            hideRemoveSelection
            options={documentLevelOptions}
            onChange={(event) => {
              onChangeDocumentLevel(event.target.value as DocumentLevel, formik);
            }}
            formControlProps={{ fullWidth: true }}
          />
          <PIMFormikSelect
            disabled={!isAuthorizedToWriteGlobalPM}
            name='detailName'
            label={t('Detail')}
            hideRemoveSelection
            options={documentDetailOptions}
            formControlProps={{ fullWidth: true }}
          />
          <PIMTextField
            label={t('Uploaddatum')}
            className={classes.TextField}
            fullWidth
            disabled
            value={document?.createdAt ? Utility.formatDate(new Date(document.createdAt)) : undefined}
          />
          {documentCategory && isExportEnabledForCategory(documentCategory) && (
            <PIMFormikToggle name={'isExported'} label={t('Exportieren zur Website')} />
          )}
          <Divider variant='fullWidth' className={classes.Divider} />
          {isAuthorizedToWriteAdmin && (
            <PIMButton
              color='warning'
              icon='trash'
              disabled={formik.isSubmitting}
              onClick={() => setShowDeleteDialog(true)}>
              {t('Dokument löschen')}
            </PIMButton>
          )}
          <MessageDialog
            show={showDeleteDialog}
            messageText={t(`FrageDokumentLöschen`, { filename: document.fileName })}
            title={t('Dokument löschen')}
            confirmColor='warning'
            confirmIcon='trash'
            confirmLabel={t('Löschen')}
            onClose={() => setShowDeleteDialog(false)}
            onCancel={() => setShowDeleteDialog(false)}
            onConfirm={onDeleteDocument.execute}
          />
        </>
      )}
    />
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    Divider: {
      margin: theme.spacing(2, 0, 4),
    },
    FileNameField: {
      flex: 1,
      padding: theme.spacing(0, 2, 0, 0),
    },
    TextField: {
      '&.MuiTextField-root': {
        marginTop: '16px',
        marginBottom: '8px',
      },
    },
    LinkButton: { '&.Mui-disabled': { opacity: 0.5 } },
  })
);

import { Breadcrumbs, Button, createStyles, DialogActions, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { routes } from '../../routes/routes';
import { Menu } from '@mui/material';
import { BreadCrumbContext } from '../BreadCrumbs/BreadCrumbContext';
import { NotificationContext } from '../../contexts/NotificationContext';
import { QuickLinkRequest, useMurexinPimContextApi } from '../../api';
import DoneIcon from '@material-ui/icons/Done';
import { SaveIcon } from '../../features/Article/ArticleDetailed/DetailedButton/SaveButton';
import { Form, Formik } from 'formik';
import { FormikTextField } from '@dccs/react-formik-mui';
import { useTranslation } from 'react-i18next';
import { IRouterParams } from '../../routes';
import { useAsync } from 'react-async-hook';
import { AddLinkIcon } from '../../shared/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginLeft: theme.spacing(2),
      marginTop: '24px',
      marginBottom: '24px',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
      '& .MuiDialogActions-root': {
        paddingTop: '8px',
      },
    },
    navigator: {
      fontSize: '16px',
      color: 'black',
    },
    font: {
      fontSize: 'inerhit',
      color: 'black',
    },
    MuiBreadcrumbsOl: {
      display: 'flex',
      alignItems: 'center',
      color: 'black',
      fontSize: 'medium',
    },
    alignBtn: {
      float: 'right',
      margin: '10px',
    },
    create: {
      backgroundColor: '#F9D548',
      color: 'black',
      borderRadius: '4px',
      padding: '19px 24px',
      width: '172px',
    },
    QuickLinktitle: {
      fontSize: '16px',
      lineHeight: '18px',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'normal',
      color: '#000000',
    },
    item: {
      marginTop: '10px',
      padding: '0 10px 10px 10px',
    },
    displayFlex: {
      display: 'flex',
    },
    padding: {
      padding: '10px 16px',
    },
    t: {
      paddingTop: '8px',
      padding: '0px',
      '& .MuiDialogActions-root': {
        paddingTop: '8px',
        padding: '0px',
      },
    },
  })
);

interface IProps {
  site: string | null;
}

export function CountryAdminBreadCrumbs(props: IProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const breadcrumb = React.useContext(BreadCrumbContext);
  const notification = React.useContext(NotificationContext);
  const api = useMurexinPimContextApi();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { country, divisionId, productId } = useParams<IRouterParams>();
  const { site } = props;

  const divisionName = useAsync(async () => {
    if (!divisionId) return '';
    const response = await api.divisionService.divisionServiceByIdDetail(Number(divisionId), country);
    return response.data.divisionName ?? '';
  }, [divisionId, country]);

  const productGroupDescription = useAsync(async () => {
    if (!productId) return '';
    const response = await api.productService.productServiceGetByIdDetail(country, Number(productId));
    return response.data.productGroupDescription ?? '';
  }, [divisionId, country]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function trimString() {
    const locationTest = window.location.origin;
    return window.location.href.slice(locationTest.length + 1);
  }

  function addQuickLink(value: string) {
    const trimmedString = trimString();
    const quicklink: QuickLinkRequest = {
      path: trimmedString,
      title: value,
      breadCrumbs: country
        .concat(divisionId === null || divisionId === undefined ? '' : ' » '.concat(divisionName.result ?? divisionId))
        .concat(site === null || site === undefined ? '' : ' » '.concat(site)),
    };
    api.quickLink
      .quickLinkCreate(quicklink)
      .then(() => {
        notification.success(t('Quicklink hinzugefügt'));
        breadcrumb.reload();
      })
      .catch(() => notification.error(t('Quicklink Speichern fehlgeschlagen')));
  }

  return (
    <div className={classes.root}>
      <div className={classes.displayFlex}>
        <Breadcrumbs separator='»' className={classes.MuiBreadcrumbsOl} aria-label='breadcrumb'>
          <NavLink style={{ textDecoration: 'none', color: 'black' }} color='textPrimary' to={routes.division(country)}>
            {country}
          </NavLink>
          {divisionId && (
            <NavLink
              style={{ textDecoration: 'none', color: 'black' }}
              color='textPrimary'
              to={routes.product(country, divisionId)}>
              {divisionName.result}
            </NavLink>
          )}
          {productId && (
            <NavLink
              style={{ textDecoration: 'none', color: 'black' }}
              color='textPrimary'
              to={routes.material(country, divisionId, productId)}>
              {productGroupDescription.result}
            </NavLink>
          )}
          {site && (
            <span style={{ textDecoration: 'none', color: 'black' }} color='textPrimary'>
              {props.site}
            </span>
          )}
        </Breadcrumbs>
        {breadcrumb.quickLinks?.find((d) => d.path === trimString()) ? (
          <Button startIcon={<DoneIcon />} />
        ) : (
          <>
            <Button disabled={divisionName.loading} startIcon={<AddLinkIcon />} onClick={handleClick} />
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              keepMounted
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  width: '300px',
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    left: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              // transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}>
              <Formik
                initialValues={{ quickLinkName: '' }}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  addQuickLink(values.quickLinkName);
                }}>
                {() => (
                  <Form>
                    <div className={classes.padding}>
                      <span className={classes.QuickLinktitle}>{t('Quicklink hinzufügen')}</span>
                      <FormikTextField
                        variant='filled'
                        fullWidth
                        label={t('Quicklink Name')}
                        inputProps={{ maxLength: 35 }}
                        name={'quickLinkName'}
                      />
                      <DialogActions className={classes.t}>
                        <Button className={classes.create} endIcon={<SaveIcon />} type='submit'>
                          {t('Erstellen')}
                        </Button>
                      </DialogActions>
                    </div>
                  </Form>
                )}
              </Formik>
            </Menu>
          </>
        )}
      </div>
    </div>
  );
}

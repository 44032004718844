import React, { useState } from 'react';
import { createStyles, makeStyles, Grid } from '@material-ui/core';
import ListView from '../../../shared/Components/ListView';
import { GridColumns, GridValueFormatterParams } from '@mui/x-data-grid';
import { useAsync } from 'react-async-hook';
import { ProductGroupResponse, useMurexinPimContextApi } from '../../../api';
import PIMTextField from '../../../shared/Components/PIMTextField';
import { useTranslation } from 'react-i18next';
import { IRouterParams } from '../../../routes';
import { useParams } from 'react-router-dom';
import { CircleLoader } from '../../../utils/CircleLoader';
import { CountryAdminBreadCrumbs } from '../../../layouts/CountryAdminBreadCrumbs/CountryAdminBreadCrumbs';
import { ProductGroupSidebar } from './ProductGroupSidebar';
import { SidebarMode } from '../../../shared/Enum';

export function ProductGroupDescription(): JSX.Element {
  const classes = useStyles();
  const api = useMurexinPimContextApi();
  const [selectedProductGroupCountry, setSelectedProductGroupCountry] = useState<undefined | ProductGroupResponse>();
  const [searchText, setSearchText] = useState<string>('');
  const { t } = useTranslation();
  const { country, divisionId } = useParams<IRouterParams>();

  let productGroupCountriesList: ProductGroupResponse[] = [];

  const countryName = useAsync(async () => {
    const response = await api.countryService.countryServiceList();
    return response.data.find((c) => c.code === country)?.name;
  }, [country]);

  const productGroupCountries = useAsync(async () => {
    const response = await api.productService.productServiceByDivisionIdDetail(country, Number(divisionId));
    return response.data ?? [];
  }, [country]);

  if (productGroupCountries.result && productGroupCountries.result.length > 0) {
    productGroupCountriesList = productGroupCountries.result;
  }

  const division = useAsync(async () => {
    const response = await api.divisionService.divisionServiceByIdDetail(Number(divisionId), country);
    return response.data ?? {};
  }, [divisionId, country]);

  const columnDefinition: GridColumns = [
    { field: 'id', headerName: '', hide: true },
    {
      field: 'productGroupName',
      headerName: t('Produktgruppencode'),
      width: 200,
      valueFormatter: (params: GridValueFormatterParams) => {
        const countryCode = (params.row as ProductGroupResponse).countryCode ?? '';
        const divisionName = division?.result?.divisionName ?? '';
        return `${countryCode}-${divisionName}${params.value as string}`;
      },
    },
    { field: 'productGroupDescription', headerName: t('Produktgruppenbezeichnung Original'), width: 400 },
    {
      field: 'productGroupCountryDescription',
      headerName: `${t('Produktgruppenbezeichnung')} ${countryName.result ?? country}`,
      width: 400,
    },
  ];

  if (searchText) {
    productGroupCountriesList = productGroupCountriesList.filter((productGroup: ProductGroupResponse) => {
      return (
        productGroup.productGroupName?.toLowerCase().includes(searchText.toLowerCase()) ||
        productGroup.productGroupCountryDescription?.toLowerCase().includes(searchText.toLowerCase()) ||
        productGroup.productGroupDescription?.toLowerCase().includes(searchText.toLowerCase())
      );
    });
  }

  return (
    <>
      <CircleLoader open={productGroupCountries.loading} />
      <Grid container className={classes.Container}>
        <CountryAdminBreadCrumbs site={t('Produktgruppen')} />
        <Grid container item xs={12} className={classes.DivisionCountryPage}>
          <Grid item xs={8} className={classes.DivisionCountryGrid}>
            <div className={classes.Title}>{t('Produktgruppen bearbeiten')}</div>
            <Grid item xs={12}>
              <PIMTextField
                label={t('Element suchen')}
                className={classes.SearchTextField}
                fullWidth
                onChange={(event) => setSearchText(event.target.value ?? '')}
              />
            </Grid>
            <Grid item xs={12}>
              <ListView
                columns={columnDefinition}
                data={productGroupCountriesList}
                onChangeSelection={(item) => {
                  setSelectedProductGroupCountry(item);
                }}
                selectedItem={selectedProductGroupCountry}
              />
            </Grid>
          </Grid>
          {selectedProductGroupCountry && (
            <Grid item xs={4} className={classes.Sidebar}>
              <ProductGroupSidebar
                mode={SidebarMode.Update}
                countryName={countryName.result ?? country}
                divisionName={division?.result?.divisionName ?? ''}
                productGroup={selectedProductGroupCountry}
                handleOnSave={async () => {
                  await productGroupCountries.execute();
                  setSelectedProductGroupCountry(undefined);
                }}
                handleOnCancel={() => {
                  if (selectedProductGroupCountry) setSelectedProductGroupCountry(undefined);
                }}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    DivisionCountryPage: {
      padding: '24px 0px 0px 8px',
    },
    DivisionCountryGrid: {
      paddingRight: '24px',
      minHeight: '100%',
    },
    SearchTextField: {
      marginBottom: '24px',
    },
    TitleRow: {
      fontSize: '36px',
      padding: '0 0 24px 0',
    },
    Sidebar: {
      marginTop: '-100px',
      minHeight: '100%',
    },
    Container: {
      height: '100%',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      margin: '0px 0px 0px 0px',
    },
    Title: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '36px',
      marginBottom: '24px',
      lineHeight: '45px',
    },
  })
);

import React, { ReactElement, useContext, useState } from 'react';
import { Grid } from '@material-ui/core';
import { PIMTab, PIMTabPanel, PIMTabs } from '../../../shared/Components/PIMTabs';
import { InfoMail } from './InfoMail/InfoMail';
import { ForeignCountries } from './ForeignCountries/ForeignCountries';
import { useTranslation } from 'react-i18next';
import { AuthorizationContext } from '../../../auth';
import { IRouterParams } from '../../../routes';
import { useParams } from 'react-router-dom';
import { CountryPermission } from '../../../api';

type SidebarItem = {
  label: string;
  index: number;
};

export function ProductPortfolio(): ReactElement {
  const { t } = useTranslation();
  const { country } = useParams<IRouterParams>();
  const { hasCountryPermission } = useContext(AuthorizationContext);
  const isAuthorizedToWrite = hasCountryPermission(country, CountryPermission.AdministrationWrite);
  const showInfoMail = isAuthorizedToWrite;
  const [selectTabIndex, setSelectTabIndex] = useState(0);
  const INFO_MAIL_TAB_INDEX = 0;
  const COUNTRIES_TAB_INDEX = showInfoMail ? 1 : 0;

  const sidebarItems: SidebarItem[] = showInfoMail
    ? [
        { label: t('Info Mail'), index: INFO_MAIL_TAB_INDEX },
        { label: t('Fremdländer'), index: COUNTRIES_TAB_INDEX },
      ]
    : [{ label: t('Fremdländer'), index: COUNTRIES_TAB_INDEX }];

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number): void => {
    setSelectTabIndex(newValue);
  };

  return (
    <Grid container>
      <Grid item xs={2}>
        <PIMTabs orientation='vertical' value={selectTabIndex} onChange={handleChangeTab}>
          {sidebarItems.map((item) => {
            return <PIMTab key={item.index} label={item.label} />;
          })}
        </PIMTabs>
      </Grid>
      <Grid item xs={10}>
        {isAuthorizedToWrite && (
          <PIMTabPanel value={selectTabIndex} index={INFO_MAIL_TAB_INDEX}>
            <InfoMail />
          </PIMTabPanel>
        )}
        <PIMTabPanel value={selectTabIndex} index={COUNTRIES_TAB_INDEX}>
          <ForeignCountries />
        </PIMTabPanel>
      </Grid>
    </Grid>
  );
}

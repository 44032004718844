import * as React from 'react';
import { useAsync } from 'react-async-hook';
import { CountryPermission, GlobalPermission, useMurexinPimContextApi } from '../api';
import { CircleLoader } from '../utils/CircleLoader';

// Values are defined in back end and provided here as a convenience. See class
// Role in Murexin.PIM.Application.Shared.Authorization
export type RoleName =
  | 'Administrator'
  | 'GlobalProductManager'
  | 'LocalProductManager'
  | 'Reader'
  | 'AuthenticatedUser';

export interface IAuthorization {
  roleName: RoleName;
  hasGlobalPermission: (requiredPermission: GlobalPermission) => boolean;
  hasCountryPermission: (countryCode: string, requiredPermission: CountryPermission) => boolean;
}

const defaultContext: IAuthorization = {
  roleName: 'AuthenticatedUser',
  hasGlobalPermission: () => false,
  hasCountryPermission: () => false,
};
export const AuthorizationContext = React.createContext<IAuthorization>(defaultContext);
AuthorizationContext.displayName = 'AuthorizationContext';

interface IProps {
  children: React.ReactElement;
}

export function AuthorizationProvider(props: IProps): JSX.Element {
  const context = useMurexinPimContextApi();
  const authorizationContextResponse = useAsync(async () => {
    try {
      const userPermissionsResponse = await context.user.userPermissionsList();
      const userPermissions = userPermissionsResponse.data;
      const updatedAuthorizationValue: IAuthorization = {
        roleName: userPermissions.roleName as RoleName,
        hasGlobalPermission: (requiredPermission: GlobalPermission) =>
          userPermissions.globalPermissions.some((permission) => permission === requiredPermission),
        hasCountryPermission: (countryCode: string, requiredPermission: CountryPermission) => {
          const countryPermissionsGroup = userPermissions.countryPermissionsGroups.find(
            (permissionGroup) => permissionGroup.countryCode === countryCode
          );
          const hasPermission =
            !!countryPermissionsGroup &&
            countryPermissionsGroup.permissions.some((permission) => permission === requiredPermission);
          return hasPermission;
        },
      };
      return updatedAuthorizationValue;
    } catch {
      // TODO: maybe redirect to error page, we could not read permissions, we assume server error
      return defaultContext;
    }
  }, []);

  // Wait with rendering of application until authorization context is loaded. This is required so that
  // routes can check against current user permissions if they render or redirect.
  if (authorizationContextResponse.loading) {
    return <CircleLoader open />;
  }

  return (
    <AuthorizationContext.Provider value={authorizationContextResponse.result as IAuthorization}>
      {props.children}
    </AuthorizationContext.Provider>
  );
}

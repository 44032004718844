import React, { useContext } from 'react';
import { createStyles, Grid, makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import {
  ProductHintMaterialGroupResponse,
  ProductHintMaterialGroupRequest,
  useMurexinPimContextApi,
  CountryPermission,
  GlobalPermission,
} from '../../../../api';
import { useAsync } from 'react-async-hook';
import { Form, Formik } from 'formik';
import { CircleLoader } from '../../../../utils/CircleLoader';
import { NotificationContext } from '../../../../contexts/NotificationContext';
import PIMButton from '../../../../shared/Components/PIMButton';
import { IRouterParams } from '../../../../routes';
import { ProductHintAttributes } from './ProductHintAttributes';
import { useTranslation } from 'react-i18next';
import { AuthorizationContext } from '../../../../auth/AuthorizationContext';
import { ProductManagementProps } from '../../ProductManagement/ProductManagement';
import useFormikSaveAndClose from '../../../../utils/customHooks/useFormikSaveAndClose';

interface IProps {
  countrySelectionComponent: React.ReactNode;
  translationCountryCode: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    Container: {
      marginRight: '24px',
    },
    root: {
      marginTop: '-79px',
    },
    TextField: {
      '&.MuiTextField-root': {
        marginTop: '8px',
        marginBottom: '8px',
      },
    },
    ActionButtonRow: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'flex-end',
      paddingBottom: '23px',
      '& .makeStyles-alignRight2-168': {
        marginRight: '0px',
      },
    },
    ActionButtonGrid: {
      display: 'flex',
      alignContent: 'flex-end',
      justifyContent: 'flex-end',
      marginBottom: '32px',
      marginTop: '-80px',
    },
    topButtons: {
      marginLeft: '24px',
    },
    select: {
      '& .MuiFormControl-root': {
        width: 'calc(100% - 48px)',
      },
    },
    linkButton: {
      marginTop: '20px',
    },
  })
);

export function ProductHint(props: IProps & ProductManagementProps): React.ReactElement {
  const { country, materialId } = useParams<IRouterParams>();
  const materialGroupId = Number(materialId);
  const classes = useStyles();
  const api = useMurexinPimContextApi();
  const notification = React.useContext(NotificationContext);
  const { translationCountryCode, countrySelectionComponent, saveOnCloseRef, onDirtyChanged } = props;
  const { hasCountryPermission, hasGlobalPermission } = useContext(AuthorizationContext);
  const isAuthorizedToWrite = hasCountryPermission(country, CountryPermission.Write);
  const isAuthorizedToWriteGlobal = hasGlobalPermission(GlobalPermission.AdministrationWrite);
  const showTranslationPanel = !!translationCountryCode && isAuthorizedToWrite;
  const { t } = useTranslation();
  const formikRef = useFormikSaveAndClose<ProductHintMaterialGroupResponse>(saveOnCloseRef);

  const productHintResponses = useAsync(async () => {
    const response = await api.admin.adminProductHintList();
    return response.data ?? [];
  }, []);

  const productHintMaterialGroupResponse = useAsync(async () => {
    const response = await api.materialGroupService.materialGroupServiceProductHintMaterialGroupDetail(
      country,
      materialGroupId
    );
    return response.data ?? {};
  }, [country, materialGroupId]);

  const productHintMaterialGroupResponseTranslation = useAsync(async () => {
    if (!!translationCountryCode) {
      const technicalDetailResponse = await api.materialGroupService.materialGroupServiceProductHintMaterialGroupDetail(
        translationCountryCode,
        materialGroupId
      );

      return technicalDetailResponse.data ?? {};
    }

    return {};
  }, [translationCountryCode, materialGroupId]);

  async function updateData(values: ProductHintMaterialGroupResponse) {
    const request: ProductHintMaterialGroupRequest = {
      id: values.id,
      standardTxt: values.standardTxt,
    };
    try {
      await api.materialGroupService.materialGroupServiceProductHintMaterialGroupCreate(
        country,
        materialGroupId,
        request
      );
      void productHintMaterialGroupResponse.execute();
      props.cleanUpForm();
      notification.success(t('Speichern erfolgreich'));
    } catch (error) {
      notification.error(t('Speichern fehlgeschlagen'));
      throw error;
    }
  }

  const productHintOptions =
    productHintResponses?.result?.map((p) => ({
      key: p.id,
      value: p.title,
    })) ?? [];

  if (productHintResponses.loading) {
    return <CircleLoader open={true} />;
  } else {
    return (
      <>
        <CircleLoader
          open={productHintMaterialGroupResponse.loading || productHintMaterialGroupResponseTranslation.loading}
        />
        <Formik
          enableReinitialize={!productHintMaterialGroupResponse.loading}
          innerRef={formikRef}
          initialValues={productHintMaterialGroupResponse.result as ProductHintMaterialGroupResponse}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            void updateData(values);
          }}>
          {({ isSubmitting, dirty, handleReset, handleSubmit, setFieldValue, handleChange }) => (
            <Form
              className={(classes.root, classes.Container)}
              onChange={(e) => {
                onDirtyChanged();
                handleChange(e);
              }}>
              <Grid container item xs={showTranslationPanel ? 12 : 8}>
                <Grid className={classes.ActionButtonGrid} item xs={12}>
                  {isAuthorizedToWrite && (
                    <>
                      {countrySelectionComponent}
                      <PIMButton
                        className={classes.topButtons}
                        icon='close'
                        disabled={isSubmitting || !dirty}
                        onClick={() => {
                          notification.info(t('Daten zurückgesetzt'));
                          handleReset();
                        }}>
                        {t('Abbrechen')}
                      </PIMButton>
                      <PIMButton
                        color='primary'
                        icon='save'
                        className={classes.topButtons}
                        busy={isSubmitting}
                        disabled={!dirty}
                        onClick={() => handleSubmit()}>
                        {t('Speichern')}
                      </PIMButton>
                    </>
                  )}
                </Grid>
                <Grid container item spacing={2}>
                  <Grid item xs={showTranslationPanel ? 6 : 12}>
                    <ProductHintAttributes
                      onChange={(
                        e: React.ChangeEvent<{
                          name?: string | undefined;
                          value: unknown;
                        }>
                      ) => {
                        setFieldValue(
                          'standardTxt',
                          productHintResponses?.result?.find((p) => p.id === e.target.value)?.content
                        );
                        handleChange(e);
                      }}
                      productHintOptions={productHintOptions}
                      authorizedToWrite={isAuthorizedToWrite}
                      authorizedToWriteGlobal={isAuthorizedToWriteGlobal}
                    />
                  </Grid>
                  {showTranslationPanel && !productHintMaterialGroupResponseTranslation.loading && (
                    <Grid item xs={6}>
                      <Formik
                        initialValues={
                          productHintMaterialGroupResponseTranslation.result as ProductHintMaterialGroupResponse
                        }
                        onSubmit={() => {
                          return;
                        }}>
                        <ProductHintAttributes
                          readOnly
                          authorizedToWrite={isAuthorizedToWrite}
                          authorizedToWriteGlobal={isAuthorizedToWriteGlobal}
                        />
                      </Formik>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

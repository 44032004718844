import React from 'react';
import { useAsync } from 'react-async-hook';
import { useMurexinPimContextApi, UserInformation } from '../api/MurexinPimApi';
import { CircularProgress } from '@material-ui/core';

const unauthenticatedUser = { isAuthenticated: false } as UserInformation;
export const LoginContext = React.createContext<LoginProps>({ user: unauthenticatedUser });

interface IProps {
  children: React.ReactNode;
}

interface LoginProps {
  user: UserInformation;
}

export function Login(props: IProps): JSX.Element {
  const context = useMurexinPimContextApi();
  const authenticate = useAsync(async () => {
    try {
      const response = await context.user.userList();
      return response.data ?? unauthenticatedUser;
    } catch {
      return unauthenticatedUser;
    }
  }, []);

  if (authenticate.loading) {
    return <CircularProgress />;
  } else if (authenticate.result !== undefined && authenticate.result.isAuthenticated) {
    return <LoginContext.Provider value={{ user: authenticate.result }}>{props.children}</LoginContext.Provider>;
  } else {
    return <>{(window.location.href = '/login')}</>;
  }
}

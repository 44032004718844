import React from 'react';
import { CountryPermission } from '../api';
import { IRouterParams } from './IRouterParams';
import { AuthorizedRoute, SimplifiedRouteProps } from '.';

export function CountryAdministrationRoute(props: SimplifiedRouteProps): JSX.Element {
  return (
    <AuthorizedRoute
      {...props}
      isAuthorized={({ hasCountryPermission }, routeProps) => {
        const routerParams = routeProps.match.params as IRouterParams;
        const countryCode = routerParams.country ?? '';
        return hasCountryPermission(countryCode, CountryPermission.AdministrationRead);
      }}
    />
  );
}

export const ApiImageUrlsBuilder = {
  /**
   * Builds a base url which can be extended with an id.
   *
   * Note: This URL ends with a "/"!
   *
   * @param country
   * @returns
   */
  buildMaterialGroupBaseImageUrl: (country: string): string => {
    return `/api/materialGroup/imageStore/${country}/image/`;
  },

  /**
   * Builds a full image loading url.
   *
   * @param country
   * @param imageId
   * @returns
   */
  buildMaterialGroupImageUrl: (country: string, imageId: number): string => {
    return `${ApiImageUrlsBuilder.buildMaterialGroupBaseImageUrl(country)}${imageId}`;
  },

  /**
   *
   * @param country
   * @param fileName
   * @returns
   */
  buildPictogramImageUrl: (country: string, fileName: string): string => {
    return `/api/Pictogram/${country}/image?fileName=${fileName}/`;
  },

  /**
   *
   * @param country
   * @param fileName
   * @returns
   */
  buildSealOfApprovalAndNormImageUrl: (country: string, fileName: string): string => {
    return `/api/SealOfApprovalAndNorm/${country}/image?fileName=${fileName}/`;
  },

  /**
   *
   * @param country
   * @param documentId
   * @returns
   */
  buildEditDocumentEditSidebarIconUrlByCountryAndDocumentId: (country: string, documentId: number): string => {
    return `/api/Document/${country}/${documentId}/file`;
  },

  /**
   *
   * @param documentId
   * @returns
   */
  builAdminDocumentSidebarIconUrlByDocumentId: (documentId: number): string => {
    return `/api/admin/DocumentAdmin/${documentId}/file`;
  },

  /**
   *
   * @param materialGroupId
   * @param fileName
   * @returns
   */
  // TODO add filename to request
  buildContainerLayoutExportFileUrl: (materialGroupId: number, fileName: string): string => {
    return `/api/admin/ContainerLayoutExport/export/${materialGroupId}/file?fileName=${fileName}`;
  },
};

import React, { useContext } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { ArticleGrid } from './ArticleGrid';
import { ArticleResponse, CountryPermission } from '../../../api';
import { useParams } from 'react-router-dom';
import { IRouterParams } from '../../../routes';
import { AuthorizationContext } from '../../../auth';

interface IProps {
  updateValue: (view: number, articleId?: number) => void;
  articleList: ArticleResponse[] | null | undefined;
  readOnly?: boolean;
  erpImport?: boolean;
}

function ArticleList(props: IProps): JSX.Element {
  const { updateValue, articleList, readOnly, erpImport } = props;
  const { country } = useParams<IRouterParams>();
  const { hasCountryPermission } = useContext(AuthorizationContext);
  const isAuthorizedToWrite = hasCountryPermission(country, CountryPermission.Write);

  const classes = useStyles();
  return (
    <div>
      <Grid
        className={`${classes.articleBlock} ${
          readOnly || !isAuthorizedToWrite || erpImport ? '' : classes.buttonOffset
        }`}
        container>
        <Grid item xs={12}>
          <ArticleGrid updateValue={updateValue} articleList={articleList} readOnly={readOnly} erpImport={erpImport} />
        </Grid>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles(() => ({
  articleBlock: {
    width: '95%',
  },
  buttonOffset: {
    marginTop: '-56px',
  },
}));

export default ArticleList;

export const routes = {
  product: (country?: string, divisionId?: string): string =>
    `/${country || ':country'}/division/${divisionId || ':divisionId'}/productgroup`,
  division: (country?: string): string => `/${country || ':country'}/division`,
  material: (country?: string, divisionId?: string, productId?: string): string =>
    `/${country || ':country'}/division/${divisionId || ':divisionId'}/productgroup/${
      productId || ':productId'
    }/materialgroup`,
  materialGroup: {
    list: (country?: string, divisionId?: string, productId?: string): string =>
      `/${country || ':country'}/division/${divisionId || ':divisionId'}/productgroup/${
        productId || ':productId'
      }/materialgroup`,
    selected: (country?: string, divisionId?: string, productGroupId?: string, materialGroupId?: number): string =>
      `/${country || ':country'}/division/${divisionId || ':divisionId'}/productgroup/${
        productGroupId || ':productId'
      }/materialgroup/${materialGroupId || ':materialGroupId'}`,
  },
  articles: {
    selected: (
      country?: string,
      divisionId?: string,
      productId?: string,
      materialId?: string,
      articleId?: string
    ): string =>
      `/${country || ':country'}/division/${divisionId || ':divisionId'}/productgroup/${
        productId || ':productId'
      }/materialgroup/${materialId || ':materialId'}/article/${articleId || ':articleId'}`,
  },
  breadCrumbsRoute: (): string =>
    `/:country?/(division)?/:divisionId?/(productgroup)?/:productId?/(materialgroup)?/:materialId?/(article)?/:articleId?`,
  countryBase: '/:country',
  divisionCountryBase: '/:country/division',
  adminRoute: '/administration',
  pictogramRoute: (country?: string): string => `/${country || ':country?'}/pictograms`,
  divisionDescriptionRoute: (country?: string): string => `/${country || ':country?'}/division/description`,
  productGroupDescriptionRoute: (country?: string, divisionId?: string): string =>
    `/${country || ':country?'}/division/${divisionId || ':divisionId'}/productgroup/description`,
  materialGroupDescriptionRoute: (country?: string, divisionId?: string, productId?: string): string =>
    `/${country || ':country?'}/division/${divisionId || ':divisionId'}/productgroup/${
      productId || ':productId'
    }/materialgroup/description`,
  home: '/',
  displayImage: (country?: string): string => `/${country || ':country?'}/displayImage/`,
  sealOfApproval: (country?: string): string => `/${country || ':country?'}/sealOfApproval/`,
  ProductHint: (site?: string): string => `/administration/config/${site || 'ProductHint'}`,
  GeneralHint: (site?: string): string => `/administration/config/${site || 'GeneralHint'}`,
  Substrate: (site?: string): string => `/administration/config/${site || 'Substrate'}`,
  MailingList: (): string => `/administration/MailingList`,
  AdminConfigurationLocalization: (): string => `/administration/config/Localization`,
  DocumentsManagement: (): string => '/administration/Documents',
  DynamicExport: (): string => '/administration/DynamicExport',
};

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface UserInformation {
  isAuthenticated?: boolean;
  userId?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  name?: string | null;
  email?: string | null;
}

export interface ObjectObjectTestHttpResponse {
  /** @format int32 */
  statusCode?: number;
  status?: string | null;
  error?: string | null;
  bodyError?: string | null;
  headers?: Record<string, string>;
  body?: string | null;
}

export interface ObjectObjectTestTCPResult {
  error?: string | null;
  succeeded?: boolean;
  ipAddress?: string | null;
}

export interface ObjectObjectTestPingResult {
  error?: string | null;
  succeeded?: boolean;
  status?: string | null;
  ipAddress?: string | null;

  /** @format int64 */
  roundtripTime?: number;

  /** @format int32 */
  bufferLength?: number | null;

  /** @format int32 */
  timeToLive?: number | null;
  dontFragment?: boolean | null;
  replyAddress?: string | null;
}

export enum PlatformID {
  Win32S = 'Win32S',
  Win32Windows = 'Win32Windows',
  Win32NT = 'Win32NT',
  WinCE = 'WinCE',
  Unix = 'Unix',
  Xbox = 'Xbox',
  MacOSX = 'MacOSX',
  Other = 'Other',
}

export interface Version {
  /** @format int32 */
  major?: number;

  /** @format int32 */
  minor?: number;

  /** @format int32 */
  build?: number;

  /** @format int32 */
  revision?: number;

  /** @format int32 */
  majorRevision?: number;

  /** @format int32 */
  minorRevision?: number;
}

export interface OperatingSystem {
  platform?: PlatformID;
  servicePack?: string | null;
  version?: Version;
  versionString?: string | null;
}

export interface ClaimInfo {
  type?: string | null;
  value?: string | null;
}

export interface AssemblyInformation {
  name?: string | null;
  location?: string | null;
  version?: string | null;
}

export interface DiagnosticInformation {
  environmentName?: string | null;
  serverTimeUtc?: string | null;
  serverTime?: string | null;
  startTimeUtc?: string | null;
  startTime?: string | null;
  runningTime?: string | null;
  uniqueInstanceId?: string | null;
  binaryCreationTime?: string | null;
  binaryCreationTimeUtc?: string | null;
  assemblyVersion?: string | null;
  applicationControllerVersion?: string | null;
  dotNetVersion?: string | null;
  hostName?: string | null;
  requestHeaders?: Record<string, string>;
  osVersion?: OperatingSystem;

  /** @format int32 */
  processorCount?: number;

  /** @format int32 */
  processId?: number;
  threadUICulture?: string | null;
  threadCulture?: string | null;
  claims?: ClaimInfo[] | null;
  installedRuntimes?: string[] | null;
  installedSdks?: string[] | null;
  loadedAssemblies?: AssemblyInformation[] | null;
}

export interface ObjectObjectApplicationAndUserInformation {
  environmentName?: string | null;
  version?: string | null;
  displayVersion?: string | null;
  binaryCreationTimeUtc?: string | null;
  binaryCreationTime?: string | null;
  isAuthenticated?: boolean;
  user?: UserInformation;
  setting?: any;
  options?: any;
}

export interface ArrangementLayoutResponse {
  /** @format int32 */
  id?: number;

  /** @format int32 */
  articleId?: number;
  countryCode?: string | null;
  fileName?: string | null;
  displayName?: string | null;
}

export interface ProductTypeResponse {
  code?: string | null;
  name?: string | null;
}

export interface ArticleResponse {
  /** @format int32 */
  id?: number;
  articleNumber?: string | null;
  articleCountryNumber?: string | null;
  articleDescription?: string | null;
  articleCharacter?: string | null;
  arrangementLayouts?: ArrangementLayoutResponse[] | null;
  ean?: string | null;

  /** @format double */
  fillingVolume?: number | null;
  unitOfMeasure?: string | null;
  containerType?: string | null;

  /** @format double */
  palletNumberOfContainers?: number | null;

  /** @format double */
  overCartonNumberOfContainers?: number | null;

  /** @format double */
  net?: number;

  /** @format double */
  gross?: number | null;
  mainSupplier?: string | null;

  /** @format double */
  grossPrice?: number | null;
  supplierName?: string | null;

  /** @format double */
  priceReference?: number | null;
  priceUnit?: string | null;
  createdBy?: string | null;

  /** @format date-time */
  createdAt?: string;
  productType?: ProductTypeResponse;
  isGlobal?: boolean;
  isActive?: boolean;
  erpImport?: boolean;
  countryCode?: string | null;
}

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;

  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
}

export interface ProductTypeDTO {
  code?: string | null;
  name?: string | null;
}

export interface CategoryResponse {
  /** @format int32 */
  id?: number;
  name?: string | null;
}

export interface CommentRequest {
  realName?: string | null;
  commentText?: string | null;

  /** @format int32 */
  materialGroupId?: number;
}

export interface CommentResponse {
  realName?: string | null;
  commentText?: string | null;

  /** @format date-time */
  dateTime?: string;

  /** @format int32 */
  materialGroupId?: number;

  /** @format int32 */
  id?: number;
}

export interface AdminMaterialGroupResponse {
  /** @format int32 */
  id?: number;
  materialGroupDescription?: string | null;
  materialGroupName?: string | null;

  /** @format int32 */
  productGroupId?: number;
}

export interface AdminProductGroupResponse {
  /** @format int32 */
  id?: number;

  /** @format int32 */
  divisionId?: number;
  productGroupDescription?: string | null;
  productGroupName?: string | null;
}

export interface CountryResponse {
  code?: string | null;
  name?: string | null;

  /** @format int32 */
  order?: number;
  defaultCultureCode?: string | null;
}

export interface DivisionCountryResponseModel {
  /** @format int32 */
  id: number;

  /** @format int32 */
  divisionId?: number;
  divisionName?: string | null;
  originalCountryCode?: string | null;
  countryCode?: string | null;
  originalDivisionDescription?: string | null;
  divisionCountryDescription?: string | null;
}

export interface DivisionResponseModel {
  /** @format int32 */
  id?: number;
  divisionName?: string | null;
  divisionDescription?: string | null;
  divisionColor?: string | null;
  divisionCountries?: DivisionCountryResponseModel[] | null;
}

export interface BreadCrumbInfoResponseModel {
  name?: string | null;

  /** @format int32 */
  id?: number;
}

export enum DocumentCategory {
  TechnicalDataSheet = 'TechnicalDataSheet',
  ExternalLayout = 'ExternalLayout',
  SafetyDataSheet = 'SafetyDataSheet',
  DeclarationOfPerformance = 'DeclarationOfPerformance',
  InfoSheet = 'InfoSheet',
  TestCertificate = 'TestCertificate',
  PrintingData = 'PrintingData',
  Documents = 'Documents',
  Pictures = 'Pictures',
}

export enum DocumentLevel {
  Division = 'Division',
  ProductGroup = 'ProductGroup',
  MaterialGroup = 'MaterialGroup',
}

export interface DocumentResponse {
  /** @format int32 */
  id: number;
  documentLevel: DocumentLevel;

  /** @format int32 */
  detailId?: number;
  detailName?: string | null;
  detailDescription?: string | null;
  countryCode?: string | null;
  fileName: string;

  /** @format int64 */
  fileSize: number;
  category: DocumentCategory;
  createdBy?: string | null;

  /** @format date-time */
  createdAt?: string;
  updatedBy: string;

  /** @format date-time */
  updatedAt: string;
  isExported: boolean;
}

export interface DocumentsGroup {
  category: DocumentCategory;
  documents: DocumentResponse[];
}

export interface UpdateDocumentRequest {
  category: DocumentCategory;
  isExported: boolean;
}

export interface AdminDocumentResponse {
  /** @format int32 */
  id: number;
  fileName: string;
  countryCode: string;
  countryName?: string | null;

  /** @format int32 */
  detailId?: number;
  detailName: string;
  detailDescription: string;
  documentCategory: DocumentCategory;

  /** @format date-time */
  createdAt?: string;
  documentLevel: DocumentLevel;
  isExported?: boolean;
}

export interface DocumentDetailOptionResponse {
  /** @format int32 */
  detailId: number;
  detailName: string;
  detailDescription: string;
  documentLevel: DocumentLevel;
}

export interface AdminUpdateDocumentRequest {
  /** @format int32 */
  id: number;
  countryCode: string;

  /** @format int32 */
  detailId?: number;
  detailName: string;
  documentCategory: DocumentCategory;
  documentLevel: DocumentLevel;
  isExported?: boolean;
}

export enum MaterialGroupAttributeType {
  MaterialGroupName = 'MaterialGroupName',
  MaterialGroupDescription = 'MaterialGroupDescription',
  ProductGroupName = 'ProductGroupName',
  ProductGroupDescription = 'ProductGroupDescription',
  DivisionName = 'DivisionName',
  DivisionDescription = 'DivisionDescription',
  TechnicalDetailChemicalBase = 'TechnicalDetail_ChemicalBase',
  TechnicalDetailDense = 'TechnicalDetail_Dense',
  TechnicalDetailBulkDensity = 'TechnicalDetail_BulkDensity',
  TechnicalDetailFreshMortarDensity = 'TechnicalDetail_FreshMortarDensity',
  TechnicalDetailLargestGrain = 'TechnicalDetail_LargestGrain',
  TechnicalDetailSolidStateContent = 'TechnicalDetail_SolidStateContent',
  TechnicalDetailViscosity = 'TechnicalDetail_Viscosity',
  TechnicalDetailUValue = 'TechnicalDetail_uValue',
  TechnicalDetailSDValue = 'TechnicalDetail_SDValue',
  TechnicalDetailPhValue = 'TechnicalDetail_PhValue',
  TechnicalDetailAppearance = 'TechnicalDetail_Appearance',
  TechnicalDetailColor = 'TechnicalDetail_Color',
  TechnicalDetailGloss = 'TechnicalDetail_Gloss',
  TechnicalDetailConsumption = 'TechnicalDetail_Consumption',
  TechnicalDetailProductiveness = 'TechnicalDetail_Productiveness',
  TechnicalDetailWaterDemand = 'TechnicalDetail_WaterDemand',
  TechnicalDetailMixingRatio = 'TechnicalDetail_MixingRatio',
  TechnicalDetailDilution = 'TechnicalDetail_Dilution',
  TechnicalDetailLayerThickness = 'TechnicalDetail_LayerThickness',
  TechnicalDetailJointWidth = 'TechnicalDetail_JointWidth',
  TechnicalDetailDryFilmThickness = 'TechnicalDetail_DryFilmThickness',
  TechnicalDetailVentilationTime = 'TechnicalDetail_VentilationTime',
  TechnicalDetailInsertionTime = 'TechnicalDetail_InsertionTime',
  TechnicalDetailReadyForPublishing = 'TechnicalDetail_ReadyForPublishing',
  TechnicalDetailRainproof = 'TechnicalDetail_Rainproof',
  TechnicalDetailFinalStrength = 'TechnicalDetail_FinalStrength',
  TechnicalDetailGrindability = 'TechnicalDetail_Grindability',
  TechnicalDetailMechanicallyResilient = 'TechnicalDetail_MechanicallyResilient',
  TechnicalDetailChemicallyResistant = 'TechnicalDetail_ChemicallyResistant',
  TechnicalDetailRestingTime = 'TechnicalDetail_RestingTime',
  TechnicalDetailPotLife = 'TechnicalDetail_PotLife',
  TechnicalDetailProcessingTime = 'TechnicalDetail_ProcessingTime',
  TechnicalDetailOpenTime = 'TechnicalDetail_OpenTime',
  TechnicalDetailSkinImageTime = 'TechnicalDetail_SkinImageTime',
  TechnicalDetailCorrectability = 'TechnicalDetail_Correctability',
  TechnicalDetailDryingTimeAfterFirstApplication = 'TechnicalDetail_DryingTimeAfterFirstApplication',
  TechnicalDetailDryingTime = 'TechnicalDetail_DryingTime',
  TechnicalDetailCuring = 'TechnicalDetail_Curing',
  TechnicalDetailRevisability = 'TechnicalDetail_Revisability',
  TechnicalDetailAccessibilityForNextWorkStep = 'TechnicalDetail_AccessibilityForNextWorkStep',
  TechnicalDetailAccessibility = 'TechnicalDetail_Accessibility',
  TechnicalDetailGrouting = 'TechnicalDetail_Grouting',
  TechnicalDetailFullyLoadable = 'TechnicalDetail_FullyLoadable',
  TechnicalDetailDryingSpeed = 'TechnicalDetail_DryingSpeed',
  TechnicalDetailCertificateTestReportEstablishClass = 'TechnicalDetail_CertificateTestReportEstablishClass',
  TechnicalDetailFlexuralStrength = 'TechnicalDetail_FlexuralStrength',
  TechnicalDetailCompressiveStrength = 'TechnicalDetail_CompressiveStrength',
  TechnicalDetailTensileStrength = 'TechnicalDetail_TensileStrength',
  TechnicalDetailShearStrength = 'TechnicalDetail_ShearStrength',
  TechnicalDetailPeelStrength = 'TechnicalDetail_PeelStrength',
  TechnicalDetailPullOutStrength = 'TechnicalDetail_PullOutStrength',
  TechnicalDetailShoreAHardness = 'TechnicalDetail_ShoreAHardness',
  TechnicalDetailShoreDHardness = 'TechnicalDetail_ShoreDHardness',
  TechnicalDetailShrinkage = 'TechnicalDetail_Shrinkage',
  TechnicalDetailSwellingBehaviour = 'TechnicalDetail_SwellingBehaviour',
  TechnicalDetailSlump = 'TechnicalDetail_Slump',
  TechnicalDetailCrackBridging = 'TechnicalDetail_CrackBridging',
  TechnicalDetailEModule = 'TechnicalDetail_EModule',
  TechnicalDetailElongationAtBreak = 'TechnicalDetail_ElongationAtBreak',
  TechnicalDetailPermanentlyExpandable = 'TechnicalDetail_PermanentlyExpandable',
  TechnicalDetailLinearDeformation = 'TechnicalDetail_LinearDeformation',
  TechnicalDetailPermissibleDeformation = 'TechnicalDetail_PermissibleDeformation',
  TechnicalDetailWaterPressureResistance = 'TechnicalDetail_WaterPressureResistance',
  TechnicalDetailWaterVapourDiffusionRate = 'TechnicalDetail_WaterVapourDiffusionRate',
  TechnicalDetailWaterVapourPermeability = 'TechnicalDetail_WaterVapourPermeability',
  TechnicalDetailPermeabilityForWater = 'TechnicalDetail_PermeabilityForWater',
  TechnicalDetailCapillaryWaterIntake = 'TechnicalDetail_CapillaryWaterIntake',
  TechnicalDetailUVResistance = 'TechnicalDetail_UVResistance',
  TechnicalDetailCO2Permeability = 'TechnicalDetail_CO2Permeability',
  TechnicalDetailHighCarbonizationResistance = 'TechnicalDetail_HighCarbonizationResistance',
  TechnicalDetailFireClass = 'TechnicalDetail_FireClass',
  TechnicalDetailThermoShockResistance = 'TechnicalDetail_ThermoShockResistance',
  TechnicalDetailTemperatureResistance = 'TechnicalDetail_TemperatureResistance',
  TechnicalDetailHeatResistant = 'TechnicalDetail_HeatResistant',
  TechnicalDetailFlamePoint = 'TechnicalDetail_FlamePoint',
  TechnicalDetailGlassTransitionTemperature = 'TechnicalDetail_GlassTransitionTemperature',
  TechnicalDetailCoefficientOfThermalExpansion = 'TechnicalDetail_CoefficientOfThermalExpansion',
  TechnicalDetailPenetrationDepth = 'TechnicalDetail_PenetrationDepth',
  TechnicalDetailWetAbrasionClass = 'TechnicalDetail_WetAbrasionClass',
  TechnicalDetailCoveringPower = 'TechnicalDetail_CoveringPower',
  TechnicalDetailGlossLevel = 'TechnicalDetail_GlossLevel',
  TechnicalDetailWhiteness = 'TechnicalDetail_Whiteness',
  TechnicalDetailChemicalResistance = 'TechnicalDetail_ChemicalResistance',
  TechnicalDetailLeakageResistance = 'TechnicalDetail_LeakageResistance',
  TechnicalDetailElectricalResistivity = 'TechnicalDetail_ElectricalResistivity',
  TechnicalDetailObjectAndMaterialWorkingTemperature = 'TechnicalDetail_ObjectAndMaterialWorkingTemperature',
  TechnicalDetailSubstrateTemperature = 'TechnicalDetail_SubstrateTemperature',
  TechnicalDetailWorkingTemperature = 'TechnicalDetail_WorkingTemperature',
  TechnicalDetailPermissibleRelativeAirHumidity = 'TechnicalDetail_PermissibleRelativeAirHumidity',
  TechnicalDetailMaterialThickness = 'TechnicalDetail_MaterialThickness',
  TechnicalDetailDimension = 'TechnicalDetail_Dimension',
  TechnicalDetailWeight = 'TechnicalDetail_Weight',
  TechnicalDetailBasisWeight = 'TechnicalDetail_BasisWeight',
  TechnicalDetailExtraAttribute1 = 'TechnicalDetail_ExtraAttribute1',
  TechnicalDetailExtraTextAttribute1 = 'TechnicalDetail_ExtraTextAttribute1',
  TechnicalDetailExtraAttribute2 = 'TechnicalDetail_ExtraAttribute2',
  TechnicalDetailExtraTextAttribute2 = 'TechnicalDetail_ExtraTextAttribute2',
  TechnicalDetailExtraAttribute3 = 'TechnicalDetail_ExtraAttribute3',
  TechnicalDetailExtraTextAttribute3 = 'TechnicalDetail_ExtraTextAttribute3',
  TechnicalDetailExtraAttribute4 = 'TechnicalDetail_ExtraAttribute4',
  TechnicalDetailExtraTextAttribute4 = 'TechnicalDetail_ExtraTextAttribute4',
  TechnicalDetailExtraAttribute5 = 'TechnicalDetail_ExtraAttribute5',
  TechnicalDetailExtraTextAttribute5 = 'TechnicalDetail_ExtraTextAttribute5',
  DescriptionProductTitle = 'Description_ProductTitle',
  DescriptionProductTitleLong = 'Description_ProductTitleLong',
  DescriptionAdvantage1 = 'Description_Advantage1',
  DescriptionAdvantage2 = 'Description_Advantage2',
  DescriptionAdvantage3 = 'Description_Advantage3',
  DescriptionAdvantage4 = 'Description_Advantage4',
  DescriptionAdvantage5 = 'Description_Advantage5',
  DescriptionProductDescriptionShort = 'Description_ProductDescriptionShort',
  StorageDays = 'Storage_Days',
  SealOfApprovalAndNormNorm = 'SealOfApprovalAndNorm_Norm',
  SubstrateRequirementStandard = 'Substrate_RequirementStandard',
  SubstratePreprocessing = 'Substrate_Preprocessing',
  PrepareToolText = 'Prepare_ToolText',
  PrepareMixing = 'Prepare_Mixing',
  PrepareProcess = 'Prepare_Process',
  PrepareProcessingshort = 'Prepare_Processingshort',
  ProductHintStandardText = 'ProductHint_StandardText',
  WorkSafetyInformation = 'WorkSafety_Information',
  PerfectSystemMaterialGroups = 'PerfectSystem_MaterialGroups',
  PerfectSystemSystemProductDescription = 'PerfectSystem_SystemProductDescription',
}

export interface MaterialGroupAttributeResponse {
  labelKey: string;
  type: MaterialGroupAttributeType;
}

export interface MaterialGroupTreeResponse {
  /** @format int32 */
  id: number;
  name: string;
  description?: string | null;
}

export interface ProductGroupTreeResponse {
  /** @format int32 */
  id: number;
  name: string;
  description?: string | null;
  materialGroups: MaterialGroupTreeResponse[];
}

export interface DivisionTreeResponse {
  /** @format int32 */
  id: number;
  name: string;
  description?: string | null;
  productGroups: ProductGroupTreeResponse[];
}

export interface MaterialGroupExportRequest {
  countryCode: string;
  materialGroupIds: number[];
  attributes: MaterialGroupAttributeType[];
  productTypeCodes?: string[] | null;
}

export interface ArticleExportRequest {
  countryCode: string;
  materialGroupIds: number[];
  productTypeCodes?: string[] | null;
}

export enum MemberTypes {
  Constructor = 'Constructor',
  Event = 'Event',
  Field = 'Field',
  Method = 'Method',
  Property = 'Property',
  TypeInfo = 'TypeInfo',
  Custom = 'Custom',
  NestedType = 'NestedType',
  All = 'All',
}

export enum GenericParameterAttributes {
  None = 'None',
  Covariant = 'Covariant',
  Contravariant = 'Contravariant',
  VarianceMask = 'VarianceMask',
  ReferenceTypeConstraint = 'ReferenceTypeConstraint',
  NotNullableValueTypeConstraint = 'NotNullableValueTypeConstraint',
  DefaultConstructorConstraint = 'DefaultConstructorConstraint',
  SpecialConstraintMask = 'SpecialConstraintMask',
}

export enum TypeAttributes {
  NotPublic = 'NotPublic',
  Public = 'Public',
  NestedPublic = 'NestedPublic',
  NestedPrivate = 'NestedPrivate',
  NestedFamily = 'NestedFamily',
  NestedAssembly = 'NestedAssembly',
  NestedFamANDAssem = 'NestedFamANDAssem',
  NestedFamORAssem = 'NestedFamORAssem',
  SequentialLayout = 'SequentialLayout',
  ExplicitLayout = 'ExplicitLayout',
  LayoutMask = 'LayoutMask',
  Interface = 'Interface',
  Abstract = 'Abstract',
  Sealed = 'Sealed',
  SpecialName = 'SpecialName',
  RTSpecialName = 'RTSpecialName',
  Import = 'Import',
  Serializable = 'Serializable',
  WindowsRuntime = 'WindowsRuntime',
  UnicodeClass = 'UnicodeClass',
  AutoClass = 'AutoClass',
  CustomFormatClass = 'CustomFormatClass',
  HasSecurity = 'HasSecurity',
  ReservedMask = 'ReservedMask',
  BeforeFieldInit = 'BeforeFieldInit',
  CustomFormatMask = 'CustomFormatMask',
}

export enum LayoutKind {
  Sequential = 'Sequential',
  Explicit = 'Explicit',
  Auto = 'Auto',
}

export interface StructLayoutAttribute {
  typeId?: any;
  value?: LayoutKind;
}

export type IntPtr = object;

export interface RuntimeTypeHandle {
  value?: IntPtr;
}

export enum EventAttributes {
  None = 'None',
  SpecialName = 'SpecialName',
  RTSpecialName = 'RTSpecialName',
}

export enum MethodAttributes {
  ReuseSlot = 'ReuseSlot',
  Private = 'Private',
  FamANDAssem = 'FamANDAssem',
  Assembly = 'Assembly',
  Family = 'Family',
  FamORAssem = 'FamORAssem',
  Public = 'Public',
  MemberAccessMask = 'MemberAccessMask',
  UnmanagedExport = 'UnmanagedExport',
  Static = 'Static',
  Final = 'Final',
  Virtual = 'Virtual',
  HideBySig = 'HideBySig',
  NewSlot = 'NewSlot',
  CheckAccessOnOverride = 'CheckAccessOnOverride',
  Abstract = 'Abstract',
  SpecialName = 'SpecialName',
  RTSpecialName = 'RTSpecialName',
  PinvokeImpl = 'PinvokeImpl',
  HasSecurity = 'HasSecurity',
  RequireSecObject = 'RequireSecObject',
  ReservedMask = 'ReservedMask',
}

export enum MethodImplAttributes {
  IL = 'IL',
  Native = 'Native',
  OPTIL = 'OPTIL',
  Runtime = 'Runtime',
  Unmanaged = 'Unmanaged',
  NoInlining = 'NoInlining',
  ForwardRef = 'ForwardRef',
  Synchronized = 'Synchronized',
  NoOptimization = 'NoOptimization',
  PreserveSig = 'PreserveSig',
  AggressiveInlining = 'AggressiveInlining',
  AggressiveOptimization = 'AggressiveOptimization',
  InternalCall = 'InternalCall',
  MaxMethodImplVal = 'MaxMethodImplVal',
}

export enum CallingConventions {
  Standard = 'Standard',
  VarArgs = 'VarArgs',
  Any = 'Any',
  HasThis = 'HasThis',
  ExplicitThis = 'ExplicitThis',
}

export interface RuntimeMethodHandle {
  value?: IntPtr;
}

export enum ParameterAttributes {
  None = 'None',
  In = 'In',
  Out = 'Out',
  Lcid = 'Lcid',
  Retval = 'Retval',
  Optional = 'Optional',
  HasDefault = 'HasDefault',
  HasFieldMarshal = 'HasFieldMarshal',
  Reserved3 = 'Reserved3',
  Reserved4 = 'Reserved4',
  ReservedMask = 'ReservedMask',
}

export interface MemberInfo {
  memberType?: MemberTypes;
  declaringType?: Type;
  reflectedType?: Type;
  name?: string | null;
  module?: Module;
  customAttributes?: CustomAttributeData[] | null;
  isCollectible?: boolean;

  /** @format int32 */
  metadataToken?: number;
}

export interface ParameterInfo {
  attributes?: ParameterAttributes;
  member?: MemberInfo;
  name?: string | null;
  parameterType?: Type;

  /** @format int32 */
  position?: number;
  isIn?: boolean;
  isLcid?: boolean;
  isOptional?: boolean;
  isOut?: boolean;
  isRetval?: boolean;
  defaultValue?: any;
  rawDefaultValue?: any;
  hasDefaultValue?: boolean;
  customAttributes?: CustomAttributeData[] | null;

  /** @format int32 */
  metadataToken?: number;
}

export type ICustomAttributeProvider = object;

export interface MethodInfo {
  name?: string | null;
  declaringType?: Type;
  reflectedType?: Type;
  module?: Module;
  customAttributes?: CustomAttributeData[] | null;
  isCollectible?: boolean;

  /** @format int32 */
  metadataToken?: number;
  attributes?: MethodAttributes;
  methodImplementationFlags?: MethodImplAttributes;
  callingConvention?: CallingConventions;
  isAbstract?: boolean;
  isConstructor?: boolean;
  isFinal?: boolean;
  isHideBySig?: boolean;
  isSpecialName?: boolean;
  isStatic?: boolean;
  isVirtual?: boolean;
  isAssembly?: boolean;
  isFamily?: boolean;
  isFamilyAndAssembly?: boolean;
  isFamilyOrAssembly?: boolean;
  isPrivate?: boolean;
  isPublic?: boolean;
  isConstructedGenericMethod?: boolean;
  isGenericMethod?: boolean;
  isGenericMethodDefinition?: boolean;
  containsGenericParameters?: boolean;
  methodHandle?: RuntimeMethodHandle;
  isSecurityCritical?: boolean;
  isSecuritySafeCritical?: boolean;
  isSecurityTransparent?: boolean;
  memberType?: MemberTypes;
  returnParameter?: ParameterInfo;
  returnType?: Type;
  returnTypeCustomAttributes?: ICustomAttributeProvider;
}

export interface EventInfo {
  name?: string | null;
  declaringType?: Type;
  reflectedType?: Type;
  module?: Module;
  customAttributes?: CustomAttributeData[] | null;
  isCollectible?: boolean;

  /** @format int32 */
  metadataToken?: number;
  memberType?: MemberTypes;
  attributes?: EventAttributes;
  isSpecialName?: boolean;
  addMethod?: MethodInfo;
  removeMethod?: MethodInfo;
  raiseMethod?: MethodInfo;
  isMulticast?: boolean;
  eventHandlerType?: Type;
}

export enum FieldAttributes {
  PrivateScope = 'PrivateScope',
  Private = 'Private',
  FamANDAssem = 'FamANDAssem',
  Assembly = 'Assembly',
  Family = 'Family',
  FamORAssem = 'FamORAssem',
  Public = 'Public',
  FieldAccessMask = 'FieldAccessMask',
  Static = 'Static',
  InitOnly = 'InitOnly',
  Literal = 'Literal',
  NotSerialized = 'NotSerialized',
  HasFieldRVA = 'HasFieldRVA',
  SpecialName = 'SpecialName',
  RTSpecialName = 'RTSpecialName',
  HasFieldMarshal = 'HasFieldMarshal',
  PinvokeImpl = 'PinvokeImpl',
  HasDefault = 'HasDefault',
  ReservedMask = 'ReservedMask',
}

export interface RuntimeFieldHandle {
  value?: IntPtr;
}

export interface FieldInfo {
  name?: string | null;
  declaringType?: Type;
  reflectedType?: Type;
  module?: Module;
  customAttributes?: CustomAttributeData[] | null;
  isCollectible?: boolean;

  /** @format int32 */
  metadataToken?: number;
  memberType?: MemberTypes;
  attributes?: FieldAttributes;
  fieldType?: Type;
  isInitOnly?: boolean;
  isLiteral?: boolean;
  isNotSerialized?: boolean;
  isPinvokeImpl?: boolean;
  isSpecialName?: boolean;
  isStatic?: boolean;
  isAssembly?: boolean;
  isFamily?: boolean;
  isFamilyAndAssembly?: boolean;
  isFamilyOrAssembly?: boolean;
  isPrivate?: boolean;
  isPublic?: boolean;
  isSecurityCritical?: boolean;
  isSecuritySafeCritical?: boolean;
  isSecurityTransparent?: boolean;
  fieldHandle?: RuntimeFieldHandle;
}

export enum PropertyAttributes {
  None = 'None',
  SpecialName = 'SpecialName',
  RTSpecialName = 'RTSpecialName',
  HasDefault = 'HasDefault',
  Reserved2 = 'Reserved2',
  Reserved3 = 'Reserved3',
  Reserved4 = 'Reserved4',
  ReservedMask = 'ReservedMask',
}

export interface PropertyInfo {
  name?: string | null;
  declaringType?: Type;
  reflectedType?: Type;
  module?: Module;
  customAttributes?: CustomAttributeData[] | null;
  isCollectible?: boolean;

  /** @format int32 */
  metadataToken?: number;
  memberType?: MemberTypes;
  propertyType?: Type;
  attributes?: PropertyAttributes;
  isSpecialName?: boolean;
  canRead?: boolean;
  canWrite?: boolean;
  getMethod?: MethodInfo;
  setMethod?: MethodInfo;
}

export interface TypeInfo {
  name?: string | null;
  customAttributes?: CustomAttributeData[] | null;
  isCollectible?: boolean;

  /** @format int32 */
  metadataToken?: number;
  isInterface?: boolean;
  memberType?: MemberTypes;
  namespace?: string | null;
  assemblyQualifiedName?: string | null;
  fullName?: string | null;
  assembly?: Assembly;
  module?: Module;
  isNested?: boolean;
  declaringType?: Type;
  declaringMethod?: MethodBase;
  reflectedType?: Type;
  underlyingSystemType?: Type;
  isTypeDefinition?: boolean;
  isArray?: boolean;
  isByRef?: boolean;
  isPointer?: boolean;
  isConstructedGenericType?: boolean;
  isGenericParameter?: boolean;
  isGenericTypeParameter?: boolean;
  isGenericMethodParameter?: boolean;
  isGenericType?: boolean;
  isGenericTypeDefinition?: boolean;
  isSZArray?: boolean;
  isVariableBoundArray?: boolean;
  isByRefLike?: boolean;
  hasElementType?: boolean;
  genericTypeArguments?: Type[] | null;

  /** @format int32 */
  genericParameterPosition?: number;
  genericParameterAttributes?: GenericParameterAttributes;
  attributes?: TypeAttributes;
  isAbstract?: boolean;
  isImport?: boolean;
  isSealed?: boolean;
  isSpecialName?: boolean;
  isClass?: boolean;
  isNestedAssembly?: boolean;
  isNestedFamANDAssem?: boolean;
  isNestedFamily?: boolean;
  isNestedFamORAssem?: boolean;
  isNestedPrivate?: boolean;
  isNestedPublic?: boolean;
  isNotPublic?: boolean;
  isPublic?: boolean;
  isAutoLayout?: boolean;
  isExplicitLayout?: boolean;
  isLayoutSequential?: boolean;
  isAnsiClass?: boolean;
  isAutoClass?: boolean;
  isUnicodeClass?: boolean;
  isCOMObject?: boolean;
  isContextful?: boolean;
  isEnum?: boolean;
  isMarshalByRef?: boolean;
  isPrimitive?: boolean;
  isValueType?: boolean;
  isSignatureType?: boolean;
  isSecurityCritical?: boolean;
  isSecuritySafeCritical?: boolean;
  isSecurityTransparent?: boolean;
  structLayoutAttribute?: StructLayoutAttribute;
  typeInitializer?: ConstructorInfo;
  typeHandle?: RuntimeTypeHandle;

  /** @format uuid */
  guid?: string;
  baseType?: Type;
  isSerializable?: boolean;
  containsGenericParameters?: boolean;
  isVisible?: boolean;
  genericTypeParameters?: Type[] | null;
  declaredConstructors?: ConstructorInfo[] | null;
  declaredEvents?: EventInfo[] | null;
  declaredFields?: FieldInfo[] | null;
  declaredMembers?: MemberInfo[] | null;
  declaredMethods?: MethodInfo[] | null;
  declaredNestedTypes?: TypeInfo[] | null;
  declaredProperties?: PropertyInfo[] | null;
  implementedInterfaces?: Type[] | null;
}

export enum SecurityRuleSet {
  None = 'None',
  Level1 = 'Level1',
  Level2 = 'Level2',
}

export interface Assembly {
  definedTypes?: TypeInfo[] | null;
  exportedTypes?: Type[] | null;
  codeBase?: string | null;
  entryPoint?: MethodInfo;
  fullName?: string | null;
  imageRuntimeVersion?: string | null;
  isDynamic?: boolean;
  location?: string | null;
  reflectionOnly?: boolean;
  isCollectible?: boolean;
  isFullyTrusted?: boolean;
  customAttributes?: CustomAttributeData[] | null;
  escapedCodeBase?: string | null;
  manifestModule?: Module;
  modules?: Module[] | null;
  globalAssemblyCache?: boolean;

  /** @format int64 */
  hostContext?: number;
  securityRuleSet?: SecurityRuleSet;
}

export interface ModuleHandle {
  /** @format int32 */
  mdStreamVersion?: number;
}

export interface Module {
  assembly?: Assembly;
  fullyQualifiedName?: string | null;
  name?: string | null;

  /** @format int32 */
  mdStreamVersion?: number;

  /** @format uuid */
  moduleVersionId?: string;
  scopeName?: string | null;
  moduleHandle?: ModuleHandle;
  customAttributes?: CustomAttributeData[] | null;

  /** @format int32 */
  metadataToken?: number;
}

export interface ConstructorInfo {
  name?: string | null;
  declaringType?: Type;
  reflectedType?: Type;
  module?: Module;
  customAttributes?: CustomAttributeData[] | null;
  isCollectible?: boolean;

  /** @format int32 */
  metadataToken?: number;
  attributes?: MethodAttributes;
  methodImplementationFlags?: MethodImplAttributes;
  callingConvention?: CallingConventions;
  isAbstract?: boolean;
  isConstructor?: boolean;
  isFinal?: boolean;
  isHideBySig?: boolean;
  isSpecialName?: boolean;
  isStatic?: boolean;
  isVirtual?: boolean;
  isAssembly?: boolean;
  isFamily?: boolean;
  isFamilyAndAssembly?: boolean;
  isFamilyOrAssembly?: boolean;
  isPrivate?: boolean;
  isPublic?: boolean;
  isConstructedGenericMethod?: boolean;
  isGenericMethod?: boolean;
  isGenericMethodDefinition?: boolean;
  containsGenericParameters?: boolean;
  methodHandle?: RuntimeMethodHandle;
  isSecurityCritical?: boolean;
  isSecuritySafeCritical?: boolean;
  isSecurityTransparent?: boolean;
  memberType?: MemberTypes;
}

export interface CustomAttributeTypedArgument {
  argumentType?: Type;
  value?: any;
}

export interface CustomAttributeNamedArgument {
  memberInfo?: MemberInfo;
  typedValue?: CustomAttributeTypedArgument;
  memberName?: string | null;
  isField?: boolean;
}

export interface CustomAttributeData {
  attributeType?: Type;
  constructor?: ConstructorInfo;
  constructorArguments?: CustomAttributeTypedArgument[] | null;
  namedArguments?: CustomAttributeNamedArgument[] | null;
}

export interface Type {
  name?: string | null;
  customAttributes?: CustomAttributeData[] | null;
  isCollectible?: boolean;

  /** @format int32 */
  metadataToken?: number;
  isInterface?: boolean;
  memberType?: MemberTypes;
  namespace?: string | null;
  assemblyQualifiedName?: string | null;
  fullName?: string | null;
  assembly?: Assembly;
  module?: Module;
  isNested?: boolean;
  declaringType?: Type;
  declaringMethod?: MethodBase;
  reflectedType?: Type;
  underlyingSystemType?: Type;
  isTypeDefinition?: boolean;
  isArray?: boolean;
  isByRef?: boolean;
  isPointer?: boolean;
  isConstructedGenericType?: boolean;
  isGenericParameter?: boolean;
  isGenericTypeParameter?: boolean;
  isGenericMethodParameter?: boolean;
  isGenericType?: boolean;
  isGenericTypeDefinition?: boolean;
  isSZArray?: boolean;
  isVariableBoundArray?: boolean;
  isByRefLike?: boolean;
  hasElementType?: boolean;
  genericTypeArguments?: Type[] | null;

  /** @format int32 */
  genericParameterPosition?: number;
  genericParameterAttributes?: GenericParameterAttributes;
  attributes?: TypeAttributes;
  isAbstract?: boolean;
  isImport?: boolean;
  isSealed?: boolean;
  isSpecialName?: boolean;
  isClass?: boolean;
  isNestedAssembly?: boolean;
  isNestedFamANDAssem?: boolean;
  isNestedFamily?: boolean;
  isNestedFamORAssem?: boolean;
  isNestedPrivate?: boolean;
  isNestedPublic?: boolean;
  isNotPublic?: boolean;
  isPublic?: boolean;
  isAutoLayout?: boolean;
  isExplicitLayout?: boolean;
  isLayoutSequential?: boolean;
  isAnsiClass?: boolean;
  isAutoClass?: boolean;
  isUnicodeClass?: boolean;
  isCOMObject?: boolean;
  isContextful?: boolean;
  isEnum?: boolean;
  isMarshalByRef?: boolean;
  isPrimitive?: boolean;
  isValueType?: boolean;
  isSignatureType?: boolean;
  isSecurityCritical?: boolean;
  isSecuritySafeCritical?: boolean;
  isSecurityTransparent?: boolean;
  structLayoutAttribute?: StructLayoutAttribute;
  typeInitializer?: ConstructorInfo;
  typeHandle?: RuntimeTypeHandle;

  /** @format uuid */
  guid?: string;
  baseType?: Type;
  isSerializable?: boolean;
  containsGenericParameters?: boolean;
  isVisible?: boolean;
}

export interface MethodBase {
  memberType?: MemberTypes;
  name?: string | null;
  declaringType?: Type;
  reflectedType?: Type;
  module?: Module;
  customAttributes?: CustomAttributeData[] | null;
  isCollectible?: boolean;

  /** @format int32 */
  metadataToken?: number;
  attributes?: MethodAttributes;
  methodImplementationFlags?: MethodImplAttributes;
  callingConvention?: CallingConventions;
  isAbstract?: boolean;
  isConstructor?: boolean;
  isFinal?: boolean;
  isHideBySig?: boolean;
  isSpecialName?: boolean;
  isStatic?: boolean;
  isVirtual?: boolean;
  isAssembly?: boolean;
  isFamily?: boolean;
  isFamilyAndAssembly?: boolean;
  isFamilyOrAssembly?: boolean;
  isPrivate?: boolean;
  isPublic?: boolean;
  isConstructedGenericMethod?: boolean;
  isGenericMethod?: boolean;
  isGenericMethodDefinition?: boolean;
  containsGenericParameters?: boolean;
  methodHandle?: RuntimeMethodHandle;
  isSecurityCritical?: boolean;
  isSecuritySafeCritical?: boolean;
  isSecurityTransparent?: boolean;
}

export interface Exception {
  message?: string | null;
  targetSite?: MethodBase;
  stackTrace?: string | null;
  data?: Record<string, any>;
  innerException?: Exception;
  helpLink?: string | null;
  source?: string | null;

  /** @format int32 */
  hResult?: number;
}

export interface AggregateException {
  targetSite?: MethodBase;
  stackTrace?: string | null;
  data?: Record<string, any>;
  innerException?: Exception;
  helpLink?: string | null;
  source?: string | null;

  /** @format int32 */
  hResult?: number;
  innerExceptions?: Exception[] | null;
  message?: string | null;
}

export enum TaskStatus {
  Created = 'Created',
  WaitingForActivation = 'WaitingForActivation',
  WaitingToRun = 'WaitingToRun',
  Running = 'Running',
  WaitingForChildrenToComplete = 'WaitingForChildrenToComplete',
  RanToCompletion = 'RanToCompletion',
  Canceled = 'Canceled',
  Faulted = 'Faulted',
}

export enum TaskCreationOptions {
  None = 'None',
  PreferFairness = 'PreferFairness',
  LongRunning = 'LongRunning',
  AttachedToParent = 'AttachedToParent',
  DenyChildAttach = 'DenyChildAttach',
  HideScheduler = 'HideScheduler',
  RunContinuationsAsynchronously = 'RunContinuationsAsynchronously',
}

export interface WebsiteFileDataResponse {
  Url?: string | null;

  /** @format date-time */
  LastModified?: string;
  Dateiname?: string | null;
}

export interface WebsiteArticleResponse {
  Artikelnummer?: string | null;
  Bezeichnung?: string | null;
  EAN?: string | null;
  GebindeArtNr?: string | null;
  Mengeneinheit?: string | null;

  /** @format double */
  PaletteAnzGebinde?: number | null;
  Produktart?: string | null;
  Deleted?: boolean;
  Laenderzuordnung?: string | null;
}

export interface WebsiteMaterialGroupResponse {
  SpartenBezeichnung?: string | null;
  ProduktgruppenBezeichnung?: string | null;

  /** @format int32 */
  Deleted?: number;
  Bezeichnung?: string | null;
  Code?: string | null;
  refernzPg?: string | null;
  Kurztext?: string | null;
  Vorteil1?: string | null;
  Vorteil2?: string | null;
  Vorteil3?: string | null;
  Vorteil4?: string | null;
  Vorteil5?: string | null;
  Neuprodukt?: boolean;
  AnzeigeBild?: WebsiteFileDataResponse;
  Verbrauch?: string | null;
  Farbe?: string | null;
  Systemprodukte?: string[] | null;
  SysProdBeschreibung?: string | null;
  Aktiv?: boolean;
  Artikel?: WebsiteArticleResponse[] | null;
  Piktogramme?: WebsiteFileDataResponse[] | null;
  Pruefzeichen?: WebsiteFileDataResponse[] | null;
  TechMerkblaetter?: WebsiteFileDataResponse[] | null;
  Sicherheitsdatenblaetter?: WebsiteFileDataResponse[] | null;
  Infoblaetter?: WebsiteFileDataResponse[] | null;
  Drucksorten?: WebsiteFileDataResponse[] | null;
  Leistungserklaerungen?: WebsiteFileDataResponse[] | null;
  Sparte_Code?: string | null;
  Sparte_Landerkennung?: string | null;

  /** @format int32 */
  Sparte_Reihenfolge?: number;
  Sparte_Spartenfarbe?: string | null;
  Sparte_Logotitel?: string | null;
  Produktgruppe_Code?: string | null;
  Sparte_Techmerkblaetter?: WebsiteFileDataResponse[] | null;
  Sparte_Sicherheitsdatenblaetter?: WebsiteFileDataResponse[] | null;
  Sparte_Infoblaetter?: WebsiteFileDataResponse[] | null;
  Sparte_Drucksorten?: WebsiteFileDataResponse[] | null;
  Sparte_Leistungserklaerungen?: WebsiteFileDataResponse[] | null;
}

export interface WebsiteGroupResponse {
  W?: WebsiteMaterialGroupResponse[] | null;
}

export interface WebsiteGroupResponseIEnumerableTask {
  /** @format int32 */
  id?: number;
  exception?: AggregateException;
  status?: TaskStatus;
  isCanceled?: boolean;
  isCompleted?: boolean;
  isCompletedSuccessfully?: boolean;
  creationOptions?: TaskCreationOptions;
  asyncState?: any;
  isFaulted?: boolean;
  result?: WebsiteGroupResponse[] | null;
}

export interface GeneralHintResponse {
  /** @format int32 */
  id?: number;
  countryCode?: string | null;
  countryName?: string | null;
  content?: string | null;
}

export interface GeneralHintRequest {
  /** @format int32 */
  id?: number;
  countryCode?: string | null;
  content?: string | null;
}

export interface ImageStoreResponse {
  /** @format int32 */
  id: number;

  /** @format int32 */
  materialGroupId?: number | null;
  defaultImage?: boolean;
  fileName?: string | null;

  /** @format int32 */
  fileSize?: number;
  materialGroupDescription?: string | null;

  /** @format uuid */
  azureStorageGuid?: string;
  updatedBy?: string | null;

  /** @format date-time */
  updatedAt?: string;
  createdBy?: string | null;

  /** @format date-time */
  createdAt?: string;
}

export interface ERPImportDataRequest {
  divisionName?: string | null;
  productGroupName?: string | null;
  productGroupDescription?: string | null;
  materialGroupName?: string | null;
  materialGroupDescription?: string | null;
  articleNumber?: string | null;
  articleDescription?: string | null;
  articleCharacter?: string | null;
  ean?: string | null;

  /** @format double */
  fillingVolume?: number;
  unitOfMeasure?: string | null;
  containerType?: string | null;

  /** @format double */
  numberOfPallets?: number;

  /** @format double */
  overCartonNumberOfContainer?: number;

  /** @format double */
  net?: number;

  /** @format double */
  gross?: number;
  mainSupplier?: string | null;
  supplierName?: string | null;
  productType?: string | null;

  /** @format double */
  grossPrice?: number;
  createdBy?: string | null;

  /** @format date-time */
  createdAt?: string;

  /** @format int32 */
  durability?: number;

  /** @format double */
  priceReferenceQuantity?: number;
  priceUnit?: string | null;
  divisionColor?: string | null;
}

export interface ERPImportRequest {
  erpImportData?: ERPImportDataRequest[] | null;
}

export interface ERPImportResponse {
  /** @format int32 */
  runId?: number;
  createdBy?: string | null;

  /** @format date-time */
  createdAt?: string;
  updatedBy?: string | null;

  /** @format date-time */
  updatedAt?: string;

  /** @format int32 */
  numberOfEntries?: number;
}

export interface CultureDefinition {
  cultureCode: string;
  isActive?: boolean;
}

export enum LocalizationSectionCode {
  STATIC = 'STATIC',
}

export interface LocalizationDefinition {
  cultureDefinitions: CultureDefinition[];
  translationSections: LocalizationSectionCode[];
}

export interface LocalizationTextModel {
  sectionCode: string;
  textCode: string;
  translations: Record<string, string>;
}

export interface TranslationDictionary {
  cultureCode: string;
  texts: Record<string, Record<string, string>>;
}

export interface MailResponse {
  /** @format int32 */
  id: number;
  countryCode?: string | null;
  eMail: string;
}

export interface MailingListResponse {
  countryCode: string;
  mailingList?: MailResponse[] | null;
}

export interface CreateMailRequest {
  countryCode?: string | null;
  eMail?: string | null;
}

export interface UpdateMailRequest {
  /** @format int32 */
  id?: number;
  countryCode?: string | null;
  eMail?: string | null;
}

export interface MailNotificationRequest {
  /** @format int32 */
  id?: number;

  /** @format int32 */
  materialGroupId?: number;
  subject?: string | null;
  body?: string | null;
  selectedMailingListCodes?: string[] | null;
}

export interface MaterialGroupCountryResponse {
  /** @format int32 */
  materialGroupId?: number;
  countryCodes?: string[] | null;
}

export interface MaterialGroupCountryRequest {
  /** @format int32 */
  materialGroupId?: number;
  countryCodes?: string[] | null;
}

export interface MaterialGroupResponse {
  createdBy?: string | null;

  /** @format date-time */
  createdAt?: string;
  updatedBy?: string | null;

  /** @format date-time */
  updatedAt?: string;
  isActive?: boolean;

  /** @format int32 */
  id?: number;
  materialGroupDescription?: string | null;
  materialGroupName?: string | null;
  materialGroupTitle?: string | null;

  /** @format int32 */
  divisionId?: number;

  /** @format int32 */
  productGroupId?: number;
  countryCode?: string | null;
  erpImport?: boolean;

  /** @format int32 */
  imageStoreMaterialGroupId?: number | null;
}

export interface SearchMaterialGroupResponse {
  createdBy?: string | null;

  /** @format date-time */
  createdAt?: string;
  updatedBy?: string | null;

  /** @format date-time */
  updatedAt?: string;
  isActive?: boolean;

  /** @format int32 */
  id?: number;
  materialGroupDescription?: string | null;
  materialGroupName?: string | null;
  materialGroupTitle?: string | null;

  /** @format int32 */
  productGroupId?: number;
  productName?: string | null;

  /** @format int32 */
  imageStoreMaterialGroupId?: number | null;
}

export interface MaterialGroupUpdateRequest {
  materialGroupName?: string | null;
  materialGroupDescription?: string | null;
  productTitle?: string | null;
}

export enum StorageType {
  Limited = 'Limited',
  Unlimited = 'Unlimited',
}

export interface StorageResponse {
  /** @format int32 */
  id?: number;
  storageType?: StorageType;

  /** @format int32 */
  days?: number;

  /** @format int32 */
  materialGroupId?: number;
}

export interface StorageRequest {
  storageType?: StorageType;

  /** @format int32 */
  days?: number;
}

export interface SubstrateMaterialGroupResponse {
  /** @format int32 */
  id?: number;
  requirementStandard?: string | null;
  preprocessing?: string | null;

  /** @format int32 */
  materialGroupId?: number;
  countryCode?: string | null;
}

export interface SubstrateMaterialGroupRequest {
  /** @format int32 */
  id?: number;
  requirementStandard?: string | null;
  preprocessing?: string | null;
}

export interface AdvantageResponse {
  /** @format int32 */
  advantageId?: number;
  advantageName?: string | null;

  /** @format int32 */
  descriptionId?: number;

  /** @format int32 */
  displayOrder?: number;
}

export interface DescriptionResponseModel {
  /** @format int32 */
  id?: number;
  newProduct?: boolean;
  advantage?: AdvantageResponse[] | null;
  productDescriptionLong?: string | null;
  productDescriptionShort?: string | null;

  /** @format int32 */
  materialGroupId?: number;
  productTitle?: string | null;
  countryCode?: string | null;
}

export interface AdvantageRequest {
  /** @format int32 */
  advantageId?: number;
  advantageName?: string | null;

  /** @format int32 */
  descriptionId?: number;

  /** @format int32 */
  displayOrder?: number;
}

export interface DescriptionRequestModel {
  /** @format int32 */
  id?: number;
  newProduct?: boolean;
  advantage?: AdvantageRequest[] | null;
  productDescriptionLong?: string | null;
  productDescriptionShort?: string | null;
  productTitle?: string | null;
}

export interface PerfectSystemMaterialGroupResponseModel {
  /** @format int32 */
  materialGroupId?: number;
  materialGroup?: MaterialGroupResponse;
}

export interface PerfectSystemResponseModel {
  /** @format int32 */
  id?: number;
  countryCode?: string | null;
  systemProductDescription?: string | null;

  /** @format int32 */
  materialGroupId?: number;
  perfectSystemMaterialGroups?: PerfectSystemMaterialGroupResponseModel[] | null;
}

export interface PerfectSystemMaterialGroupRequestModel {
  /** @format int32 */
  materialGroupId?: number;
}

export interface PerfectSystemRequestModel {
  /** @format int32 */
  id?: number;
  systemProductDescription?: string | null;
  perfectSystemMaterialGroups?: PerfectSystemMaterialGroupRequestModel[] | null;
}

export interface PrepareResponseModel {
  /** @format int32 */
  id?: number;
  countryCode?: string | null;
  toolText?: string | null;
  mixing?: string | null;
  process?: string | null;
  processingShort?: string | null;

  /** @format int32 */
  materialGroupId?: number;
}

export interface PrepareRequestModel {
  /** @format int32 */
  id?: number;
  toolText?: string | null;
  mixing?: string | null;
  process?: string | null;
  processingShort?: string | null;

  /** @format int32 */
  materialGroupId?: number;
}

export interface ProductHintMaterialGroupResponse {
  /** @format int32 */
  id?: number;
  standardTxt?: string | null;

  /** @format int32 */
  materialGroupId?: number;
  countryCode?: string | null;
}

export interface ProductHintMaterialGroupRequest {
  /** @format int32 */
  id?: number;
  standardTxt?: string | null;
}

export interface TechnicalDetailsResponseModel {
  /** @format int32 */
  id?: number;

  /** @format int32 */
  materialGroupId?: number;
  countryCode?: string | null;
  chemicalBase?: string | null;
  dense?: string | null;
  bulkDensity?: string | null;
  freshMortarDensity?: string | null;
  largestGrain?: string | null;
  solidStateContent?: string | null;
  viscosity?: string | null;
  µValue?: string | null;
  sdValue?: string | null;
  phValue?: string | null;
  appearance?: string | null;
  color?: string | null;
  gloss?: string | null;
  consumption?: string | null;
  productiveness?: string | null;
  waterDemand?: string | null;
  mixingRatio?: string | null;
  dilution?: string | null;
  layerThickness?: string | null;
  jointWidth?: string | null;
  dryFilmThickness?: string | null;
  ventilationTime?: string | null;
  insertionTime?: string | null;
  readyForPublishing?: string | null;
  rainproof?: string | null;
  finalStrength?: string | null;
  grindability?: string | null;
  mechanicallyResilient?: string | null;
  chemicallyResistant?: string | null;
  restingTime?: string | null;
  potLife?: string | null;
  processingTime?: string | null;
  openTime?: string | null;
  skinImageTime?: string | null;
  correctability?: string | null;
  dryingTimeAfterFirstApplication?: string | null;
  dryingTime?: string | null;
  curing?: string | null;
  revisability?: string | null;
  accessibilityForNextWorkStep?: string | null;
  accessibility?: string | null;
  grouting?: string | null;
  fullyLoadable?: string | null;
  dryingSpeed?: string | null;
  certificateTestReportEstablishClass?: string | null;
  flexuralStrength?: string | null;
  compressiveStrength?: string | null;
  tensileStrength?: string | null;
  shearStrength?: string | null;
  peelStrength?: string | null;
  pullOutStrength?: string | null;
  shoreAHardness?: string | null;
  shoreDHardness?: string | null;
  shrinkage?: string | null;
  swellingBehaviour?: string | null;
  slump?: string | null;
  crackBridging?: string | null;
  eModule?: string | null;
  elongationAtBreak?: string | null;
  permanentlyExpandable?: string | null;
  linearDeformation?: string | null;
  permissibleDeformation?: string | null;
  waterPressureResistance?: string | null;
  waterVapourDiffusionRate?: string | null;
  waterVapourPermeability?: string | null;
  permeabilityForWater?: string | null;
  capillaryWaterIntake?: string | null;
  uvResistance?: string | null;
  cO2Permeability?: string | null;
  highCarbonizationResistance?: string | null;
  fireClass?: string | null;
  thermoShockResistance?: string | null;
  temperatureResistance?: string | null;
  heatResistant?: string | null;
  flamePoint?: string | null;
  glassTransitionTemperature?: string | null;
  coefficientOfThermalExpansion?: string | null;
  penetrationDepth?: string | null;
  wetAbrasionClass?: string | null;
  coveringPower?: string | null;
  glossLevel?: string | null;
  whiteness?: string | null;
  chemicalResistance?: string | null;
  leakageResistance?: string | null;
  electricalResistivity?: string | null;
  objectAndMaterialWorkingTemperature?: string | null;
  substrateTemperature?: string | null;
  workingTemperature?: string | null;
  permissibleRelativeAirHumidity?: string | null;
  materialThickness?: string | null;
  dimension?: string | null;
  weight?: string | null;
  basisWeight?: string | null;
  extraAttribute1?: string | null;
  extraAttribute2?: string | null;
  extraAttribute3?: string | null;
  extraAttribute4?: string | null;
  extraAttribute5?: string | null;
  extraTextAttribute1?: string | null;
  extraTextAttribute2?: string | null;
  extraTextAttribute3?: string | null;
  extraTextAttribute4?: string | null;
  extraTextAttribute5?: string | null;
}

export interface TechnicalDetailsRequestModel {
  /** @format int32 */
  id?: number;
  chemicalBase?: string | null;
  dense?: string | null;
  bulkDensity?: string | null;
  freshMortarDensity?: string | null;
  largestGrain?: string | null;
  solidStateContent?: string | null;
  viscosity?: string | null;
  µValue?: string | null;
  sdValue?: string | null;
  phValue?: string | null;
  appearance?: string | null;
  color?: string | null;
  gloss?: string | null;
  consumption?: string | null;
  productiveness?: string | null;
  waterDemand?: string | null;
  mixingRatio?: string | null;
  dilution?: string | null;
  layerThickness?: string | null;
  jointWidth?: string | null;
  dryFilmThickness?: string | null;
  ventilationTime?: string | null;
  insertionTime?: string | null;
  readyForPublishing?: string | null;
  rainproof?: string | null;
  finalStrength?: string | null;
  grindability?: string | null;
  mechanicallyResilient?: string | null;
  chemicallyResistant?: string | null;
  restingTime?: string | null;
  potLife?: string | null;
  processingTime?: string | null;
  openTime?: string | null;
  skinImageTime?: string | null;
  correctability?: string | null;
  dryingTimeAfterFirstApplication?: string | null;
  dryingTime?: string | null;
  curing?: string | null;
  revisability?: string | null;
  accessibilityForNextWorkStep?: string | null;
  accessibility?: string | null;
  grouting?: string | null;
  fullyLoadable?: string | null;
  dryingSpeed?: string | null;
  certificateTestReportEstablishClass?: string | null;
  flexuralStrength?: string | null;
  compressiveStrength?: string | null;
  tensileStrength?: string | null;
  shearStrength?: string | null;
  peelStrength?: string | null;
  pullOutStrength?: string | null;
  shoreAHardness?: string | null;
  shoreDHardness?: string | null;
  shrinkage?: string | null;
  swellingBehaviour?: string | null;
  slump?: string | null;
  crackBridging?: string | null;
  eModule?: string | null;
  elongationAtBreak?: string | null;
  permanentlyExpandable?: string | null;
  linearDeformation?: string | null;
  permissibleDeformation?: string | null;
  waterPressureResistance?: string | null;
  waterVapourDiffusionRate?: string | null;
  waterVapourPermeability?: string | null;
  permeabilityForWater?: string | null;
  capillaryWaterIntake?: string | null;
  uvResistance?: string | null;
  cO2Permeability?: string | null;
  highCarbonizationResistance?: string | null;
  fireClass?: string | null;
  thermoShockResistance?: string | null;
  temperatureResistance?: string | null;
  heatResistant?: string | null;
  flamePoint?: string | null;
  glassTransitionTemperature?: string | null;
  coefficientOfThermalExpansion?: string | null;
  penetrationDepth?: string | null;
  wetAbrasionClass?: string | null;
  coveringPower?: string | null;
  glossLevel?: string | null;
  whiteness?: string | null;
  chemicalResistance?: string | null;
  leakageResistance?: string | null;
  electricalResistivity?: string | null;
  objectAndMaterialWorkingTemperature?: string | null;
  substrateTemperature?: string | null;
  workingTemperature?: string | null;
  permissibleRelativeAirHumidity?: string | null;
  materialThickness?: string | null;
  dimension?: string | null;
  weight?: string | null;
  basisWeight?: string | null;
  extraAttribute1?: string | null;
  extraAttribute2?: string | null;
  extraAttribute3?: string | null;
  extraAttribute4?: string | null;
  extraAttribute5?: string | null;
  extraTextAttribute1?: string | null;
  extraTextAttribute2?: string | null;
  extraTextAttribute3?: string | null;
  extraTextAttribute4?: string | null;
  extraTextAttribute5?: string | null;
}

export interface WorksafetyResponseModel {
  /** @format int32 */
  id?: number;
  countryCode?: string | null;
  workSafetyInformation?: string | null;

  /** @format int32 */
  materialGroupId?: number;
}

export interface WorksafetyRequestModel {
  /** @format int32 */
  id?: number;
  workSafetyInformation?: string | null;

  /** @format int32 */
  materialGroupId?: number;
}

export interface PictogramImageResponse {
  fileName?: string | null;
  updatedBy?: string | null;

  /** @format date-time */
  updatedAt?: string;
  createdBy?: string | null;

  /** @format date-time */
  createdAt?: string;
}

export interface PictogramResponse {
  /** @format int32 */
  id?: number;
  fileName?: string | null;
}

export interface PictogramRequest {
  fileName?: string | null;
}

export interface PictogramResponseModel {
  createdBy?: string | null;

  /** @format date-time */
  createdAt?: string;
  updatedBy?: string | null;

  /** @format date-time */
  updatedAt?: string;
  isActive?: boolean;
  id: string;
  fileName?: string | null;
  fullName?: string | null;

  /** @format int64 */
  fileSize?: number;
}

export interface ProductHintResponse {
  /** @format int32 */
  id?: number;
  title?: string | null;
  content?: string | null;
}

export interface ProductHintRequest {
  /** @format int32 */
  id?: number;
  title?: string | null;
  content?: string | null;
}

export interface ProductGroupResponse {
  /** @format int32 */
  id: number;
  productGroupName?: string | null;
  productGroupDescription?: string | null;
  productGroupCountryDescription?: string | null;
  countryCode?: string | null;
}

export interface ProductGroupCreateRequest {
  /** @format int32 */
  divisionId?: number;
  productGroupName?: string | null;
  productGroupDescription?: string | null;
}

export interface ProductGroupCountryRequest {
  /** @format int32 */
  productGroupId?: number;
  productGroupCountryDescription?: string | null;
}

export interface ProductGroupUpdateRequest {
  productGroupName?: string | null;
  productGroupDescription?: string | null;
  productGroupCountryDescription?: string | null;
}

export interface QuickLinkResponse {
  /** @format int32 */
  id?: number;
  userName?: string | null;
  title?: string | null;
  path?: string | null;
  breadCrumbs?: string | null;
}

export interface QuickLinkRequest {
  title?: string | null;
  path?: string | null;
  breadCrumbs?: string | null;
}

export interface SealOfApprovalImageResponse {
  id: string;
  category?: CategoryResponse;
  fileName?: string | null;
  fullName?: string | null;

  /** @format int64 */
  fileSize?: number;

  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;

  /** @format date-time */
  updatedAt?: string;
  updatedBy?: string | null;
}

export interface SealOfApprovalRequest {
  fullName: string;
}

export interface SealOfApprovalAndNormMaterialGroupRequest {
  /** @format int32 */
  id?: number;
  countryCode?: string | null;

  /** @format int32 */
  materialGroupId?: number;
  norm?: string | null;
  sealOfApprovals?: SealOfApprovalRequest[] | null;
}

export interface SealOfApprovalResponse {
  fullName: string;
}

export interface SealOfApprovalAndNormMaterialGroupResponse {
  /** @format int32 */
  id?: number;
  country?: string | null;

  /** @format int32 */
  materialGroupId?: number;
  norm?: string | null;
  sealOfApprovals?: SealOfApprovalResponse[] | null;
}

export interface SearchResponse {
  countryCode: string;
  countryName: string;

  /** @format int32 */
  materialGroupId: number;
  materialGroupDescription: string;
  materialGroupName: string;
  materialGroupTitle?: string | null;

  /** @format int32 */
  productGroupId: number;

  /** @format int32 */
  divisionId: number;

  /** @format int32 */
  articleId?: number | null;
  articleNumber?: string | null;
  articleDescription?: string | null;
  ean?: string | null;
}

export interface SubstrateResponse {
  /** @format int32 */
  id?: number;
  countryCode?: string | null;
  title?: string | null;
  content?: string | null;
}

export interface SubstrateRequest {
  /** @format int32 */
  id?: number;
  title?: string | null;
  content?: string | null;
}

export interface TechnicalDatasheetAttachmentResponse {
  /** @format int32 */
  id?: number;
  fileName?: string | null;

  /** @format date-time */
  createdAt?: string;
}

export enum GlobalPermission {
  Read = 'Read',
  QuickLinkWrite = 'QuickLinkWrite',
  AdministrationRead = 'AdministrationRead',
  AdministrationWrite = 'AdministrationWrite',
  AdminProductAndMaterialGroupWrite = 'AdminProductAndMaterialGroupWrite',
  ApiKeyGenerate = 'ApiKeyGenerate',
}

export enum CountryPermission {
  Read = 'Read',
  Write = 'Write',
  AdministrationRead = 'AdministrationRead',
  AdministrationWrite = 'AdministrationWrite',
  CreateMaterialGroup = 'CreateMaterialGroup',
  UpdateMaterialGroup = 'UpdateMaterialGroup',
  DeleteMaterialGroup = 'DeleteMaterialGroup',
  CreateProductGroup = 'CreateProductGroup',
  UpdateProductGroup = 'UpdateProductGroup',
  DeleteProductGroup = 'DeleteProductGroup',
}

export interface CountryPermissionsGroupResponse {
  countryCode: string;
  permissions: CountryPermission[];
}

export interface UserPermissionsResponse {
  roleName: string;
  globalPermissions: GlobalPermission[];
  countryPermissionsGroups: CountryPermissionsGroupResponse[];
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = '';
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
  }

  private addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  private addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join('&');
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : '';
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];

        if (property instanceof Array && property.every((item) => item instanceof Blob)) {
          for (let file of property) {
            formData.append(key, file);
          }
        } else {
          formData.append(
            key,
            property instanceof Blob
              ? property
              : typeof property === 'object' && property !== null
              ? JSON.stringify(property)
              : property !== null && property !== undefined
              ? `${property}`
              : ''
          );
        }
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  private mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
        ...(requestParams.headers || {}),
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : void 0,
      body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Murexin.PIM.Web
 * @version 1.6.12.0
 */
export class MurexinPimContextApi<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags ApiKey
   * @name GenerateCreate
   * @request POST:/generate
   */
  generateCreate = (query?: { name?: string | null }, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/generate`,
      method: 'POST',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags ApplicationController`2
   * @name DccsAspNetCoreApplicationController
   * @request GET:/application
   */

  dccsAspNetCoreApplicationController = (params: RequestParams = {}) =>
    this.request<ObjectObjectApplicationAndUserInformation, any>({
      path: `/application`,
      method: 'GET',
      format: 'json',
      ...params,
    });

  cookieTest = {
    /**
     * No description
     *
     * @tags ApplicationController`2
     * @name CookieTestList
     * @request GET:/application/cookieTest
     */
    cookieTestList: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/application/cookieTest`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
  serverTimeUtc = {
    /**
     * No description
     *
     * @tags ApplicationController`2
     * @name ServerTimeUtcList
     * @request GET:/application/serverTimeUtc
     */
    serverTimeUtcList: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/application/serverTimeUtc`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
  serverTime = {
    /**
     * No description
     *
     * @tags ApplicationController`2
     * @name ServerTimeList
     * @request GET:/application/serverTime
     */
    serverTimeList: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/application/serverTime`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
  startTimeUtc = {
    /**
     * No description
     *
     * @tags ApplicationController`2
     * @name StartTimeUtcList
     * @request GET:/application/startTimeUtc
     */
    startTimeUtcList: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/application/startTimeUtc`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
  startTime = {
    /**
     * No description
     *
     * @tags ApplicationController`2
     * @name StartTimeList
     * @request GET:/application/startTime
     */
    startTimeList: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/application/startTime`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
  runningTime = {
    /**
     * No description
     *
     * @tags ApplicationController`2
     * @name RunningTimeList
     * @request GET:/application/runningTime
     */
    runningTimeList: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/application/runningTime`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
  uniqueInstanceId = {
    /**
     * No description
     *
     * @tags ApplicationController`2
     * @name UniqueInstanceIdList
     * @request GET:/application/uniqueInstanceId
     */
    uniqueInstanceIdList: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/application/uniqueInstanceId`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
  environmentName = {
    /**
     * No description
     *
     * @tags ApplicationController`2
     * @name EnvironmentNameList
     * @request GET:/application/environmentName
     */
    environmentNameList: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/application/environmentName`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
  version = {
    /**
     * No description
     *
     * @tags ApplicationController`2
     * @name VersionList
     * @request GET:/application/version
     */
    versionList: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/application/version`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
  binaryCreationTime = {
    /**
     * No description
     *
     * @tags ApplicationController`2
     * @name BinaryCreationTimeList
     * @request GET:/application/binaryCreationTime
     */
    binaryCreationTimeList: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/application/binaryCreationTime`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
  binaryCreationTimeUtc = {
    /**
     * No description
     *
     * @tags ApplicationController`2
     * @name BinaryCreationTimeUtcList
     * @request GET:/application/binaryCreationTimeUtc
     */
    binaryCreationTimeUtcList: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/application/binaryCreationTimeUtc`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
  displayVersion = {
    /**
     * No description
     *
     * @tags ApplicationController`2
     * @name DisplayVersionList
     * @request GET:/application/displayVersion
     */
    displayVersionList: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/application/displayVersion`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
  isAuthenticated = {
    /**
     * No description
     *
     * @tags ApplicationController`2
     * @name IsAuthenticatedList
     * @request GET:/application/isAuthenticated
     */
    isAuthenticatedList: (params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/application/isAuthenticated`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
  checkUser = {
    /**
     * No description
     *
     * @tags ApplicationController`2
     * @name CheckUserList
     * @request GET:/application/checkUser
     */
    checkUserList: (params: RequestParams = {}) =>
      this.request<UserInformation, any>({
        path: `/application/checkUser`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
  options = {
    /**
     * No description
     *
     * @tags ApplicationController`2
     * @name OptionsList
     * @request GET:/application/options
     */
    optionsList: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/application/options`,
        method: 'GET',
        ...params,
      }),
  };
  settings = {
    /**
     * No description
     *
     * @tags ApplicationController`2
     * @name SettingsList
     * @request GET:/application/settings
     */
    settingsList: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/application/settings`,
        method: 'GET',
        ...params,
      }),
  };
  user = {
    /**
     * No description
     *
     * @tags ApplicationController`2
     * @name UserList
     * @request GET:/application/user
     */
    userList: (params: RequestParams = {}) =>
      this.request<UserInformation, any>({
        path: `/application/user`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags User
     * @name UserPermissionsList
     * @request GET:/api/User/Permissions
     */,

    userPermissionsList: (params: RequestParams = {}) =>
      this.request<UserPermissionsResponse, any>({
        path: `/api/User/Permissions`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
  testhttp = {
    /**
     * No description
     *
     * @tags ApplicationController`2
     * @name TesthttpDetail
     * @request GET:/application/testhttp/{url}
     */
    testhttpDetail: (url: string | null, params: RequestParams = {}) =>
      this.request<ObjectObjectTestHttpResponse, any>({
        path: `/application/testhttp/${url}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
  testhttps = {
    /**
     * No description
     *
     * @tags ApplicationController`2
     * @name TesthttpsDetail
     * @request GET:/application/testhttps/{url}
     */
    testhttpsDetail: (url: string | null, params: RequestParams = {}) =>
      this.request<ObjectObjectTestHttpResponse, any>({
        path: `/application/testhttps/${url}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
  testTcp = {
    /**
     * No description
     *
     * @tags ApplicationController`2
     * @name TestTcpDetail
     * @request GET:/application/testTCP/{hostOrIpAndPort}
     */
    testTcpDetail: (hostOrIpAndPort: string | null, params: RequestParams = {}) =>
      this.request<ObjectObjectTestTCPResult, any>({
        path: `/application/testTCP/${hostOrIpAndPort}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
  ping = {
    /**
     * No description
     *
     * @tags ApplicationController`2
     * @name PingDetail
     * @request GET:/application/ping/{host}
     */
    pingDetail: (host: string | null, params: RequestParams = {}) =>
      this.request<ObjectObjectTestPingResult, any>({
        path: `/application/ping/${host}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
  diagnostic = {
    /**
     * No description
     *
     * @tags ApplicationController`2
     * @name DiagnosticList
     * @request GET:/application/diagnostic
     */
    diagnosticList: (params: RequestParams = {}) =>
      this.request<DiagnosticInformation, any>({
        path: `/application/diagnostic`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
  applicationDataJs = {
    /**
     * No description
     *
     * @tags ApplicationController`2
     * @name ApplicationDataJsList
     * @request GET:/application/applicationData.js
     */
    applicationDataJsList: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/application/applicationData.js`,
        method: 'GET',
        ...params,
      }),
  };
  articleService = {
    /**
     * No description
     *
     * @tags ArticleService
     * @name ArticleServiceArticleDetail
     * @request GET:/api/ArticleService/{country}/article/{articleId}
     */
    articleServiceArticleDetail: (country: string | null, articleId: number, params: RequestParams = {}) =>
      this.request<ArticleResponse, ProblemDetails>({
        path: `/api/ArticleService/${country}/article/${articleId}`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags ArticleService
     * @name ArticleServiceArticleCreate
     * @request POST:/api/ArticleService/{country}/article/{articleId}
     */,

    articleServiceArticleCreate: (
      country: string | null,
      articleId: number,
      data: {
        ArticleId?: number;
        MaterialGroupId?: number;
        ArticleDescription?: string | null;
        ArticleNumber?: string | null;
        EAN?: string | null;
        ContainerType?: string | null;
        ProductTypeCode?: string | null;
        PalletNumberOfContainers?: number | null;
        OverCartonNumberOfContainers?: number | null;
        ArticleCountryNumber?: string | null;
        IsGlobal?: boolean;
        AddedArrangementLayoutFiles?: File[] | null;
      },
      query?: { DeletedArrangementLayoutIds?: number[] | null },
      params: RequestParams = {}
    ) =>
      this.request<ArticleResponse, ProblemDetails>({
        path: `/api/ArticleService/${country}/article/${articleId}`,
        method: 'POST',
        query: query,
        body: data,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags ArticleService
     * @name ArticleServiceArticleDelete
     * @request DELETE:/api/ArticleService/{country}/article/{articleId}
     */,

    articleServiceArticleDelete: (country: string | null, articleId: number, params: RequestParams = {}) =>
      this.request<void, ProblemDetails>({
        path: `/api/ArticleService/${country}/article/${articleId}`,
        method: 'DELETE',
        ...params,
      })
    /**
     * No description
     *
     * @tags ArticleService
     * @name ArticleServiceArticleListMaterialGroupDetail
     * @request GET:/api/ArticleService/{country}/articleList/materialGroup/{materialGroupId}
     */,

    articleServiceArticleListMaterialGroupDetail: (
      country: string | null,
      materialGroupId: number,
      params: RequestParams = {}
    ) =>
      this.request<ArticleResponse[], ProblemDetails>({
        path: `/api/ArticleService/${country}/articleList/materialGroup/${materialGroupId}`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags ArticleService
     * @name ArticleServiceArticleCountryCreate
     * @request POST:/api/ArticleService/{country}/articleCountry/{articleId}
     */,

    articleServiceArticleCountryCreate: (
      country: string | null,
      articleId: number,
      data: {
        ArticleId?: number;
        ArticleDescription?: string | null;
        ProductTypeCode?: string | null;
        ArticleCountryNumber?: string | null;
        AddedArrangementLayoutFiles?: File[] | null;
      },
      query?: { DeletedArrangementLayoutIds?: number[] | null },
      params: RequestParams = {}
    ) =>
      this.request<ArticleResponse, ProblemDetails>({
        path: `/api/ArticleService/${country}/articleCountry/${articleId}`,
        method: 'POST',
        query: query,
        body: data,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags ArticleService
     * @name ArticleServiceErpArticleCreate
     * @request POST:/api/ArticleService/{country}/ERPArticle/{articleId}
     */,

    articleServiceErpArticleCreate: (
      country: string | null,
      articleId: number,
      data: {
        ArticleId?: number;
        ArticleCountryNumber?: string | null;
        AddedArrangementLayoutFiles?: File[] | null;
        IsGlobal?: boolean;
      },
      query?: { DeletedArrangementLayoutIds?: number[] | null },
      params: RequestParams = {}
    ) =>
      this.request<ArticleResponse, ProblemDetails>({
        path: `/api/ArticleService/${country}/ERPArticle/${articleId}`,
        method: 'POST',
        query: query,
        body: data,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags ArticleService
     * @name ArticleServiceProductTypesDetail
     * @request GET:/api/ArticleService/{country}/productTypes
     */,

    articleServiceProductTypesDetail: (country: string, params: RequestParams = {}) =>
      this.request<ProductTypeDTO[], any>({
        path: `/api/ArticleService/${country}/productTypes`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags ArticleService
     * @name ArticleServiceArrangementLayoutDetail
     * @request GET:/api/ArticleService/{country}/arrangementLayout/{layoutId}
     */,

    articleServiceArrangementLayoutDetail: (country: string | null, layoutId: number, params: RequestParams = {}) =>
      this.request<File, ProblemDetails>({
        path: `/api/ArticleService/${country}/arrangementLayout/${layoutId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
  category = {
    /**
     * No description
     *
     * @tags Category
     * @name CategoryList
     * @request GET:/api/Category
     */
    categoryList: (params: RequestParams = {}) =>
      this.request<CategoryResponse[], any>({
        path: `/api/Category`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
  comment = {
    /**
     * No description
     *
     * @tags Comment
     * @name CommentCreate
     * @request POST:/api/Comment/{country}
     */
    commentCreate: (country: string | null, data: CommentRequest, params: RequestParams = {}) =>
      this.request<CommentResponse, any>({
        path: `/api/Comment/${country}`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags Comment
     * @name CommentByMaterialIdDetail
     * @request GET:/api/Comment/{country}/ByMaterialId/{materialGroupId}
     */,

    commentByMaterialIdDetail: (materialGroupId: number, country: string | null, params: RequestParams = {}) =>
      this.request<CommentResponse[], any>({
        path: `/api/Comment/${country}/ByMaterialId/${materialGroupId}`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags Comment
     * @name CommentDeactivateUpdate
     * @request PUT:/api/Comment/{country}/{commentId}/deactivate
     */,

    commentDeactivateUpdate: (commentId: number, country: string | null, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Comment/${country}/${commentId}/deactivate`,
        method: 'PUT',
        ...params,
      }),
  };
  admin = {
    /**
     * No description
     *
     * @tags ContainerLayoutExport
     * @name AdminContainerLayoutExportMaterialGroupsList
     * @request GET:/api/admin/ContainerLayoutExport/MaterialGroups
     */
    adminContainerLayoutExportMaterialGroupsList: (params: RequestParams = {}) =>
      this.request<AdminMaterialGroupResponse[], any>({
        path: `/api/admin/ContainerLayoutExport/MaterialGroups`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags ContainerLayoutExport
     * @name AdminContainerLayoutExportProductGroupsList
     * @request GET:/api/admin/ContainerLayoutExport/ProductGroups
     */,

    adminContainerLayoutExportProductGroupsList: (params: RequestParams = {}) =>
      this.request<AdminProductGroupResponse[], any>({
        path: `/api/admin/ContainerLayoutExport/ProductGroups`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags ContainerLayoutExport
     * @name AdminContainerLayoutExportExportFileDetail
     * @request GET:/api/admin/ContainerLayoutExport/export/{materialGroupId}/file
     */,

    adminContainerLayoutExportExportFileDetail: (
      materialGroupId: number,
      query?: { fileName?: string | null },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/admin/ContainerLayoutExport/export/${materialGroupId}/file`,
        method: 'GET',
        query: query,
        ...params,
      })
    /**
     * No description
     *
     * @tags DocumentAdmin
     * @name AdminDocumentAdminList
     * @request GET:/api/admin/DocumentAdmin
     */,

    adminDocumentAdminList: (params: RequestParams = {}) =>
      this.request<AdminDocumentResponse[], any>({
        path: `/api/admin/DocumentAdmin`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags DocumentAdmin
     * @name AdminDocumentAdminDetailsList
     * @request GET:/api/admin/DocumentAdmin/details
     */,

    adminDocumentAdminDetailsList: (params: RequestParams = {}) =>
      this.request<DocumentDetailOptionResponse[], any>({
        path: `/api/admin/DocumentAdmin/details`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags DocumentAdmin
     * @name AdminDocumentAdminFileDetail
     * @request GET:/api/admin/DocumentAdmin/{documentId}/file
     */,

    adminDocumentAdminFileDetail: (documentId: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/admin/DocumentAdmin/${documentId}/file`,
        method: 'GET',
        ...params,
      })
    /**
     * No description
     *
     * @tags DocumentAdmin
     * @name AdminDocumentAdminUpdate
     * @request PUT:/api/admin/DocumentAdmin/{documentId}
     */,

    adminDocumentAdminUpdate: (documentId: string, data: AdminUpdateDocumentRequest, params: RequestParams = {}) =>
      this.request<void, ProblemDetails | void>({
        path: `/api/admin/DocumentAdmin/${documentId}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      })
    /**
     * No description
     *
     * @tags DocumentAdmin
     * @name AdminDocumentAdminDelete
     * @request DELETE:/api/admin/DocumentAdmin/{documentId}
     */,

    adminDocumentAdminDelete: (documentId: number, params: RequestParams = {}) =>
      this.request<void, ProblemDetails | void>({
        path: `/api/admin/DocumentAdmin/${documentId}`,
        method: 'DELETE',
        ...params,
      })
    /**
     * No description
     *
     * @tags DynamicExport
     * @name AdminDynamicExportMaterialGroupAttributeList
     * @request GET:/api/admin/DynamicExport/materialGroupAttribute
     */,

    adminDynamicExportMaterialGroupAttributeList: (params: RequestParams = {}) =>
      this.request<MaterialGroupAttributeResponse[], any>({
        path: `/api/admin/DynamicExport/materialGroupAttribute`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags DynamicExport
     * @name AdminDynamicExportDivisionDetail
     * @request GET:/api/admin/DynamicExport/{country}/division
     */,

    adminDynamicExportDivisionDetail: (country: string | null, params: RequestParams = {}) =>
      this.request<DivisionTreeResponse[], any>({
        path: `/api/admin/DynamicExport/${country}/division`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags DynamicExport
     * @name AdminDynamicExportMaterialGroupFileCreate
     * @request POST:/api/admin/DynamicExport/materialGroupFile
     */,

    adminDynamicExportMaterialGroupFileCreate: (data: MaterialGroupExportRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/admin/DynamicExport/materialGroupFile`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      })
    /**
     * No description
     *
     * @tags DynamicExport
     * @name AdminDynamicExportArticleFileCreate
     * @request POST:/api/admin/DynamicExport/articleFile
     */,

    adminDynamicExportArticleFileCreate: (data: ArticleExportRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/admin/DynamicExport/articleFile`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      })
    /**
     * No description
     *
     * @tags GeneralHint
     * @name AdminGeneralHintList
     * @request GET:/api/admin/GeneralHint
     */,

    adminGeneralHintList: (params: RequestParams = {}) =>
      this.request<GeneralHintResponse[], any>({
        path: `/api/admin/GeneralHint`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags GeneralHint
     * @name AdminGeneralHintUpdate
     * @request PUT:/api/admin/GeneralHint
     */,

    adminGeneralHintUpdate: (data: GeneralHintRequest[] | null, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/admin/GeneralHint`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags GeneralHint
     * @name AdminGeneralHintDetail
     * @request GET:/api/admin/GeneralHint/{country}
     */,

    adminGeneralHintDetail: (country: string | null, params: RequestParams = {}) =>
      this.request<GeneralHintResponse, any>({
        path: `/api/admin/GeneralHint/${country}`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags ProductHint
     * @name AdminProductHintList
     * @request GET:/api/admin/ProductHint
     */,

    adminProductHintList: (params: RequestParams = {}) =>
      this.request<ProductHintResponse[], any>({
        path: `/api/admin/ProductHint`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags ProductHint
     * @name AdminProductHintCreate
     * @request POST:/api/admin/ProductHint
     */,

    adminProductHintCreate: (data: ProductHintRequest[] | null, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/admin/ProductHint`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags Substrate
     * @name AdminSubstrateDetail
     * @request GET:/api/admin/Substrate/{country}
     */,

    adminSubstrateDetail: (country: string | null, params: RequestParams = {}) =>
      this.request<SubstrateResponse[], any>({
        path: `/api/admin/Substrate/${country}`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags Substrate
     * @name AdminSubstrateUpdate
     * @request PUT:/api/admin/Substrate/{country}
     */,

    adminSubstrateUpdate: (country: string | null, data: SubstrateRequest[] | null, params: RequestParams = {}) =>
      this.request<SubstrateResponse[], any>({
        path: `/api/admin/Substrate/${country}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  countryService = {
    /**
     * No description
     *
     * @tags CountryService
     * @name CountryServiceList
     * @request GET:/api/CountryService
     */
    countryServiceList: (params: RequestParams = {}) =>
      this.request<CountryResponse[], any>({
        path: `/api/CountryService`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
  divisionService = {
    /**
     * No description
     *
     * @tags DivisionService
     * @name DivisionServiceDetail
     * @request GET:/api/DivisionService/{country}
     */
    divisionServiceDetail: (country: string | null, params: RequestParams = {}) =>
      this.request<DivisionResponseModel[], any>({
        path: `/api/DivisionService/${country}`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags DivisionService
     * @name DivisionServiceByIdDetail
     * @request GET:/api/DivisionService/{country}/ById/{id}
     */,

    divisionServiceByIdDetail: (id: number, country: string, params: RequestParams = {}) =>
      this.request<DivisionResponseModel, any>({
        path: `/api/DivisionService/${country}/ById/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags DivisionService
     * @name DivisionServiceDivisionCountriesDetail
     * @request GET:/api/DivisionService/{country}/divisionCountries
     */,

    divisionServiceDivisionCountriesDetail: (country: string | null, params: RequestParams = {}) =>
      this.request<DivisionResponseModel[], any>({
        path: `/api/DivisionService/${country}/divisionCountries`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags DivisionService
     * @name DivisionServiceGetDivisionCountriesByCountryCodeDetail
     * @request GET:/api/DivisionService/{country}/getDivisionCountriesByCountryCode
     */,

    divisionServiceGetDivisionCountriesByCountryCodeDetail: (country: string | null, params: RequestParams = {}) =>
      this.request<DivisionCountryResponseModel[], any>({
        path: `/api/DivisionService/${country}/getDivisionCountriesByCountryCode`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags DivisionService
     * @name DivisionServiceGetDivisionCountryByIdDetail
     * @request GET:/api/DivisionService/{country}/getDivisionCountryById/{id}
     */,

    divisionServiceGetDivisionCountryByIdDetail: (id: number, country: string, params: RequestParams = {}) =>
      this.request<DivisionCountryResponseModel, any>({
        path: `/api/DivisionService/${country}/getDivisionCountryById/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags DivisionService
     * @name DivisionServiceDivisionCountryCreate
     * @request POST:/api/DivisionService/{country}/divisionCountry
     */,

    divisionServiceDivisionCountryCreate: (
      country: string,
      data: { DivisionId?: number; CountryCode?: string | null; DivisionDescription?: string | null },
      params: RequestParams = {}
    ) =>
      this.request<DivisionCountryResponseModel, ProblemDetails>({
        path: `/api/DivisionService/${country}/divisionCountry`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags DivisionService
     * @name DivisionServiceDivisionCountryUpdate
     * @request PUT:/api/DivisionService/{country}/divisionCountry/{divisionCountryId}
     */,

    divisionServiceDivisionCountryUpdate: (
      divisionCountryId: number,
      country: string,
      data: { DivisionId?: number; CountryCode?: string | null; DivisionDescription?: string | null },
      params: RequestParams = {}
    ) =>
      this.request<DivisionCountryResponseModel, ProblemDetails>({
        path: `/api/DivisionService/${country}/divisionCountry/${divisionCountryId}`,
        method: 'PUT',
        body: data,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags DivisionService
     * @name DivisionServiceBreadCrumbsInfoDetail
     * @request GET:/api/DivisionService/{country}/BreadCrumbsInfo/{id}/{id2}/{id3}
     */,

    divisionServiceBreadCrumbsInfoDetail: (
      country: string | null,
      id: number,
      id2: number | null,
      id3: number | null,
      params: RequestParams = {}
    ) =>
      this.request<BreadCrumbInfoResponseModel[], any>({
        path: `/api/DivisionService/${country}/BreadCrumbsInfo/${id}/${id2}/${id3}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
  document = {
    /**
     * No description
     *
     * @tags Document
     * @name DocumentMaterialGroupDetail
     * @request GET:/api/Document/{country}/materialGroup/{materialGroupId}
     */
    documentMaterialGroupDetail: (country: string | null, materialGroupId: number, params: RequestParams = {}) =>
      this.request<DocumentsGroup[], void>({
        path: `/api/Document/${country}/materialGroup/${materialGroupId}`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags Document
     * @name DocumentDivisionDetail
     * @request GET:/api/Document/{country}/division/{divisionId}
     */,

    documentDivisionDetail: (country: string | null, divisionId: number, params: RequestParams = {}) =>
      this.request<DocumentsGroup[], void>({
        path: `/api/Document/${country}/division/${divisionId}`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags Document
     * @name DocumentProductGroupDetail
     * @request GET:/api/Document/{country}/productGroup/{productGroupId}
     */,

    documentProductGroupDetail: (country: string | null, productGroupId: number, params: RequestParams = {}) =>
      this.request<DocumentsGroup[], void>({
        path: `/api/Document/${country}/productGroup/${productGroupId}`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags Document
     * @name DocumentDetail
     * @request GET:/api/Document/{country}/{documentId}
     */,

    documentDetail: (country: string | null, documentId: number, params: RequestParams = {}) =>
      this.request<DocumentResponse, void>({
        path: `/api/Document/${country}/${documentId}`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags Document
     * @name DocumentUpdate
     * @request PUT:/api/Document/{country}/{documentId}
     */,

    documentUpdate: (
      country: string | null,
      documentId: number,
      data: UpdateDocumentRequest,
      params: RequestParams = {}
    ) =>
      this.request<void, ProblemDetails | void>({
        path: `/api/Document/${country}/${documentId}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      })
    /**
     * No description
     *
     * @tags Document
     * @name DocumentDelete
     * @request DELETE:/api/Document/{country}/{documentId}
     */,

    documentDelete: (country: string | null, documentId: number, params: RequestParams = {}) =>
      this.request<void, ProblemDetails | void>({
        path: `/api/Document/${country}/${documentId}`,
        method: 'DELETE',
        ...params,
      })
    /**
     * No description
     *
     * @tags Document
     * @name DocumentFileDetail
     * @request GET:/api/Document/{country}/{documentId}/file
     */,

    documentFileDetail: (country: string | null, documentId: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/Document/${country}/${documentId}/file`,
        method: 'GET',
        ...params,
      })
    /**
     * No description
     *
     * @tags Document
     * @name DocumentCreate
     * @request POST:/api/Document/{country}
     */,

    documentCreate: (
      country: string | null,
      data: {
        Category: DocumentCategory;
        DocumentLevel: DocumentLevel;
        DetailId: number;
        IsExported: boolean;
        File: File;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, ProblemDetails | void>({
        path: `/api/Document/${country}`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        ...params,
      }),
  };
  export = {
    /**
     * No description
     *
     * @tags Export
     * @name ExportExportWebsiteDataDetail
     * @request GET:/api/Export/{country}/exportWebsiteData
     */
    exportExportWebsiteDataDetail: (country: string | null, params: RequestParams = {}) =>
      this.request<WebsiteGroupResponseIEnumerableTask, ProblemDetails | void>({
        path: `/api/Export/${country}/exportWebsiteData`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags Export
     * @name ExportMaterialGroupImageDetail
     * @request GET:/api/Export/{country}/materialGroup/{materialId}/image
     */,

    exportMaterialGroupImageDetail: (country: string | null, materialId: number, params: RequestParams = {}) =>
      this.request<void, ProblemDetails>({
        path: `/api/Export/${country}/materialGroup/${materialId}/image`,
        method: 'GET',
        ...params,
      })
    /**
     * No description
     *
     * @tags Export
     * @name ExportPictogramImageDetail
     * @request GET:/api/Export/{country}/pictogram/{pictogramId}/image
     */,

    exportPictogramImageDetail: (country: string | null, pictogramId: number, params: RequestParams = {}) =>
      this.request<void, ProblemDetails>({
        path: `/api/Export/${country}/pictogram/${pictogramId}/image`,
        method: 'GET',
        ...params,
      })
    /**
     * No description
     *
     * @tags Export
     * @name ExportSealOfApprovalImageDetail
     * @request GET:/api/Export/{country}/sealOfApproval/{sealOfApprovalId}/image
     */,

    exportSealOfApprovalImageDetail: (country: string | null, sealOfApprovalId: number, params: RequestParams = {}) =>
      this.request<void, ProblemDetails>({
        path: `/api/Export/${country}/sealOfApproval/${sealOfApprovalId}/image`,
        method: 'GET',
        ...params,
      })
    /**
     * No description
     *
     * @tags Export
     * @name ExportTechnicalDatasheetDetail
     * @request GET:/api/Export/{country}/technicalDatasheet/{documentId}
     */,

    exportTechnicalDatasheetDetail: (country: string | null, documentId: number, params: RequestParams = {}) =>
      this.request<void, ProblemDetails>({
        path: `/api/Export/${country}/technicalDatasheet/${documentId}`,
        method: 'GET',
        ...params,
      })
    /**
     * No description
     *
     * @tags Export
     * @name ExportSafetyDatasheetDetail
     * @request GET:/api/Export/{country}/safetyDatasheet/{documentId}
     */,

    exportSafetyDatasheetDetail: (country: string | null, documentId: number, params: RequestParams = {}) =>
      this.request<void, ProblemDetails>({
        path: `/api/Export/${country}/safetyDatasheet/${documentId}`,
        method: 'GET',
        ...params,
      })
    /**
     * No description
     *
     * @tags Export
     * @name ExportInfoSheetDetail
     * @request GET:/api/Export/{country}/infoSheet/{documentId}
     */,

    exportInfoSheetDetail: (country: string | null, documentId: number, params: RequestParams = {}) =>
      this.request<void, ProblemDetails>({
        path: `/api/Export/${country}/infoSheet/${documentId}`,
        method: 'GET',
        ...params,
      })
    /**
     * No description
     *
     * @tags Export
     * @name ExportPrintingDataDetail
     * @request GET:/api/Export/{country}/printingData/{documentId}
     */,

    exportPrintingDataDetail: (country: string | null, documentId: number, params: RequestParams = {}) =>
      this.request<void, ProblemDetails>({
        path: `/api/Export/${country}/printingData/${documentId}`,
        method: 'GET',
        ...params,
      })
    /**
     * No description
     *
     * @tags Export
     * @name ExportDeclarationOfPerformanceDetail
     * @request GET:/api/Export/{country}/declarationOfPerformance/{documentId}
     */,

    exportDeclarationOfPerformanceDetail: (country: string | null, documentId: number, params: RequestParams = {}) =>
      this.request<void, ProblemDetails>({
        path: `/api/Export/${country}/declarationOfPerformance/${documentId}`,
        method: 'GET',
        ...params,
      }),
  };
  materialGroup = {
    /**
     * No description
     *
     * @tags ImageStore
     * @name MaterialGroupImageStoreLoadAllImagesDataDetail
     * @request GET:/api/materialGroup/ImageStore/{country}/loadAllImagesData
     */
    materialGroupImageStoreLoadAllImagesDataDetail: (country: string | null, params: RequestParams = {}) =>
      this.request<ImageStoreResponse[], any>({
        path: `/api/materialGroup/ImageStore/${country}/loadAllImagesData`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags ImageStore
     * @name MaterialGroupImageStoreSaveImageCreate
     * @request POST:/api/materialGroup/ImageStore/{country}/saveImage
     */,

    materialGroupImageStoreSaveImageCreate: (
      country: string | null,
      data: {
        FullName?: string | null;
        File?: File | null;
        FileName?: string | null;
        DefaultImage?: boolean;
        AzureStorageGuid?: string;
      },
      query?: { materialGroupId?: number | null },
      params: RequestParams = {}
    ) =>
      this.request<boolean, any>({
        path: `/api/materialGroup/ImageStore/${country}/saveImage`,
        method: 'POST',
        query: query,
        body: data,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags ImageStore
     * @name MaterialGroupImageStoreImageDetail
     * @request GET:/api/materialGroup/ImageStore/{country}/image/{imageStoreMaterialGroupId}
     */,

    materialGroupImageStoreImageDetail: (
      country: string | null,
      imageStoreMaterialGroupId: number,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/materialGroup/ImageStore/${country}/image/${imageStoreMaterialGroupId}`,
        method: 'GET',
        ...params,
      })
    /**
     * No description
     *
     * @tags ImageStore
     * @name MaterialGroupImageStoreImageDelete
     * @request DELETE:/api/materialGroup/ImageStore/{country}/image/{imageStoreMaterialGroupId}
     */,

    materialGroupImageStoreImageDelete: (
      country: string | null,
      imageStoreMaterialGroupId: number,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/materialGroup/ImageStore/${country}/image/${imageStoreMaterialGroupId}`,
        method: 'DELETE',
        ...params,
      }),
  };
  importErp = {
    /**
     * No description
     *
     * @tags ImportERP
     * @name ImportErpCreate
     * @request POST:/api/ImportERP
     */
    importErpCreate: (data: ERPImportRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/ImportERP`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      })
    /**
     * No description
     *
     * @tags ImportERP
     * @name ImportErpDatasyncAllrunsCreate
     * @request POST:/api/ImportERP/datasync/allruns
     */,

    importErpDatasyncAllrunsCreate: (params: RequestParams = {}) =>
      this.request<ERPImportResponse[], any>({
        path: `/api/ImportERP/datasync/allruns`,
        method: 'POST',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags ImportERP
     * @name ImportErpDatasyncCreate
     * @request POST:/api/ImportERP/datasync/{erpImportRunId}
     */,

    importErpDatasyncCreate: (erpImportRunId: number, params: RequestParams = {}) =>
      this.request<ERPImportResponse, any>({
        path: `/api/ImportERP/datasync/${erpImportRunId}`,
        method: 'POST',
        format: 'json',
        ...params,
      }),
  };
  localization = {
    /**
     * No description
     *
     * @tags Localization
     * @name LocalizationDefinitionList
     * @request GET:/api/Localization/definition
     */
    localizationDefinitionList: (params: RequestParams = {}) =>
      this.request<LocalizationDefinition, any>({
        path: `/api/Localization/definition`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags Localization
     * @name LocalizationTextCreate
     * @request POST:/api/Localization/text
     */,

    localizationTextCreate: (data: LocalizationTextModel, params: RequestParams = {}) =>
      this.request<LocalizationTextModel, any>({
        path: `/api/Localization/text`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags Localization
     * @name LocalizationTextList
     * @request GET:/api/Localization/text
     */,

    localizationTextList: (
      query?: { sectionCode?: string | null; textCode?: string | null },
      params: RequestParams = {}
    ) =>
      this.request<LocalizationTextModel, any>({
        path: `/api/Localization/text`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags Localization
     * @name LocalizationTextPartialUpdate
     * @request PATCH:/api/Localization/text
     */,

    localizationTextPartialUpdate: (data: LocalizationTextModel, params: RequestParams = {}) =>
      this.request<LocalizationTextModel, any>({
        path: `/api/Localization/text`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags Localization
     * @name LocalizationTextDelete
     * @request DELETE:/api/Localization/text
     */,

    localizationTextDelete: (data: LocalizationTextModel, params: RequestParams = {}) =>
      this.request<LocalizationTextModel, any>({
        path: `/api/Localization/text`,
        method: 'DELETE',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags Localization
     * @name LocalizationTranslationListList
     * @request GET:/api/Localization/translation/list
     */,

    localizationTranslationListList: (
      query?: { sectionCodes?: string | null; cultureCode?: string | null },
      params: RequestParams = {}
    ) =>
      this.request<TranslationDictionary, any>({
        path: `/api/Localization/translation/list`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags Localization
     * @name LocalizationTextListList
     * @request GET:/api/Localization/text/list
     */,

    localizationTextListList: (query?: { sectionCodes?: string | null }, params: RequestParams = {}) =>
      this.request<LocalizationTextModel[], any>({
        path: `/api/Localization/text/list`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  };
  mail = {
    /**
     * No description
     *
     * @tags Mail
     * @name MailList
     * @request GET:/api/Mail
     */
    mailList: (params: RequestParams = {}) =>
      this.request<MailingListResponse[], void>({
        path: `/api/Mail`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags Mail
     * @name MailCreate
     * @request POST:/api/Mail
     */,

    mailCreate: (data: CreateMailRequest, params: RequestParams = {}) =>
      this.request<MailResponse, ProblemDetails | void>({
        path: `/api/Mail`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags Mail
     * @name MailUpdate
     * @request PUT:/api/Mail/{id}
     */,

    mailUpdate: (id: number, data: UpdateMailRequest, params: RequestParams = {}) =>
      this.request<MailResponse, ProblemDetails | void>({
        path: `/api/Mail/${id}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags Mail
     * @name MailDelete
     * @request DELETE:/api/Mail/{id}
     */,

    mailDelete: (id: number, params: RequestParams = {}) =>
      this.request<MailResponse, ProblemDetails | void>({
        path: `/api/Mail/${id}`,
        method: 'DELETE',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags Mail
     * @name MailSendCreate
     * @request POST:/api/Mail/{country}/send
     */,

    mailSendCreate: (country: string | null, data: MailNotificationRequest, params: RequestParams = {}) =>
      this.request<boolean, ProblemDetails>({
        path: `/api/Mail/${country}/send`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags Mail
     * @name MailReminderCreate
     * @request POST:/api/Mail/reminder
     */,

    mailReminderCreate: (params: RequestParams = {}) =>
      this.request<boolean, ProblemDetails>({
        path: `/api/Mail/reminder`,
        method: 'POST',
        format: 'json',
        ...params,
      }),
  };
  materialGroupCountry = {
    /**
     * No description
     *
     * @tags MaterialGroupCountry
     * @name MaterialGroupCountryDetail
     * @request GET:/api/MaterialGroupCountry/{country}/{materialGroupId}
     */
    materialGroupCountryDetail: (country: string | null, materialGroupId: number, params: RequestParams = {}) =>
      this.request<MaterialGroupCountryResponse, any>({
        path: `/api/MaterialGroupCountry/${country}/${materialGroupId}`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags MaterialGroupCountry
     * @name MaterialGroupCountryCreate
     * @request POST:/api/MaterialGroupCountry/{country}
     */,

    materialGroupCountryCreate: (
      country: string | null,
      data: MaterialGroupCountryRequest,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/MaterialGroupCountry/${country}`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  materialGroupService = {
    /**
     * No description
     *
     * @tags MaterialGroupService
     * @name MaterialGroupServiceCreate
     * @request POST:/api/MaterialGroupService/{country}
     */
    materialGroupServiceCreate: (country: string | null, data: MaterialGroupResponse, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/MaterialGroupService/${country}`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags MaterialGroupService
     * @name MaterialGroupServiceDetail
     * @request GET:/api/MaterialGroupService/{country}
     */,

    materialGroupServiceDetail: (country: string | null, params: RequestParams = {}) =>
      this.request<MaterialGroupResponse[], any>({
        path: `/api/MaterialGroupService/${country}`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags MaterialGroupService
     * @name MaterialGroupServiceMaterialGroupDetail
     * @request GET:/api/MaterialGroupService/{country}/MaterialGroup/{materialGroupId}
     */,

    materialGroupServiceMaterialGroupDetail: (
      country: string | null,
      materialGroupId: number,
      params: RequestParams = {}
    ) =>
      this.request<MaterialGroupResponse, any>({
        path: `/api/MaterialGroupService/${country}/MaterialGroup/${materialGroupId}`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags MaterialGroupService
     * @name MaterialGroupServiceSearchDetail
     * @request GET:/api/MaterialGroupService/{country}/search
     */,

    materialGroupServiceSearchDetail: (country: string | null, params: RequestParams = {}) =>
      this.request<SearchMaterialGroupResponse[], any>({
        path: `/api/MaterialGroupService/${country}/search`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags MaterialGroupService
     * @name MaterialGroupServiceByProductIdDetail
     * @request GET:/api/MaterialGroupService/{country}/ByProductId/{productId}
     */,

    materialGroupServiceByProductIdDetail: (country: string | null, productId: number, params: RequestParams = {}) =>
      this.request<MaterialGroupResponse[], any>({
        path: `/api/MaterialGroupService/${country}/ByProductId/${productId}`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags MaterialGroupService
     * @name MaterialGroupServiceMaterialGroupTitleUpdate
     * @request PUT:/api/MaterialGroupService/{country}/materialGroup/{materialGroupId}/title
     */,

    materialGroupServiceMaterialGroupTitleUpdate: (
      country: string | null,
      materialGroupId: number,
      query?: { title?: string | null },
      params: RequestParams = {}
    ) =>
      this.request<MaterialGroupResponse, ProblemDetails>({
        path: `/api/MaterialGroupService/${country}/materialGroup/${materialGroupId}/title`,
        method: 'PUT',
        query: query,
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags MaterialGroupService
     * @name MaterialGroupServiceMaterialGroupUpdate
     * @request PUT:/api/MaterialGroupService/{country}/materialGroup/{materialGroupId}
     */,

    materialGroupServiceMaterialGroupUpdate: (
      country: string | null,
      materialGroupId: number,
      data: MaterialGroupUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<MaterialGroupResponse, ProblemDetails>({
        path: `/api/MaterialGroupService/${country}/materialGroup/${materialGroupId}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags MaterialGroupService
     * @name MaterialGroupServiceMaterialGroupDelete
     * @request DELETE:/api/MaterialGroupService/{country}/materialGroup/{materialGroupId}
     */,

    materialGroupServiceMaterialGroupDelete: (
      country: string | null,
      materialGroupId: number,
      params: RequestParams = {}
    ) =>
      this.request<boolean, ProblemDetails>({
        path: `/api/MaterialGroupService/${country}/materialGroup/${materialGroupId}`,
        method: 'DELETE',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags MaterialGroupService
     * @name MaterialGroupServiceMaterialGroupStorageDetail
     * @request GET:/api/MaterialGroupService/{country}/materialGroup/{materialGroupId}/storage
     */,

    materialGroupServiceMaterialGroupStorageDetail: (
      country: string | null,
      materialGroupId: number,
      params: RequestParams = {}
    ) =>
      this.request<StorageResponse, any>({
        path: `/api/MaterialGroupService/${country}/materialGroup/${materialGroupId}/storage`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags MaterialGroupService
     * @name MaterialGroupServiceMaterialGroupStorageUpdate
     * @request PUT:/api/MaterialGroupService/{country}/materialGroup/{materialGroupId}/storage
     */,

    materialGroupServiceMaterialGroupStorageUpdate: (
      country: string | null,
      materialGroupId: number,
      data: StorageRequest,
      params: RequestParams = {}
    ) =>
      this.request<StorageResponse, ProblemDetails>({
        path: `/api/MaterialGroupService/${country}/materialGroup/${materialGroupId}/storage`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags MaterialGroupService
     * @name MaterialGroupServiceSubstrateMaterialGroupDetail
     * @request GET:/api/MaterialGroupService/{country}/{materialGroupId}/SubstrateMaterialGroup
     */,

    materialGroupServiceSubstrateMaterialGroupDetail: (
      country: string | null,
      materialGroupId: number,
      params: RequestParams = {}
    ) =>
      this.request<SubstrateMaterialGroupResponse, void>({
        path: `/api/MaterialGroupService/${country}/${materialGroupId}/SubstrateMaterialGroup`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags MaterialGroupService
     * @name MaterialGroupServiceSubstrateMaterialGroupCreate
     * @request POST:/api/MaterialGroupService/{country}/{materialGroupId}/SubstrateMaterialGroup
     */,

    materialGroupServiceSubstrateMaterialGroupCreate: (
      country: string | null,
      materialGroupId: number,
      data: SubstrateMaterialGroupRequest,
      params: RequestParams = {}
    ) =>
      this.request<SubstrateMaterialGroupResponse, void>({
        path: `/api/MaterialGroupService/${country}/${materialGroupId}/SubstrateMaterialGroup`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags MaterialGroupService
     * @name MaterialGroupServiceDescriptionDetail
     * @request GET:/api/MaterialGroupService/{country}/{materialGroupId}/Description
     */,

    materialGroupServiceDescriptionDetail: (
      country: string | null,
      materialGroupId: number,
      params: RequestParams = {}
    ) =>
      this.request<DescriptionResponseModel, void>({
        path: `/api/MaterialGroupService/${country}/${materialGroupId}/Description`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags MaterialGroupService
     * @name MaterialGroupServiceDescriptionCreate
     * @request POST:/api/MaterialGroupService/{country}/{materialGroupId}/Description
     */,

    materialGroupServiceDescriptionCreate: (
      country: string | null,
      materialGroupId: number,
      data: DescriptionRequestModel,
      params: RequestParams = {}
    ) =>
      this.request<void, ProblemDetails | void>({
        path: `/api/MaterialGroupService/${country}/${materialGroupId}/Description`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      })
    /**
     * No description
     *
     * @tags MaterialGroupService
     * @name MaterialGroupServiceMaterialGroupPerfectSystemDetail
     * @request GET:/api/MaterialGroupService/{country}/materialGroup/{materialGroupId}/PerfectSystem
     */,

    materialGroupServiceMaterialGroupPerfectSystemDetail: (
      country: string | null,
      materialGroupId: number,
      params: RequestParams = {}
    ) =>
      this.request<PerfectSystemResponseModel, ProblemDetails>({
        path: `/api/MaterialGroupService/${country}/materialGroup/${materialGroupId}/PerfectSystem`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags MaterialGroupService
     * @name MaterialGroupServiceMaterialGroupPerfectSystemCreate
     * @request POST:/api/MaterialGroupService/{country}/materialGroup/{materialGroupId}/PerfectSystem
     */,

    materialGroupServiceMaterialGroupPerfectSystemCreate: (
      country: string | null,
      materialGroupId: number,
      data: PerfectSystemRequestModel,
      params: RequestParams = {}
    ) =>
      this.request<PerfectSystemResponseModel, ProblemDetails>({
        path: `/api/MaterialGroupService/${country}/materialGroup/${materialGroupId}/PerfectSystem`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags MaterialGroupService
     * @name MaterialGroupServiceMaterialGroupPrepareDetail
     * @request GET:/api/MaterialGroupService/{country}/materialGroup/{materialGroupId}/Prepare
     */,

    materialGroupServiceMaterialGroupPrepareDetail: (
      country: string | null,
      materialGroupId: number,
      params: RequestParams = {}
    ) =>
      this.request<PrepareResponseModel, ProblemDetails>({
        path: `/api/MaterialGroupService/${country}/materialGroup/${materialGroupId}/Prepare`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags MaterialGroupService
     * @name MaterialGroupServiceMaterialGroupPrepareCreate
     * @request POST:/api/MaterialGroupService/{country}/materialGroup/{materialGroupId}/Prepare
     */,

    materialGroupServiceMaterialGroupPrepareCreate: (
      country: string | null,
      materialGroupId: number,
      data: PrepareRequestModel,
      params: RequestParams = {}
    ) =>
      this.request<PrepareResponseModel, ProblemDetails>({
        path: `/api/MaterialGroupService/${country}/materialGroup/${materialGroupId}/Prepare`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags MaterialGroupService
     * @name MaterialGroupServiceProductHintMaterialGroupDetail
     * @request GET:/api/MaterialGroupService/{country}/{materialGroupId}/ProductHintMaterialGroup
     */,

    materialGroupServiceProductHintMaterialGroupDetail: (
      country: string | null,
      materialGroupId: number,
      params: RequestParams = {}
    ) =>
      this.request<ProductHintMaterialGroupResponse, void>({
        path: `/api/MaterialGroupService/${country}/${materialGroupId}/ProductHintMaterialGroup`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags MaterialGroupService
     * @name MaterialGroupServiceProductHintMaterialGroupCreate
     * @request POST:/api/MaterialGroupService/{country}/{materialGroupId}/ProductHintMaterialGroup
     */,

    materialGroupServiceProductHintMaterialGroupCreate: (
      country: string | null,
      materialGroupId: number,
      data: ProductHintMaterialGroupRequest,
      params: RequestParams = {}
    ) =>
      this.request<ProductHintMaterialGroupResponse, void>({
        path: `/api/MaterialGroupService/${country}/${materialGroupId}/ProductHintMaterialGroup`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags MaterialGroupService
     * @name MaterialGroupServiceMaterialGroupTechnicalDetailsDetail
     * @request GET:/api/MaterialGroupService/{country}/materialGroup/{materialGroupId}/TechnicalDetails
     */,

    materialGroupServiceMaterialGroupTechnicalDetailsDetail: (
      country: string | null,
      materialGroupId: number,
      params: RequestParams = {}
    ) =>
      this.request<TechnicalDetailsResponseModel, ProblemDetails>({
        path: `/api/MaterialGroupService/${country}/materialGroup/${materialGroupId}/TechnicalDetails`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags MaterialGroupService
     * @name MaterialGroupServiceMaterialGroupTechnicalDetailsCreate
     * @request POST:/api/MaterialGroupService/{country}/materialGroup/{materialGroupId}/TechnicalDetails
     */,

    materialGroupServiceMaterialGroupTechnicalDetailsCreate: (
      country: string | null,
      materialGroupId: number,
      data: TechnicalDetailsRequestModel,
      params: RequestParams = {}
    ) =>
      this.request<TechnicalDetailsResponseModel, ProblemDetails>({
        path: `/api/MaterialGroupService/${country}/materialGroup/${materialGroupId}/TechnicalDetails`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags MaterialGroupService
     * @name MaterialGroupServiceMaterialGroupWorkSafetyDetail
     * @request GET:/api/MaterialGroupService/{country}/materialGroup/{materialGroupId}/WorkSafety
     */,

    materialGroupServiceMaterialGroupWorkSafetyDetail: (
      country: string | null,
      materialGroupId: number,
      params: RequestParams = {}
    ) =>
      this.request<WorksafetyResponseModel, any>({
        path: `/api/MaterialGroupService/${country}/materialGroup/${materialGroupId}/WorkSafety`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags MaterialGroupService
     * @name MaterialGroupServiceMaterialGroupWorkSafetyCreate
     * @request POST:/api/MaterialGroupService/{country}/materialGroup/{materialGroupId}/WorkSafety
     */,

    materialGroupServiceMaterialGroupWorkSafetyCreate: (
      country: string | null,
      materialGroupId: number,
      data: WorksafetyRequestModel,
      params: RequestParams = {}
    ) =>
      this.request<WorksafetyResponseModel, any>({
        path: `/api/MaterialGroupService/${country}/materialGroup/${materialGroupId}/WorkSafety`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  pictogram = {
    /**
     * No description
     *
     * @tags Pictogram
     * @name PictogramMetadataDetail
     * @request GET:/api/Pictogram/{country}/metadata
     */
    pictogramMetadataDetail: (country: string | null, params: RequestParams = {}) =>
      this.request<PictogramImageResponse[], void>({
        path: `/api/Pictogram/${country}/metadata`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags Pictogram
     * @name PictogramImageDetail
     * @request GET:/api/Pictogram/{country}/image
     */,

    pictogramImageDetail: (country: string | null, query?: { fileName?: string | null }, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/Pictogram/${country}/image`,
        method: 'GET',
        query: query,
        ...params,
      })
    /**
     * No description
     *
     * @tags Pictogram
     * @name PictogramMaterialGroupDetail
     * @request GET:/api/Pictogram/{country}/materialGroup/{materialGroupId}
     */,

    pictogramMaterialGroupDetail: (country: string | null, materialGroupId: number, params: RequestParams = {}) =>
      this.request<PictogramResponse[], void>({
        path: `/api/Pictogram/${country}/materialGroup/${materialGroupId}`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags Pictogram
     * @name PictogramMaterialGroupCreate
     * @request POST:/api/Pictogram/{country}/materialGroup/{materialGroupId}
     */,

    pictogramMaterialGroupCreate: (
      country: string | null,
      materialGroupId: number,
      data: PictogramRequest[] | null,
      params: RequestParams = {}
    ) =>
      this.request<PictogramResponse[], void>({
        path: `/api/Pictogram/${country}/materialGroup/${materialGroupId}`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags Pictogram
     * @name PictogramPictogramsDetail
     * @request GET:/api/Pictogram/{country}/pictograms
     */,

    pictogramPictogramsDetail: (country: string | null, params: RequestParams = {}) =>
      this.request<PictogramResponseModel[], ProblemDetails>({
        path: `/api/Pictogram/${country}/pictograms`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags Pictogram
     * @name PictogramPictogramCreate
     * @request POST:/api/Pictogram/{country}/pictogram
     */,

    pictogramPictogramCreate: (
      country: string | null,
      data: { FullName?: string | null; File?: File | null },
      params: RequestParams = {}
    ) =>
      this.request<void, ProblemDetails>({
        path: `/api/Pictogram/${country}/pictogram`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        ...params,
      })
    /**
     * No description
     *
     * @tags Pictogram
     * @name PictogramPictogramDelete
     * @request DELETE:/api/Pictogram/{country}/pictogram/{fileName}
     */,

    pictogramPictogramDelete: (country: string | null, fileName: string | null, params: RequestParams = {}) =>
      this.request<boolean, ProblemDetails>({
        path: `/api/Pictogram/${country}/pictogram/${fileName}`,
        method: 'DELETE',
        format: 'json',
        ...params,
      }),
  };
  productService = {
    /**
     * No description
     *
     * @tags ProductService
     * @name ProductServiceDetail
     * @request GET:/api/ProductService/{country}
     */
    productServiceDetail: (country: string | null, params: RequestParams = {}) =>
      this.request<ProductGroupResponse[], any>({
        path: `/api/ProductService/${country}`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags ProductService
     * @name ProductServiceCreate
     * @request POST:/api/ProductService/{country}
     */,

    productServiceCreate: (country: string | null, data: ProductGroupCreateRequest, params: RequestParams = {}) =>
      this.request<ProductGroupResponse, ProblemDetails>({
        path: `/api/ProductService/${country}`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags ProductService
     * @name ProductServiceByDivisionIdDetail
     * @request GET:/api/ProductService/{country}/ByDivisionId/{divisionId}
     */,

    productServiceByDivisionIdDetail: (country: string | null, divisionId: number, params: RequestParams = {}) =>
      this.request<ProductGroupResponse[], any>({
        path: `/api/ProductService/${country}/ByDivisionId/${divisionId}`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags ProductService
     * @name ProductServiceGetByIdDetail
     * @request GET:/api/ProductService/{country}/GetById/{productId}
     */,

    productServiceGetByIdDetail: (country: string | null, productId: number, params: RequestParams = {}) =>
      this.request<ProductGroupResponse, any>({
        path: `/api/ProductService/${country}/GetById/${productId}`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags ProductService
     * @name ProductServiceProductGroupCountryUpdate
     * @request PUT:/api/ProductService/{country}/productGroupCountry
     */,

    productServiceProductGroupCountryUpdate: (
      country: string | null,
      data: ProductGroupCountryRequest,
      params: RequestParams = {}
    ) =>
      this.request<ProductGroupResponse, any>({
        path: `/api/ProductService/${country}/productGroupCountry`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags ProductService
     * @name ProductServiceProductGroupUpdate
     * @request PUT:/api/ProductService/{country}/productGroup/{productGroupId}
     */,

    productServiceProductGroupUpdate: (
      country: string | null,
      productGroupId: number,
      data: ProductGroupUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<ProductGroupResponse, ProblemDetails>({
        path: `/api/ProductService/${country}/productGroup/${productGroupId}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags ProductService
     * @name ProductServiceProductGroupDelete
     * @request DELETE:/api/ProductService/{country}/productGroup/{productGroupId}
     */,

    productServiceProductGroupDelete: (country: string | null, productGroupId: number, params: RequestParams = {}) =>
      this.request<boolean, ProblemDetails>({
        path: `/api/ProductService/${country}/productGroup/${productGroupId}`,
        method: 'DELETE',
        format: 'json',
        ...params,
      }),
  };
  quickLink = {
    /**
     * No description
     *
     * @tags QuickLink
     * @name QuickLinkDetail
     * @request GET:/api/QuickLink/{path}
     */
    quickLinkDetail: (path: string | null, params: RequestParams = {}) =>
      this.request<QuickLinkResponse, ProblemDetails>({
        path: `/api/QuickLink/${path}`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags QuickLink
     * @name QuickLinkList
     * @request GET:/api/QuickLink
     */,

    quickLinkList: (params: RequestParams = {}) =>
      this.request<QuickLinkResponse[], ProblemDetails>({
        path: `/api/QuickLink`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags QuickLink
     * @name QuickLinkCreate
     * @request POST:/api/QuickLink
     */,

    quickLinkCreate: (data: QuickLinkRequest, params: RequestParams = {}) =>
      this.request<QuickLinkResponse, ProblemDetails>({
        path: `/api/QuickLink`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags QuickLink
     * @name QuickLinkDelete
     * @request DELETE:/api/QuickLink/{id}
     */,

    quickLinkDelete: (id: number, params: RequestParams = {}) =>
      this.request<boolean, ProblemDetails>({
        path: `/api/QuickLink/${id}`,
        method: 'DELETE',
        format: 'json',
        ...params,
      }),
  };
  sealOfApprovalAndNorm = {
    /**
     * No description
     *
     * @tags SealOfApprovalAndNorm
     * @name SealOfApprovalAndNormImagesDetail
     * @request GET:/api/SealOfApprovalAndNorm/{country}/images
     */
    sealOfApprovalAndNormImagesDetail: (country: string | null, params: RequestParams = {}) =>
      this.request<SealOfApprovalImageResponse[], void>({
        path: `/api/SealOfApprovalAndNorm/${country}/images`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags SealOfApprovalAndNorm
     * @name SealOfApprovalAndNormImageDetail
     * @request GET:/api/SealOfApprovalAndNorm/{country}/image
     */,

    sealOfApprovalAndNormImageDetail: (
      country: string | null,
      query?: { fileName?: string | null },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/SealOfApprovalAndNorm/${country}/image`,
        method: 'GET',
        query: query,
        ...params,
      })
    /**
     * No description
     *
     * @tags SealOfApprovalAndNorm
     * @name SealOfApprovalAndNormMaterialGroupCreate
     * @request POST:/api/SealOfApprovalAndNorm/{country}/materialGroup/{materialGroupId}
     */,

    sealOfApprovalAndNormMaterialGroupCreate: (
      country: string | null,
      materialGroupId: number,
      data: SealOfApprovalAndNormMaterialGroupRequest,
      params: RequestParams = {}
    ) =>
      this.request<boolean, void>({
        path: `/api/SealOfApprovalAndNorm/${country}/materialGroup/${materialGroupId}`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags SealOfApprovalAndNorm
     * @name SealOfApprovalAndNormMaterialGroupDetail
     * @request GET:/api/SealOfApprovalAndNorm/{country}/materialGroup/{materialGroupId}
     */,

    sealOfApprovalAndNormMaterialGroupDetail: (
      country: string | null,
      materialGroupId: number,
      params: RequestParams = {}
    ) =>
      this.request<SealOfApprovalAndNormMaterialGroupResponse, void>({
        path: `/api/SealOfApprovalAndNorm/${country}/materialGroup/${materialGroupId}`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags SealOfApprovalAndNorm
     * @name SealOfApprovalAndNormSealOfApprovalCreate
     * @request POST:/api/SealOfApprovalAndNorm/{country}/SealOfApproval
     */,

    sealOfApprovalAndNormSealOfApprovalCreate: (
      country: string | null,
      data: { FullName?: string | null; Category?: string | null; FileName?: string | null; File?: File | null },
      params: RequestParams = {}
    ) =>
      this.request<boolean, ProblemDetails>({
        path: `/api/SealOfApprovalAndNorm/${country}/SealOfApproval`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags SealOfApprovalAndNorm
     * @name SealOfApprovalAndNormSealOfApprovalUpdate
     * @request PUT:/api/SealOfApprovalAndNorm/{country}/SealOfApproval
     */,

    sealOfApprovalAndNormSealOfApprovalUpdate: (
      country: string | null,
      query?: { fileName?: string | null; oldCategoryName?: string | null; newCategoryName?: string | null },
      params: RequestParams = {}
    ) =>
      this.request<void, ProblemDetails>({
        path: `/api/SealOfApprovalAndNorm/${country}/SealOfApproval`,
        method: 'PUT',
        query: query,
        ...params,
      })
    /**
     * No description
     *
     * @tags SealOfApprovalAndNorm
     * @name SealOfApprovalAndNormSealOfApprovalDelete
     * @request DELETE:/api/SealOfApprovalAndNorm/{country}/SealOfApproval/{path}/{category}
     */,

    sealOfApprovalAndNormSealOfApprovalDelete: (
      country: string | null,
      path: string | null,
      category: string | null,
      params: RequestParams = {}
    ) =>
      this.request<boolean, ProblemDetails>({
        path: `/api/SealOfApprovalAndNorm/${country}/SealOfApproval/${path}/${category}`,
        method: 'DELETE',
        format: 'json',
        ...params,
      }),
  };
  search = {
    /**
     * No description
     *
     * @tags Search
     * @name SearchDetail
     * @request GET:/api/search/{searchText}/{countryCode}
     */
    searchDetail: (searchText: string | null, countryCode: string | null, params: RequestParams = {}) =>
      this.request<SearchResponse[], ProblemDetails>({
        path: `/api/search/${searchText}/${countryCode}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
  technicalDatasheetAttachment = {
    /**
     * No description
     *
     * @tags TechnicalDatasheetAttachment
     * @name TechnicalDatasheetAttachmentMaterialGroupDetail
     * @request GET:/api/TechnicalDatasheetAttachment/{country}/materialGroup/{materialGroupId}
     */
    technicalDatasheetAttachmentMaterialGroupDetail: (
      country: string | null,
      materialGroupId: number,
      params: RequestParams = {}
    ) =>
      this.request<TechnicalDatasheetAttachmentResponse[], void>({
        path: `/api/TechnicalDatasheetAttachment/${country}/materialGroup/${materialGroupId}`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags TechnicalDatasheetAttachment
     * @name TechnicalDatasheetAttachmentMaterialGroupCreate
     * @request POST:/api/TechnicalDatasheetAttachment/{country}/materialGroup/{materialGroupId}
     */,

    technicalDatasheetAttachmentMaterialGroupCreate: (
      country: string | null,
      materialGroupId: number,
      data: { files?: File[] | null },
      params: RequestParams = {}
    ) =>
      this.request<TechnicalDatasheetAttachmentResponse[], ProblemDetails | void>({
        path: `/api/TechnicalDatasheetAttachment/${country}/materialGroup/${materialGroupId}`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags TechnicalDatasheetAttachment
     * @name TechnicalDatasheetAttachmentDetail
     * @request GET:/api/TechnicalDatasheetAttachment/{country}/{technicalDatasheetAttachmentId}
     */,

    technicalDatasheetAttachmentDetail: (
      country: string | null,
      technicalDatasheetAttachmentId: number,
      params: RequestParams = {}
    ) =>
      this.request<TechnicalDatasheetAttachmentResponse, void>({
        path: `/api/TechnicalDatasheetAttachment/${country}/${technicalDatasheetAttachmentId}`,
        method: 'GET',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags TechnicalDatasheetAttachment
     * @name TechnicalDatasheetAttachmentDelete
     * @request DELETE:/api/TechnicalDatasheetAttachment/{country}/{technicalDatasheetAttachmentId}
     */,

    technicalDatasheetAttachmentDelete: (
      country: string | null,
      technicalDatasheetAttachmentId: number,
      params: RequestParams = {}
    ) =>
      this.request<void, ProblemDetails | void>({
        path: `/api/TechnicalDatasheetAttachment/${country}/${technicalDatasheetAttachmentId}`,
        method: 'DELETE',
        ...params,
      })
    /**
     * No description
     *
     * @tags TechnicalDatasheetAttachment
     * @name TechnicalDatasheetAttachmentFileDetail
     * @request GET:/api/TechnicalDatasheetAttachment/{country}/{technicalDatasheetAttachmentId}/file
     */,

    technicalDatasheetAttachmentFileDetail: (
      country: string | null,
      technicalDatasheetAttachmentId: number,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/TechnicalDatasheetAttachment/${country}/${technicalDatasheetAttachmentId}/file`,
        method: 'GET',
        ...params,
      }),
  };
  tmbGenerator = {
    /**
     * No description
     *
     * @tags TMBGenerator
     * @name TmbGeneratorMaterialgroupGenerateworddocumentCreate
     * @request POST:/api/TMBGenerator/{country}/materialgroup/{materialGroupId}/generateworddocument
     */
    tmbGeneratorMaterialgroupGenerateworddocumentCreate: (
      country: string | null,
      materialGroupId: number,
      params: RequestParams = {}
    ) =>
      this.request<DocumentResponse, any>({
        path: `/api/TMBGenerator/${country}/materialgroup/${materialGroupId}/generateworddocument`,
        method: 'POST',
        format: 'json',
        ...params,
      })
    /**
     * No description
     *
     * @tags TMBGenerator
     * @name TmbGeneratorMaterialgroupGenerateexternallayoutworddocumentCreate
     * @request POST:/api/TMBGenerator/{country}/materialgroup/{materialGroupId}/generateexternallayoutworddocument
     */,

    tmbGeneratorMaterialgroupGenerateexternallayoutworddocumentCreate: (
      country: string | null,
      materialGroupId: number,
      params: RequestParams = {}
    ) =>
      this.request<DocumentResponse, any>({
        path: `/api/TMBGenerator/${country}/materialgroup/${materialGroupId}/generateexternallayoutworddocument`,
        method: 'POST',
        format: 'json',
        ...params,
      }),
  };
}

/**
 * React Hook to use.
 */

import * as React from 'react';
import { CurrentCultureStateContext } from '../contexts';

export function useMurexinPimContextApi() {
  const { currentCulture } = React.useContext(CurrentCultureStateContext);
  const [api] = React.useState(
    new MurexinPimContextApi({
      baseUrl: `${window.apiUrl}`,
      securityWorker: async () => {
        let headers: object = {
          'Accept-Language': currentCulture,
        };

        return { headers } as RequestParams;
      },
    })
  );

  return api;
}

import React from 'react';
import { Divider, makeStyles } from '@material-ui/core';
import { CommentLoader } from './CommentsLoader';
import { useTranslation } from 'react-i18next';

export function CommentBox(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.base}>
      <span className={classes.title}>{t('Kommentare')}</span>
      <Divider component='div' variant='inset' className={classes.divider} />
      <CommentLoader />
    </div>
  );
}

const useStyles = makeStyles(() => ({
  base: {
    paddingTop: '24px',
    paddingBottom: '16px',
  },
  title: {
    fontFamily: 'Roboto',
    fontSize: '24px',
    fontWeight: 'lighter',
    lineHeight: '29px',
    letterSpacing: '0em',
    textAlign: 'left',
  },
  divider: {
    height: '2px',
    backgroundColor: 'black',
    padding: '0px',
    width: '100%',
    marginLeft: '0px',
  },
}));

import React, { ReactElement, useContext } from 'react';
import { Card, createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import RadioGroup from '@mui/material/RadioGroup';
import { StorageRequest, StorageType, CountryPermission, useMurexinPimContextApi } from '../../../../api';
import { useAsync } from 'react-async-hook';
import { useParams } from 'react-router-dom';
import { CircleLoader } from '../../../../utils/CircleLoader';
import { Form, Formik } from 'formik';
import { NotificationContext } from '../../../../contexts/NotificationContext';
import PIMButton from '../../../../shared/Components/PIMButton';
import PIMTextField from '../../../../shared/Components/PIMTextField';
import PIMFormikTextField from '../../../../shared/Components/PIMFormikTextField';
import { IRouterParams } from '../../../../routes';
import PIMFormikRadio from '../../../../shared/Components/PIMFormikRadio';
import PIMInformationBox from '../../../../shared/Components/PIMInformationBox';
import { AuthorizationContext } from '../../../../auth';
import { useTranslation } from 'react-i18next';
import useFormikSaveAndClose from '../../../../utils/customHooks/useFormikSaveAndClose';
import { ProductManagementProps } from '../../ProductManagement/ProductManagement';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    Card: {
      padding: theme.spacing(2),
    },
    ActionButtonRow: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'flex-end',
      paddingBottom: '32px',
      marginTop: '-80px',
      '& .makeStyles-alignRight2-168': {
        marginRight: '0px',
      },
    },
    topButtons: {
      marginLeft: '24px',
    },
    TextField: {
      '&.MuiTextField-root': {
        marginTop: '8px',
        marginBottom: '8px',
      },
    },
    StorageTypeRow: {
      display: 'flex',
    },
    StorageTypeLimited: {
      marginRight: theme.spacing(2),
    },
    Radio: {
      marginTop: '8px',
      marginBottom: '8px',
    },
    InformationBox: {
      marginTop: '8px',
    },
  })
);

interface IProps {
  erpImport?: boolean;
}

function Storage(props: IProps & ProductManagementProps): ReactElement {
  const classes = useStyles();
  const { country, materialId } = useParams<IRouterParams>();
  const { onDirtyChanged, cleanUpForm, saveOnCloseRef, erpImport } = props;
  const { hasCountryPermission } = useContext(AuthorizationContext);
  const isAuthorizedToWrite = hasCountryPermission(country, CountryPermission.Write);
  const api = useMurexinPimContextApi();
  const notification = useContext(NotificationContext);
  const { t } = useTranslation();

  const formikRef = useFormikSaveAndClose<StorageRequest>(saveOnCloseRef);

  const storageData = useAsync(
    () => api.materialGroupService.materialGroupServiceMaterialGroupStorageDetail(country, Number(materialId)),
    [country, materialId]
  );

  async function updateData(data: StorageRequest) {
    try {
      await api.materialGroupService.materialGroupServiceMaterialGroupStorageUpdate(country, Number(materialId), data);
      cleanUpForm();
      void storageData.execute();
      notification.success(t('Speichern erfolgreich'));
    } catch (error) {
      notification.error(t('Speichern fehlgeschlagen'));
      throw error;
    }
  }

  if (storageData.loading) {
    return <CircleLoader open={true} />;
  } else if (storageData.result) {
    return (
      <>
        <Formik
          initialValues={storageData.result.data ?? {}}
          innerRef={formikRef}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            void updateData(values);
          }}
          enableReinitialize>
          {({ isSubmitting, dirty, handleSubmit, handleChange, values, resetForm }) => (
            <Form
              onChange={(e) => {
                onDirtyChanged();
                handleChange(e);
              }}>
              <Grid container spacing={0}>
                {isAuthorizedToWrite && !erpImport && (
                  <Grid item xs={8} className={classes.ActionButtonRow}>
                    <PIMButton
                      className={classes.topButtons}
                      icon='close'
                      disabled={isSubmitting || !dirty}
                      onClick={() => {
                        notification.info(t('Daten zurückgesetzt'));
                        resetForm(storageData.result);
                        cleanUpForm();
                      }}>
                      {t('Abbrechen')}
                    </PIMButton>
                    <PIMButton
                      color='primary'
                      icon='save'
                      className={classes.topButtons}
                      busy={isSubmitting}
                      disabled={!dirty}
                      onClick={() => handleSubmit()}>
                      {t('Speichern')}
                    </PIMButton>
                  </Grid>
                )}

                <Grid item xs={8}>
                  <Card className={classes.Card}>
                    <PIMTextField
                      className={classes.TextField}
                      fullWidth
                      label={t('Lager Bedingungen')}
                      value={t('LagerbedingungenInhalt')}
                      disabled
                    />
                    <RadioGroup name='storageType' onChange={handleChange}>
                      <Grid item xs={12} className={classes.StorageTypeRow}>
                        <Grid item xs={6} className={classes.StorageTypeLimited}>
                          <PIMFormikRadio
                            value={StorageType.Limited}
                            label={t('Begrenzt haltbar')}
                            disabled={erpImport}
                            checked={values.storageType === StorageType.Limited}
                          />
                          <PIMFormikTextField
                            className={classes.TextField}
                            fullWidth
                            label={t('Tage')}
                            name='days'
                            type='number'
                            InputProps={{
                              inputProps: {
                                min: 0,
                              },
                            }}
                            disabled={values.storageType === StorageType.Unlimited || erpImport}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <PIMFormikRadio
                            className={classes.Radio}
                            value={StorageType.Unlimited}
                            label={t('Unbegrenzt haltbar')}
                            disabled={erpImport}
                            checked={values.storageType === StorageType.Unlimited}
                          />
                        </Grid>
                      </Grid>
                    </RadioGroup>
                    {erpImport && (
                      <PIMInformationBox className={classes.InformationBox}>
                        <span>
                          {t('InfoAusERPTeil1')}
                          <br />
                          {t('InfoAusERPTeil2')}
                        </span>
                      </PIMInformationBox>
                    )}
                  </Card>
                </Grid>
                <Grid item xs={4} />
              </Grid>
            </Form>
          )}
        </Formik>
      </>
    );
  } else {
    return <div>Error!</div>;
  }
}

export default Storage;

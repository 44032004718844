import * as React from 'react';
import * as ReactDOM from 'react-dom';
import 'react-placeholder/lib/reactPlaceholder.css';
import App from './App';
import { History } from 'history';
import { AccountInfo } from '@azure/msal-browser';

import './i18n/i18n';

declare global {
  interface IGetTokenResponse {
    id_token: string;
    access_token: string;
  }

  type GetTokenType = () => Promise<IGetTokenResponse>;

  interface Window {
    renderPuma: (
      containerId: string,
      apiUrl: string,
      history: History,
      isAuthenticated: boolean,
      user: AccountInfo,
      getToken: GetTokenType
    ) => void;
    unmountPuma: (containerId: string) => void;
    apiUrl: string;
  }
}

window.renderPuma = (containerId, apiUrl) => {
  window.apiUrl = apiUrl;
  ReactDOM.render(<App />, document.getElementById(containerId));
};

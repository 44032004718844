import React from 'react';

interface IProps {
  className?: string;
}

export function TrashIcon(props: IProps): JSX.Element {
  const { className } = props;

  return (
    <svg
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M18.177 23.25H7.677C7.27918 23.25 6.89765 23.092 6.61634 22.8107C6.33504 22.5294 6.177 22.1478 6.177 21.75V8.25H19.677V21.75C19.677 22.1478 19.519 22.5294 19.2377 22.8107C18.9564 23.092 18.5748 23.25 18.177 23.25Z'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M10.677 18.75V12.75' stroke='black' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M15.177 18.75V12.75' stroke='black' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M2.427 6.21202L21.501 2.15802'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.541 0.783026L9.14099 1.71803C8.94786 1.75878 8.76467 1.83725 8.60193 1.94893C8.43918 2.06062 8.30008 2.20332 8.1926 2.36887C8.08511 2.53442 8.01136 2.71955 7.97556 2.91366C7.93976 3.10776 7.94263 3.30703 7.98399 3.50003L8.29999 4.96503L15.636 3.40503L15.32 1.93803C15.2372 1.54898 15.0033 1.20874 14.6697 0.992148C14.336 0.775552 13.9301 0.70033 13.541 0.783026V0.783026Z'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { createStyles, makeStyles, Grid } from '@material-ui/core';
import { NotificationContext } from '../../../../contexts/NotificationContext';
import { CountryPermission, PictogramImageResponse, PictogramResponse, useMurexinPimContextApi } from '../../../../api';
import { CircleLoader } from '../../../../utils/CircleLoader';
import { PictogramSidebar } from './PictogramSidebar';
import { FieldArray, Form, Formik, FieldArrayRenderProps } from 'formik';
import { ISortInformation, SortImageListDropDown } from '../../../../layouts/CustomImageList/SortImageListDropDown';
import PIMButton from '../../../../shared/Components/PIMButton';
import _ from 'lodash';
import { useAsync } from 'react-async-hook';
import PictogramAttributes from './PictogramAttributes';
import { IRouterParams } from '../../../../routes/IRouterParams';
import { AuthorizationContext } from '../../../../auth';
import { useTranslation } from 'react-i18next';
import { ProductManagementProps } from '../../ProductManagement/ProductManagement';
import useFormikSaveAndClose from '../../../../utils/customHooks/useFormikSaveAndClose';
import PIMTextField from '../../../../shared/Components/PIMTextField';

interface IProps {
  countrySelectionComponent: React.ReactNode;
  translationCountryCode: string;
}

export interface Pictogram {
  fileName: string;
  isUsed: boolean;

  createdAt?: Date;
  createdBy?: string;

  updatedAt?: Date;
  updatedBy?: string;
}

interface IFormikPictograms {
  usedPictograms: Pictogram[];
}

const useStyles = makeStyles(() =>
  createStyles({
    Form: {
      height: '100%',
    },
    Container: {
      height: '100%',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      margin: '0px 0px 0px 0px',
    },
    FilterRow: {
      display: 'flex !important',
      flexDirection: 'row',
      margin: '0px 8px 8px 0px',
    },
    ActionButtonRow: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'flex-end',
      paddingBottom: '16px',
      marginTop: '-80px',
      marginRight: '16px',
    },
    ActionButton: {
      marginLeft: '24px',
    },
    Filter: {
      width: 'calc(100% - 8px)',
      margin: '16px 0px 0px 0px',
    },
    Sidebar: {
      minHeight: '100%',
      marginTop: '-231px',
      zIndex: 1101,
      backgroundColor: 'white',
    },
    Dropdown: {
      marginRight: '16px',
      '& .MuiFilledInput-root': {
        margin: '0px 0px 0px 0px',
      },
    },
  })
);

export function Pictograms(props: IProps & ProductManagementProps): JSX.Element {
  const classes = useStyles();
  const notification = useContext(NotificationContext);
  const { country, materialId } = useParams<IRouterParams>();
  const { countrySelectionComponent, translationCountryCode, saveOnCloseRef } = props;
  const materialGroupId = Number(materialId);
  const api = useMurexinPimContextApi();
  const [selectedItem, setSelectedItem] = useState<Pictogram | undefined>(undefined);
  const [searchText, setSearchText] = useState<string>('');
  const { t } = useTranslation();
  const { hasCountryPermission } = useContext(AuthorizationContext);
  const isAuthorizedToWrite = hasCountryPermission(country, CountryPermission.Write);
  const [sortDirection, setSortDirection] = useState<ISortInformation>({
    direction: 'asc',
    column: 'fileName',
  });
  const showTranslationPanel = !!translationCountryCode;
  const hasSelectedItem = selectedItem !== undefined;

  const formikRef = useFormikSaveAndClose<IFormikPictograms>(saveOnCloseRef);

  const pictogramImageResponse = useAsync(async () => {
    if (!!country) {
      const response = await api.pictogram.pictogramMetadataDetail(country);
      return response.data ?? [];
    }
    return [];
  }, [country]);

  const pictogramImageResponseTranslation = useAsync(async () => {
    if (!!translationCountryCode) {
      const response = await api.pictogram.pictogramMetadataDetail(translationCountryCode);
      return response.data ?? [];
    }
    return [];
  }, [translationCountryCode]);

  const pictogramsResponse = useAsync(async () => {
    if (!!country) {
      const response = await api.pictogram.pictogramMaterialGroupDetail(country, materialGroupId);
      return response.data ?? [];
    }
    return [];
  }, [country, materialGroupId]);

  const pictogramsTranslationResponse = useAsync(async () => {
    if (!!translationCountryCode) {
      const response = await api.pictogram.pictogramMaterialGroupDetail(translationCountryCode, materialGroupId);
      return response.data ?? [];
    }
    return [];
  }, [translationCountryCode, materialGroupId]);

  async function updatePictogramMaterialGroups(values: IFormikPictograms) {
    const pictogramRequest =
      values.usedPictograms?.map((pictogramResponse: PictogramResponse) => ({
        fileName: pictogramResponse.fileName,
      })) ?? [];

    try {
      await api.pictogram.pictogramMaterialGroupCreate(country, materialGroupId, pictogramRequest);
      setSelectedItem(undefined);
      setSearchText('');
      await pictogramImageResponse.execute();
      await pictogramsResponse.execute();
      props.cleanUpForm();
      notification.success(t('Speichern erfolgreich'));
    } catch (error) {
      notification.error(t('Speichern fehlgeschlagen'));
      throw error;
    }
  }

  const getTranslationCountryPictograms = (formikPictograms: IFormikPictograms): Pictogram[] => {
    const usedPictogramImages = formikPictograms.usedPictograms;

    const usedPictograms = pictogramImageResponseTranslation.result
      ?.filter((pictogramImage) => usedPictogramImages.some((s) => s.fileName === pictogramImage.fileName))
      .map((pictogramImage) => ({ ...pictogramImage, isUsed: true } as Pictogram));

    return usedPictograms ?? [];
  };

  const getOriginCountryPictograms = (formikPictograms: IFormikPictograms): Pictogram[] => {
    const usedPictogramImages = formikPictograms.usedPictograms;

    const allPictograms =
      pictogramImageResponse.result?.map((pictogramImage: PictogramImageResponse) => {
        const isUsed = usedPictogramImages?.some((s) => s.fileName === pictogramImage.fileName);

        return {
          ...pictogramImage,
          isUsed,
        } as Pictogram;
      }) ?? [];

    const pictogramsFilteredBySearchText = getPictogramsContainingSearchTextInFileName(allPictograms);
    const orderedPictograms = getOrderedPictograms(pictogramsFilteredBySearchText);

    return orderedPictograms;
  };

  const getPictogramsContainingSearchTextInFileName = (allPictograms: Pictogram[]): Pictogram[] => {
    const filterLowerCase = searchText.toLocaleLowerCase();
    return allPictograms.filter((pictogram) => pictogram.fileName?.toLocaleLowerCase().includes(filterLowerCase));
  };

  const getOrderedPictograms = (pictograms: Pictogram[]) => {
    const orderedPictograms = _.orderBy(pictograms, [sortDirection.column], [sortDirection.direction]);
    return orderedPictograms;
  };

  const handleClickPictogram = (pictogram: Pictogram) => {
    if (selectedItem !== undefined && selectedItem.fileName === pictogram.fileName) {
      setSelectedItem(undefined);
    } else {
      setSelectedItem(pictogram);
    }
  };

  const handleChangePictogram = (formikArrayHelpers: FieldArrayRenderProps, pictogram: Pictogram) => {
    const pictogramValues = (formikArrayHelpers.form.values as IFormikPictograms).usedPictograms;

    if (pictogramValues) {
      const index = pictogramValues.findIndex((p: PictogramResponse) => p.fileName === pictogram.fileName);

      if (pictogram.isUsed) {
        if (index < 0) {
          formikArrayHelpers.push(pictogram);
          props.onDirtyChanged();
        }
      } else {
        if (index >= 0) {
          formikArrayHelpers.remove(index);
          props.onDirtyChanged();
        }
      }
    }
  };

  const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchText(event.target.value);
  };

  const handleChangeSortDirection = (value: ISortInformation) => {
    setSortDirection(value);
  };

  const preventSubmitOnEnter = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  return (
    <>
      <CircleLoader
        open={
          pictogramImageResponse.loading ||
          pictogramsResponse.loading ||
          pictogramImageResponseTranslation.loading ||
          pictogramsTranslationResponse.loading
        }
      />
      <Formik
        enableReinitialize={!pictogramImageResponse.loading && !pictogramsResponse.loading}
        innerRef={formikRef}
        initialValues={{ usedPictograms: pictogramsResponse.result ?? [] } as IFormikPictograms}
        onSubmit={updatePictogramMaterialGroups}>
        {(formik) => (
          <Form className={classes.Form} onKeyDown={preventSubmitOnEnter}>
            <FieldArray
              name='usedPictograms'
              render={(arrayHelpers) => (
                <Grid container className={classes.Container}>
                  <Grid container item xs={12}>
                    <Grid item xs={showTranslationPanel && !hasSelectedItem ? 12 : 8}>
                      {isAuthorizedToWrite && (
                        <Grid item xs={12} className={classes.ActionButtonRow}>
                          {countrySelectionComponent}
                          <PIMButton
                            className={classes.ActionButton}
                            icon='close'
                            disabled={
                              formik.isSubmitting ||
                              !formik.dirty ||
                              pictogramImageResponse.loading ||
                              pictogramsResponse.loading
                            }
                            onClick={() => {
                              notification.info(t('Daten zurückgesetzt'));
                              formik.handleReset();
                              setSelectedItem(undefined);
                            }}>
                            {t('Abbrechen')}
                          </PIMButton>
                          <PIMButton
                            color='primary'
                            icon='save'
                            className={classes.ActionButton}
                            busy={formik.isSubmitting}
                            disabled={!formik.dirty || pictogramImageResponse.loading || pictogramsResponse.loading}
                            type='submit'>
                            {t('Speichern')}
                          </PIMButton>
                        </Grid>
                      )}
                      <Grid
                        item
                        xs={showTranslationPanel && !hasSelectedItem ? 6 : 12}
                        container
                        className={classes.FilterRow}>
                        <Grid item xs={8}>
                          <PIMTextField label={t('Filter')} onChange={handleChangeFilter} className={classes.Filter} />
                        </Grid>
                        <Grid item xs={4}>
                          <SortImageListDropDown
                            className={classes.Dropdown}
                            possibleColumns={['fileName', 'fileSize', 'updatedAt', 'updatedBy']}
                            currentSortDirection={sortDirection}
                            selectSortDirection={handleChangeSortDirection}
                          />
                        </Grid>
                      </Grid>
                      <Grid container item>
                        <Grid item xs={showTranslationPanel && !hasSelectedItem ? 6 : 12}>
                          <PictogramAttributes
                            countryCode={country}
                            handleClickPictogram={handleClickPictogram}
                            pictograms={getOriginCountryPictograms(formik.values)}
                          />
                        </Grid>
                        {!!showTranslationPanel && !hasSelectedItem && (
                          <Grid item xs={6}>
                            <Formik
                              enableReinitialize={
                                !pictogramsTranslationResponse.loading && !pictogramImageResponseTranslation.loading
                              }
                              initialValues={
                                {
                                  usedPictograms: pictogramsTranslationResponse.result ?? [],
                                } as IFormikPictograms
                              }
                              onSubmit={() => {
                                return;
                              }}>
                              {(innerFormik) => (
                                <PictogramAttributes
                                  readonly
                                  countryCode={translationCountryCode}
                                  pictograms={getTranslationCountryPictograms(innerFormik.values)}
                                />
                              )}
                            </Formik>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    {hasSelectedItem && (
                      <Grid item xs={4} className={classes.Sidebar}>
                        <PictogramSidebar
                          authorizedToWrite={isAuthorizedToWrite}
                          pictogram={selectedItem}
                          onChange={(pictogram: Pictogram) => handleChangePictogram(arrayHelpers, pictogram)}
                          onCloseSidebar={() => setSelectedItem(undefined)}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}
            />
          </Form>
        )}
      </Formik>
    </>
  );
}

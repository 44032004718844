import React from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import './App.css';
import { AuthorizationProvider, Login } from './auth';
import { CurrentCultureStateProvider } from './contexts';
import { PIMLayout } from './layouts';
import { SnackbarProvider } from 'notistack';
import { HomeRoute } from './features/HomePage/HomeRoute';
import { AdminRoutes } from './features/Admin/AdminRoutes';
import { NotificationProvider } from './contexts/NotificationContext';
import { BreadCrumbProvider } from './layouts/BreadCrumbs/BreadCrumbContext';
import { BrowserRouter } from 'react-router-dom';

function App(): JSX.Element {
  return (
    <MuiThemeProvider theme={theme}>
      <Login>
        <CurrentCultureStateProvider>
          {() => (
            <NotificationProvider>
              <AuthorizationProvider>
                <BreadCrumbProvider>
                  <SnackbarProvider
                    maxSnack={3}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    preventDuplicate>
                    <BrowserRouter>
                      <PIMLayout>
                        <AdminRoutes />
                        <HomeRoute />
                      </PIMLayout>
                    </BrowserRouter>
                  </SnackbarProvider>
                </BreadCrumbProvider>
              </AuthorizationProvider>
            </NotificationProvider>
          )}
        </CurrentCultureStateProvider>
      </Login>
    </MuiThemeProvider>
  );
}

const theme = createMuiTheme({
  typography: {
    fontSize: 12.25,
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    h1: {
      fontSize: '36px',
      fontWeight: 400,
      fontStyle: 'normal',
      lineHeight: '120%',
    },
    h2: {
      fontSize: '28px',
      fontWeight: 400,
      fontStyle: 'normal',
      lineHeight: '120%',
    },
    h3: {
      fontSize: '20px',
      fontWeight: 400,
      fontStyle: 'normal',
      lineHeight: '120%',
    },
    h4: {
      fontSize: '18px',
      fontWeight: 400,
      fontStyle: 'normal',
      lineHeight: '120%',
    },
  },
  palette: {
    secondary: {
      main: '#e8e8e8',
      contrastText: '#000000',
    },
    primary: {
      main: '#FFDD00',
      contrastText: '#000000',
    },
    background: {
      default: '#f8f8f8',
    },
    warning: {
      main: '#FC7A57',
      contrastText: '#000000',
    },
    error: {
      main: '#FC7A57',
      contrastText: '#000000',
    },
    success: {
      main: '#A5D200',
      contrastText: '#000000',
    },
    info: {
      main: '#0A9DD5',
      contrastText: '#000000',
    },
  },
  overrides: {
    MuiTextField: {
      root: {
        fontSize: 17,
      },
    },
    MuiTableCell: {
      head: {
        whiteSpace: 'nowrap',
      },
    },
    MuiButton: {
      contained: {
        boxShadow: 'none',
        textTransform: 'none',
      },
      outlined: {
        textTransform: 'none',
      },
      text: {
        textTransform: 'none',
      },
    },
    MuiDrawer: {
      paper: {
        bottom: 0,
        top: 0,
        height: 'auto',
        borderRadius: 4,
        margin: 16,
      },
    },
  },
  shape: {
    borderRadius: 2,
  },
});

export default App;

import React from 'react';

interface IProps {
  className?: string;
  strokeColor?: string;
}

export function ErrorIcon(props: IProps): JSX.Element {
  const { className, strokeColor } = props;

  return (
    <svg
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M22.25 21C22.8023 21 23.25 20.6753 23.25 20L12 1L0.75 20C0.75 20.5 1.19772 21 1.75 21H22.25Z'
        stroke={strokeColor ?? 'black'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15 12L9 18'
        stroke={strokeColor ?? 'black'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9 12L15 18'
        stroke={strokeColor ?? 'black'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

import React, { ReactElement } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Card, Theme } from '@material-ui/core';
import PIMFormikTextField from '../../../../shared/Components/PIMFormikTextField';
import PIMTextField from '../../../../shared/Components/PIMTextField';
import { GeneralHintResponse } from '../../../../api';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    TextField: {
      '&.MuiTextField-root': {
        marginTop: '8px',
        marginBottom: '8px',
      },
      '& .MuiInputBase-input.Mui-disabled': {
        color: 'black',
      },
      '& .MuiFilledInput-underline.Mui-disabled:before': {
        borderBottomStyle: 'none',
      },
    },
    card: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    select: {
      '& .MuiFormControl-root': {
        width: 'calc(100% - 48px)',
      },
    },
    linkButton: {
      marginTop: '20px',
    },
    makeHidden: {
      visibility: 'hidden',
    },
  })
);

interface IProps {
  readOnly?: boolean;
  generalHint?: GeneralHintResponse;
}

export function WorkSafetyAttributes(props: IProps): ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();
  const { readOnly, generalHint } = props;
  const generalHintValue = generalHint?.content ?? t('Hinweis nicht gesetzt');

  return (
    <>
      <Card className={classes.card}>
        <PIMFormikTextField
          label={t('Arbeitssicherheitsinformation')}
          name='workSafetyInformation'
          className={classes.TextField}
          fullWidth
          multiline
          disabled={readOnly}
        />
        <PIMTextField
          label={t('Allgemeine Hinweise')}
          className={classes.TextField}
          fullWidth
          multiline
          disabled
          value={generalHintValue}
        />
      </Card>
    </>
  );
}

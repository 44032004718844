import React from 'react';
import { Button, makeStyles, Theme } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { createStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    saveButton: {
      alignItems: 'flex-start',
      padding: '19px 24px',
      position: 'inherit',
      width: '172px',
      height: '62px',
      left: '817px',
      top: '195px',
      background: theme.palette.primary.main,
      borderRadius: '4px',
      marginLeft: '10px',
    },
    alignRight: {
      marginLeft: 'auto',
      marginRight: '15px',
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    displayed: {
      display: 'contents',
    },
  })
);

const BootstrapButtonSave = withStyles({
  root: {
    backgroundColor: '#FFDD00',
    fontSize: '18px',
    lineHeight: '24px',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      backgroundColor: '#FFDD00',
      borderColor: '#FFDD00',
      boxShadow: 'none',
    },
  },
})(Button);

export function SaveButton(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.displayed}>
      <BootstrapButtonSave
        variant='contained'
        endIcon={<SaveIcon />}
        size='large'
        color='primary'
        className={classes.saveButton}
        type='submit'>
        {t('Speichern')}
      </BootstrapButtonSave>
    </div>
  );
}

export function SaveIcon(): JSX.Element {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M21.75 23.25H2.25C1.85218 23.25 1.47064 23.092 1.18934 22.8107C0.908035 22.5294 0.75 22.1478 0.75 21.75V7.243C0.75017 6.44742 1.06635 5.68448 1.629 5.122L5.121 1.629C5.39963 1.35029 5.73045 1.1292 6.09455 0.978379C6.45865 0.827557 6.8489 0.749953 7.243 0.75H21.75C22.1478 0.75 22.5294 0.908035 22.8107 1.18934C23.092 1.47064 23.25 1.85218 23.25 2.25V21.75C23.25 22.1478 23.092 22.5294 22.8107 22.8107C22.5294 23.092 22.1478 23.25 21.75 23.25Z'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.25 0.75V6.75C8.25 7.14782 8.40804 7.52936 8.68934 7.81066C8.97064 8.09196 9.35218 8.25 9.75 8.25H17.25C17.6478 8.25 18.0294 8.09196 18.3107 7.81066C18.592 7.52936 18.75 7.14782 18.75 6.75V0.75'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M15.75 3.75V5.25' stroke='black' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M17.25 12.75H6.75C6.35218 12.75 5.97064 12.908 5.68934 13.1893C5.40804 13.4706 5.25 13.8522 5.25 14.25V23.25H18.75V14.25C18.75 13.8522 18.592 13.4706 18.3107 13.1893C18.0294 12.908 17.6478 12.75 17.25 12.75Z'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M8.25 15.75H12.75' stroke='black' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8.25 18.75H15.75' stroke='black' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
}

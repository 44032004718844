import React from 'react';
import { makeStyles, Card, Theme } from '@material-ui/core';
import { CardProps } from '@mui/material';

interface Props {
  color?: string | undefined | null;
  isSelected?: boolean;
}

function getCardColor(props: Props) {
  if (props.color === undefined) {
    return 'none';
  }

  return `inset 0px -5px 0px ${props.color ? props.color : '#D3D3D3'}`;
}

export function PIMCard(props: Props & CardProps): JSX.Element {
  const { isSelected, className = '', ...other } = props;
  const classes = useStyles(props);

  const classNames = [classes.PimCard, className];
  if (isSelected === true) {
    classNames.push(classes.Selected);
  }

  return <Card className={classNames.join(' ')} {...other} />;
}

const useStyles = makeStyles((theme: Theme) => ({
  PimCard: {
    width: '100%',
    minWidth: '400px',
    marginBottom: '16px',
    border: '1px solid rgba(0, 0, 0, 0.3)',
    boxShadow: (props: Props) => getCardColor(props),
  },
  Selected: {
    backgroundColor: theme.palette.primary.main,
  },
}));

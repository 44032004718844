import React from 'react';
import { useParams } from 'react-router-dom';
import { createStyles, makeStyles, Grid, TextField } from '@material-ui/core';
import { SealOfApproval } from './SealOfApprovalAndNorm';
import { PIMToggle } from '../../../../shared/Components/PIMToggle/PIMToggle';
import PIMButton from '../../../../shared/Components/PIMButton';
import { useTranslation } from 'react-i18next';
import { ApiImageUrlsBuilder } from '../../../../utils/apiImageUrls';

interface IProps {
  sealOfApproval?: SealOfApproval;
  authorizedToWrite: boolean;
  onChange: (sealOfApproval: SealOfApproval) => void;
  onCloseSidebar: () => void;
}

interface IParams {
  country: string;
  divisionId: string;
  productId: string;
  materialId: string;
  articleId?: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    Container: {
      flexDirection: 'column',
      flexWrap: 'nowrap',
      alignItems: 'flex-start',
      minHeight: '100%',
      padding: '20px 40px 0px 40px',
    },
    FileName: {
      width: '100%',
      margin: '16px 0px',
    },
    ImageDiv: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      margin: '16px 0px',
    },
    Image: {
      width: '100%',
      height: '100%',
    },
    SidebarGrid: {
      height: '100%',
    },
    TopButtons: {
      margin: '72px 0px 16px 0px',
    },
  })
);

export function SealOfApprovalAndNormSidebar(props: IProps): JSX.Element {
  const classes = useStyles();
  const { country } = useParams<IParams>();
  const { sealOfApproval, authorizedToWrite } = props;
  const { t } = useTranslation();

  const handleOnChangeInUse = (checked: boolean) => {
    if (sealOfApproval) {
      sealOfApproval.isUsed = checked;

      if (props.onChange && sealOfApproval !== undefined) {
        props.onChange(sealOfApproval);
      }
    }
  };

  return (
    <Grid item xs={12} className={classes.SidebarGrid}>
      {sealOfApproval === undefined ? (
        <div>{t('Prüfzeichen oder Norm auswählen')}</div>
      ) : (
        <Grid item xs={12} className={classes.Container}>
          <PIMButton className={classes.TopButtons} icon='close' onClick={props.onCloseSidebar}>
            {t('Schließen')}
          </PIMButton>
          <TextField
            className={classes.FileName}
            label={t('Dateiname')}
            disabled
            variant='filled'
            type='text'
            value={sealOfApproval.fileName}
          />
          <PIMToggle
            onChange={(event: React.ChangeEvent<{}>, checked: boolean) => handleOnChangeInUse(checked)}
            checked={sealOfApproval.isUsed}
            label={t('In Verwendung')}
            disabled={!authorizedToWrite}
          />
          <div className={classes.ImageDiv}>
            <img
              className={classes.Image}
              src={ApiImageUrlsBuilder.buildSealOfApprovalAndNormImageUrl(country, sealOfApproval.fullName)}
            />
          </div>
        </Grid>
      )}
    </Grid>
  );
}

import React, { ReactElement } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { FormikTextField, FormikTextFieldProps } from '@dccs/react-formik-mui';

export function PIMFormikTextField(props: FormikTextFieldProps): ReactElement {
  const classes = useStyles();
  const { className = '', ...rest } = props;

  return <FormikTextField className={`${classes.textField} ${className}`} variant='filled' useField {...rest} />;
}

const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    '& .MuiFormHelperText-root': { fontSize: 12 },
    '& .MuiFormLabel-root': {
      color: '#676767',
      fontSize: '16px',
    },
    '& .MuiFormLabel-root.MuiInputLabel-shrink': {
      color: 'rgba(0, 0, 0, 0.6)',
      fontSize: 12,
      zIndex: 1,
      transform: 'translate(12px, 10px)',
    },
    '& .MuiFormLabel-root.Mui-focused': { color: '#000000' },
    '& .MuiInputBase-root': { fontSize: 16, background: '#F2F2F2', boxShadow: 'inset 0px -1px 0px #8C8C8C' },
    '& .MuiInputBase-root:focus': { boxShadow: 'inset 0px -1px 0px #000000' },
    '& .MuiInputBase-root.Mui-disabled': { background: '#FAFAFA', boxShadow: 'inset 0px -1px 0px #E0E0E0' },
    '& .MuiInputBase-root.Mui-disabled:focus': { boxShadow: 'inset 0px -1px 0px #E0E0E0' },
    '& .MuiFilledInput-underline:after': { borderColor: '#000000' },
    '& .MuiFilledInput-underline.Mui-error:after': { borderColor: theme.palette.error.main },
    '& .MuiInputBase-input.Mui-disabled': { color: 'black' },
    '& .MuiFilledInput-underline.Mui-disabled:before': { borderBottomStyle: 'none' },
  },
}));

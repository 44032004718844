import { createStyles, makeStyles, Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TechnicalDatasheetAttachmentResponse } from '../../../../api';
import PIMButton from '../../../../shared/Components/PIMButton';
import PIMTextField from '../../../../shared/Components/PIMTextField';
import { ImagePlaceholderIcon } from '../../../../shared/icons/ImagePlaceholderIcon';

interface IProps {
  attachment: TechnicalDatasheetAttachmentResponse;
  imageSource: string;
  showPlaceholder?: boolean | null;
  onCloseSidebar: () => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    Container: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      alignItems: 'flex-start',
      height: '100%',
      padding: '20px 40px 0px 40px',
    },
    FileName: {
      width: '100%',
      margin: '16px 0px',
    },
    ImageDiv: {
      margin: '16px 0px',
    },
    Image: {
      width: '100%',
      height: '100%',
      marginTop: '11px',
    },
    TopButtons: {
      margin: '72px 0px 16px 0px',
    },
  })
);

export function TechnicalDatasheetAttachmentsSideBar(props: IProps): JSX.Element {
  const classes = useStyles();
  const { attachment, imageSource } = props;
  const { t } = useTranslation();

  return (
    <Grid item xs={12} className={classes.Container}>
      <PIMButton className={classes.TopButtons} icon='close' onClick={props.onCloseSidebar}>
        {t('Schließen')}
      </PIMButton>
      <PIMTextField
        className={classes.FileName}
        label={t('Dateiname')}
        disabled
        variant='filled'
        type='text'
        value={attachment.fileName}
      />
      <div className={classes.ImageDiv}>
        {props.showPlaceholder ? (
          <ImagePlaceholderIcon className={classes.Image} />
        ) : (
          <img className={classes.Image} src={imageSource} />
        )}
      </div>
    </Grid>
  );
}

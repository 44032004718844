import React from 'react';

export function ListViewIcon(): JSX.Element {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g width='24' height='24' fill='white'>
        <path d='M8.25 3.74805H23.25' stroke='black' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M8.25 12.748H23.25' stroke='black' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M8.25 21.748H23.25' stroke='black' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path
          d='M0.75 0.748047H5.25V5.24805H0.75V0.748047Z'
          stroke='black'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M0.75 9.74805H5.25V14.248H0.75V9.74805Z'
          stroke='black'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M0.75 18.748H5.25V23.248H0.75V18.748Z'
          stroke='black'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  );
}

import React, { useEffect } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Quicklinks } from './list/Quicklinks';
import { useTranslation } from 'react-i18next';
import { CurrentCultureStateContext } from '../../contexts';
import { SearchGrid } from './search/SearchGrid';

const useStyles = makeStyles(() => ({
  root: {
    paddingLeft: '8px',
  },
  dashBoard: {
    marginTop: '24px',
  },
}));

export const Home = (): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { setCurrentCulture } = React.useContext(CurrentCultureStateContext);

  useEffect(() => {
    setCurrentCulture('en');
  });

  return (
    <Grid container xs={12} className={classes.root}>
      <Grid item xs={8}>
        <Grid item xs={12} className={classes.dashBoard}>
          {t('Dashboard')}
          <SearchGrid />
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Quicklinks />
      </Grid>
    </Grid>
  );
};

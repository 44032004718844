import React, { ReactElement, useContext } from 'react';
import {
  useMurexinPimContextApi,
  MaterialGroupCountryResponse,
  CountryPermission,
} from '../../../../api/MurexinPimApi';
import { useAsync } from 'react-async-hook';
import { CircleLoader } from '../../../../utils/CircleLoader';
import { Formik, Form, FieldArray, FieldArrayRenderProps } from 'formik';
import { NotificationContext } from '../../../../contexts/NotificationContext';
import { Grid, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { AuthorizationContext } from '../../../../auth';
import { IRouterParams } from '../../../../routes';
import { useParams } from 'react-router-dom';
import PIMButton from '../../../../shared/Components/PIMButton';
import PIMFormikCheckbox from '../../../../shared/Components/PIMFormikCheckbox';

export function ForeignCountries(): ReactElement {
  const classes = useStyles();
  const notification = React.useContext(NotificationContext);
  const { country, materialId } = useParams<IRouterParams>();
  const { t } = useTranslation();
  const { hasCountryPermission } = useContext(AuthorizationContext);
  const isAuthorizedToWrite = hasCountryPermission(country, CountryPermission.Write);

  const materialGroupId = Number(materialId);

  const api = useMurexinPimContextApi();
  const countriesResponse = useAsync(() => api.countryService.countryServiceList(), []);
  const originCount = 1;
  const countriesLength = Number(countriesResponse.result?.data.length) - originCount;
  const materialGropuCountryResponse = useAsync(
    () => api.materialGroupCountry.materialGroupCountryDetail(country, materialGroupId),
    [country, materialGroupId]
  );

  const materialGropuResponse = useAsync(async () => {
    const result = await api.materialGroupService.materialGroupServiceMaterialGroupDetail(country, materialGroupId);
    return result.data ?? {};
  }, [country, materialGroupId]);

  const materialGroupOriginCountryCode = materialGropuResponse.result?.countryCode ?? '';
  const readOnly = country !== materialGroupOriginCountryCode;

  async function updateData(values: MaterialGroupCountryResponse) {
    const updatevar = await api.materialGroupCountry.materialGroupCountryCreate(country, values);

    if (updatevar && updatevar.status === 200) {
      notification.success(t('Speichern erfolgreich'));
      materialGropuCountryResponse
        .execute()
        .catch(() => notification.error(t('Materialgruppenländer konnten nicht geladen werden')));
    } else {
      notification.error(t('Speichern fehlgeschlagen'));
    }
  }

  const handleOnChangeCountry = (
    checked: boolean,
    countryCode: string,
    arrayHelpers: FieldArrayRenderProps,
    index: number
  ) => {
    if (checked) {
      arrayHelpers.push(countryCode);
    } else if (index >= 0) {
      arrayHelpers.remove(index);
    }
  };

  const SelectOrUnselectAllCountries = (checked: boolean, arrayHelpers: FieldArrayRenderProps, length: number) => {
    if (countriesResponse.result !== undefined) {
      for (let i = length - 1; i >= 0; --i) {
        arrayHelpers.remove(i);
      }
      if (checked) {
        countriesResponse.result.data.forEach((countryResponse) => {
          if (countryResponse.code !== materialGroupOriginCountryCode) {
            arrayHelpers.push(countryResponse.code);
          }
        });
      }
    }
  };

  if (countriesResponse.loading || materialGropuCountryResponse.loading || materialGropuResponse.loading) {
    return <CircleLoader open={true} />;
  } else {
    return (
      <Formik
        initialValues={
          materialGropuCountryResponse.result?.data ?? {
            materialGroupId,
          }
        }
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          updateData(values).catch(() => notification.error(t('Speichern fehlgeschlagen')));
        }}>
        {({ values, resetForm, isSubmitting, dirty }) => (
          <Form>
            <Grid container>
              {!readOnly && isAuthorizedToWrite && (
                <Grid item xs={12}>
                  <Grid item container xs={6} justifyContent='flex-end' className={classes.ActionButtonRow}>
                    <PIMButton
                      icon='close'
                      className={classes.Button}
                      disabled={isSubmitting || !dirty}
                      onClick={() => {
                        notification.info(t('Daten zurückgesetzt'));
                        resetForm();
                      }}>
                      {t('Abbrechen')}
                    </PIMButton>
                    <PIMButton color='primary' type='submit' icon='save' busy={isSubmitting} disabled={!dirty}>
                      {t('Speichern')}
                    </PIMButton>
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12}>
                <Grid item container xs={6}>
                  <Grid container xs={12} className={classes.title}>
                    {t('Fremdländer')}
                  </Grid>
                  <FieldArray name='countryCodes'>
                    {(arrayHelpers) => (
                      <>
                        {!readOnly && isAuthorizedToWrite && (
                          <Grid container>
                            <PIMFormikCheckbox
                              name=''
                              color='primary'
                              checked={(values.countryCodes?.length ?? 0) === countriesLength}
                              onChange={(event: React.ChangeEvent<{}>, checked: boolean) =>
                                SelectOrUnselectAllCountries(checked, arrayHelpers, values.countryCodes?.length ?? 0)
                              }
                              label={t('Alle an-/abwählen')}
                              formControlProps={{ className: classes.CheckBox }}
                              formControlLabelProps={{ labelPlacement: 'end' }}
                            />
                          </Grid>
                        )}
                        {countriesResponse.result?.data.map((m) => {
                          return (
                            <Grid container key={m.code}>
                              <PIMFormikCheckbox
                                name=''
                                color='primary'
                                checked={
                                  values.countryCodes?.some((countryCode) => m.code === countryCode) ||
                                  materialGroupOriginCountryCode === m.code
                                }
                                disabled={readOnly || m.code === materialGroupOriginCountryCode || !isAuthorizedToWrite}
                                onChange={(event: React.ChangeEvent<{}>, checked: boolean) =>
                                  handleOnChangeCountry(
                                    checked,
                                    m.code ?? '',
                                    arrayHelpers,
                                    values.countryCodes?.indexOf(m.code ?? '') ?? -1
                                  )
                                }
                                label={m.name}
                                formControlProps={{ className: classes.CheckBox }}
                                formControlLabelProps={{ labelPlacement: 'end' }}
                              />
                            </Grid>
                          );
                        })}
                      </>
                    )}
                  </FieldArray>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    );
  }
}

const useStyles = makeStyles(() => ({
  title: {
    fontFamily: 'Roboto',
    fontSize: '20px',
    fontStyle: 'normal',
    lineHeight: '24px',
    marginBottom: '10px',
    marginTop: '30px',
    marginLeft: '-3px',
  },
  ActionButtonRow: {
    marginTop: '-75px',
  },
  Button: {
    marginRight: '24px',
  },
  CheckBox: {
    '&.MuiFormControl-root': {
      marginTop: '0px',
      marginBottom: '0px',
    },
  },
}));

import React, { useContext, useState } from 'react';
import { createStyles, Grid, makeStyles } from '@material-ui/core';
import {
  PerfectSystemMaterialGroupResponseModel,
  PerfectSystemResponseModel,
  useMurexinPimContextApi,
  PerfectSystemMaterialGroupRequestModel,
  SearchMaterialGroupResponse,
  CountryPermission,
} from '../../../../api';
import { NotificationContext } from '../../../../contexts/NotificationContext';
import { useHistory, useParams } from 'react-router-dom';
import { CircleLoader } from '../../../../utils/CircleLoader';
import { Form, Formik, FormikProps, FieldArrayRenderProps } from 'formik';
import { PerfectSystemSidebar } from './PerfectSystemSidebar';
import { IRouterParams, routes } from '../../../../routes';
import MessageDialog from '../../../../shared/Components/MessageDialog';
import { useAsync } from 'react-async-hook';
import PIMButton from '../../../../shared/Components/PIMButton';
import { FilterOptionsState } from '@mui/material';
import PerfectSystemAttributes from './PerfectSystemAttributes';
import { useTranslation } from 'react-i18next';
import { AuthorizationContext } from '../../../../auth';
import { ProductManagementProps } from '../../ProductManagement/ProductManagement';
import useFormikSaveAndClose from '../../../../utils/customHooks/useFormikSaveAndClose';

const useStyles = makeStyles(() =>
  createStyles({
    Form: {
      height: '100%',
    },
    Container: {
      height: '100%',
    },
    ContentGrid: {
      paddingRight: '16px',
    },
    ActionButtonRow: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'flex-end',
      paddingBottom: '32px',
      marginTop: '-80px',
    },
    ActionButtonRowHidden: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'flex-end',
    },
    TopButtons: {
      marginLeft: '24px',
    },
    Sidebar: {
      minHeight: '100%',
      marginTop: '-231px',
      zIndex: 1101,
      backgroundColor: 'white',
    },
  })
);

interface IProps {
  countrySelectionComponent: JSX.Element;
  translationCountryCode: string;
}

export default function PerfectSystem(props: IProps & ProductManagementProps): JSX.Element {
  const classes = useStyles();
  const api = useMurexinPimContextApi();
  const notification = React.useContext(NotificationContext);
  const { country, materialId } = useParams<IRouterParams>();
  const materialGroupId = Number(materialId);
  const { onDirtyChanged, cleanUpForm, countrySelectionComponent, translationCountryCode, saveOnCloseRef } = props;
  const history = useHistory();
  const [showNotSavedMessage, setShowNotSavedMessage] = useState(false);
  const [selectedItem, setSelectedItem] = useState<PerfectSystemMaterialGroupResponseModel | undefined>(undefined);
  const showTranslationPanel = !!translationCountryCode;
  const { t } = useTranslation();
  const { hasCountryPermission } = useContext(AuthorizationContext);
  const isAuthorizedToWrite = hasCountryPermission(country, CountryPermission.Write);
  const formikRef = useFormikSaveAndClose<PerfectSystemResponseModel>(saveOnCloseRef);

  const perfectSystemAttributes = useAsync(async () => {
    const response = await api.materialGroupService.materialGroupServiceMaterialGroupPerfectSystemDetail(
      country,
      materialGroupId
    );
    return response.data ?? {};
  }, [country, materialGroupId]);

  const perfectSystemAttributesTranslation = useAsync(async () => {
    if (!!translationCountryCode) {
      const response = await api.materialGroupService.materialGroupServiceMaterialGroupPerfectSystemDetail(
        translationCountryCode,
        materialGroupId
      );
      return response.data ?? {};
    }
    return {};
  }, [translationCountryCode, materialGroupId]);

  function handleFormOnChange(
    e: React.FormEvent<HTMLFormElement> | undefined,
    formik: FormikProps<PerfectSystemResponseModel>
  ) {
    formik.handleChange(e);
    onDirtyChanged();
  }

  const handleOnResetForm = () => {
    cleanUpForm();
  };

  const handleOpenMaterialGroupLink = (
    linkToDivisionId: number,
    linkToProductGroupId: number,
    linkToMaterialGroupId: number,
    formik: FormikProps<PerfectSystemResponseModel>
  ) => {
    if (formik.dirty) {
      setShowNotSavedMessage(true);
    } else {
      history.push(
        routes.articles.selected(
          country,
          String(linkToDivisionId),
          String(linkToProductGroupId),
          String(linkToMaterialGroupId),
          '0'
        ),
        null
      );
    }
  };

  function removeSelectedMaterialGroups(
    allGroups: SearchMaterialGroupResponse[],
    state: FilterOptionsState<SearchMaterialGroupResponse>,
    selectedGroups?: PerfectSystemMaterialGroupRequestModel[] | null | undefined
  ): SearchMaterialGroupResponse[] {
    let filterList = allGroups.filter((materialGroup) => materialGroup?.id !== materialGroupId);
    if (selectedGroups !== null && selectedGroups !== undefined) {
      filterList = filterList.filter(
        (materialGroup) => !selectedGroups?.some((g) => g.materialGroupId === materialGroup.id)
      );
    }

    return filterList.filter(
      (materialGroup) =>
        materialGroup.materialGroupDescription?.toUpperCase().includes(state.inputValue.toUpperCase()) ?? false
    );
  }

  async function updateData(values: PerfectSystemResponseModel) {
    const updatevar = await api.materialGroupService.materialGroupServiceMaterialGroupPerfectSystemCreate(
      country,
      materialGroupId,
      values
    );

    if (updatevar && updatevar.status === 200) {
      notification.success(t('Speichern erfolgreich'));
      cleanUpForm();
      await perfectSystemAttributes.execute();
    } else if (!updatevar || updatevar.status === 204) {
      notification.info(t('Keine Änderungen vorhanden'));
      cleanUpForm();
      await perfectSystemAttributes.execute();
    } else {
      notification.error(t('Speichern fehlgeschlagen'));
    }
  }

  const handelOnClickMaterialGroup = (perfectSystemMaterialGroup: PerfectSystemMaterialGroupResponseModel) => {
    // TODO: change it to Id
    if (selectedItem !== undefined && selectedItem.materialGroupId === perfectSystemMaterialGroup.materialGroupId) {
      setSelectedItem(undefined);
    } else {
      setSelectedItem(perfectSystemMaterialGroup);
    }
  };

  const handleOnAddMaterialGroup = (arrayHelpers: FieldArrayRenderProps, value: SearchMaterialGroupResponse) => {
    arrayHelpers.push({
      materialGroupId: value.id,
      materialGroup: value,
    });

    onDirtyChanged();
  };

  const handleOnDeleteMaterialGroup = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined,
    arrayHelpers: FieldArrayRenderProps,
    index: number
  ) => {
    arrayHelpers.remove(index);
    setSelectedItem(undefined);
    onDirtyChanged();

    if (event) event.stopPropagation();
  };

  return (
    <>
      <CircleLoader open={perfectSystemAttributes.loading || perfectSystemAttributesTranslation.loading} />
      <Formik
        enableReinitialize={!perfectSystemAttributes.loading}
        innerRef={formikRef}
        initialValues={perfectSystemAttributes.result as PerfectSystemResponseModel}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          await updateData(values);
        }}
        onReset={() => handleOnResetForm()}>
        {(formik) => (
          <Form className={classes.Form} onChange={(e) => handleFormOnChange(e, formik)}>
            <Grid className={classes.Container} container>
              <Grid container item xs={12}>
                <Grid
                  className={classes.ContentGrid}
                  item
                  xs={showTranslationPanel && selectedItem === undefined ? 12 : 8}>
                  <Grid
                    className={isAuthorizedToWrite ? classes.ActionButtonRow : classes.ActionButtonRowHidden}
                    item
                    xs={12}>
                    {isAuthorizedToWrite && countrySelectionComponent}
                    {isAuthorizedToWrite && (
                      <>
                        <PIMButton
                          className={classes.TopButtons}
                          disabled={formik.isSubmitting || perfectSystemAttributes.loading || !formik.dirty}
                          icon='close'
                          onClick={() => formik.handleReset()}>
                          {t('Abbrechen')}
                        </PIMButton>
                        <PIMButton
                          className={classes.TopButtons}
                          icon='save'
                          disabled={!formik.dirty || perfectSystemAttributes.loading}
                          busy={formik.isSubmitting}
                          color='primary'
                          type='submit'>
                          {t('Speichern')}
                        </PIMButton>
                      </>
                    )}
                  </Grid>
                  <Grid container item spacing={2}>
                    <Grid item xs={showTranslationPanel && selectedItem === undefined ? 6 : 12}>
                      <PerfectSystemAttributes
                        country={country}
                        perfectSystemMaterialGroups={formik.values?.perfectSystemMaterialGroups}
                        removeSelectedMaterialGroups={removeSelectedMaterialGroups}
                        handleOnAddMaterialGroup={handleOnAddMaterialGroup}
                        selectedItem={selectedItem}
                        handelOnClickMaterialGroup={handelOnClickMaterialGroup}
                        handleOnDeleteMaterialGroup={handleOnDeleteMaterialGroup}
                        isAuthorizedToWrite={isAuthorizedToWrite}
                      />
                    </Grid>
                    {!!showTranslationPanel && selectedItem === undefined && (
                      <Grid item xs={6}>
                        <Formik
                          enableReinitialize={!perfectSystemAttributesTranslation.loading}
                          initialValues={perfectSystemAttributesTranslation.result as PerfectSystemResponseModel}
                          onSubmit={() => {
                            return;
                          }}>
                          {(translationFormik) => (
                            <PerfectSystemAttributes
                              country={translationCountryCode}
                              readonly
                              perfectSystemMaterialGroups={translationFormik.values?.perfectSystemMaterialGroups}
                            />
                          )}
                        </Formik>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                {selectedItem !== undefined ? (
                  <Grid item xs={4} className={classes.Sidebar}>
                    <PerfectSystemSidebar
                      isAuthorizedToWrite={isAuthorizedToWrite}
                      perfectSystemMaterialGroup={selectedItem}
                      onClickMaterialGroupLink={(
                        linkToDivisionId: number,
                        linkToProductGroupId: number,
                        linkToMaterialGroupId: number
                      ) =>
                        handleOpenMaterialGroupLink(
                          linkToDivisionId,
                          linkToProductGroupId,
                          linkToMaterialGroupId,
                          formik
                        )
                      }
                    />
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>

      <MessageDialog
        show={showNotSavedMessage}
        messageText={t('Bitte die Daten zuerst speichern')}
        title={t('Perfektes System')}
        onClose={() => setShowNotSavedMessage(false)}
        onConfirm={() => setShowNotSavedMessage(false)}
        confirmLabel='OK'
      />
    </>
  );
}

import React, { ReactElement } from 'react';
import { AccordionDetails, AccordionDetailsProps, makeStyles } from '@material-ui/core';

export function PIMAccordionDetails(props: AccordionDetailsProps): ReactElement {
  const classes = useStyles();
  const { className = '', ...rest } = props;

  return <AccordionDetails className={`${classes.accordionDetails} ${className}`} {...rest} />;
}

const useStyles = makeStyles(() => ({
  accordionDetails: {
    padding: 0,
  },
}));

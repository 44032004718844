import React, { useState } from 'react';
import { Card, Grid, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { HttpResponse, ProblemDetails, QuickLinkResponse, useMurexinPimContextApi } from '../../../api';
import { useAsync } from 'react-async-hook';
import { NavLink } from 'react-router-dom';
import { NotificationContext } from '../../../contexts/NotificationContext';
import { BreadCrumbContext } from '../../../layouts/BreadCrumbs/BreadCrumbContext';
import { DeleteDialog } from '../../../utils/DeleteDialog';
import { useTranslation } from 'react-i18next';
import PIMInformationBox from '../../../shared/Components/PIMInformationBox';
import { AddLinkIcon } from '../../../shared/icons';
import PIMIconButton from '../../../shared/Components/PIMIconButton';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '82px',
    paddingRight: '24px',
    maxWidth: '100%',
  },
  header: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '36px',
    marginBottom: '24px',
  },
  title: {
    fontSize: '20px',
    lineHeight: '120%',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    color: '#000000',
  },
  secondary: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontSize: '14px',
    fontWeight: 400,
    color: '#000000',
    opacity: '0.6',
  },
  btnMargin: {
    margin: 'auto',
  },
  margin: {
    padding: '6px',
    paddingLeft: '16px',
  },
  muiPaperRoot: {
    marginTop: '24px',
    borderRadius: '0px',
    boxShadow: 'none',
    border: '1px solid rgba(0,0,0,0.3)',
  },
  infoBox: {
    marginTop: '24px',
    maxWidth: '100%',
    paddingRight: '24px',
  },
}));

export const Quicklinks = (): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const api = useMurexinPimContextApi();
  const [quicklinks, setQuicklinks] = useState<QuickLinkResponse[]>();
  const notification = React.useContext(NotificationContext);
  const breadcrumb = React.useContext(BreadCrumbContext);
  const [open, setOpen] = React.useState(false);
  const [Linkid, setId] = React.useState(0);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useAsync(() => api.quickLink.quickLinkList().then((d) => setQuicklinks(d.data)), []);

  async function deleteFunction() {
    const deleteVar = await api.quickLink.quickLinkDelete(Linkid);
    if (deleteVar.status === 200) {
      await api.quickLink
        .quickLinkList()
        .then((d: HttpResponse<QuickLinkResponse[], ProblemDetails>) => {
          notification.success(t('Quicklink wurde gelöscht'));
          breadcrumb.reload();
          setQuicklinks(d.data);
          setOpen(false);
          setId(0);
        })
        .catch(() => notification.error(t('Quicklink kann nicht gelöscht werden')));
    }
  }

  const handleClickOpen = (deleteId: number) => {
    setOpen(true);
    setId(deleteId);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Grid>
        <Grid item xs={4} className={classes.root}>
          <div className={classes.header}>{t('Meine Quicklinks')}</div>
          {quicklinks?.map((link) => (
            <Card key={link.id} className={classes.muiPaperRoot}>
              <Grid container>
                <Grid item xs={11} className={classes.margin}>
                  <NavLink key={link.id} style={{ textDecoration: 'none' }} to={link.path}>
                    <Typography className={classes.title} gutterBottom>
                      {link.title}
                    </Typography>
                    <Typography color='textSecondary' className={classes.secondary}>
                      {link.breadCrumbs}
                    </Typography>
                  </NavLink>
                </Grid>
                <Grid className={classes.btnMargin} item xs={1}>
                  <PIMIconButton onClick={() => handleClickOpen(Number(link.id))} iconName='trash' />
                </Grid>
              </Grid>
            </Card>
          ))}
        </Grid>
        <Grid item xs={4} className={classes.infoBox}>
          <PIMInformationBox>
            <span>
              {t('Meine Quicklinks1')}
              <ul>
                <li>{t('Meine Quicklinks2')}</li>
                <li>
                  {t('Meine Quicklinks3')}
                  <AddLinkIcon />
                </li>
                <li>{t('Meine Quicklinks4')}</li>
              </ul>
            </span>
          </PIMInformationBox>
        </Grid>
      </Grid>
      <DeleteDialog
        handleClose={handleClose}
        open={open}
        fullScreen={fullScreen}
        delete={deleteFunction}
        saveText={t('Möchten Sie diesen Quicklink wirklich löschen?')}
        title={t('Quicklink löschen')}
      />
    </>
  );
};

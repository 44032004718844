import { InputAdornment, makeStyles } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import PIMFormikSidebar from '../../../shared/Components/PIMFormikSidebar';
import PIMFormikTextField from '../../../shared/Components/PIMFormikTextField';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IRouterParams } from '../../../routes';
import {
  CountryPermission,
  ProductGroupCountryRequest,
  ProductGroupCreateRequest,
  ProductGroupResponse,
  ProductGroupUpdateRequest,
  useMurexinPimContextApi,
} from '../../../api';
import { NotificationContext } from '../../../contexts';
import PIMButton from '../../../shared/Components/PIMButton';
import MessageDialog from '../../../shared/Components/MessageDialog';
import { SidebarMode } from '../../../shared/Enum';
import { AuthorizationContext } from '../../../auth';

interface ISidebarProps {
  mode: SidebarMode;
  countryName?: string;
  divisionName: string;
  productGroup?: ProductGroupResponse;
  handleOnSave: () => void;
  handleOnCancel: () => void;
  handleOnClose?: () => void;
}

interface ProductGroupSidebarModel {
  id: number;
  productGroupName: string;
  productGroupDescription: string;
  productGroupCountryDescription: string;
}

export function ProductGroupSidebar(props: ISidebarProps): JSX.Element {
  const classes = useStyles();
  const { country, divisionId } = useParams<IRouterParams>();
  const { t } = useTranslation();
  const api = useMurexinPimContextApi();
  const notificationContext = useContext(NotificationContext);
  const notification = useContext(NotificationContext);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const { hasCountryPermission } = useContext(AuthorizationContext);
  const { handleOnCancel, handleOnClose, handleOnSave, divisionName, mode, productGroup, countryName } = props;

  const canDeleteProductGroup = hasCountryPermission(country, CountryPermission.DeleteProductGroup);
  const canEditProductGroup =
    productGroup?.countryCode === country && hasCountryPermission(country, CountryPermission.UpdateProductGroup);
  const canEditProductGroupCountryDescription =
    hasCountryPermission(country, CountryPermission.AdministrationWrite) || canEditProductGroup;

  const productGroupNameLength = 2;

  const onHandleOnSubmit = async (values: ProductGroupSidebarModel) => {
    if (mode === SidebarMode.Create) {
      await addProductGroup(values);
    } else if (mode === SidebarMode.Update) {
      await updateProductGroup(values);
    }
  };

  const initialValue: ProductGroupSidebarModel = {
    id: productGroup?.id ?? 0,
    productGroupDescription: productGroup?.productGroupDescription ?? '',
    productGroupName: productGroup?.productGroupName ?? '',
    productGroupCountryDescription: productGroup?.productGroupCountryDescription ?? '',
  };

  const validationSchema = Yup.object().shape({
    productGroupDescription: Yup.string().min(0, t('zu kurz')).max(50, t('zu lang')).required(t('Feld erforderlich')),
    productGroupName: Yup.string()
      .matches(RegExp('^\\d+$'), t('Nur Ziffern erlaubt'))
      .length(productGroupNameLength, t('Anzahl stimmt nicht', { fieldLength: productGroupNameLength }))
      .required(t('Feld erforderlich')),
  });

  async function addProductGroup(request: ProductGroupCreateRequest) {
    try {
      await api.productService.productServiceCreate(country, {
        productGroupDescription: request.productGroupDescription,
        productGroupName: request.productGroupName,
        divisionId: Number(divisionId),
      });

      notificationContext.success(t('Produktgruppe erfolgreich erstellt'));
      handleOnSave();
    } catch {
      notificationContext.error(t('Produktgruppe kann nicht erstellt werden'));
    } finally {
      if (handleOnClose) handleOnClose();
    }
  }

  const updateProductGroup = async (data: ProductGroupSidebarModel) => {
    try {
      if (canEditProductGroup) {
        if (productGroup !== undefined)
          await api.productService.productServiceProductGroupUpdate(country, productGroup.id, {
            productGroupName: data.productGroupName,
            productGroupDescription: data.productGroupDescription,
            productGroupCountryDescription: data.productGroupCountryDescription,
          } as ProductGroupUpdateRequest);
      } else if (canEditProductGroupCountryDescription) {
        await api.productService.productServiceProductGroupCountryUpdate(country, {
          productGroupId: data.id,
          productGroupCountryDescription: data.productGroupCountryDescription,
        } as ProductGroupCountryRequest);
      }
      notification.success(t('Produktgruppe erfolgreich aktualisiert'));
      handleOnSave();
    } catch {
      notification.error(t('Speichern fehlgeschlagen'));
    }
  };

  async function deleteProductGroup() {
    setShowDeleteDialog(false);
    try {
      if (productGroup !== undefined) {
        await api.productService.productServiceProductGroupDelete(country, productGroup?.id);
        notification.success(t('Produktgruppe erfolgreich gelöscht'));
        handleOnSave();
      }
    } catch {
      notification.error(t('Der Löschvorgang ist fehlgeschlagen'));
    }
  }

  return (
    <PIMFormikSidebar
      title={props.mode === SidebarMode.Create ? t('Neue Produktgruppe hinzufügen') : t('Produktgruppe bearbeiten')}
      cancelButtonText={t('Abbrechen')}
      formikInitialValues={initialValue}
      validationSchema={validationSchema}
      handleOnSubmit={onHandleOnSubmit}
      handleOnCancel={handleOnCancel}>
      <>
        <PIMFormikTextField
          name='productGroupName'
          label={t('Produktgruppencode')}
          type='text'
          placeholder='XX'
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                {mode === SidebarMode.Create ? country : productGroup?.countryCode}-{divisionName}
              </InputAdornment>
            ),
          }}
          className={classes.ProductGroupPrefix}
          disabled={props.mode === SidebarMode.Update && !canEditProductGroup}
        />
        <PIMFormikTextField
          name='productGroupDescription'
          label={mode === SidebarMode.Update ? t('Produktgruppenbezeichnung Original') : t('Produktgruppe')}
          type='text'
          fullWidth
          aria-required
          disabled={props.mode === SidebarMode.Update && !canEditProductGroup}
        />
        {props.mode === SidebarMode.Update && (
          <>
            <PIMFormikTextField
              label={t('Produktgruppenbezeichnung')
                .concat(' ')
                .concat(countryName ?? '')}
              name='productGroupCountryDescription'
              fullWidth
              disabled={!canEditProductGroupCountryDescription}
            />
            <hr className={classes.separatorLine} />
            {canDeleteProductGroup && (
              <PIMButton
                color='warning'
                icon='trash'
                onClick={() => {
                  setShowDeleteDialog(true);
                }}
                disabled={productGroup?.countryCode !== country}>
                {t('Produktgruppe löschen')}
              </PIMButton>
            )}
          </>
        )}
        <MessageDialog
          show={showDeleteDialog}
          title={t('Wollen Sie diese Produktgruppe löschen?')}
          messageText={t('HinweisProduktgruppeLöschenFreigegebeneLänder.')}
          confirmColor='warning'
          confirmIcon='trash'
          confirmLabel={t('Löschen')}
          onClose={() => setShowDeleteDialog(false)}
          onCancel={() => {
            setShowDeleteDialog(false);
          }}
          onConfirm={deleteProductGroup}
        />
      </>
    </PIMFormikSidebar>
  );
}

const useStyles = makeStyles(() => ({
  separatorLine: {
    margin: '32px 0',
  },
  ProductGroupPrefix: {
    '& .MuiInputAdornment-root': {
      marginRight: '4px',
      color: 'black',
    },
  },
}));

import React from 'react';
import * as Icons from './index';

export type IconName =
  | 'addFile'
  | 'add'
  | 'close'
  | 'comment'
  | 'down'
  | 'download'
  | 'duplicate'
  | 'erp'
  | 'error'
  | 'global'
  | 'gridView'
  | 'imagePlaceholder'
  | 'information'
  | 'link'
  | 'listView'
  | 'local'
  | 'nonEditable'
  | 'nonErp'
  | 'right'
  | 'save'
  | 'search'
  | 'send'
  | 'selected'
  | 'success'
  | 'trash';

export function getIcon(iconName?: IconName): React.ReactNode {
  switch (iconName) {
    case 'addFile':
      return <Icons.AddFileIcon />;
    case 'add':
      return <Icons.AddIcon />;
    case 'close':
      return <Icons.CloseIcon />;
    case 'comment':
      return <Icons.CommentIcon />;
    case 'down':
      return <Icons.DownIcon />;
    case 'download':
      return <Icons.DownloadIcon />;
    case 'duplicate':
      return <Icons.DuplicateIcon />;
    case 'erp':
      return <Icons.ERPIcon />;
    case 'error':
      return <Icons.ErrorIcon />;
    case 'global':
      return <Icons.GlobalIcon />;
    case 'gridView':
      return <Icons.GridViewIcon />;
    case 'imagePlaceholder':
      return <Icons.ImagePlaceholderIcon />;
    case 'information':
      return <Icons.InformationIcon />;
    case 'link':
      return <Icons.LinkIcon />;
    case 'listView':
      return <Icons.ListViewIcon />;
    case 'local':
      return <Icons.LocalIcon />;
    case 'nonEditable':
      return <Icons.NonEditable />;
    case 'nonErp':
      return <Icons.NonERPIcon />;
    case 'right':
      return <Icons.RightIcon />;
    case 'save':
      return <Icons.SaveIcon />;
    case 'search':
      return <Icons.SearchIcon />;
    case 'send':
      return <Icons.SendIcon />;
    case 'success':
      return <Icons.SuccessIcon />;
    case 'selected':
      return <Icons.SelectedIcon />;
    case 'trash':
      return <Icons.TrashIcon />;
    default:
      return undefined;
  }
}

import React from 'react';

export function InformationIcon(): JSX.Element {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M12 12.754V9.75403' stroke='#0A9DD5' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M21 9.75403C21 17.654 14.068 22.085 12.371 23.054C12.2579 23.1187 12.1298 23.1527 11.9995 23.1527C11.8692 23.1527 11.7411 23.1187 11.628 23.054C9.931 22.084 3 17.652 3 9.75403C3 7.36708 3.94821 5.07789 5.63604 3.39007C7.32387 1.70224 9.61305 0.754028 12 0.754028C14.3869 0.754028 16.6761 1.70224 18.364 3.39007C20.0518 5.07789 21 7.36708 21 9.75403Z'
        stroke='#0A9DD5'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 7.56494C11.7929 7.56494 11.625 7.39705 11.625 7.18994C11.625 6.98283 11.7929 6.81494 12 6.81494'
        stroke='#0A9DD5'
        strokeWidth='1.5'
      />
      <path
        d='M12 7.56494C12.2071 7.56494 12.375 7.39705 12.375 7.18994C12.375 6.98283 12.2071 6.81494 12 6.81494'
        stroke='#0A9DD5'
        strokeWidth='1.5'
      />
    </svg>
  );
}

import React from 'react';
import { GlobalPermission } from '../api';
import { AuthorizedRoute, SimplifiedRouteProps } from '.';

export function GlobalAdministrationRoute(props: SimplifiedRouteProps): JSX.Element {
  return (
    <AuthorizedRoute
      {...props}
      isAuthorized={({ hasGlobalPermission }) => hasGlobalPermission(GlobalPermission.AdministrationRead)}
    />
  );
}

import React, { ReactElement } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { FormikSelect, FormikSelectProps } from '@dccs/react-formik-mui';

export function PIMFormikSelect(props: FormikSelectProps): ReactElement {
  const classes = useStyles();
  const { formControlProps = {}, ...rest } = props;
  const { className = '', ...formControlPropsRest } = formControlProps;

  return (
    <FormikSelect
      variant='filled'
      formControlProps={{ className: `${classes.selectField} ${className}`, ...formControlPropsRest }}
      useField
      {...rest}
    />
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  selectField: {
    '& .MuiFormHelperText-root': { fontSize: 12 },
    '& .MuiFormLabel-root': {
      color: '#676767',
      fontSize: 16,
      zIndex: 1,
      transform: 'translate(12px, 20px)',
      pointerEvents: 'none',
    },
    '& .MuiFormLabel-root.MuiInputLabel-shrink': {
      color: 'rgba(0, 0, 0, 0.6)',
      fontSize: 12,
      zIndex: 1,
      transform: 'translate(12px, 10px)',
    },
    '& .MuiSelect-icon': {
      color: '#6F6F6F',
    },
    '& .MuiFormLabel-root.Mui-focused': { color: '#000000' },
    '& .MuiSelect-root': { fontSize: 16, background: '#F2F2F2', boxShadow: 'inset 0px -1px 0px #8C8C8C' },
    '& .MuiSelect-root:focus': { boxShadow: 'inset 0px -1px 0px #000000' },
    '& .MuiSelect-root.Mui-disabled': {
      color: '#000000',
      background: '#FAFAFA',
      boxShadow: 'inset 0px -1px 0px #E0E0E0',
    },
    '& .MuiSelect-root.Mui-disabled:focus': { boxShadow: 'inset 0px -1px 0px #E0E0E0' },
    '& .MuiFilledInput-underline:after': { borderColor: '#000000' },
    '& .MuiFilledInput-underline.Mui-error:after': { borderColor: theme.palette.error.main },
    '& .MuiFilledInput-underline.Mui-disabled:before': { borderBottomStyle: 'none' },
  },
}));

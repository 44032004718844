import React from 'react';

export function LocalIcon(): JSX.Element {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.25 8.25C8.25 9.24456 8.64509 10.1984 9.34835 10.9017C10.0516 11.6049 11.0054 12 12 12C12.9946 12 13.9484 11.6049 14.6517 10.9017C15.3549 10.1984 15.75 9.24456 15.75 8.25C15.75 7.25544 15.3549 6.30161 14.6517 5.59835C13.9484 4.89509 12.9946 4.5 12 4.5C11.0054 4.5 10.0516 4.89509 9.34835 5.59835C8.64509 6.30161 8.25 7.25544 8.25 8.25Z'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 0.75C13.9891 0.75 15.8968 1.54018 17.3033 2.9467C18.7098 4.35322 19.5 6.26088 19.5 8.25C19.5 11.657 14.426 20.2 12.625 22.915C12.5566 23.0181 12.4637 23.1027 12.3547 23.1613C12.2456 23.2198 12.1238 23.2504 12 23.2504C11.8762 23.2504 11.7544 23.2198 11.6453 23.1613C11.5363 23.1027 11.4434 23.0181 11.375 22.915C9.574 20.2 4.5 11.657 4.5 8.25C4.5 6.26088 5.29018 4.35322 6.6967 2.9467C8.10322 1.54018 10.0109 0.75 12 0.75V0.75Z'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

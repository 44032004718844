import { createStyles, makeStyles, Grid } from '@material-ui/core';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Pictogram } from './Pictograms';
import { PIMToggle } from '../../../../shared/Components/PIMToggle/PIMToggle';
import { IRouterParams } from '../../../../routes/IRouterParams';
import PIMTextField from '../../../../shared/Components/PIMTextField';
import PIMButton from '../../../../shared/Components/PIMButton';
import { useTranslation } from 'react-i18next';

interface IProps {
  pictogram?: Pictogram;
  onChange: (pictogram: Pictogram) => void;
  onCloseSidebar: () => void;
  authorizedToWrite: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    Container: {
      flexDirection: 'column',
      flexWrap: 'nowrap',
      alignItems: 'flex-start',
      height: '100%',
      padding: '20px 40px 0px 40px',
    },
    FileName: {
      width: '100%',
      margin: '16px 0px',
    },
    ImageDiv: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      margin: '16px 0px',
    },
    Image: {
      width: '100%',
      height: '100%',
    },
    SidebarGrid: {
      height: '100%',
    },
    TopButtons: {
      margin: '72px 0px 16px 0px',
    },
  })
);

export function PictogramSidebar(props: IProps): JSX.Element {
  const classes = useStyles();
  const { country } = useParams<IRouterParams>();
  const { pictogram, authorizedToWrite } = props;
  const { t } = useTranslation();

  const handleOnChangeInUse = (checked: boolean) => {
    if (pictogram) {
      pictogram.isUsed = checked;

      if (props.onChange) {
        props.onChange(pictogram);
      }
    }
  };

  return (
    <Grid item xs={12} className={classes.SidebarGrid}>
      {pictogram === undefined ? (
        <div>{t('Bitte wählen Sie einen Piktogramm aus!')}</div>
      ) : (
        <Grid container className={classes.Container}>
          <PIMButton className={classes.TopButtons} icon='close' onClick={props.onCloseSidebar}>
            {t('Schließen')}
          </PIMButton>
          <PIMTextField
            className={classes.FileName}
            label={t('Dateiname')}
            disabled
            variant='filled'
            type='text'
            value={pictogram.fileName}
          />
          <PIMToggle
            onChange={(event: React.ChangeEvent<{}>, checked: boolean) => handleOnChangeInUse(checked)}
            checked={pictogram.isUsed}
            label={t('In Verwendung')}
            disabled={!authorizedToWrite}
          />
          <div className={classes.ImageDiv}>
            <img className={classes.Image} src={`/api/Pictogram/${country}/image?fileName=${pictogram.fileName}`} />
          </div>
        </Grid>
      )}
    </Grid>
  );
}

import React from 'react';

interface IProps {
  className?: string;
}

export function ERPIcon(props: IProps): JSX.Element {
  const { className } = props;

  return (
    <svg className={className} viewBox='0 0 62 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0.5' y='0.5' width='61' height='31' rx='3.5' fill='#4FC9BE' fillOpacity='0.1' />
      <path
        d='M10 17.223L12.45 20.7C12.5429 20.8388 12.6677 20.9534 12.8138 21.0343C12.96 21.1152 13.1234 21.16 13.2904 21.165C13.4573 21.17 13.6231 21.135 13.7738 21.063C13.9246 20.991 14.0559 20.884 14.157 20.751L22 10.828'
        stroke='#4FC9BE'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.75 15.999C4.75 18.9827 5.93526 21.8442 8.04505 23.954C10.1548 26.0638 13.0163 27.249 16 27.249C18.9837 27.249 21.8452 26.0638 23.955 23.954C26.0647 21.8442 27.25 18.9827 27.25 15.999C27.25 13.0153 26.0647 10.1539 23.955 8.04407C21.8452 5.93429 18.9837 4.74902 16 4.74902C13.0163 4.74902 10.1548 5.93429 8.04505 8.04407C5.93526 10.1539 4.75 13.0153 4.75 15.999V15.999Z'
        stroke='#4FC9BE'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M39.7705 19.4268V20.5H34.5V19.4268H39.7705ZM34.7666 10.5469V20.5H33.4473V10.5469H34.7666ZM39.0732 14.8262V15.8994H34.5V14.8262H39.0732ZM39.7021 10.5469V11.627H34.5V10.5469H39.7021ZM41.4043 10.5469H44.6992C45.4466 10.5469 46.0778 10.6608 46.5928 10.8887C47.1123 11.1165 47.5065 11.4538 47.7754 11.9004C48.0488 12.3424 48.1855 12.887 48.1855 13.5342C48.1855 13.9899 48.0921 14.4069 47.9053 14.7852C47.723 15.1589 47.4587 15.4779 47.1123 15.7422C46.7705 16.002 46.3604 16.1956 45.8818 16.3232L45.5127 16.4668H42.416L42.4023 15.3936H44.7402C45.2142 15.3936 45.6084 15.3115 45.9229 15.1475C46.2373 14.9788 46.4743 14.7533 46.6338 14.4707C46.7933 14.1882 46.873 13.876 46.873 13.5342C46.873 13.1514 46.7979 12.8164 46.6475 12.5293C46.4971 12.2422 46.2601 12.0212 45.9365 11.8662C45.6175 11.7067 45.2051 11.627 44.6992 11.627H42.7236V20.5H41.4043V10.5469ZM47.2217 20.5L44.8018 15.9883L46.1758 15.9814L48.6299 20.418V20.5H47.2217ZM53.7021 16.5967H51.043V15.5234H53.7021C54.2171 15.5234 54.6341 15.4414 54.9531 15.2773C55.2721 15.1133 55.5046 14.8854 55.6504 14.5938C55.8008 14.3021 55.876 13.9694 55.876 13.5957C55.876 13.2539 55.8008 12.9326 55.6504 12.6318C55.5046 12.3311 55.2721 12.0895 54.9531 11.9072C54.6341 11.7204 54.2171 11.627 53.7021 11.627H51.3506V20.5H50.0312V10.5469H53.7021C54.4541 10.5469 55.0898 10.6768 55.6094 10.9365C56.1289 11.1963 56.5231 11.5563 56.792 12.0166C57.0609 12.4723 57.1953 12.9941 57.1953 13.582C57.1953 14.2201 57.0609 14.7646 56.792 15.2158C56.5231 15.667 56.1289 16.0111 55.6094 16.248C55.0898 16.4805 54.4541 16.5967 53.7021 16.5967Z'
        fill='#4FC9BE'
      />
      <rect x='0.5' y='0.5' width='61' height='31' rx='3.5' stroke='#4FC9BE' />
    </svg>
  );
}

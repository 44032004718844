import React from 'react';

export function RightIcon(): JSX.Element {
  return (
    <svg width='14' height='24' viewBox='0 0 14 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.856556 21.102L9.6217 12.3318C9.66161 12.2919 9.69328 12.2447 9.71488 12.1926C9.73649 12.1405 9.74761 12.0847 9.74761 12.0283C9.74761 11.972 9.73649 11.9161 9.71488 11.8641C9.69328 11.812 9.66161 11.7647 9.6217 11.7249L0.856556 2.95461C0.684302 2.79887 0.545488 2.60974 0.448538 2.39873C0.351587 2.18771 0.298521 1.95919 0.29256 1.72704C0.286599 1.49489 0.327868 1.26395 0.41386 1.04824C0.499854 0.832519 0.628778 0.636523 0.79281 0.472141C0.956844 0.307758 1.15257 0.178418 1.3681 0.0919666C1.58363 0.00551566 1.81449 -0.0362427 2.04665 -0.0307747C2.27881 -0.0253066 2.50744 0.0272739 2.71866 0.123776C2.92989 0.220279 3.1193 0.358691 3.27541 0.530613L12.9543 10.2095C13.1932 10.4483 13.3828 10.7318 13.5121 11.0439C13.6414 11.356 13.708 11.6905 13.708 12.0283C13.708 12.3662 13.6414 12.7007 13.5121 13.0128C13.3828 13.3248 13.1932 13.6084 12.9543 13.8472L3.27541 23.526C3.1193 23.698 2.92989 23.8364 2.71866 23.9329C2.50744 24.0294 2.27881 24.082 2.04665 24.0874C1.81449 24.0929 1.58363 24.0511 1.3681 23.9647C1.15257 23.8782 0.956844 23.7489 0.79281 23.5845C0.628778 23.4201 0.499854 23.2241 0.41386 23.0084C0.327868 22.7927 0.286599 22.5618 0.29256 22.3296C0.298521 22.0975 0.351587 21.8689 0.448538 21.6579C0.545488 21.4469 0.684302 21.2578 0.856556 21.102Z'
        fill='currentColor'
      />
    </svg>
  );
}

import { createStyles, Link, makeStyles, Theme } from '@material-ui/core';
import { Card, IconButton } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import PIMFormikSelect from '../../../../shared/Components/PIMFormikSelect';
import PIMFormikTextField from '../../../../shared/Components/PIMFormikTextField';
import { LinkIcon } from '../../../../shared/icons';

interface IProps {
  readonly?: boolean;
  substrateOptions?:
    | {
        key: number | undefined;
        value: string | undefined | null;
        content: string | null | undefined;
      }[]
    | [];
  authorizedToWrite: boolean;
  authorizedToWriteGlobal: boolean;
  openAdminSubstrate?: () => void;
  onChange?: (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => void;
}

export default function SubstrateAttributes(props: IProps): ReactElement {
  const classes = useStyles();
  const { readonly, substrateOptions, authorizedToWrite, authorizedToWriteGlobal, openAdminSubstrate, onChange } =
    props;
  const { t } = useTranslation();

  return (
    <Card className={classes.Card}>
      {authorizedToWrite && (
        <div
          className={
            !readonly
              ? authorizedToWriteGlobal
                ? classes.Select
                : classes.SelectWide
              : (classes.Select, classes.HiddenComponent)
          }>
          <PIMFormikSelect
            name={'substrateResponses'}
            label={substrateOptions?.length === 0 ? t('Keine Untergrundvorlagen vorhanden') : t('Untergrundvorlagen')}
            hideRemoveSelection
            options={substrateOptions ?? []}
            disabled={substrateOptions?.length === 0 || !authorizedToWrite}
            onChange={onChange}
          />
          {authorizedToWriteGlobal && (
            <IconButton
              className={classes.LinkButton}
              onClick={openAdminSubstrate}
              component={Link}
              disabled={!authorizedToWrite}>
              <LinkIcon />
            </IconButton>
          )}
        </div>
      )}
      <PIMFormikTextField
        label={t('Voraussetzung Untergründe "Standard"')}
        name={'requirementStandard'}
        className={classes.TextField}
        fullWidth
        multiline
        disabled={readonly || !authorizedToWrite}
      />
      <PIMFormikTextField
        label={t('Untergrundvorbehandlung kurz')}
        name={'preprocessing'}
        className={classes.TextField}
        fullWidth
        multiline
        disabled={readonly || !authorizedToWrite}
      />
    </Card>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    Card: {
      padding: theme.spacing(2),
    },
    TextField: {
      '&.MuiTextField-root': {
        marginTop: '8px',
        marginBottom: '8px',
      },
    },
    Select: {
      '& .MuiFormControl-root': {
        width: 'calc(100% - 48px)',
      },
    },
    SelectWide: {
      '& .MuiFormControl-root': {
        width: '100%',
      },
    },
    LinkButton: {
      marginTop: '20px',
    },
    HiddenComponent: {
      visibility: 'hidden',
    },
  })
);

import { Card, Grid, makeStyles, MenuItem } from '@material-ui/core';
import React, { Fragment, useContext, useState } from 'react';
import { useAsync, useAsyncCallback } from 'react-async-hook';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { CountryPermission, CountryResponse, SearchResponse, useMurexinPimContextApi } from '../../../api';
import { AuthorizationContext } from '../../../auth';
import { routes } from '../../../routes';
import PIMButton from '../../../shared/Components/PIMButton';
import PIMSelect from '../../../shared/Components/PIMSelect';
import PIMTextField from '../../../shared/Components/PIMTextField';
import { CircleLoader } from '../../../utils/CircleLoader';

export function SearchGrid(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const api = useMurexinPimContextApi();
  const { hasCountryPermission } = useContext(AuthorizationContext);

  const [searchStatement, setSearchStatement] = useState<string>('');
  const [searchCountryCode, setSearchCountryCode] = useState<string>('');

  const MinimumSearchTextLength = 3;
  const isSearchTextTooShort = searchStatement.length < MinimumSearchTextLength;

  const permittedCountriesList = useAsync(async () => {
    const response = await api.countryService.countryServiceList();
    return response.data.filter((country: CountryResponse) => {
      if (country.code) return hasCountryPermission(country.code, CountryPermission.Read);
    });
  }, []);

  const searchAsyncResult = useAsyncCallback(async () => {
    const response = await api.search.searchDetail(searchStatement, searchCountryCode);
    return response.data ?? [];
  });

  const getMaterialGroupTitle = (serachResponse: SearchResponse): string => {
    let materialGroupTitle = serachResponse.materialGroupTitle;

    if (materialGroupTitle == null || materialGroupTitle.trim() === '') {
      materialGroupTitle = serachResponse.materialGroupDescription;
    }

    return materialGroupTitle;
  };

  return (
    <>
      <CircleLoader open={searchAsyncResult.loading} />
      <Grid container>
        <Grid item xs={12} className={classes.searchArea}>
          <div className={classes.Title}>{t('Suche')}</div>
          <Grid container spacing={4} xs={12}>
            <Grid item xs={7}>
              <PIMTextField
                value={searchStatement}
                label={t('Suchbegriff')}
                className={classes.searchTextField}
                fullWidth
                onChange={(e) => setSearchStatement(e.target.value)}
                onKeyDown={async (e) => {
                  if (e.key === 'Enter' && searchStatement.length >= MinimumSearchTextLength)
                    await searchAsyncResult.execute();
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <PIMSelect
                defaultValue=''
                label={t('Land')}
                removeSelectionText={t('Global')}
                className={classes.countrySelect}
                fullWidth
                onChange={(e) => {
                  setSearchCountryCode(e.target.value as string);
                }}>
                {permittedCountriesList.result?.map((country) => (
                  <MenuItem key={country.name} value={country.code ?? undefined}>
                    {country.name}
                  </MenuItem>
                ))}
              </PIMSelect>
            </Grid>
            <Grid item xs={2}>
              <PIMButton
                icon='search'
                color='primary'
                disabled={isSearchTextTooShort || searchAsyncResult.loading}
                onClick={() => searchAsyncResult.execute()}
                fullWidth>
                {t('Suchen')}
              </PIMButton>
            </Grid>
          </Grid>

          <Grid item xs={12} className={classes.resultArea}>
            {searchAsyncResult.result && searchAsyncResult.result.length === 0 && (
              <span>{t('Keine Ergebnisse gefunden.')}</span>
            )}
            {searchAsyncResult.result?.map((item, index) => (
              <Card className={classes.rowCard} key={index}>
                <Grid container className={classes.ResultGrid} spacing={2}>
                  <Fragment>
                    <Grid item xs={2}>
                      <span className={classes.CardTitle}> {item.countryName}</span>
                      <br />
                      <span className={classes.CardSubTitle}> {item.countryCode}</span>
                    </Grid>
                    <Grid item xs={4} className={classes.GridItem}>
                      <NavLink
                        key={item.materialGroupId}
                        style={{ textDecoration: 'none' }}
                        to={routes.articles.selected(
                          item.countryCode.toString(),
                          item.divisionId.toString(),
                          item.productGroupId.toString(),
                          item.materialGroupId.toString(),
                          '0'
                        )}>
                        <div>
                          <span className={classes.CardTitle}> {getMaterialGroupTitle(item)}</span>
                          <br />
                          <span className={classes.CardSubTitle}> {item.materialGroupName}</span>
                        </div>
                      </NavLink>
                    </Grid>
                    <Grid item xs={6} className={item.articleId ? classes.GridItem : ''}>
                      {item.articleId && (
                        <NavLink
                          key={item.materialGroupId}
                          style={{ textDecoration: 'none' }}
                          to={routes.articles.selected(
                            item.countryCode.toString(),
                            item.divisionId.toString(),
                            item.productGroupId.toString(),
                            item.materialGroupId.toString(),
                            item.articleId.toString()
                          )}>
                          <div>
                            <span className={classes.CardTitle}>{item.articleDescription}</span>
                            <br />
                            <span className={classes.CardSubTitle}>
                              {item.articleNumber} · {item.ean}
                            </span>
                          </div>
                        </NavLink>
                      )}
                    </Grid>
                  </Fragment>
                </Grid>
              </Card>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  searchArea: {
    marginTop: '40px',
    paddingRight: '24px',
    maxWidth: '100%',
  },
  resultArea: {
    paddingRight: '33px',
    maxWidth: '100%',
    paddingBottom: '25px',
    paddingTop: '24px',
  },
  rowCard: {
    marginTop: '8px',
    paddingTop: '20px',
    paddingLeft: '20px',
    paddingBottom: '20px',
    borderRadius: '0px',
    boxShadow: 'none',
    border: '1px solid rgba(0,0,0,0.3)',
    cursor: 'pointer',

    '&.MuiPaper-outlined': {
      border: 'none',
      borderRadius: '0px',
    },
    '&.Inactive': {
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
    },
    '&.Selected': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  searchTextField: {
    marginTop: '0px',
  },
  countrySelect: {
    width: '100%',
  },
  ResultGrid: {
    alignItems: 'center',
    paddingRight: '20px',
  },
  GridItem: {
    padding: '5px 15px',

    '&:hover': {
      backgroundColor: 'rgba(255, 221, 0, 0.7)',
    },
  },
  CardTitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '20px',
    marginBottom: '4px',
    color: '#000000',
  },
  CardSubTitle: {
    fontSize: '14px',
    color: '#000000',
    opacity: '0.6',
  },
  Title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '36px',
    marginBottom: '24px',
    lineHeight: '45px',
  },
}));

import * as React from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { ImageStoreResponse, useMurexinPimContextApi } from '../../../api';
import { NotificationContext } from '../../../contexts/NotificationContext';
import { ApiImageUrlsBuilder } from '../../../utils/apiImageUrls';
import { useTranslation } from 'react-i18next';
import MessageDialog from '../../../shared/Components/MessageDialog';
import { useState } from 'react';
import PIMButton from '../../../shared/Components/PIMButton';
import PIMFormikToggle from '../../../shared/Components/PIMFormikToggle';
import { SearchMaterialGroupDropdown } from '../../Article/ArticleDetailed/PerfectSystem/SearchMaterialGroupDropdown';
import PIMFormikTextField from '../../../shared/Components/PIMFormikTextField';

interface IProps {
  isAuthorizedToWrite: boolean;
  newItem: boolean;
  selectedPicture: ImageStoreResponse | null;
  country: string;
  file: File | null;
  reload: () => void;
  resetFile: () => void;
  checkIsMaterialGroupAssigned: (selectedMaterialGroupId: number) => boolean;
  actionDelete: () => void;
}

export function DisplayImageSideBar(props: IProps): JSX.Element {
  const classes = useStyles();
  const api = useMurexinPimContextApi();
  const { t } = useTranslation();
  const notification = React.useContext(NotificationContext);
  const { checkIsMaterialGroupAssigned } = props;
  const [showWarningDialog, setShowWarningDialog] = useState<boolean>(false);

  const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';

  function beforeOnSubmit(values: ImageStoreResponse, handleSubmit: () => void) {
    const isMaterialGroupAssigned = checkIsMaterialGroupAssigned(values.materialGroupId ?? 0);

    if (isMaterialGroupAssigned) {
      setShowWarningDialog(true);
    } else {
      handleSubmit();
    }
  }

  async function uploadNewFile(values: ImageStoreResponse) {
    if (!props.file) return;
    return api.materialGroup
      .materialGroupImageStoreSaveImageCreate(
        props.country,
        {
          File: props.file,
          FullName: props.file.name,
          DefaultImage: values.defaultImage ? values.defaultImage : false,
          FileName: props.file.name,
          AzureStorageGuid: values.azureStorageGuid || EMPTY_GUID,
        },
        { materialGroupId: values.materialGroupId }
      )
      .then(() => {
        notification.success(t('Hochladen erfolgreich'));
        props.reload();
      })
      .catch(() => {
        notification.error(t('Hochladen fehlgeschlagen'));
        props.reload();
      });
  }

  async function uploadFile(values: ImageStoreResponse) {
    return api.materialGroup
      .materialGroupImageStoreSaveImageCreate(
        props.country,
        {
          DefaultImage: values.defaultImage,
          FileName: values.fileName,
          File: null,
          AzureStorageGuid: values.azureStorageGuid || EMPTY_GUID,
        },
        { materialGroupId: values.materialGroupId }
      )
      .then(() => {
        notification.success(t('Hochladen erfolgreich'));
        props.reload();
      })
      .catch(() => {
        notification.error(t('Hochladen fehlgeschlagen'));
        props.reload();
      });
  }

  const initialValues = props.selectedPicture;
  if (initialValues) {
    // TODO: don't mutate react state => selected picture is a state in parent component
    initialValues.materialGroupDescription =
      initialValues.materialGroupDescription ?? t('Keine Warengruppe zugewiesen');
  }

  return (
    <>
      {initialValues ? (
        <div className={classes.actionArea}>
          <Grid container className={classes.whiteBackground}>
            <Grid item xs={12} className={classes.gridItem}>
              <Formik
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  void uploadFile(values);
                }}
                enableReinitialize>
                {({ isSubmitting, resetForm, dirty, values, handleSubmit, setFieldValue }) => (
                  <Form>
                    <div className={classes.topButtons}>
                      {props.isAuthorizedToWrite && (
                        <>
                          <PIMButton
                            className={classes.buttonSeperator}
                            icon='close'
                            disabled={isSubmitting}
                            onClick={() => {
                              resetForm();
                              props.resetFile();
                            }}>
                            {t('Abbrechen')}
                          </PIMButton>
                          <PIMButton
                            color='primary'
                            icon='save'
                            className={classes.topButtons}
                            busy={isSubmitting}
                            disabled={isSubmitting || !dirty}
                            onClick={() => beforeOnSubmit(values, handleSubmit)}>
                            {t('Speichern')}
                          </PIMButton>
                        </>
                      )}
                    </div>
                    <Grid container>
                      <Grid item xs={12} className={classes.gridItem}>
                        <PIMFormikTextField name='fileName' label={t('Dateiname')} disabled fullWidth />
                        <PIMFormikToggle name='defaultImage' label={t('Standard')} />
                        <PIMFormikTextField
                          label={t('Aktuelle Warengruppe')}
                          name='materialGroupDescription'
                          disabled
                          fullWidth
                        />
                        <SearchMaterialGroupDropdown
                          country={props.country}
                          onChange={(_, value) => {
                            if (!!value) {
                              setFieldValue('materialGroupId', value.id);
                            }
                          }}
                          disabled={!props.isAuthorizedToWrite}
                          hideCountryCode
                          hideProductName
                          selectText={t('Neue Warengruppe zuweisen')}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={9} className={classes.gridItem}>
                      <Box textAlign='center'>
                        <div className={classes.paddingImage}>
                          <img
                            className={classes.width}
                            src={
                              values.id !== undefined
                                ? ApiImageUrlsBuilder.buildMaterialGroupImageUrl(props.country, values.id)
                                : ''
                            }
                            alt={props.selectedPicture?.fileName ?? ''}
                          />
                        </div>
                      </Box>
                    </Grid>
                    <hr className={classes.separatorLine} />
                    <PIMButton
                      color='warning'
                      icon='trash'
                      onClick={() => {
                        props.actionDelete();
                      }}>
                      {t('Anzeigebild löschen')}
                    </PIMButton>
                    <MessageDialog
                      show={showWarningDialog}
                      messageText={t('Warengruppe Speichern Text')}
                      title={t('Warengruppe Speichern')}
                      onClose={() => setShowWarningDialog(false)}
                      onConfirm={() => {
                        setShowWarningDialog(false);
                        handleSubmit();
                      }}
                      onCancel={() => {
                        resetForm();
                        setShowWarningDialog(false);
                      }}
                      confirmLabel={t('Speichern')}
                      cancelLabel={t('Abbrechen')}
                    />
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </div>
      ) : (
        <div className={classes.actionArea}>
          <Grid container className={classes.whiteBackground}>
            <Grid item xs={12} className={classes.gridItem}>
              <Formik
                initialValues={
                  props.selectedPicture != null ? props.selectedPicture : { id: -1, fileName: props.file?.name }
                }
                onSubmit={(values, { setSubmitting, setFieldValue }) => {
                  setSubmitting(true);
                  setFieldValue('fileName', props.file?.name);
                  void uploadNewFile(values);
                }}
                enableReinitialize={true}>
                {({ isSubmitting, resetForm, values, handleSubmit, setFieldValue }) => (
                  <Form>
                    <div>
                      {props.isAuthorizedToWrite && (
                        <>
                          <PIMButton
                            className={classes.topButtons}
                            icon='close'
                            disabled={isSubmitting}
                            onClick={() => {
                              resetForm();
                              props.resetFile();
                            }}>
                            {t('Abbrechen')}
                          </PIMButton>
                          <PIMButton
                            color='primary'
                            icon='save'
                            className={classes.topButtons}
                            busy={isSubmitting}
                            disabled={isSubmitting}
                            onClick={() => beforeOnSubmit(values, handleSubmit)}>
                            {t('Speichern')}
                          </PIMButton>
                        </>
                      )}
                    </div>
                    <Grid container>
                      <Grid item xs={12} className={classes.gridItem}>
                        <PIMFormikTextField name='fileName' label={t('Dateiname')} disabled fullWidth />
                        <PIMFormikToggle
                          className={classes.buttonSeperator}
                          name='defaultImage'
                          label={t('Standard')}
                        />
                        <PIMFormikTextField
                          label={t('Aktuelle Warengruppe')}
                          name='materialGroupDescription'
                          disabled
                          fullWidth
                        />
                        <SearchMaterialGroupDropdown
                          country={props.country}
                          onChange={(_, value) => {
                            if (!!value) {
                              setFieldValue('materialGroupId', value.id);
                            }
                          }}
                          disabled={!props.isAuthorizedToWrite}
                          hideCountryCode
                          hideProductName
                          selectText={t('Neue Warengruppe zuweisen')}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.gridItem}>
                      {props.file ? (
                        <Box textAlign='center'>
                          <div className={classes.paddingImage}>
                            <img
                              src={URL.createObjectURL(props.file)}
                              alt={props.file.name}
                              className={classes.width}
                            />
                          </div>
                        </Box>
                      ) : null}
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
}

const useStyles = makeStyles(() => ({
  topButtons: {
    marginBottom: '16px',
    marginRight: '24px',
    paddingLeft: '10px',
  },
  buttonSeperator: {
    marginRight: '24px',
  },
  actionArea: {
    minHeight: '100%',
  },
  paddingImage: {
    paddingTop: '30px',
  },
  width: {
    width: '100%',
  },
  gridItem: {
    paddingLeft: '10px',
    paddingRight: '10px',
    width: '100%',
    maxWidth: '100%',
  },
  whiteBackground: {
    backgroundColor: 'white',
    paddingLeft: '10px',
    paddingTop: '10px',
    paddingBottom: '20px',
    minHeight: '100%',
  },
  separatorLine: {
    margin: '32px 0',
  },
}));

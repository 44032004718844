import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { useParams } from 'react-router-dom';
import { CommentResponse } from '../../api/MurexinPimApi';
import { useTranslation } from 'react-i18next';
import PIMButton from '../../shared/Components/PIMButton';
import PIMFormikTextField from '../../shared/Components/PIMFormikTextField';

interface IParams {
  divisionId: string;
  productId: string;
  materialId: string;
}

interface IProps {
  addComment: (comment: CommentResponse) => void;
}

export function AddingComment(props: IProps): JSX.Element {
  const classes = useStyles();
  const { materialId } = useParams<IParams>();
  const { t } = useTranslation();

  function addComment(comment: CommentResponse) {
    comment.dateTime = '';
    comment.materialGroupId = Number(materialId);
    props.addComment(comment);
  }

  return (
    <div>
      <Grid container spacing={0}>
        <Grid item xs={4} />
        <Grid item xs={4}>
          <div className={classes.margin}>
            <Formik
              initialValues={{
                commentText: '',
                dateTime: '',
                id: 0,
                materialGroupId: Number(materialId),
                realName: '',
              }}
              onSubmit={(values: CommentResponse, { setSubmitting, resetForm }) => {
                setSubmitting(false);
                addComment(values);
                resetForm();
              }}>
              <Form>
                <PIMFormikTextField label={t('Kommentar')} name='commentText' fullWidth multiline />
                <PIMButton color='primary' icon='comment' type='submit' className={classes.addCommentButton}>
                  {t('Kommentieren')}
                </PIMButton>
              </Form>
            </Formik>
          </div>
        </Grid>
        <Grid item xs={4} />
      </Grid>
    </div>
  );
}

const useStyles = makeStyles(() => ({
  margin: {
    width: '100%',
  },
  addCommentButton: {
    float: 'right',
  },
}));

import React, { ReactElement } from 'react';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  checkBox: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    '&.Mui-disabled': {
      opacity: 0.5,
    },
  },
}));

export function PIMCheckbox(props: CheckboxProps): ReactElement {
  const classes = useStyles();

  return (
    <Checkbox
      className={classes.checkBox}
      color='primary'
      icon={
        <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <rect x='0.5' y='0.5' width='15' height='15' rx='1.5' fill='white' stroke='#8F8F8F' />
        </svg>
      }
      checkedIcon={
        <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <rect x='0.5' y='0.5' width='15' height='15' rx='1.5' fill='#FFDD00' stroke='#AA8A08' />
          <path
            d='M6.31608 12.5933L3.36142 9.48217C3.12516 9.2196 2.99553 8.87034 3.00012 8.50869C3.0047 8.14705 3.14314 7.80155 3.38597 7.54572C3.6288 7.28988 3.95687 7.14389 4.30038 7.1388C4.64389 7.13372 4.97573 7.26994 5.2253 7.51849L7.10476 9.49718L10.6249 4.55608C10.7288 4.41011 10.8589 4.28711 11.008 4.19411C11.157 4.10111 11.322 4.03992 11.4935 4.01405C11.665 3.98817 11.8397 3.99811 12.0075 4.0433C12.1754 4.0885 12.3332 4.16805 12.4718 4.27743C12.6105 4.38681 12.7273 4.52387 12.8156 4.68078C12.904 4.83769 12.9621 5.01138 12.9867 5.19194C13.0112 5.3725 13.0018 5.55639 12.9589 5.7331C12.9159 5.90982 12.8404 6.07591 12.7365 6.22188L8.30427 12.4446C8.19085 12.6037 8.04627 12.7354 7.88032 12.8306C7.71437 12.9258 7.53093 12.9824 7.3424 12.9965C7.15388 13.0106 6.96468 12.9819 6.78761 12.9123C6.61055 12.8428 6.44974 12.734 6.31608 12.5933Z'
            fill='white'
            stroke='#AA8A08'
            strokeWidth='0.75'
            strokeLinejoin='round'
          />
        </svg>
      }
      indeterminateIcon={
        <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <rect x='0.5' y='0.5' width='15' height='15' rx='1.5' fill='white' stroke='#AA8A08' />
          <rect x='3.5' y='3.5' width='9' height='9' rx='0.5' fill='#FFDD00' stroke='#AA8A08' />
        </svg>
      }
      inputProps={{ 'aria-label': 'decorative checkbox' }}
      {...props}
    />
  );
}

import React, { useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { BreadCrumb } from '../layouts/BreadCrumbs/BreadCrumbsLoader';
import { CountryPermission } from '../api';
import { IRouterParams } from './IRouterParams';
import { AuthorizedRoute, SimplifiedRouteProps } from '.';

export function CountryRoute({ component, render, ...rest }: SimplifiedRouteProps): JSX.Element {
  const renderComponent = useCallback(
    (routeProps: RouteComponentProps) => {
      if (component)
        return (
          <>
            <BreadCrumb />
            {component && React.createElement(component, routeProps)}
          </>
        );

      return render && render(routeProps);
    },
    [component, render]
  );

  return (
    <AuthorizedRoute
      {...rest}
      render={renderComponent}
      isAuthorized={({ hasCountryPermission }, routeProps) => {
        const routerParams = routeProps.match.params as IRouterParams;
        const countryCode = routerParams.country ?? '';
        return hasCountryPermission(countryCode, CountryPermission.Read);
      }}
    />
  );
}

import React from 'react';

export function GlobalIcon(): JSX.Element {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12 23.25C18.2132 23.25 23.25 18.2132 23.25 12C23.25 5.7868 18.2132 0.75 12 0.75C5.7868 0.75 0.75 5.7868 0.75 12C0.75 18.2132 5.7868 23.25 12 23.25Z'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.88 23.05C8.31 20.85 7.25 16.72 7.25 12.05C7.25 7.38 8.31 3.15 9.88 1'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.12 23.05C15.69 20.85 16.75 16.72 16.75 12.05C16.75 7.38 15.69 3.15 14.12 1'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M0.75 12H23.25' stroke='black' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M2.05005 17.25H21.9501' stroke='black' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M2.05005 6.75H21.9501' stroke='black' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
}

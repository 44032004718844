import React from 'react';
import { useAsync } from 'react-async-hook';
import { useRouteMatch } from 'react-router-dom';
import { BreadCrumbInfoResponseModel, useMurexinPimContextApi } from '../../api';
import { CurrentCultureStateContext } from '../../contexts';
import { routes } from '../../routes/routes';
import { CircleLoader } from '../../utils/CircleLoader';
import { CustomBreadCrumb } from './BreadCrumbs';

interface IParams {
  country: string;
  divisionId?: string;
  productId?: string;
  materialId?: string;
}

interface IProps {
  params: IParams;
  isMaterial?: string;
}

export function BreadCrumb(): JSX.Element {
  const { currentCulture } = React.useContext(CurrentCultureStateContext);
  const match1 = useRouteMatch<IParams>({
    path: routes.breadCrumbsRoute(),
    exact: true,
  });

  const match2 = useRouteMatch<string>({
    path: routes.material(),
    exact: true,
  });

  const match = match1;
  if (match == null) {
    return <CustomBreadCrumb infoProps={[]} culture={currentCulture} isMaterial={match2?.params} />;
  }
  return <BreadCrumbsLoader params={match?.params} isMaterial={match2?.params} />;
}

export function BreadCrumbsLoader(props: IProps): JSX.Element {
  const { currentCulture } = React.useContext(CurrentCultureStateContext);
  const { country, divisionId, productId, materialId } = props.params;

  const api = useMurexinPimContextApi();
  const breadCrumbs = useAsync<BreadCrumbInfoResponseModel[]>(async () => {
    const response = await api.divisionService.divisionServiceBreadCrumbsInfoDetail(
      country,
      divisionId !== undefined ? Number(divisionId) : 0,
      productId !== undefined ? Number(productId) : 0,
      materialId !== undefined ? Number(materialId) : 0
    );
    return response.data ?? [];
  }, [country, divisionId, productId, materialId]);

  if (breadCrumbs.loading) {
    return <CircleLoader open={true} />;
  } else
    return (
      <CustomBreadCrumb
        infoProps={breadCrumbs.result ? breadCrumbs.result : []}
        culture={currentCulture}
        isMaterial={props.isMaterial}
      />
    );
}

import React, { useState } from 'react';
import { Accordion, AccordionProps, makeStyles, Theme } from '@material-ui/core';

export function ExpansionTrackingAccordion(props: AccordionProps): JSX.Element {
  const [userExpanded, setUserExpanded] = useState(false);
  const classes = useStyles();
  const { expanded, disabled, onChange, className = '', ...rest } = props;
  const exp = expanded !== undefined ? expanded : userExpanded && !disabled;

  return (
    <Accordion
      className={`${classes.accordion} ${className}`}
      {...rest}
      expanded={exp}
      disabled={disabled}
      onChange={(event, changedExpanded) => {
        setUserExpanded(changedExpanded);
        if (onChange) {
          onChange(event, changedExpanded);
        }
      }}
    />
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  accordion: {
    margin: theme.spacing(1, 0),
    '&.Mui-expanded': {
      margin: theme.spacing(1, 0),
    },
  },
}));

import React, { useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { ProductGrid } from './ProductGrid';
import { useAsync } from 'react-async-hook';
import { useMurexinPimContextApi } from '../../../api';
import { IRouterParams } from '../../../routes';
import { useParams } from 'react-router-dom';
import { CircleLoader } from '../../../utils/CircleLoader';
import { ProductGroupSidebar } from './ProductGroupSidebar';
import { SidebarMode } from '../../../shared/Enum';

function ProductList(): JSX.Element {
  const classes = useStyles();
  const api = useMurexinPimContextApi();
  const { country, divisionId } = useParams<IRouterParams>();
  const [openProductSidebar, setOpenSidebar] = useState(false);

  const division = useAsync(async () => {
    const response = await api.divisionService.divisionServiceByIdDetail(Number(divisionId), country);
    return response.data ?? {};
  }, []);

  const productGroupResponse = useAsync(async () => {
    const response = await api.productService.productServiceByDivisionIdDetail(country, Number(divisionId));
    return response.data ?? [];
  }, [country, divisionId]);

  const divisionName = division?.result?.divisionName ?? divisionId;

  if (productGroupResponse.loading || division.loading) {
    return <CircleLoader open={true} />;
  } else {
    return (
      <Grid container className={classes.ProductList}>
        <Grid item xs={8}>
          <ProductGrid
            handleOpenProductGroupSidebar={() => setOpenSidebar(true)}
            divisionName={divisionName}
            productGroups={productGroupResponse.result ?? []}
          />
        </Grid>
        <Grid item xs={4} className={classes.Sidebar}>
          {!!openProductSidebar && (
            <ProductGroupSidebar
              mode={SidebarMode.Create}
              divisionName={divisionName}
              handleOnCancel={() => setOpenSidebar(false)}
              handleOnClose={() => setOpenSidebar(false)}
              handleOnSave={async () => {
                await productGroupResponse.execute();
              }}
            />
          )}
        </Grid>
      </Grid>
    );
  }
}

const useStyles = makeStyles(() => ({
  ProductList: {
    height: '100%',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    padding: '16px 0px 0px 16px',
  },
  Sidebar: {
    marginTop: '-92px',
    minHeight: '100%',
  },
}));

export default ProductList;

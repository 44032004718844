import React, { ReactElement, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Storage from '../ArticleDetailed/Storage/Storage';
import Description from '../ArticleDetailed/Description/Description';
import { Substrate } from '../ArticleDetailed/Substrate/Substrate';
import { TechnicalDetail } from '../ArticleDetailed/TechnicalDetail/TechnicalDetail';
import { Grid, MenuItem, useMediaQuery, useTheme } from '@material-ui/core';
import { Prepare } from '../ArticleDetailed/Prepare/Prepare';
import PerfectSystem from '../ArticleDetailed/PerfectSystem/PerfectSystem';
import { WorkSafety } from '../ArticleDetailed/WorkSafety/WorkSafety';
import { ProductHint } from '../ArticleDetailed/ProductHint/ProductHint';
import { TechnicalDatasheetAttachments } from '../ArticleDetailed/TechnicalDatasheetAttachments/TechnicalDatasheetAttachments';
import { Pictograms } from '../ArticleDetailed/Pictograms/Pictograms';
import SealOfApprovalAndNorm from '../ArticleDetailed/SealOfApprovalAndNorm';
import { PIMTab, PIMTabs, PIMTabPanel } from '../../../shared/Components/PIMTabs';
import { useAsync } from 'react-async-hook';
import { useMurexinPimContextApi } from '../../../api';
import { IRouterParams } from '../../../routes';
import { useParams } from 'react-router-dom';
import { CircleLoader } from '../../../utils/CircleLoader';
import PIMSelect from '../../../shared/Components/PIMSelect';
import { useTranslation } from 'react-i18next';
import { SaveChangesDialog } from '../../../utils/SaveChangesDialog';

const useStyles = makeStyles(() =>
  createStyles({
    SideBar: {
      height: '100%',
    },
  })
);

const tabPanelNotSelected = -1;

interface IProps {
  erpImport?: boolean;
}

export interface ProductManagementProps {
  onDirtyChanged: () => void;
  cleanUpForm: () => void;
  saveOnCloseRef: React.RefObject<{
    handleSave: () => Promise<void>;
  }>;
}

export function ProductManagement(props: IProps): ReactElement {
  const classes = useStyles();
  const { erpImport } = props;
  const [showDialog, setShowDialog] = useState(false);
  const [displayedTabPanel, setDisplayedTabPanel] = useState(0);
  const [selectedTabPanel, setSelectedTabPanel] = useState(tabPanelNotSelected);
  const [currentFormHasChanged, setCurrentFormHasChanged] = useState(false);
  const [translationCountryCode, setTranslationCountryCode] = useState('');
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const api = useMurexinPimContextApi();
  const { country } = useParams<IRouterParams>();
  const { t } = useTranslation();

  const sideBarItems: string[] = [
    t('Produktbeschreibung'),
    t('Lagerung'),
    t('Piktogramme'),
    t('Technische Daten'),
    t('Prüfzeichen & Norm'),
    t('Untergrund'),
    t('Verarbeitung'),
    t('Produkt und Verarbeitungshinweise'),
    t('Perfektes System'),
    t('Arbeitssicherheit, -schutz und Umwelt'),
    t('Technisches Merkblatt Anhänge'),
  ];

  const countryOptionsResponse = useAsync(async () => {
    const countriesList = await api.countryService.countryServiceList();
    const options =
      countriesList.data
        ?.filter((p) => p.code !== country && (p.code === 'AT' || p.code === 'GB'))
        .map((p) => ({
          key: p.code ?? '',
          value: p.name ?? '',
        })) ?? [];

    return options;
  }, []);

  const countryOptions = countryOptionsResponse.result ?? [];

  function handleDirtyChanged() {
    setCurrentFormHasChanged(true);
  }

  function handleClean() {
    setCurrentFormHasChanged(false);
  }

  const handleTabSwitch = (event: React.SyntheticEvent, newValue: number): void => {
    if (currentFormHasChanged) {
      setSelectedTabPanel(newValue);
      setShowDialog(true);
    } else {
      changeTabPanel(newValue);
    }
  };

  const changeTabPanel = (newDisplayedPanel: number) => {
    setDisplayedTabPanel(newDisplayedPanel);
    setSelectedTabPanel(tabPanelNotSelected);
    setCurrentFormHasChanged(false);
    setShowDialog(false);
  };

  const handleDialogClose = () => {
    setSelectedTabPanel(tabPanelNotSelected);
    setShowDialog(false);
  };

  const saveOnCloseRef = React.useRef({ handleSave: () => Promise.resolve() });

  const countrySelection = (
    <PIMSelect
      label={countryOptions.length === 0 ? t('Keine Länder vorhanden') : t('Übersetzung')}
      removeSelectionText={t('Auswahl entfernen')}
      value={translationCountryCode}
      disabled={countryOptions.length === 0}
      shrinkLabel={translationCountryCode !== ''}
      onChange={(e) => {
        setTranslationCountryCode(e.target.value as string);
      }}>
      {countryOptions.map((d) => (
        <MenuItem key={d.key} value={d.key}>
          {d.value}
        </MenuItem>
      ))}
    </PIMSelect>
  );

  if (countryOptionsResponse.loading) {
    return <CircleLoader open={true} />;
  } else {
    return (
      <>
        <Grid className={classes.SideBar} container>
          <Grid item xs={3}>
            <PIMTabs orientation='vertical' value={displayedTabPanel} onChange={handleTabSwitch}>
              {sideBarItems.map((val, index) => {
                return <PIMTab key={index} label={val} />;
              })}
            </PIMTabs>
          </Grid>
          <Grid item xs={9}>
            <PIMTabPanel value={displayedTabPanel} index={0}>
              <Description
                onDirtyChanged={handleDirtyChanged}
                cleanUpForm={handleClean}
                translationCountryCode={translationCountryCode}
                countrySelectionComponent={countrySelection}
                saveOnCloseRef={saveOnCloseRef}
              />
            </PIMTabPanel>
            <PIMTabPanel value={displayedTabPanel} index={1}>
              <Storage
                onDirtyChanged={handleDirtyChanged}
                cleanUpForm={handleClean}
                saveOnCloseRef={saveOnCloseRef}
                erpImport={erpImport}
              />
            </PIMTabPanel>
            <PIMTabPanel value={displayedTabPanel} index={2}>
              <Pictograms
                onDirtyChanged={handleDirtyChanged}
                cleanUpForm={handleClean}
                saveOnCloseRef={saveOnCloseRef}
                translationCountryCode={translationCountryCode}
                countrySelectionComponent={countrySelection}
              />
            </PIMTabPanel>
            <PIMTabPanel value={displayedTabPanel} index={3}>
              <TechnicalDetail
                onDirtyChanged={handleDirtyChanged}
                cleanUpForm={handleClean}
                saveOnCloseRef={saveOnCloseRef}
                translationCountryCode={translationCountryCode}
                countrySelectionComponent={countrySelection}
              />
            </PIMTabPanel>
            <PIMTabPanel value={displayedTabPanel} index={4}>
              <SealOfApprovalAndNorm
                onDirtyChanged={handleDirtyChanged}
                cleanUpForm={handleClean}
                saveOnCloseRef={saveOnCloseRef}
                translationCountryCode={translationCountryCode}
                countrySelectionComponent={countrySelection}
              />
            </PIMTabPanel>
            <PIMTabPanel value={displayedTabPanel} index={5}>
              <Substrate
                onDirtyChanged={handleDirtyChanged}
                cleanUpForm={handleClean}
                saveOnCloseRef={saveOnCloseRef}
                translationCountryCode={translationCountryCode}
                countrySelectionComponent={countrySelection}
              />
            </PIMTabPanel>
            <PIMTabPanel value={displayedTabPanel} index={6}>
              <Prepare
                onDirtyChanged={handleDirtyChanged}
                cleanUpForm={handleClean}
                saveOnCloseRef={saveOnCloseRef}
                translationCountryCode={translationCountryCode}
                countrySelectionComponent={countrySelection}
              />
            </PIMTabPanel>
            <PIMTabPanel value={displayedTabPanel} index={7}>
              <ProductHint
                onDirtyChanged={handleDirtyChanged}
                cleanUpForm={handleClean}
                saveOnCloseRef={saveOnCloseRef}
                translationCountryCode={translationCountryCode}
                countrySelectionComponent={countrySelection}
              />
            </PIMTabPanel>
            <PIMTabPanel value={displayedTabPanel} index={8}>
              <PerfectSystem
                onDirtyChanged={handleDirtyChanged}
                cleanUpForm={handleClean}
                saveOnCloseRef={saveOnCloseRef}
                translationCountryCode={translationCountryCode}
                countrySelectionComponent={countrySelection}
              />
            </PIMTabPanel>
            <PIMTabPanel value={displayedTabPanel} index={9}>
              <WorkSafety
                onDirtyChanged={handleDirtyChanged}
                cleanUpForm={handleClean}
                saveOnCloseRef={saveOnCloseRef}
                translationCountryCode={translationCountryCode}
                countrySelectionComponent={countrySelection}
              />
            </PIMTabPanel>
            <PIMTabPanel value={displayedTabPanel} index={10}>
              <TechnicalDatasheetAttachments />
            </PIMTabPanel>
          </Grid>
        </Grid>
        <SaveChangesDialog
          handleClose={() => changeTabPanel(selectedTabPanel)}
          handleCancel={handleDialogClose}
          handleSaveAndClose={async () => {
            if (saveOnCloseRef.current.handleSave !== null) {
              try {
                await saveOnCloseRef.current.handleSave();
                changeTabPanel(selectedTabPanel);
              } catch {
                handleDialogClose();
              }
            }
          }}
          open={showDialog}
          fullScreen={fullScreen}
          title={t('Wollen Sie die vorgenommenen Änderungen speichern?')}
          saveText={t('Nicht gespeicherte Änderungen gehen verloren')}
        />
      </>
    );
  }
}

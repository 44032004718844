import React, { ReactElement } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ExpansionTrackingAccordion } from '../../../../shared/Components/Accordion';
import PIMAccordionDetails from '../../../../shared/Components/PIMAccordionDetails';
import PIMAccordionSummary from '../../../../shared/Components/PIMAccordionSummary';
import { RightIcon } from '../../../../shared/icons';
import PIMFormikTextField from '../../../../shared/Components/PIMFormikTextField';
import { ITechnicalDetailAccordions } from './TechnicalDetail';
import { useTranslation } from 'react-i18next';

const useClassess = makeStyles(() =>
  createStyles({
    AccordionDetails: {
      padding: '16px 24px',
    },
    AccordionDetailsContent: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    TextField: {
      '&.MuiTextField-root': {
        marginTop: '8px',
        marginBottom: '8px',
      },
    },
  })
);

interface IProps {
  originCountry: boolean;
  onDirtyChanged: () => void;
  onExpandAccordion: (expanded: ITechnicalDetailAccordions) => void;
  expandedAccordions: ITechnicalDetailAccordions;
  authorizedToWrite: boolean;
}

export function TechnicalDetailAttributes(props: IProps): ReactElement {
  const classes = useClassess();
  const { t } = useTranslation();
  const { originCountry, onExpandAccordion, expandedAccordions, authorizedToWrite } = props;
  return (
    <>
      <ExpansionTrackingAccordion
        onChange={(e, expanded) => onExpandAccordion({ ...props.expandedAccordions, general: expanded })}
        expanded={expandedAccordions.general}>
        <PIMAccordionSummary expandIcon={<RightIcon />}>
          {t('Chemische/Physikalische Eigenschaften')}
        </PIMAccordionSummary>
        <PIMAccordionDetails className={classes.AccordionDetails}>
          <div className={classes.AccordionDetailsContent}>
            <PIMFormikTextField
              label={t('chemische Basis')}
              name={'chemicalBase'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Dichte')}
              name={'dense'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Schüttdichte')}
              name={'bulkDensity'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Frischmörteldichte')}
              name={'freshMortarDensity'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Größtkorn')}
              name={'largestGrain'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Festkörpergehalt')}
              name={'solidStateContent'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Viskosität')}
              name={'viscosity'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('µ-Wert')}
              name={'µValue'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('sD-Wert')}
              name={'sdValue'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('pH-Wert')}
              name={'phValue'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Aussehen')}
              name={'appearance'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Farbe')}
              name={'color'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Glanz')}
              name={'gloss'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
          </div>
        </PIMAccordionDetails>
      </ExpansionTrackingAccordion>
      <ExpansionTrackingAccordion
        onChange={(e, expanded) => onExpandAccordion({ ...props.expandedAccordions, BAT: expanded })}
        expanded={expandedAccordions.BAT}>
        <PIMAccordionSummary expandIcon={<RightIcon />}>{t('Verarbeitungseigenschaften')}</PIMAccordionSummary>
        <PIMAccordionDetails className={classes.AccordionDetails}>
          <div className={classes.AccordionDetailsContent}>
            <PIMFormikTextField
              label={t('Verbrauch')}
              name={'consumption'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Ergiebigkeit')}
              name={'productiveness'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Wasserbedarf')}
              name={'waterDemand'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Mischungsverhältnis')}
              name={'mixingRatio'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Verdünnung')}
              name={'dilution'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Schichtdicke')}
              name={'layerThickness'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Fugenbreite')}
              name={'jointWidth'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Trockenschichtdicke')}
              name={'dryFilmThickness'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Ablüftezeit')}
              name={'ventilationTime'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Einlegezeit')}
              name={'insertionTime'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Verlegereife')}
              name={'readyForPublishing'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Regenfestigkeit')}
              name={'rainproof'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Endfestigkeit')}
              name={'finalStrength'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Schleifbarkeit')}
              name={'grindability'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('mechanische Belastbarkeit')}
              name={'mechanicallyResilient'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('chemische Belastbarkeit')}
              name={'chemicallyResistant'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Rastzeit')}
              name={'restingTime'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Topfzeit')}
              name={'potLife'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Verarbeitungszeit')}
              name={'processingTime'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Offenzeit')}
              name={'openTime'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Hautbildezeit')}
              name={'skinImageTime'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Korrigierbarkeit')}
              name={'correctability'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Trockenzeit nach 1. Auftrag')}
              name={'dryingTimeAfterFirstApplication'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Trocknungszeit')}
              name={'dryingTime'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Durchhärtung')}
              name={'curing'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Überarbeitbarkeit')}
              name={'revisability'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Begehbarkeit für nächsten Arbeitsschritt')}
              name={'accessibilityForNextWorkStep'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Begehbarkeit')}
              name={'accessibility'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Verfugbarkeit')}
              name={'grouting'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('volle Belastbarkeit')}
              name={'fullyLoadable'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Trocknungsgeschwindigkeit')}
              name={'dryingSpeed'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            {/* </fieldset> */}
          </div>
        </PIMAccordionDetails>
      </ExpansionTrackingAccordion>
      <ExpansionTrackingAccordion
        onChange={(e, expanded) => onExpandAccordion({ ...props.expandedAccordions, FLT: expanded })}
        expanded={expandedAccordions.FLT}>
        <PIMAccordionSummary expandIcon={<RightIcon />}>{t('Prüfungen')}</PIMAccordionSummary>

        <PIMAccordionDetails className={classes.AccordionDetails}>
          <div className={classes.AccordionDetailsContent}>
            <PIMFormikTextField
              label={t('Zertifikate/Prüfberichte/errichte Klasse')}
              name={'certificateTestReportEstablishClass'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Biegezugfestigkeit')}
              name={'flexuralStrength'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Druckfestigkeit')}
              name={'compressiveStrength'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Zugfestigkeit')}
              name={'tensileStrength'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Scherfestigkeit')}
              name={'shearStrength'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Schälfestigkeit')}
              name={'peelStrength'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Auszugsfestigkeit')}
              name={'pullOutStrength'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Shore-A-Härte')}
              name={'shoreAHardness'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Shore-D-Härte')}
              name={'shoreDHardness'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Schwindverhalten')}
              name={'shrinkage'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Quellverhalten')}
              name={'swellingBehaviour'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Ausbreitmaß')}
              name={'slump'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Rissüberbrückung')}
              name={'crackBridging'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('E-Modul')}
              name={'eModule'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Bruchdehnung')}
              name={'elongationAtBreak'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Dauerdehnfähigkeit')}
              name={'permanentlyExpandable'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Lineare Verformung')}
              name={'linearDeformation'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('zulässige Gesamtverformung')}
              name={'permissibleDeformation'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Wasserdruckbeständigkeit')}
              name={'waterPressureResistance'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Wasserdampfdiffusionsfähigkeit')}
              name={'waterVapourDiffusionRate'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Wasserdampfdurchlässigkeit')}
              name={'waterVapourPermeability'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Wasserdichtheit')}
              name={'permeabilityForWater'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Kapillare Wasseraufnahme')}
              name={'capillaryWaterIntake'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('UV-Beständigkeit')}
              name={'uvResistance'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('CO2-Durchlässigkeit')}
              name={'cO2Permeability'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Karbonatisierungswiderstand')}
              name={'highCarbonizationResistance'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Brandklasse')}
              name={'fireClass'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Temperaturwechselbeständigkeit')}
              name={'thermoShockResistance'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Temperaturbeständigkeit')}
              name={'temperatureResistance'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Hitzebeständigkeit')}
              name={'heatResistant'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Flammpunkt')}
              name={'flamePoint'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Glasübergangstemperatur')}
              name={'glassTransitionTemperature'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Thermischer Ausdehnungskoeffizient')}
              name={'coefficientOfThermalExpansion'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Eindringtiefe')}
              name={'penetrationDepth'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Nassabriebklasse')}
              name={'wetAbrasionClass'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Deckvermögen')}
              name={'coveringPower'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Glanzgrad')}
              name={'glossLevel'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Weißgrad')}
              name={'whiteness'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Chemikalienbeständigkeit')}
              name={'chemicalResistance'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Ableitwiderstand')}
              name={'leakageResistance'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Elektrische Widerstandsfähigkeit')}
              name={'electricalResistivity'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
          </div>
        </PIMAccordionDetails>
      </ExpansionTrackingAccordion>
      <ExpansionTrackingAccordion
        onChange={(e, expanded) => onExpandAccordion({ ...props.expandedAccordions, FAT: expanded })}
        expanded={expandedAccordions.FAT}>
        <PIMAccordionSummary expandIcon={<RightIcon />}>{t('Allgemeine Angaben')}</PIMAccordionSummary>
        <PIMAccordionDetails className={classes.AccordionDetails}>
          <div className={classes.AccordionDetailsContent}>
            <PIMFormikTextField
              label={t('Objekt- und Material-Verarbeitungstemperatur')}
              name={'objectAndMaterialWorkingTemperature'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Untergrundtemperatur')}
              name={'substrateTemperature'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Verarbeitungstemperatur')}
              name={'workingTemperature'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Zulässige relative Luftfeuchtigkeit')}
              name={'permissibleRelativeAirHumidity'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Materialdicke')}
              name={'materialThickness'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Abmessungen')}
              name={'dimension'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Gewicht')}
              name={'weight'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Flächengewicht')}
              name={'basisWeight'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Zusatz Attribut Titel 1')}
              name={'extraAttribute1'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Zusatz Attribut Textinhalt 1')}
              name={'extraTextAttribute1'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Zusatz Attribut Titel 2')}
              name={'extraAttribute2'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Zusatz Attribut Textinhalt 2')}
              name={'extraTextAttribute2'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Zusatz Attribut Titel 3')}
              name={'extraAttribute3'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Zusatz Attribut Textinhalt 3')}
              name={'extraTextAttribute3'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Zusatz Attribut Titel 4')}
              name={'extraAttribute4'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Zusatz Attribut Textinhalt 4')}
              name={'extraTextAttribute4'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Zusatz Attribut Titel 5')}
              name={'extraAttribute5'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
            <PIMFormikTextField
              label={t('Zusatz Attribut Textinhalt 5')}
              name={'extraTextAttribute5'}
              className={classes.TextField}
              fullWidth
              multiline
              disabled={!originCountry || !authorizedToWrite}
            />
          </div>
        </PIMAccordionDetails>
      </ExpansionTrackingAccordion>
    </>
  );
}

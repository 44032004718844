import React from 'react';
import { AppBar, Box, Grid, makeStyles, Tab, Tabs } from '@material-ui/core';
import ArticleList from '../list/ArticleList';
import { ArticleEditSidebar } from '../list/ArticleEditSidebar';
import { ProductManagement } from '../ProductManagement/ProductManagement';
import { useAsync } from 'react-async-hook';
import { ArticleResponse, useMurexinPimContextApi } from '../../../api';
import { CommentBox } from '../../../layouts/Comments/CommentBox';
import { useParams } from 'react-router-dom';
import { CircleLoader } from '../../../utils/CircleLoader';
import { ArticleSideBar } from '../list/ArticleSideBar';
import { DocumentsAndLeafletsMainPanel } from '../DocumentsAndLeaflets';
import { ProductPortfolio } from '../ProductPortfolio';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  SubMenu: {
    height: '100%',
    flexDirection: 'column',
  },
  Title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontSize: '36px',
    lineHeight: '43px',
    verticalAlign: 'Top',
    paddingLeft: theme.spacing(1),
    marginBottom: '24px',
    marginTop: '40px',
  },
  TabPanel: {
    flex: 1,
  },
  indicator: {
    backgroundColor: 'black',
    height: '10px',
    top: '45px',
    minWidth: '45px',
  },
  Tab: {
    minWidth: 45,
    maxWidth: 400,
    '& .MuiTab-wrapper': {
      textTransform: 'none',
      fontSize: '20px',
      fontWeight: '400',
    },
  },
  TabPanelGrid: {
    height: '100%',
  },
  background: {
    backgroundColor: theme.palette.background.default,
    color: 'black',
  },
  Sidebar: {
    marginTop: '-231px',
    zIndex: 1101,
    backgroundColor: 'white',
  },
  boxPadding: {
    height: '100%',
    padding: '0px',
    paddingLeft: theme.spacing(1),
  },
  Container: {
    flexDirection: 'column',
  },
  AppBar: {
    '&.MuiAppBar-root': {
      zIndex: 0,
    },
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface IParams {
  country: string;
  materialId?: string;
}

function TabPanel(props: TabPanelProps): JSX.Element {
  const { children, value, index, ...other } = props;
  const classes = useStyles();
  return (
    <div
      className={classes.TabPanel}
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3} className={classes.boxPadding}>
          {children}
        </Box>
      )}
    </div>
  );
}

export function SubMenu(): JSX.Element {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [view, setView] = React.useState(0);
  const [articleIdState, setArticleId] = React.useState(0);
  const { country, materialId } = useParams<IParams>();
  const { t } = useTranslation();

  function updateValue(newView: number, articleId?: number) {
    setView(newView);
    setArticleId(articleId ? articleId : 0);
  }

  const articlesResponse = useAsync(async () => {
    setValue(0); // Reset Tab to overview
    const response = await api.articleService.articleServiceArticleListMaterialGroupDetail(country, Number(materialId));
    return response.data ?? [];
  }, [country, materialId]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    if (newValue === 0) {
      setView(0);
    } else {
      setView(3);
    }
  };

  const api = useMurexinPimContextApi();

  const materialGroup = useAsync(
    () => api.materialGroupService.materialGroupServiceMaterialGroupDetail(country, Number(materialId)),
    [country, materialId]
  );

  const getMaterialGroupTitle = (): string => {
    let materialGroupTitle = materialGroup.result?.data?.materialGroupTitle ?? '';

    if (materialGroupTitle == null || materialGroupTitle.trim() === '') {
      materialGroupTitle = materialGroup.result?.data?.materialGroupDescription ?? '';
    }

    return materialGroupTitle;
  };

  if (materialGroup.loading) {
    return <CircleLoader open={true} />;
  } else {
    return (
      <Grid container className={classes.SubMenu} spacing={0}>
        <Grid container item xs={12} direction={'column'}>
          <AppBar elevation={0} position='static' className={classes.AppBar}>
            <Tabs
              value={value}
              onChange={handleChange}
              classes={{ root: classes.background }}
              TabIndicatorProps={{ className: classes.indicator }}>
              <Tab label={t('Übersicht')} className={classes.Tab} />
              <Tab label={t('Dokumente und Merkblätter')} className={classes.Tab} />
              <Tab label={t('Produktmanagement')} className={classes.Tab} />
              <Tab label={t('Produktportfolio')} className={classes.Tab} />
            </Tabs>
          </AppBar>
          <p className={classes.Title}>{getMaterialGroupTitle()}</p>
          <TabPanel value={value} index={0}>
            <Grid container className={classes.TabPanelGrid}>
              <Grid item xs={8}>
                <ArticleList
                  updateValue={updateValue}
                  articleList={articlesResponse.result}
                  erpImport={materialGroup.result?.data?.erpImport}
                />
              </Grid>
              {view === 0 ? (
                <Grid item xs={4} className={classes.Sidebar}>
                  <ArticleSideBar imageStoreMaterialGroupId={materialGroup.result?.data?.imageStoreMaterialGroupId} />
                </Grid>
              ) : view === 2 ? (
                <Grid item xs={4} className={classes.Sidebar}>
                  <Grid container>
                    <LoadEditBar
                      articleId={articleIdState}
                      updateValue={updateValue}
                      reloadArticle={async () => await articlesResponse.execute()}
                    />
                  </Grid>
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <CommentBox />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <DocumentsAndLeafletsMainPanel />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <ProductManagement erpImport={materialGroup.result?.data?.erpImport} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <ProductPortfolio />
          </TabPanel>
        </Grid>
      </Grid>
    );
  }
}

interface IEditProps {
  articleId: number;
  updateValue: (view: number, articleId?: number) => void;
  reloadArticle: () => void;
}

export function LoadEditBar(props: IEditProps): JSX.Element {
  const { articleId, updateValue, reloadArticle } = props;
  const api = useMurexinPimContextApi();
  const { country, materialId } = useParams<IParams>();

  const articleResponse = useAsync(async () => {
    if (articleId <= 0) {
      const newArticle: ArticleResponse = {};
      return newArticle;
    } else {
      const response = await api.articleService.articleServiceArticleDetail(country, articleId);
      return response.data ?? {};
    }
  }, [country, articleId]);

  if (articleResponse.loading) {
    return <CircleLoader open={true} />;
  } else if (articleResponse.result) {
    return (
      <ArticleEditSidebar
        articleResponse={articleResponse.result}
        countryCode={country}
        materialGroupId={Number(materialId)}
        articleId={articleId}
        updateValue={updateValue}
        reload={reloadArticle}
      />
    );
  } else {
    return <div>Error</div>;
  }
}

import React, { useContext } from 'react';
import { createStyles, makeStyles, Grid } from '@material-ui/core';
import PIMButton from '../../../shared/Components/PIMButton';
import { Form, Formik } from 'formik';
import PIMFormikTextField from '../../../shared/Components/PIMFormikTextField';
import { DivisionCountryResponseModel, useMurexinPimContextApi } from '../../../api';
import { NotificationContext } from '../../../contexts/NotificationContext';
import { useTranslation } from 'react-i18next';
import { IRouterParams } from '../../../routes';
import { useParams } from 'react-router-dom';

interface IProps {
  spartenModel: DivisionCountryResponseModel;
  countryCode: string;
  countryName: string;
  onClose?: () => void;
  onSave?: () => void;
}

export function DivisionDescriptionEditSidebar(props: IProps): JSX.Element {
  const classes = useStyles();
  const api = useMurexinPimContextApi();
  const notification = useContext(NotificationContext);
  const { t } = useTranslation();
  const { spartenModel, countryName, onClose } = props;
  const { country } = useParams<IRouterParams>();

  const updateTransalation = async (data: DivisionCountryResponseModel) => {
    try {
      await api.divisionService.divisionServiceDivisionCountryCreate(country, {
        DivisionId: data.divisionId,
        CountryCode: data.countryCode,
        DivisionDescription: data.divisionCountryDescription,
      });
      notification.info(t('Update successfull'));
      if (props.onSave) props.onSave();
    } catch {
      notification.warning(t('Update failed'));
    }
  };

  return (
    <Grid container className={classes.Container}>
      <Formik initialValues={spartenModel} onSubmit={updateTransalation} enableReinitialize={true}>
        {(formik) => (
          <Form className={classes.Form}>
            <Grid item xs={12} className={classes.ActionButtonRow}>
              <PIMButton
                icon='close'
                className={classes.TopButtons}
                disabled={formik.isSubmitting}
                onClick={onClose}
                type='reset'>
                {t('Abbrechen')}
              </PIMButton>
              <PIMButton color='primary' icon='save' busy={formik.isSubmitting} disabled={!formik.dirty} type='submit'>
                {t('Speichern')}
              </PIMButton>
            </Grid>
            <Grid item xs={12}>
              {t('Spartenbezeichnungen bearbeiten')}
            </Grid>
            <Grid item xs={12}>
              <PIMFormikTextField label={t('Sparten-Code')} name='divisionName' disabled fullWidth />
              <PIMFormikTextField
                label={t('Spartenbezeichnung Original')}
                name='originalDivisionDescription'
                disabled
                fullWidth
              />
              <PIMFormikTextField
                label={t('Spartenbezeichnung') + ' ' + countryName}
                name='divisionCountryDescription'
                fullWidth
              />
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    Container: {
      margin: '0px',
      backgroundColor: 'white',
      padding: '72px 40px 24px 40px',
      height: '100%',
    },
    ActionButtonRow: {
      marginBottom: '24px',
    },
    TopButtons: {
      marginRight: '24px',
    },
    Form: {
      width: '100%',
    },
  })
);

import React from 'react';

export function AddIcon(): JSX.Element {
  return (
    <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M12.75 7.75V16.75' stroke='black' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8.25 12.25H17.25' stroke='black' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M1.5 12.25C1.5 15.2337 2.68526 18.0952 4.79505 20.205C6.90483 22.3147 9.76631 23.5 12.75 23.5C15.7337 23.5 18.5952 22.3147 20.705 20.205C22.8147 18.0952 24 15.2337 24 12.25C24 9.26631 22.8147 6.40483 20.705 4.29505C18.5952 2.18526 15.7337 1 12.75 1C9.76631 1 6.90483 2.18526 4.79505 4.29505C2.68526 6.40483 1.5 9.26631 1.5 12.25V12.25Z'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

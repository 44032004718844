import React, { ReactElement, useState } from 'react';
import { ExpansionTrackingAccordion } from '../../../../shared/Components/Accordion';
import PIMAccordionSummary from '../../../../shared/Components/PIMAccordionSummary';
import PIMAccordionDetails from '../../../../shared/Components/PIMAccordionDetails';
import { CategoryTranslationResponse, SealOfApproval } from './SealOfApprovalAndNorm';
import { DataView } from '../../../../shared/Enum/DataView';
import TileView from '../../../../shared/Components/TileView';
import ListView from '../../../../shared/Components/ListView';
import ImageItem from '../../../../shared/Components/ImageItem';
import Utility from '../../../../shared/Utility';
import { GridColumns, GridRenderCellParams, GridValueGetterParams, GridValueFormatterParams } from '@mui/x-data-grid';
import { createStyles, makeStyles, Card } from '@material-ui/core';
import { SelectedIcon } from '../../../../shared/icons';
import {
  CategoryResponse,
  SealOfApprovalAndNormMaterialGroupResponse,
  SealOfApprovalImageResponse,
  SealOfApprovalResponse,
} from '../../../../api';
import _ from 'lodash';
import { ISortInformation, SortImageListDropDown } from '../../../../layouts/CustomImageList/SortImageListDropDown';
import { Grid } from '@mui/material';
import PIMFormikTextField from '../../../../shared/Components/PIMFormikTextField';
import { useTranslation } from 'react-i18next';
import { ApiImageUrlsBuilder } from '../../../../utils/apiImageUrls';
import PIMTextField from '../../../../shared/Components/PIMTextField';

const useStyles = makeStyles(() =>
  createStyles({
    Accordion: {
      boxShadow: 'none',
      marginRight: '16px',
      marginBottom: '8px',
      '&.MuiAccordion-root.Mui-expanded': {
        marginRight: '16px',
      },
      '&.MuiAccordion-root:before': {
        backgroundColor: 'rgba(0, 0, 0, 0)',
      },
    },
    AccordionSummary: {
      background: '#FFFFFF',
      height: '50px !important',
      minHeight: '50px !important',
      boxShadow: '0px 1px 0px 0px #00000040',
    },
    AccordionDetails: {
      display: 'grid',
    },
    Card: {
      boxShadow: 'none',
    },
    ColumnImage: {
      height: '24px',
      width: '24px',
    },
    FilterRow: {
      display: 'flex !important',
    },
    NormTextField: {
      '&.MuiFormControl-root': {
        marginRight: '16px',
      },
    },
    Filter: {
      width: 'calc(100% - 8px)',
      marginTop: '8px',
    },
    SortDropdown: {
      marginRight: '16px',
      marginTop: '8px',
      '& .MuiFilledInput-root': {
        margin: '0px 0px 0px 0px',
      },
    },
    makeHidden: {
      visibility: 'hidden',
    },
  })
);

interface IProps {
  country: string;
  categoriesResponse: CategoryResponse[];
  sealOfApprovalImagesResponse: SealOfApprovalImageResponse[];
  sealOfApprovalAndNormData: SealOfApprovalAndNormMaterialGroupResponse;
  dataView: DataView;
  readOnly?: boolean;
  showOnlyUsedSeals?: boolean;
  authorizedToWrite?: boolean;
  selectedSeal?: SealOfApproval;
  onClickCategory: (category: CategoryTranslationResponse, expanded: boolean) => void;
  onClickSealOfApproval?: (sealOfApproval: SealOfApproval | undefined) => void;
}

export function SealOfApprovalAndNormAttributes(props: IProps): ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();

  const listViewColumns: GridColumns = [
    { field: 'id', headerName: '', hide: true },
    {
      field: 'image',
      headerName: ' ',
      sortable: false,
      filterable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      // eslint-disable-next-line react/display-name
      renderCell: (params: GridRenderCellParams) => (
        <img
          className={classes.ColumnImage}
          src={ApiImageUrlsBuilder.buildSealOfApprovalAndNormImageUrl(
            country,
            (params.row as SealOfApproval).fullName ?? ''
          )}
        />
      ),
      minWidth: 50,
      width: 50,
    },
    {
      field: 'fileName',
      headerName: t('Dateiname'),
      flex: 1,
      minWidth: 200,
      sortable: false,
      filterable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
    },
    {
      field: 'updatedAt',
      headerName: t('Zuletzt bearbeitet'),
      headerAlign: 'left',
      align: 'left',
      type: 'dateTime',
      valueGetter: (params: GridValueGetterParams) => params.value && Utility.formatDateTime(params.value as Date),
      width: 150,
      sortable: false,
      filterable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
    },
    {
      field: 'updatedBy',
      headerName: t('Bearbeiter'),
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 80,
      width: 180,
      sortable: false,
      filterable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
    },
    {
      field: 'fileSize',
      headerName: t('Größe'),
      headerAlign: 'right',
      align: 'right',
      valueFormatter: (params: GridValueFormatterParams) =>
        params.value && Utility.formatFileSize(params.value as number),
      minWidth: 100,
      width: 120,
      sortable: false,
      filterable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
    },
    {
      field: 'isUsed',
      headerName: t('In Verwendung'),
      headerAlign: 'center',
      width: 160,
      align: 'center',
      sortable: false,
      filterable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams): React.ReactNode => {
        if (params.value === true) {
          return <SelectedIcon />;
        } else {
          return <></>;
        }
      },
    },
  ];

  const {
    country,
    categoriesResponse,
    sealOfApprovalImagesResponse,
    sealOfApprovalAndNormData,
    dataView,
    readOnly,
    showOnlyUsedSeals,
    authorizedToWrite,
    selectedSeal,
    onClickCategory,
    onClickSealOfApproval,
  } = props;
  const [searchText, setSearchText] = useState<string>('');
  const [sortDirection, setSortDirection] = useState<ISortInformation>({
    direction: 'asc',
    column: 'fileName',
  });

  const getOrderdSealOfApproval = (allSealOfApproval: SealOfApproval[]): SealOfApproval[] => {
    return _.orderBy(allSealOfApproval, [sortDirection.column], [sortDirection.direction]);
  };

  const onChangeSortDirection = (value: ISortInformation) => {
    setSortDirection(value);
  };

  const getAllSealOfApprovalByCategory = (
    category: CategoryTranslationResponse,
    selectedSealOfApproval: SealOfApprovalResponse[] | null | undefined
  ): SealOfApproval[] => {
    const allSealOfApprovalImagesByCategory = sealOfApprovalImagesResponse.filter(
      (sealOfApprovalImage: SealOfApprovalImageResponse) => sealOfApprovalImage.category?.id === category.id
    );
    const allSealOfApprovalByCategory = allSealOfApprovalImagesByCategory.map(
      (sealOfApprovalImage: SealOfApprovalImageResponse) => {
        let isUsed = false;
        if (selectedSealOfApproval) {
          const index = selectedSealOfApproval.findIndex(
            (s: SealOfApprovalResponse) => s.fullName === sealOfApprovalImage.fullName
          );
          if (index >= 0) {
            isUsed = true;
          }
        }

        return {
          ...sealOfApprovalImage,
          isUsed,
        } as SealOfApproval;
      }
    );

    return allSealOfApprovalByCategory;
  };

  const renderCategories = (data: SealOfApprovalAndNormMaterialGroupResponse): React.ReactNode => {
    return categoriesResponse.map((category: CategoryTranslationResponse) => {
      let allSealOfApprovalByCategory = getAllSealOfApprovalByCategory(category, data?.sealOfApprovals);

      if (showOnlyUsedSeals) {
        allSealOfApprovalByCategory = allSealOfApprovalByCategory.filter((seal) => seal.isUsed);
      }

      const isSearching = !!searchText;

      if (isSearching) {
        const searchValue = searchText.toLocaleLowerCase();
        allSealOfApprovalByCategory = allSealOfApprovalByCategory.filter((d) =>
          d.fileName.toLocaleLowerCase().includes(searchValue)
        );
      }

      const orderedAllSealOfApprovalByCategory = getOrderdSealOfApproval(allSealOfApprovalByCategory);

      const hasSeals = orderedAllSealOfApprovalByCategory.length > 0;
      const isExpanded = isSearching ? hasSeals : category.isExpanded;
      const categorySummary = `${category.name ?? ''} (${orderedAllSealOfApprovalByCategory.length})`;

      return (
        <ExpansionTrackingAccordion
          key={category.id}
          hidden={!hasSeals && isSearching}
          className={classes.Accordion}
          disabled={!hasSeals}
          expanded={isExpanded}
          onChange={(__, expanded) => onClickCategory(category, expanded)}>
          <PIMAccordionSummary className={classes.AccordionSummary}>{categorySummary}</PIMAccordionSummary>
          {hasSeals && (
            <PIMAccordionDetails className={classes.AccordionDetails}>
              <Card className={classes.Card}>
                {dataView === DataView.List ? (
                  <ListView<SealOfApproval>
                    columns={listViewColumns}
                    data={orderedAllSealOfApprovalByCategory}
                    onChangeSelection={(item) => onClickSealOfApproval && onClickSealOfApproval(item)}
                    selectedItem={selectedSeal}
                    disabledSelection={readOnly}
                  />
                ) : (
                  <TileView>
                    {orderedAllSealOfApprovalByCategory.map((sealOfApproval: SealOfApproval) => {
                      let updatedAt = new Date();
                      if (sealOfApproval.updatedAt !== undefined) updatedAt = new Date(sealOfApproval.updatedAt);

                      // TODO: change imagesSrc to link with id
                      return (
                        <ImageItem
                          description={sealOfApproval.fileName}
                          showSubDescription={true}
                          showSelected={selectedSeal?.fullName === sealOfApproval.fullName}
                          subDescription={Utility.formatDateTime(updatedAt)}
                          isClickable={!readOnly}
                          isSelected={sealOfApproval.isUsed}
                          onClickItem={() => onClickSealOfApproval && onClickSealOfApproval(sealOfApproval)}
                          key={sealOfApproval.id}
                          imageSrc={ApiImageUrlsBuilder.buildSealOfApprovalAndNormImageUrl(
                            country,
                            sealOfApproval.fullName
                          )}
                        />
                      );
                    })}
                  </TileView>
                )}
              </Card>
            </PIMAccordionDetails>
          )}
        </ExpansionTrackingAccordion>
      );
    });
  };

  const handleFilterSearchTextChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    event.stopPropagation();
    setSearchText(event?.target?.value ?? '');
  };

  return (
    <>
      <Grid container item xs={12}>
        <PIMFormikTextField
          className={classes.NormTextField}
          name='norm'
          label={t('Geprüft nach')}
          fullWidth
          multiline
          disabled={!authorizedToWrite || readOnly}
        />
      </Grid>
      <Grid
        container
        item
        xs={12}
        className={!readOnly ? `${classes.FilterRow}` : `${classes.FilterRow} ${classes.makeHidden}`}>
        <Grid item xs={8}>
          <PIMTextField
            label={t('Filter')}
            onChange={handleFilterSearchTextChange}
            fullWidth
            className={classes.Filter}
          />
        </Grid>
        <Grid item xs={4}>
          <SortImageListDropDown
            possibleColumns={['fileName', 'fileSize', 'updatedAt', 'updatedBy']}
            currentSortDirection={sortDirection}
            selectSortDirection={onChangeSortDirection}
            className={classes.SortDropdown}
          />
        </Grid>
      </Grid>
      {renderCategories(sealOfApprovalAndNormData)}
    </>
  );
}

import React, { ReactElement, useContext } from 'react';
import {
  useMurexinPimContextApi,
  CountryResponse,
  MailNotificationRequest,
  LocalizationSectionCode,
} from '../../../../api/MurexinPimApi';
import { useAsync } from 'react-async-hook';
import { useParams } from 'react-router-dom';
import { IRouterParams } from '../../../../routes';
import { Grid, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { NotificationContext } from '../../../../contexts/NotificationContext';
import { Formik, Form, FieldArray, FieldArrayRenderProps } from 'formik';
import { CircleLoader } from '../../../../utils/CircleLoader';
import PIMButton from '../../../../shared/Components/PIMButton';
import PIMFormikTextField from '../../../../shared/Components/PIMFormikTextField';
import PIMFormikCheckbox from '../../../../shared/Components/PIMFormikCheckbox';
import PIMTextField from '../../../../shared/Components/PIMTextField';
import { LoginContext } from '../../../../auth';
import { COUNTRY_NAME_FOR_GENERAL_ENTRY, GetMailingListFromCountries } from '../../../Admin/MailingList/MailingList';

interface UserData {
  email: string;
  fullName: string;
}

const EMAIL_PLACEHOLDER = '<E-Mail>';
const NAME_PLACEHOLDER = '<Name>';

export function InfoMail(): ReactElement {
  const classes = useStyles();
  const api = useMurexinPimContextApi();
  const notification = React.useContext(NotificationContext);
  const { country, materialId } = useParams<IRouterParams>();
  const { user } = useContext(LoginContext);
  const { t, i18n } = useTranslation();
  const translationGerman = i18n.getFixedT('de', LocalizationSectionCode.STATIC);
  const translationEnglish = i18n.getFixedT('en', LocalizationSectionCode.STATIC);

  const materialGroupId = Number(materialId);
  const userData: UserData = {
    email: user.email ?? EMAIL_PLACEHOLDER,
    fullName: user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : NAME_PLACEHOLDER,
  };

  const countriesResponse = useAsync(async () => {
    const response = await api.countryService.countryServiceList();
    const data = response.data ?? [];
    return GetMailingListFromCountries(data, t);
  }, []);

  const materialGroupCountryResponse = useAsync(async () => {
    const response = await api.materialGroupCountry.materialGroupCountryDetail(country, materialGroupId);
    return response.data ?? [];
  }, [country, materialGroupId]);

  const materialGroupResponse = useAsync(async () => {
    const result = await api.materialGroupService.materialGroupServiceMaterialGroupDetail(country, materialGroupId);
    return result.data ?? {};
  }, [country, materialGroupId]);

  const isLoading = () => {
    return countriesResponse.loading || materialGroupCountryResponse.loading || materialGroupResponse.loading;
  };

  const materialGroupOriginCountryCode: string = materialGroupResponse.result?.countryCode ?? '';

  const handleOnChangeCountry = (checked: boolean, countryCode: string, arrayHelpers: FieldArrayRenderProps) => {
    const index = (arrayHelpers.form.values as MailNotificationRequest).selectedMailingListCodes?.findIndex(
      (c: string) => c === countryCode
    );
    if (!!index) {
      if (checked) {
        arrayHelpers.push(countryCode);
      } else if (index >= 0) {
        arrayHelpers.remove(index);
      }
    }
  };

  const sendInfoMail = async (values: MailNotificationRequest, resetForm: () => void) => {
    const request: MailNotificationRequest = {
      ...values,
      selectedMailingListCodes: values.selectedMailingListCodes?.map((c) => {
        return c === COUNTRY_NAME_FOR_GENERAL_ENTRY ? '' : c;
      }),
    };

    try {
      await api.mail.mailSendCreate(country, request);
      resetForm();
      notification.success(t('E-Mail erfolgreich versendet'));
    } catch {
      notification.error(t('Fehler beim Senden der E-mail'));
    }
  };

  const SelectOrUnselectAllCountries = (checked: boolean, arrayHelpers: FieldArrayRenderProps) => {
    const length = (arrayHelpers.form.values as MailNotificationRequest).selectedMailingListCodes?.length ?? 0;

    if (countriesResponse.result !== undefined) {
      for (let i = length - 1; i >= 0; --i) {
        arrayHelpers.remove(i);
      }
      if (checked) {
        countriesResponse.result.forEach((countryResponse: CountryResponse) => {
          arrayHelpers.push(countryResponse.code);
        });
      }
    }
  };

  const getCountryListPart = (
    firstPart: boolean,
    values: MailNotificationRequest,
    arrayHelpers: FieldArrayRenderProps
  ): ReactElement[] => {
    const countryList = countriesResponse.result ?? [];

    const count = countriesResponse.result?.length ?? 0;

    let startIndex = 0;
    let endIndex = count;

    if (firstPart) {
      startIndex = 0;
      endIndex = Math.floor(count / 2 + (count % 2));
    } else {
      startIndex = Math.floor(count / 2 + (count % 2));
      endIndex = count;
    }

    const countriesResults = new Array<ReactElement>();

    for (let index = startIndex; index < endIndex; index++) {
      const countryResponse = countryList[index];
      countriesResults.push(
        <PIMFormikCheckbox
          name=''
          color='primary'
          checked={values.selectedMailingListCodes?.some((countryCode: string) => countryCode === countryResponse.code)}
          onChange={(event: React.ChangeEvent<{}>, checked: boolean) => {
            handleOnChangeCountry(checked, countryResponse.code ?? '', arrayHelpers);
          }}
          label={countryResponse.name}
          formControlProps={{ className: classes.CheckBox }}
          formControlLabelProps={{ labelPlacement: 'end' }}
        />
      );
    }

    return countriesResults;
  };

  const selectedCountries = [
    ...(materialGroupCountryResponse.result?.countryCodes ?? []),
    materialGroupOriginCountryCode,
  ];

  const initialInfoMailRequest: MailNotificationRequest = {
    selectedMailingListCodes: selectedCountries,
    materialGroupId,
    subject: translationEnglish('InfoMailBetreffVorlage', {
      materialGroupName: materialGroupResponse.result?.materialGroupDescription ?? '',
    }),
    body:
      translationGerman('InfoMailTextVorlage', {
        materialGroupName: materialGroupResponse.result?.materialGroupDescription ?? '',
        authorName: userData.fullName,
        authorEmail: userData.email,
        interpolation: { escapeValue: false },
      }) +
      '\n\n' +
      translationEnglish('InfoMailTextVorlage', {
        materialGroupName: materialGroupResponse.result?.materialGroupDescription ?? '',
        authorName: userData.fullName,
        authorEmail: userData.email,
        interpolation: { escapeValue: false },
      }),
  };

  if (isLoading()) {
    return <CircleLoader open={true} />;
  } else {
    return (
      <Formik
        initialValues={initialInfoMailRequest}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          await sendInfoMail(values, resetForm);
        }}>
        {({ values, resetForm, isSubmitting, dirty }) => (
          <Form>
            <Grid container>
              <Grid item xs={12}>
                <Grid item container xs={6} className={classes.Container}>
                  <Grid item container xs={12} justifyContent='flex-end' className={classes.ActionButtonRow}>
                    <PIMButton
                      icon='close'
                      className={classes.Button}
                      disabled={isSubmitting || !dirty}
                      onClick={() => {
                        notification.info(t('Daten zurückgesetzt'));
                        resetForm();
                      }}>
                      {t('Abbrechen')}
                    </PIMButton>
                    <PIMButton color='primary' type='submit' icon='send' busy={isSubmitting} disabled={isSubmitting}>
                      {t('Senden')}
                    </PIMButton>
                  </Grid>
                  <Grid container className={classes.Content}>
                    <Grid item xs={12}>
                      <PIMTextField
                        label={t('Warengruppe')}
                        value={materialGroupResponse.result?.materialGroupDescription ?? ''}
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <div className={classes.Div}>{t('E-Mail Verteiler')}</div>
                    </Grid>
                    <FieldArray name='selectedMailingListCodes'>
                      {(arrayHelpers) => (
                        <Grid item container direction='row' xs={12}>
                          <Grid item xs={4}>
                            <PIMFormikCheckbox
                              name=''
                              color='primary'
                              checked={
                                (values.selectedMailingListCodes?.length ?? 0) === countriesResponse.result?.length
                              }
                              onChange={(event: React.ChangeEvent<{}>, checked: boolean) =>
                                SelectOrUnselectAllCountries(checked, arrayHelpers)
                              }
                              label={t('Alle an-/abwählen')}
                              formControlProps={{ className: classes.CheckBox }}
                              formControlLabelProps={{ labelPlacement: 'end' }}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <Grid item container direction='column' xs={12}>
                              {getCountryListPart(true, values, arrayHelpers)}
                            </Grid>
                          </Grid>
                          <Grid item xs={4}>
                            <Grid item container direction='column' xs={12}>
                              {getCountryListPart(false, values, arrayHelpers)}
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </FieldArray>
                    <Grid item xs={12}>
                      <PIMFormikTextField label={t('Betreff')} name={'subject'} fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                      <PIMFormikTextField label={t('Text')} name={'body'} fullWidth multiline />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    );
  }
}

const useStyles = makeStyles(() => ({
  ActionButtonRow: {
    marginTop: '-96px',
  },
  Button: {
    marginRight: '24px',
  },
  CheckBox: {
    '&.MuiFormControl-root': {
      marginTop: '0px',
      marginBottom: '0px',
    },
  },
  Container: {
    padding: '16px 0px',
    background: 'white',
  },
  Content: {
    padding: '0px 24px',
  },
  Div: {
    padding: '16px 0px',
  },
}));

import * as React from 'react';
import { createStyles, makeStyles, Snackbar, Theme, useTheme } from '@material-ui/core';
import MuiAlert, { AlertProps, Color } from '@material-ui/lab/Alert';
import { SuccessIcon, InformationIcon, ErrorIcon } from '../shared/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    padding: {
      paddingTop: theme.spacing(4),
    },
    alert: {
      color: 'black',
      font: 'Roboto',
      fontSize: '16px',
      fontWeight: 'normal',
      borderRadius: '4px',
    },
    success: {
      background: `${theme.palette.success.main}19`,
      border: `1px solid ${theme.palette.success.main}`,
    },
    info: {
      background: `${theme.palette.info.main}19`,
      border: `1px solid ${theme.palette.info.main}`,
    },
    error: {
      background: `${theme.palette.error.main}19`,
      border: `1px solid ${theme.palette.error.main}`,
    },
  })
);

interface INotificationValue {
  info: (value: string) => void;
  error: (value: string) => void;
  warning: (value: string) => void;
  success: (value: string) => void;
}

export const NotificationContext = React.createContext<INotificationValue>({} as INotificationValue);

interface IProps {
  children: React.ReactNode;
}

export function NotificationProvider(props: IProps): JSX.Element {
  const [message, setMessage] = React.useState<string>('');
  const [open, setOpen] = React.useState<boolean>(false);
  const [color, setColor] = React.useState<Color>('info');
  const classes = useStyles();
  const theme = useTheme();

  function Alert(alertProps: AlertProps) {
    const { severity } = alertProps;
    return (
      <MuiAlert
        className={`${classes.alert} ${
          severity === 'success' ? classes.success : severity === 'info' ? classes.info : classes.error
        }`}
        iconMapping={{
          success: <SuccessIcon strokeColor={theme.palette.success.main} />,
          info: <InformationIcon />,
          error: <ErrorIcon strokeColor={theme.palette.error.main} />,
          warning: <ErrorIcon strokeColor={theme.palette.error.main} />,
        }}
        elevation={6}
        variant='filled'
        {...alertProps}
      />
    );
  }

  function showNotification(alertMessage: string, severity: Color) {
    setMessage(alertMessage);
    setColor(severity);
    setOpen(true);
  }

  function info(infoMessage: string) {
    showNotification(infoMessage, 'info');
  }
  function error(errorMessage: string) {
    showNotification(errorMessage, 'error');
  }
  function warning(warningMessage: string) {
    showNotification(warningMessage, 'warning');
  }
  function success(successMessage: string) {
    showNotification(successMessage, 'success');
  }

  const handleClose = (_: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <NotificationContext.Provider
      value={{
        info,
        error,
        warning,
        success,
      }}>
      <>
        {props.children}
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          autoHideDuration={5000}
          onClose={handleClose}
          className={classes.padding}>
          <Alert severity={color}>{message}</Alert>
        </Snackbar>
      </>
    </NotificationContext.Provider>
  );
}

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { LocalizationSectionCode } from '../api';

// Currently application doesn't wait for initialization of i18n to be completed.
// void is used to mark Promise as intentionally ignored.
void i18n
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: 'de',
    ns: LocalizationSectionCode.STATIC,
    defaultNS: LocalizationSectionCode.STATIC,
    debug: false,
    saveMissing: false,
    saveMissingTo: 'current',
    lowerCaseLng: true,
    parseMissingKeyHandler: (missingKey: string) => {
      return '<' + missingKey + '>';
    },
  });

export default i18n;

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { DataGrid, GridColumns, GridInputSelectionModel, GridSelectionModel } from '@mui/x-data-grid';

interface IListViewProps<DataRowType extends { id: number | string }> {
  selectedItem?: DataRowType;
  data: DataRowType[];
  columns: GridColumns;
  disabledSelection?: boolean;
  hideFooter?: boolean;
  disabledPagination?: boolean;
  noResultsMessage?: string;
  loading?: boolean;
  onChangeSelection?: (selectedItem: DataRowType | undefined) => void;
}

export function ListView<DataRowType extends { id: number | string }>(props: IListViewProps<DataRowType>): JSX.Element {
  const classes = useStyles();
  const { selectedItem, data, columns, hideFooter, disabledSelection, disabledPagination, noResultsMessage, loading } =
    props;
  const pageSizeOptions = [10, 25, 50];
  const [pageSize, setPageSize] = useState(pageSizeOptions[0]);

  const handleOnSelectionModelChange = (selectionModel: GridSelectionModel) => {
    if (props.onChangeSelection) {
      if (selectionModel.length === 1 && data !== null) {
        const selectedRow = data.find((row: DataRowType) => row.id === selectionModel[0]);
        props.onChangeSelection(selectedRow as DataRowType);
      }
    }
  };

  return (
    <DataGrid
      className={classes.dataGrid}
      rows={data}
      loading={loading}
      autoHeight
      columns={columns}
      rowHeight={48}
      headerHeight={64}
      pageSize={disabledPagination ? undefined : pageSize}
      hideFooter={hideFooter}
      hideFooterPagination={disabledPagination}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      rowsPerPageOptions={pageSizeOptions}
      selectionModel={selectedItem?.id as GridInputSelectionModel}
      onSelectionModelChange={handleOnSelectionModelChange}
      components={{
        // Collapses resize icon space, since nothing gets rendered
        // eslint-disable-next-line react/display-name
        ColumnResizeIcon: () => <></>,
        // eslint-disable-next-line react/display-name
        NoRowsOverlay: () => <div className={classes.NoRowsOverlay}>{noResultsMessage ?? 'No rows'}</div>,
      }}
      hideFooterSelectedRowCount
      disableColumnMenu
      disableSelectionOnClick={disabledSelection}
    />
  );
}

const useStyles = makeStyles(() => ({
  dataGrid: {
    backgroundColor: '#ffffff',
    border: 'none',
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 700,
      fontSize: 14,
      color: '#000000',
    },
    '& .MuiDataGrid-columnHeaders': {
      border: 'none',
    },
    '& .MuiDataGrid-columnHeader:focus': {
      outline: 'none',
    },
    '& .MuiDataGrid-cell': {
      fontWeight: 400,
      fontSize: 14,
      color: '#000000',
    },
    '& .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '& .MuiDataGrid-row:hover': {
      backgroundColor: 'rgba(255, 221, 0, 0.5)',
    },
    '& .MuiDataGrid-row.Mui-selected': {
      backgroundColor: 'rgba(255, 221, 0, 0.5)',
    },
    '& .MuiDataGrid-row.Mui-selected:hover': {
      backgroundColor: 'rgba(255, 221, 0, 0.5)',
    },
    '& .MuiDataGrid-footerContainer': {
      minHeight: 0,
      height: 32,
    },
  },
  NoRowsOverlay: {
    inset: '64px 0 0 0',
    display: 'flex',
    position: 'absolute',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(248, 248, 248, 0.38)',
  },
}));

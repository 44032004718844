import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { routes } from '../../routes/routes';
import { NavLink } from 'react-router-dom';
import { Button, DialogActions } from '@material-ui/core';
import { BreadCrumbContext } from './BreadCrumbContext';
import { QuickLinkRequest, useMurexinPimContextApi } from '../../api';
import DoneIcon from '@material-ui/icons/Done';
import { NotificationContext } from '../../contexts/NotificationContext';
import { SaveIcon } from '../../features/Article/ArticleDetailed/DetailedButton/SaveButton';
import Menu from '@mui/material/Menu';
import { Form, Formik } from 'formik';
import { FormikTextField } from '@dccs/react-formik-mui';
import { useTranslation } from 'react-i18next';
import { AddLinkIcon } from '../../shared/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginLeft: theme.spacing(2),
      marginTop: '24px',
      marginBottom: '24px',
      height: '28px',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
      '& .MuiDialogActions-root': {
        paddingTop: '8px',
      },
    },
    navigator: {
      fontSize: '16px',
      color: 'black',
    },
    font: {
      fontSize: 'inerhit',
      color: 'black',
    },
    MuiBreadcrumbsOl: {
      display: 'flex',
      alignItems: 'center',
      color: 'black',
      fontSize: 'medium',
    },
    title: {
      fontFamily: 'Roboto',
      fontStyle: 'Light',
      fontSize: '36px',
      lineHeight: '43px',
      verticalAlign: 'Top',
    },
    item: {
      marginTop: '10px',
      padding: '0 10px 10px 10px',
    },
    role: {
      height: 'auto',
    },
    user: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '16px',
    },
    userRoles: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '14px',
      color: '#797979',
    },
    userDivider: {
      backgroundColor: '#424242',
    },
    alignBtn: {
      float: 'right',
      margin: '10px',
    },
    create: {
      backgroundColor: '#F9D548',
      color: 'black',
      borderRadius: '4px',
      padding: '19px 24px',
      width: '172px',
    },
    QuickLinktitle: {
      fontSize: '16px',
      lineHeight: '18px',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'normal',
      color: '#000000',
    },
    displayFlex: {
      display: 'flex',
    },
    padding: {
      padding: '10px 16px',
    },
    t: {
      paddingTop: '8px',
      padding: '0px',
      '& .MuiDialogActions-root': {
        paddingTop: '8px',
        padding: '0px',
      },
    },
  })
);

interface IInfo {
  name?: string | null;
  id?: number;
}

interface IProps {
  infoProps: IInfo[];
  culture: string;
  isMaterial?: string;
}

export function CustomBreadCrumb(props: IProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const breadcrumb = React.useContext(BreadCrumbContext);
  const notification = React.useContext(NotificationContext);
  const api = useMurexinPimContextApi();
  const { infoProps } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function trimString() {
    const locationTest = window.location.origin;
    return window.location.href.slice(locationTest.length + 1);
  }

  function addQuickLink(value: string) {
    const trimmedString = trimString();
    const quicklink: QuickLinkRequest = {
      path: trimmedString,
      title: value,
      breadCrumbs: infoProps.map((info) => info.name).join(' » '),
    };
    api.quickLink
      .quickLinkCreate(quicklink)
      .then(() => {
        notification.success(t('Quicklink hinzugefügt'));
        breadcrumb.reload();
      })
      .catch(() => notification.error(t('Quicklink Speichern fehlgeschlagen')));
  }

  return (
    <div className={classes.root}>
      <div className={classes.displayFlex}>
        <Breadcrumbs separator='»' className={classes.MuiBreadcrumbsOl} aria-label='breadcrumb'>
          {infoProps && infoProps[0] && (
            <NavLink
              style={{ textDecoration: 'none', color: 'black' }}
              to={routes.division(infoProps[0].name ? infoProps[0].name : undefined)}>
              {infoProps[0].name}
            </NavLink>
          )}
          {infoProps && infoProps[1] && (
            <NavLink
              style={{ textDecoration: 'none', color: 'black' }}
              color='textPrimary'
              to={routes.product(infoProps[0].name ? infoProps[0].name : undefined, infoProps[1].id?.toString())}>
              {infoProps[1].name}
            </NavLink>
          )}
          {infoProps && infoProps[2] && (
            <NavLink
              style={{ textDecoration: 'none', color: 'black' }}
              color='textPrimary'
              to={routes.material(
                infoProps[0].name ? infoProps[0].name : undefined,
                infoProps[1].id?.toString(),
                infoProps[2].id?.toString()
              )}>
              {infoProps[2].name}
            </NavLink>
          )}
          {infoProps && infoProps[3] && (
            <NavLink
              style={{ textDecoration: 'none', color: 'black' }}
              color='textPrimary'
              to={routes.material(
                infoProps[0].name ? infoProps[0].name : undefined,
                infoProps[1].id?.toString(),
                infoProps[2].id?.toString()
              )}>
              {infoProps[3].name}
            </NavLink>
          )}
        </Breadcrumbs>
        {breadcrumb.quickLinks?.find((d) => d.path === trimString()) ? (
          <Button startIcon={<DoneIcon />} />
        ) : (
          <>
            <Button startIcon={<AddLinkIcon />} onClick={handleClick} />
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              keepMounted
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  width: '300px',
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    left: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}>
              <Formik
                initialValues={{ quickLinkName: '' }}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  addQuickLink(values.quickLinkName);
                }}>
                {() => (
                  <Form>
                    <div className={classes.padding}>
                      <span className={classes.QuickLinktitle}>{t('Quicklink hinzufügen')}</span>
                      <FormikTextField
                        fullWidth
                        variant='filled'
                        label={t('Quicklink Name')}
                        inputProps={{ maxLength: 35 }}
                        name={'quickLinkName'}
                      />
                      <DialogActions className={classes.t}>
                        <Button className={classes.create} endIcon={<SaveIcon />} type='submit'>
                          {t('Erstellen')}
                        </Button>
                      </DialogActions>
                    </div>
                  </Form>
                )}
              </Formik>
            </Menu>
          </>
        )}
      </div>
    </div>
  );
}

import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { createStyles, makeStyles, Grid, Card } from '@material-ui/core';
import { NotificationContext } from '../../../../contexts/NotificationContext';
import { CountryPermission, TechnicalDatasheetAttachmentResponse, useMurexinPimContextApi } from '../../../../api';
import TileView from '../../../../shared/Components/TileView';
import ImageItem from '../../../../shared/Components/ImageItem';
import { CircleLoader } from '../../../../utils/CircleLoader';
import { TechnicalDatasheetAttachmentsSideBar } from './TechnicalDatasheetAttachmentsSideBar';
import Utility from '../../../../shared/Utility';
import { FieldArray, Form, Formik, FormikProps } from 'formik';
import { ISortInformation, SortImageListDropDown } from '../../../../layouts/CustomImageList/SortImageListDropDown';
import _ from 'lodash';
import { useAsync } from 'react-async-hook';
import { FileSelectButton } from '../../../../shared/Components/FileSelectButton';
import { useTranslation } from 'react-i18next';
import { AuthorizationContext } from '../../../../auth';
import PIMTextField from '../../../../shared/Components/PIMTextField';

interface IParams {
  country: string;
  materialId: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    Form: {
      height: '100%',
    },
    Container: {
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      margin: '0px 0px 0px 0px',
    },
    FilterRow: {
      display: 'flex !important',
      flexDirection: 'row',
      margin: '0px 0px 10px 0px',
    },
    Filter: {
      margin: '16px 16px 0px 0px',
      display: 'flex',
    },
    Card: {
      boxShadow: 'none',
    },
    AttachmentRow: {
      paddingRight: '16px',
    },
    Sidebar: {
      minHeight: '100%',
      marginTop: '-231px',
      zIndex: 1101,
      backgroundColor: 'white',
    },
    ActionButtonRow: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'flex-end',
      paddingBottom: '16px',
      marginTop: '-80px',
      marginRight: '16px',
    },
  })
);

export function TechnicalDatasheetAttachments(): JSX.Element {
  const classes = useStyles();
  const notification = useContext(NotificationContext);
  const { country, materialId } = useParams<IParams>();
  const materialGroupId = Number(materialId);
  const api = useMurexinPimContextApi();
  const { t } = useTranslation();
  const { hasCountryPermission } = useContext(AuthorizationContext);
  const isAuthorizedToWrite = hasCountryPermission(country, CountryPermission.Write);
  const [selectedItem, setSelectedItem] = useState<TechnicalDatasheetAttachmentResponse | undefined>(undefined);
  const [searchText, setSearchText] = useState<string>('');
  const [sortDirection, setSortDirection] = useState<ISortInformation>({
    direction: 'asc',
    column: 'fileName',
  });

  const technicalDatasheetAttachmentResponse = useAsync(async () => {
    const response = await api.technicalDatasheetAttachment.technicalDatasheetAttachmentMaterialGroupDetail(
      country,
      materialGroupId
    );
    return response.data ?? [];
  }, [country, materialGroupId]);

  const isLoaded = (): boolean => {
    return !technicalDatasheetAttachmentResponse.loading;
  };

  const handleFormOnChange = (
    event: React.FormEvent<HTMLFormElement> | undefined,
    formik: FormikProps<TechnicalDatasheetAttachmentResponse[]>
  ) => {
    formik.handleChange(event);
  };

  const handleClickItem = (item: TechnicalDatasheetAttachmentResponse) => {
    if (selectedItem !== undefined && selectedItem.fileName === item.fileName) {
      setSelectedItem(undefined);
    } else {
      setSelectedItem(item);
    }
  };

  const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchText(event.target.value);
  };

  const getOrderedAttachments = (
    items: TechnicalDatasheetAttachmentResponse[]
  ): TechnicalDatasheetAttachmentResponse[] => {
    return _.orderBy(items, [sortDirection.column], [sortDirection.direction]);
  };

  const onChangeSortDirection = (value: ISortInformation) => {
    setSortDirection(value);
  };

  const getTechnicalDatasheetAttachmentsContainingSearchTextInFileName = (
    items: TechnicalDatasheetAttachmentResponse[]
  ): TechnicalDatasheetAttachmentResponse[] => {
    const filterLowerCase = searchText.toLocaleLowerCase();
    return items.filter((item) => item.fileName?.toLocaleLowerCase().includes(filterLowerCase));
  };

  async function deleteAttachment(id: number): Promise<void> {
    await api.technicalDatasheetAttachment
      .technicalDatasheetAttachmentDelete(country, id)
      .then(async () => {
        void (await technicalDatasheetAttachmentResponse.execute());
        setSelectedItem(undefined);
        notification.info(t('Anhang wurde erfolgreich entfernt'));
      })
      .catch(() => {
        notification.warning(t('Anhang konnte nicht entfernt werden'));
      });
  }

  const renderImages = (): React.ReactNode => {
    const filteredAttachments = getTechnicalDatasheetAttachmentsContainingSearchTextInFileName(
      technicalDatasheetAttachmentResponse.result ?? []
    );
    const filteredAndOrderedAttachments = getOrderedAttachments(filteredAttachments);
    return (
      <TileView>
        {filteredAndOrderedAttachments.map((attachment: TechnicalDatasheetAttachmentResponse) => {
          const updatedAt = Utility.formatDateTimeUtc(new Date(attachment.createdAt ?? ''));
          return (
            <ImageItem
              description={attachment.fileName}
              showSubDescription={true}
              subDescription={updatedAt}
              isClickable={true}
              onClickItem={() => handleClickItem(attachment)}
              showDeleteButton={isAuthorizedToWrite}
              onClickDelete={() => deleteAttachment(attachment.id ?? -1)}
              key={attachment.id}
              imageSrc={`/api/TechnicalDatasheetAttachment/${country}/${attachment.id ?? -1}/file`}
              showPlaceholder={
                attachment.fileName !== null &&
                attachment.fileName !== undefined &&
                !Utility.isImageFile(attachment.fileName)
              }
            />
          );
        })}
      </TileView>
    );
  };

  async function upload(files: File[] | null): Promise<void> {
    if (!files || files.length <= 0) return;
    await api.technicalDatasheetAttachment
      .technicalDatasheetAttachmentMaterialGroupCreate(country, materialGroupId, { files })
      .then(async () => {
        void (await technicalDatasheetAttachmentResponse.execute());
        notification.info(t('Anhang wurde erfolgreich hinzugefügt'));
      })
      .catch(() => {
        notification.warning(t('Anhang konnte nicht hinzugefügt werden'));
      });
  }

  return (
    <>
      {isLoaded() === false ? (
        <CircleLoader open={true} />
      ) : (
        <Formik
          initialValues={technicalDatasheetAttachmentResponse.result ?? []}
          onSubmit={() => {
            return;
          }}
          enableReinitialize={true}>
          {(formik) => (
            <Form
              className={classes.Form}
              onChange={(event: React.FormEvent<HTMLFormElement> | undefined) => handleFormOnChange(event, formik)}>
              <FieldArray
                name='technicalDatasheetAttachmentResponses'
                render={() => (
                  <Grid className={classes.Container}>
                    <Grid item xs={8}>
                      {isAuthorizedToWrite && (
                        <Grid item xs={12} className={classes.ActionButtonRow}>
                          <FileSelectButton
                            text={t('Dateien hinzufügen')}
                            accept={[
                              'image/*',
                              'application/pdf',
                              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                              'application/msword',
                              'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
                              'application/vnd.ms-word.template.macroEnabled.12',
                            ]}
                            multipleFiles
                            onFilesSelected={upload}
                            warningText={t('Dateityp nicht erlaubt')}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12} container className={classes.FilterRow}>
                        <Grid item xs={8}>
                          <PIMTextField
                            name='ignored'
                            label={t('Filter')}
                            variant='filled'
                            onChange={handleChangeFilter}
                            className={classes.Filter}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <div>
                            <SortImageListDropDown
                              possibleColumns={['fileName', 'fileSize', 'updatedAt', 'updatedBy']}
                              currentSortDirection={sortDirection}
                              selectSortDirection={onChangeSortDirection}
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} className={classes.AttachmentRow}>
                        <Card className={classes.Card}>{renderImages()}</Card>
                      </Grid>
                    </Grid>
                    {selectedItem && (
                      <Grid item xs={4} className={classes.Sidebar}>
                        <TechnicalDatasheetAttachmentsSideBar
                          attachment={selectedItem}
                          imageSource={`/api/TechnicalDatasheetAttachment/${country}/${selectedItem?.id ?? -1}/file`}
                          showPlaceholder={
                            selectedItem?.fileName !== null &&
                            selectedItem?.fileName !== undefined &&
                            !Utility.isImageFile(selectedItem?.fileName)
                          }
                          onCloseSidebar={() => setSelectedItem(undefined)}
                        />
                      </Grid>
                    )}
                  </Grid>
                )}
              />
            </Form>
          )}
        </Formik>
      )}
    </>
  );
}

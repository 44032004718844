import React, { ReactElement } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Tabs, { TabsProps } from '@mui/material/Tabs';

export function PIMTabs(props: TabsProps): ReactElement {
  const classes = useStyles();

  const { children, onChange, ...rest } = props;

  return (
    <Tabs className={classes.Tabs} {...rest} onChange={onChange}>
      {children}
    </Tabs>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    Tabs: {
      '& .MuiTabs-indicator': {
        borderRight: '4px solid #000000',
        width: '0px',
      },
    },
  })
);

import React from 'react';
import { Button, Divider, Menu, MenuItem, MenuProps } from '@material-ui/core';
import { LocalizationSectionCode, useMurexinPimContextApi } from '../api';
import { useContext } from 'react';
import { AuthorizationContext, LoginContext } from '../auth';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useAsync } from 'react-async-hook';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  item: {
    padding: '0 16px 16px 16px',
  },
  user: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '16px',
  },
  userRoles: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '14px',
    color: '#797979',
  },
  userDivider: {
    backgroundColor: '#424242',
  },
  Button: {
    marginLeft: 'auto',
    marginRight: '0%',
    textTransform: 'none',
    fontSize: '16px',
    ' & .MuiButton-label': {
      fontWeight: '400',
    },
  },
}));

export const CustomUserMenu = withStyles(() => ({
  paper: {
    marginTop: '10px',
    padding: '16px 0',
    backgroundColor: '#212121',
    color: '#ffffff',
    textAlign: 'left',
  },
}))((props: MenuProps) => <Menu {...props} />);

export const QuickLinkMenu = withStyles(() => ({
  paper: {
    marginTop: '10px',
    padding: '10px 0',
    backgroundColor: 'white',
    color: 'black',
    textAlign: 'left',
  },
}))((props: MenuProps) => <Menu {...props} />);

export const CustomUserMenuItem = withStyles(() => ({
  root: {
    padding: '16px 16px',
  },
}))(MenuItem);

export function UserMenu(): JSX.Element {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const germanTranslation = i18n.getFixedT('de', LocalizationSectionCode.STATIC);
  const { user } = useContext(LoginContext);
  const { roleName } = useContext(AuthorizationContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const api = useMurexinPimContextApi();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    window.location.href = '/logout';
  };

  const currentVersion = useAsync(async () => {
    const response = await api.version.versionList({ format: 'text' });
    return response.data ?? germanTranslation('Unbekannt');
  }, []);

  return (
    <>
      <Button aria-controls='simple-menu' aria-haspopup='true' onClick={handleClick} className={classes.Button}>
        {user
          ? user.lastName && user.firstName
            ? user.lastName.toString() + ', ' + user.firstName.toString()
            : user.name
          : null}
      </Button>
      <CustomUserMenu
        id='simple-menu'
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <div className={classes.item}>
          <div className={classes.user}>
            {user
              ? user.lastName && user.firstName
                ? user.lastName.toString() + ', ' + user.firstName.toString()
                : user.name
              : null}
          </div>
          <div className={classes.userRoles}>
            <span>{roleName}</span>
          </div>
        </div>
        <Divider className={classes.userDivider} />
        <CustomUserMenuItem onClick={handleLogout}>{germanTranslation('Ausloggen')}</CustomUserMenuItem>
        <Divider className={classes.userDivider} />
        <CustomUserMenuItem disabled style={{ paddingBottom: '2px' }}>
          {germanTranslation('Version')} {currentVersion.result?.split('.').slice(0, -1).join('.') ?? '-'}
        </CustomUserMenuItem>
      </CustomUserMenu>
    </>
  );
}

import React from 'react';
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import {
  PerfectSystemMaterialGroupResponseModel,
  PerfectSystemMaterialGroupRequestModel,
  SearchMaterialGroupResponse,
} from '../../../../api';
import { FieldArray, FieldArrayRenderProps } from 'formik';
import { SearchMaterialGroupDropdown } from './SearchMaterialGroupDropdown';
import ImageItem from '../../../../shared/Components/ImageItem';
import TileView from '../../../../shared/Components/TileView';
import { ApiImageUrlsBuilder } from '../../../../utils/apiImageUrls';
import PIMFormikTextField from '../../../../shared/Components/PIMFormikTextField';
import { FilterOptionsState } from '@mui/material';
import Card from '@mui/material/Card';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    Card: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    SystemProductDescribtion: {
      paddingTop: '16px',
    },
    PerfrectSystemGrid: {
      paddingTop: '24px',
    },
    TextField: {
      '&.MuiTextField-root': {
        marginTop: '8px',
        marginBottom: '8px',
      },
    },
    DivReleatedMaterialGroupHeader: {
      fontFamily: 'Roboto',
      fontSize: '19px',
      fontWeight: 'normal',
    },
    DivReleatedMaterialGroup: {
      marginTop: '16px',
    },
  })
);

interface IProps {
  country: string;
  readonly?: boolean;
  isAuthorizedToWrite?: boolean;
  perfectSystemMaterialGroups: PerfectSystemMaterialGroupResponseModel[] | null | undefined;
  removeSelectedMaterialGroups?: (
    allGroups: SearchMaterialGroupResponse[],
    state: FilterOptionsState<SearchMaterialGroupResponse>,
    selectedGroups?: PerfectSystemMaterialGroupRequestModel[] | null | undefined
  ) => SearchMaterialGroupResponse[];
  handleOnAddMaterialGroup?: (arrayHelpers: FieldArrayRenderProps, value: SearchMaterialGroupResponse) => void;
  selectedItem?: PerfectSystemMaterialGroupResponseModel | undefined;
  handelOnClickMaterialGroup?: (perfectSystemMaterialGroup: PerfectSystemMaterialGroupResponseModel) => void;
  handleOnDeleteMaterialGroup?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined,
    arrayHelpers: FieldArrayRenderProps,
    index: number
  ) => void;
}

export default function PerfectSystemAttributes(props: IProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    country,
    readonly,
    isAuthorizedToWrite,
    perfectSystemMaterialGroups,
    removeSelectedMaterialGroups,
    handleOnAddMaterialGroup,
    selectedItem,
    handelOnClickMaterialGroup,
    handleOnDeleteMaterialGroup,
  } = props;

  return (
    <Card className={classes.Card}>
      <Grid className={classes.SystemProductDescribtion} item xs={12}>
        <PIMFormikTextField
          label={t('System Produkt Beschreibung')}
          name='systemProductDescription'
          className={classes.TextField}
          fullWidth
          multiline
          disabled={readonly || !isAuthorizedToWrite}
        />
      </Grid>
      <Grid className={classes.PerfrectSystemGrid} item xs={12}>
        <FieldArray name='perfectSystemMaterialGroups'>
          {(arrayHelpers) => (
            <>
              <Grid item xs={12}>
                <div className={classes.DivReleatedMaterialGroupHeader}>{t('Verlinkte Warengruppen')}</div>
                {isAuthorizedToWrite && (
                  <div className={classes.DivReleatedMaterialGroup}>
                    <SearchMaterialGroupDropdown
                      country={country}
                      filterOptions={(options, state) => {
                        if (removeSelectedMaterialGroups) {
                          return removeSelectedMaterialGroups(options, state, perfectSystemMaterialGroups);
                        } else {
                          return [];
                        }
                      }}
                      onChange={(_, value) => {
                        if (handleOnAddMaterialGroup && !!value) {
                          handleOnAddMaterialGroup(arrayHelpers, value);
                        }
                      }}
                      disabled={readonly}
                    />
                  </div>
                )}
              </Grid>
              <Grid item xs={12}>
                <TileView>
                  {perfectSystemMaterialGroups?.map((materialGroup: PerfectSystemMaterialGroupResponseModel, index) => {
                    return (
                      <ImageItem
                        description={materialGroup.materialGroup?.materialGroupDescription ?? ''}
                        key={materialGroup.materialGroupId}
                        imageSrc={
                          materialGroup.materialGroup != null &&
                          materialGroup.materialGroup.imageStoreMaterialGroupId !== null &&
                          materialGroup.materialGroup.imageStoreMaterialGroupId !== undefined
                            ? ApiImageUrlsBuilder.buildMaterialGroupImageUrl(
                                country,
                                materialGroup.materialGroup?.imageStoreMaterialGroupId
                              )
                            : ''
                        }
                        showSelected={
                          selectedItem !== undefined && selectedItem?.materialGroupId === materialGroup.materialGroupId
                        }
                        isClickable={!readonly}
                        showDeleteButton={!readonly && isAuthorizedToWrite}
                        onClickItem={() => {
                          if (handelOnClickMaterialGroup) {
                            handelOnClickMaterialGroup(materialGroup);
                          }
                        }}
                        onClickDelete={(event) => {
                          if (handleOnDeleteMaterialGroup) {
                            handleOnDeleteMaterialGroup(event, arrayHelpers, index);
                          }
                        }}
                      />
                    );
                  })}
                </TileView>
              </Grid>
            </>
          )}
        </FieldArray>
      </Grid>
    </Card>
  );
}

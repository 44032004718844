import * as React from 'react';
import { CardContent, Grid, makeStyles, createStyles } from '@material-ui/core';
import { CountryPermission, ProductGroupResponse } from '../../../api/MurexinPimApi';
import { NavLink, useParams } from 'react-router-dom';
import { routes } from '../../../routes/routes';
import { PIMCard } from '../../../shared/Components/PIMCard';
import { IRouterParams } from '../../../routes';
import { t } from 'i18next';
import { AuthorizationContext } from '../../../auth';
import PIMButton from '../../../shared/Components/PIMButton';
import { IAdminMenuItem } from '../../../shared/interfaces';

interface IProductGroupProps {
  divisionName: string;
  productGroups: ProductGroupResponse[];
  handleOpenProductGroupSidebar: () => void;
}

export function ProductGrid(props: IProductGroupProps): JSX.Element {
  const classes = useStyles();
  const { country, divisionId } = useParams<IRouterParams>();
  const { hasCountryPermission } = React.useContext(AuthorizationContext);

  const isAuthorizedToCreate = hasCountryPermission(country, CountryPermission.CreateProductGroup);
  const isAuthorizedToEdit =
    hasCountryPermission(country, CountryPermission.AdministrationWrite) ||
    hasCountryPermission(country, CountryPermission.DeleteProductGroup) ||
    hasCountryPermission(country, CountryPermission.UpdateProductGroup);

  const showAdministrationPanel = isAuthorizedToCreate || isAuthorizedToEdit;

  const adminNav: IAdminMenuItem[] = [
    {
      label: t('Produktgruppen'),
      sublabel: t('Bearbeiten und Löschen'),
      link: (countryCode: string) => routes.productGroupDescriptionRoute(countryCode, divisionId),
    },
  ];

  function productListSorted(list: ProductGroupResponse[]) {
    return (
      list.sort((a, b) => {
        const aProductGroupName = (a.countryCode ?? '')
          .concat('-')
          .concat(props.divisionName)
          .concat(a.productGroupName ?? '');
        const bProductGroupName = (b.countryCode ?? '')
          .concat('-')
          .concat(props.divisionName)
          .concat(b.productGroupName ?? '');

        return aProductGroupName.localeCompare(bProductGroupName);
      }) ?? []
    );
  }

  return (
    <Grid container>
      <Grid container item xs={12}>
        <Grid item xs={6} className={classes.ProductGroupGrid}>
          <div className={classes.Title}>{t('Produkt Übersicht')}</div>
        </Grid>
        <Grid item xs={6} className={classes.ProductGroupGrid}>
          {showAdministrationPanel && <div className={classes.Title}>{t('Administration')}</div>}
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={6} className={classes.ProductGroupGrid}>
          <Grid item xs={10}>
            {productListSorted(props.productGroups).map((product, key) => (
              <NavLink
                key={key}
                style={{ textDecoration: 'none' }}
                to={routes.material(country, divisionId, product.id?.toString())}>
                <PIMCard>
                  <CardContent className={classes.CardContent}>
                    <span className={classes.CardTitle}>
                      {product.productGroupCountryDescription ?? product.productGroupDescription}
                    </span>
                    <span className={classes.CardSubTitle}>
                      {product.countryCode}-{props.divisionName}
                      {product.productGroupName}
                    </span>
                  </CardContent>
                </PIMCard>
              </NavLink>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={6} className={classes.ProductGroupGrid}>
          <Grid item xs={10}>
            {country !== 'AT' && isAuthorizedToCreate && (
              <PIMButton
                color='primary'
                icon='add'
                onClick={props.handleOpenProductGroupSidebar}
                className={classes.AdminButton}>
                {t('Produktgruppe hinzufügen')}
              </PIMButton>
            )}
            {adminNav &&
              isAuthorizedToEdit &&
              adminNav.map((item, index) => (
                <NavLink key={index} style={{ textDecoration: 'none' }} to={item.link(country)}>
                  <PIMCard>
                    <CardContent className={classes.CardContent}>
                      <span className={classes.CardTitle}>{item.label} </span>
                      <span className={classes.CardSubTitle}> {item.sublabel}</span>
                    </CardContent>
                  </PIMCard>
                </NavLink>
              ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    ProductGroupGrid: {
      paddingRight: '24px',
    },
    CardContent: {
      display: 'flex',
      flexDirection: 'column',
      margin: '8px 16px 8px 16px',
      padding: '0px',
      '&.MuiCardContent-root:last-child': {
        padding: '0px',
      },
    },
    CardTitle: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '20px',
      marginBottom: '4px',
      color: '#000000',
    },
    CardSubTitle: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontSize: '14px',
      fontWeight: 400,
      color: '#000000',
      opacity: '0.6',
    },
    Title: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '36px',
      marginBottom: '24px',
    },
    AdminButton: {
      height: '80px',
      '& .MuiButton-root': {
        minWidth: '284px',
        marginBottom: '20px',
      },
      '& .MuiButton-label': {
        fontSize: '18px',
      },
    },
  })
);

import * as React from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { PictogramResponseModel, useMurexinPimContextApi } from '../../../api';
import { NotificationContext } from '../../../contexts/NotificationContext';
import { useTranslation } from 'react-i18next';
import PIMButton from '../../../shared/Components/PIMButton';
import { ApiImageUrlsBuilder } from '../../../utils/apiImageUrls';
import PIMFormikTextField from '../../../shared/Components/PIMFormikTextField';

interface IProps {
  selectedPicture: PictogramResponseModel | null;
  country: string;
  file: File | null;
  isAuthorizedToWrite: boolean;
  reload: () => void;
  resetFile: () => void;
  actionDelete: () => void;
}

export function AdminPictogramSideBar(props: IProps): JSX.Element {
  const classes = useStyles();
  const api = useMurexinPimContextApi();
  const notification = React.useContext(NotificationContext);
  const { t } = useTranslation();

  function uploadNewFile() {
    if (!props.file) return;
    return api.pictogram
      .pictogramPictogramCreate(props.country, {
        File: props.file,
        FullName: props.file.name,
      })
      .then(() => {
        notification.success(t('Speichern erfolgreich'));
        props.reload();
      })
      .catch(() => {
        notification.error(t('Speichern fehlgeschlagen'));
        props.reload();
      });
  }

  return (
    <>
      {props.selectedPicture != null ? (
        <div className={classes.actionArea}>
          <Grid container className={classes.whiteBackground}>
            <Grid item xs={12} className={classes.gridItem}>
              <Formik
                initialValues={props.selectedPicture != null ? props.selectedPicture : {}}
                onSubmit={() => {
                  // Nothing to submit as this is read only view.
                  // TODO: remove formik
                }}
                enableReinitialize={true}>
                {() => (
                  <Form>
                    <Grid container>
                      <Grid item xs={12} className={classes.gridItem}>
                        <PIMFormikTextField name='fileName' label={t('Dateiname')} disabled fullWidth />
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
              <Grid item xs={9} className={classes.gridItem}>
                <div className={classes.paddingImage}>
                  <img
                    className={classes.width}
                    src={ApiImageUrlsBuilder.buildPictogramImageUrl(
                      props.country,
                      props.selectedPicture?.fileName ?? ''
                    )}
                    alt={props.selectedPicture?.fileName ?? ''}
                  />
                </div>
              </Grid>
              <hr className={classes.separatorLine} />
              <PIMButton
                color='warning'
                icon='trash'
                onClick={() => {
                  props.actionDelete();
                }}>
                {t('Piktogramm löschen')}
              </PIMButton>
            </Grid>
          </Grid>
        </div>
      ) : (
        <div className={classes.actionArea}>
          <Grid container className={classes.whiteBackground}>
            <Grid item xs={12} className={classes.gridItem}>
              <Formik
                initialValues={{
                  fileName: props.file?.name,
                }}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                }}
                enableReinitialize={true}>
                {(formik) => (
                  <Form>
                    <div>
                      {props.isAuthorizedToWrite && (
                        <>
                          <PIMButton
                            className={classes.buttonSeperator}
                            icon='close'
                            disabled={formik.isSubmitting}
                            onClick={() => {
                              formik.resetForm();
                              props.resetFile();
                            }}>
                            {t('Abbrechen')}
                          </PIMButton>
                          <PIMButton
                            color='primary'
                            icon='save'
                            className={classes.topButtons}
                            busy={formik.isSubmitting}
                            disabled={formik.isSubmitting}
                            onClick={() => uploadNewFile()}>
                            {t('Speichern')}
                          </PIMButton>
                        </>
                      )}
                    </div>
                    <Grid container>
                      <Grid item xs={12} className={classes.gridItem}>
                        <PIMFormikTextField name='fileName' label={t('Dateiname')} disabled fullWidth />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.gridItem}>
                      {props.file ? (
                        <Box textAlign='center'>
                          <div className={classes.paddingImage}>
                            <img
                              src={URL.createObjectURL(props.file)}
                              alt={props.file.name}
                              className={classes.width}
                            />
                          </div>
                        </Box>
                      ) : null}
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
}

const useStyles = makeStyles(() => ({
  actionArea: {
    minHeight: '100%',
  },
  paddingImage: {
    paddingTop: '30px',
  },
  width: {
    width: '100%',
  },
  gridItem: {
    paddingLeft: '10px',
    paddingRight: '10px',
    width: '100%',
    maxWidth: '100%',
  },
  whiteBackground: {
    backgroundColor: 'white',
    paddingLeft: '10px',
    paddingTop: '10px',
    paddingBottom: '20px',
    minHeight: '100%',
  },
  separatorLine: {
    margin: '32px 0',
  },
  topButtons: {
    marginBottom: '16px',
    marginRight: '24px',
    paddingLeft: '10px',
  },
  buttonSeperator: {
    marginRight: '24px',
    paddingLeft: '10px',
  },
}));

import React from 'react';
import { makeStyles } from '@material-ui/core';
import { DivisionDataGrid } from './DivisionGrid';

function DivisionList(): JSX.Element {
  const classes = useStyles();
  return (
    <div className={classes.DivisionList}>
      <DivisionDataGrid />
    </div>
  );
}

const useStyles = makeStyles(() => ({
  DivisionList: {
    marginTop: '16px',
    marginLeft: '16px',
  },
}));

export default DivisionList;

import React, { useContext, useMemo, useState } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { GridColDef, GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid';
import { useAsync, useAsyncCallback } from 'react-async-hook';
import { useParams } from 'react-router-dom';
import { CountryPermission, PictogramResponseModel, useMurexinPimContextApi } from '../../../api';
import { CircleLoader } from '../../../utils/CircleLoader';
import { AdminPictogramSideBar } from './AdminPictogramSideBar';
import { CountryAdminBreadCrumbs } from '../../../layouts/CountryAdminBreadCrumbs/CountryAdminBreadCrumbs';
import { ISortInformation, SortImageListDropDown } from '../../../layouts/CustomImageList/SortImageListDropDown';
import { useTranslation } from 'react-i18next';
import { AuthorizationContext } from '../../../auth';
import { FileSelectButton } from '../../../shared/Components/FileSelectButton';
import MessageDialog from '../../../shared/Components/MessageDialog';
import { NotificationContext } from '../../../contexts';
import ImageItem from '../../../shared/Components/ImageItem';
import TileView from '../../../shared/Components/TileView';
import { ApiImageUrlsBuilder } from '../../../utils/apiImageUrls';
import Utility from '../../../shared/Utility';
import PIMIconButton from '../../../shared/Components/PIMIconButton';
import { DataView } from '../../../shared/Enum/DataView';
import ListView from '../../../shared/Components/ListView';
import PIMTextField from '../../../shared/Components/PIMTextField';
import _ from 'lodash';

interface IParams {
  country: string;
}

export function AdminPictogramList(): JSX.Element {
  const { t } = useTranslation();
  const api = useMurexinPimContextApi();
  const { country } = useParams<IParams>();
  const classes = useStyles();
  const notification = React.useContext(NotificationContext);
  const [selectedPictogram, setSelectedPictogram] = useState<PictogramResponseModel>();
  const [pictograms, setPictograms] = useState<PictogramResponseModel[]>([]);
  const [addedPictogramFile, setAddedPictogramFile] = useState<File | null>(null);
  const [pictogramSearch, setPictogramSearch] = useState<string>('');
  const [dataView, setDataView] = useState<DataView>(DataView.Grid);
  const { hasCountryPermission } = useContext(AuthorizationContext);
  const isAuthorizedToWrite = hasCountryPermission(country, CountryPermission.AdministrationWrite);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [sortOrder, setSortOrder] = useState<ISortInformation>({
    direction: 'asc',
    column: 'fileName',
  });

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: ' ',
      // eslint-disable-next-line react/display-name
      renderCell: (params: GridRenderCellParams) => (
        <img
          className={classes.columnImage}
          src={ApiImageUrlsBuilder.buildPictogramImageUrl(country, (params.row.fileName as string) ?? '')}
          alt={(params.row.fileName as string) ?? ''}
        />
      ),
      minWidth: 50,
      width: 50,
      sortable: false,
      filterable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
    },
    {
      field: 'fileName',
      headerName: t('Dateiname'),
      flex: 1,
      minWidth: 200,
      sortable: false,
      filterable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
    },
    {
      field: 'updatedAt',
      type: 'dateTime',
      headerName: t('Zuletzt bearbeitet'),
      align: 'right',
      headerAlign: 'right',
      valueFormatter: (params: GridValueFormatterParams) =>
        params.value && Utility.formatDateTimeUtc(params.value as Date),
      width: 150,
      sortable: false,
      filterable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
    },
    {
      field: 'updatedBy',
      headerName: t('Bearbeiter'),
      minWidth: 120,
      width: 200,
      sortable: false,
      filterable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
    },
    {
      field: 'fileSize',
      type: 'number',
      headerName: t('Größe'),
      valueFormatter: (params: GridValueFormatterParams) =>
        params.value && Utility.formatFileSize(params.value as number),
      width: 100,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      filterable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
    },
    {
      field: '',
      headerName: '',
      sortable: false,
      filterable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      align: 'center',
      width: 80,
      // eslint-disable-next-line react/display-name
      renderCell: () => (
        <PIMIconButton
          className={classes.deleteButton}
          size='small'
          onClick={() => setShowDeleteDialog(true)}
          iconName='trash'
        />
      ),
    },
  ];

  const pictogramsResponse = useAsync(async () => {
    let updatedPictograms: PictogramResponseModel[] = [];

    try {
      const response = await api.pictogram.pictogramPictogramsDetail(country);
      updatedPictograms = response.data ?? [];
    } catch {
      notification.error(t('Fehler beim Laden der Daten.'));
    }

    // Intentionally not using sealOfApprovalsResponse, since we want to show currently loaded items
    // while waiting for response from backend
    setPictograms(updatedPictograms);
    clearSelectedPictogram();
    return updatedPictograms;
  }, [country]);
  const sortedPictograms = useMemo(() => {
    return _.chain(pictograms).orderBy([sortOrder.column], [sortOrder.direction]).value();
  }, [pictograms, sortOrder]);
  let displayedPictograms = sortedPictograms;

  if (pictogramSearch) {
    const searchValue = pictogramSearch.toLocaleLowerCase();
    displayedPictograms = displayedPictograms.filter((p) => p.fileName?.toLocaleLowerCase().includes(searchValue));
  }

  function clearSelectedPictogram() {
    setAddedPictogramFile(null);
    setSelectedPictogram(undefined);
  }

  function handleAddNewPictogramFile(addedFiles: File[]) {
    if (addedFiles.length !== 1) {
      return;
    }

    const addedFile = addedFiles[0];
    setSelectedPictogram(undefined);
    setAddedPictogramFile(addedFile);
  }

  const handlePictogramSelectionChange = (newSelectedPictogram: PictogramResponseModel | undefined) => {
    if (selectedPictogram?.id === newSelectedPictogram?.id) {
      clearSelectedPictogram();
    } else {
      setSelectedPictogram(newSelectedPictogram);
    }
  };

  const onDeleteSelectedPictogram = useAsyncCallback(async () => {
    const fileName = selectedPictogram?.fileName;
    setShowDeleteDialog(false);

    if (fileName) {
      try {
        await api.pictogram.pictogramPictogramDelete(country, fileName);
        notification.success(t('Datei wurde erfolgreich gelöscht'));
        clearSelectedPictogram();
        await pictogramsResponse.execute();
      } catch {
        notification.error(t('Der Löschvorgang ist fehlgeschlagen'));
      }
    }
  });

  return (
    <>
      <CircleLoader open={pictogramsResponse.loading || onDeleteSelectedPictogram.loading} />
      <Grid container>
        <Grid item xs={8} className={classes.mainView}>
          <Grid container>
            <Grid item xs={12} container justifyContent='space-between'>
              <CountryAdminBreadCrumbs site={t('Prüfsiegel')} />
              <Grid item className={classes.icons}>
                <PIMIconButton
                  iconName='listView'
                  className={classes.dataViewButton}
                  disabled={dataView === DataView.List}
                  onClick={() => setDataView(DataView.List)}
                />
                <PIMIconButton
                  iconName='gridView'
                  className={classes.dataViewButton}
                  disabled={dataView === DataView.Grid}
                  onClick={() => setDataView(DataView.Grid)}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.filterArea}>
              <div className={classes.Title}>{t('Piktogramme')}</div>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <PIMTextField
                    label={`${t('Filter')}`}
                    onChange={(e) => setPictogramSearch(e.target.value ?? '')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3}>
                  <SortImageListDropDown
                    possibleColumns={['fileName', 'fileSize', 'updatedAt', 'updatedBy']}
                    currentSortDirection={sortOrder}
                    selectSortDirection={(selectedOrder) => setSortOrder(selectedOrder)}
                    className={classes.sortedDropdown}
                  />
                </Grid>
                <Grid item xs={4}>
                  {isAuthorizedToWrite && (
                    <FileSelectButton
                      className={classes.addButton}
                      accept={['image/*']}
                      text={t('Piktogramm hinzufügen')}
                      onFilesSelected={handleAddNewPictogramFile}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            {dataView === DataView.List ? (
              <ListView
                data={displayedPictograms}
                selectedItem={selectedPictogram}
                onChangeSelection={handlePictogramSelectionChange}
                columns={columns}
              />
            ) : (
              <TileView>
                {displayedPictograms.map((pictogram: PictogramResponseModel) => {
                  const updatedAt = pictogram.updatedAt ? new Date(pictogram.updatedAt) : new Date();

                  return (
                    <ImageItem
                      description={pictogram.fileName ?? ''}
                      isClickable
                      showSubDescription
                      showSelected={selectedPictogram?.id === pictogram.id}
                      subDescription={Utility.formatDateTime(updatedAt)}
                      key={pictogram.id}
                      imageSrc={ApiImageUrlsBuilder.buildPictogramImageUrl(country, pictogram.fileName ?? '')}
                      onClickItem={() => handlePictogramSelectionChange(pictogram)}
                      showDeleteButton={isAuthorizedToWrite}
                      onClickDelete={() => {
                        setSelectedPictogram(pictogram);
                        setShowDeleteDialog(true);
                      }}
                    />
                  );
                })}
              </TileView>
            )}
          </Grid>
        </Grid>
        {(addedPictogramFile || selectedPictogram) && (
          <Grid item xs={4} className={classes.actionArea}>
            <AdminPictogramSideBar
              isAuthorizedToWrite={isAuthorizedToWrite}
              country={country}
              file={addedPictogramFile}
              selectedPicture={selectedPictogram ?? null}
              reload={async () => {
                // TODO: fix this with close sidebar request
                await pictogramsResponse.execute();
              }}
              resetFile={clearSelectedPictogram}
              actionDelete={() => setShowDeleteDialog(true)}
            />
          </Grid>
        )}
      </Grid>
      <MessageDialog
        show={showDeleteDialog}
        title={t('Wollen Sie dieses Piktogramm löschen?')}
        messageText={t(`WarnungElementAusZugewiesenenWarengruppenEntfernt`)}
        confirmColor='warning'
        confirmIcon='trash'
        confirmLabel={t('Löschen')}
        onClose={() => setShowDeleteDialog(false)}
        onCancel={() => setShowDeleteDialog(false)}
        onConfirm={onDeleteSelectedPictogram.execute}
      />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  actionArea: {
    minHeight: '100%',
    backgroundColor: '#ffffff',
    overflowY: 'auto',
    paddingTop: '44px',
  },
  icons: {
    marginTop: '16px',
    paddingRight: '34px',
  },
  filterArea: {
    marginTop: '10px',
    marginBottom: '20px',
    width: '100%',
  },
  sortedDropdown: {
    width: '100%',
    marginTop: '0',
  },
  addButton: {
    width: '100%',
    minWidth: '300px',
    margin: '0',
    padding: '0',
    float: 'right',
  },
  deleteButton: {
    marginRight: '2px',
  },
  dataViewButton: {
    opacity: '0.5',
    '&.Mui-disabled': {
      opacity: '1',
    },
  },
  columnImage: {
    height: 32,
    width: 32,
  },
  mainView: { padding: theme.spacing(0, 2, 0, 0) },
  Title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '36px',
    marginBottom: '24px',
    lineHeight: '45px',
  },
}));

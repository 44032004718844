import { InputAdornment, makeStyles } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import PIMFormikSidebar from '../../../shared/Components/PIMFormikSidebar';
import PIMFormikTextField from '../../../shared/Components/PIMFormikTextField';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IRouterParams } from '../../../routes';
import { CountryPermission, MaterialGroupResponse, useMurexinPimContextApi } from '../../../api';
import { NotificationContext } from '../../../contexts';
import { useAsync } from 'react-async-hook';
import PIMButton from '../../../shared/Components/PIMButton';
import MessageDialog from '../../../shared/Components/MessageDialog';
import { SidebarMode } from '../../../shared/Enum';
import { AuthorizationContext } from '../../../auth';

interface ISidebarProps {
  mode: SidebarMode;
  countryName?: string;
  productGroupName: string;
  materialGroup?: MaterialGroupResponse;
  handleOnCancel?: () => void;
  handleOnClose?: () => void;
}

export function MaterialGroupSidebar(props: ISidebarProps): JSX.Element {
  const classes = useStyles();
  const { country, productId } = useParams<IRouterParams>();
  const { t } = useTranslation();
  const api = useMurexinPimContextApi();
  const notificationContext = useContext(NotificationContext);
  const materialGroupNameLength = 3;
  const notification = useContext(NotificationContext);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const { handleOnCancel, handleOnClose, productGroupName, materialGroup } = props;

  const { hasCountryPermission } = useContext(AuthorizationContext);
  const canDeleteMaterialGroup = hasCountryPermission(country, CountryPermission.DeleteMaterialGroup);
  const canEditMaterialGroup =
    materialGroup?.countryCode === country && hasCountryPermission(country, CountryPermission.UpdateMaterialGroup);
  const canEditMaterialGroupTitle = hasCountryPermission(country, CountryPermission.Write) || canEditMaterialGroup;

  const materialGroupResponse = useAsync(async () => {
    const response = await api.materialGroupService.materialGroupServiceByProductIdDetail(country, Number(productId));
    return response.data ?? [];
  }, [country, productId]);

  const onHandleOnSubmit = async (values: any) => {
    const { mode } = props;

    if (mode === SidebarMode.Create) {
      await addMaterialGroup(values);
    } else if (mode === SidebarMode.Update) {
      await updateMaterialGroup(values);
    }
  };

  async function addMaterialGroup(values: MaterialGroupSidebarModel) {
    try {
      await api.materialGroupService.materialGroupServiceCreate(values.countryCode, {
        materialGroupDescription: values.materialGroupDescription,
        materialGroupName: `${values.countryCode}-${productGroupName}${values.materialGroupNumber ?? ''}`,
        productGroupId: Number(productId),
      });

      notificationContext.success(t('Materialgruppe erfolgreich erstellt'));
      await materialGroupResponse.execute();
      if (handleOnClose) handleOnClose();
    } catch {
      notificationContext.error(t('Materialgruppe kann nicht erstellt werden'));
    }
  }

  interface MaterialGroupSidebarModel {
    materialGroupId: number;
    countryCode: string;
    materialGroupDescription: string;
    materialGroupName: string;
    materialGroupNumber?: string;
    materialGroupTitle: string;
  }

  let sidebarFormikInitialValue: MaterialGroupSidebarModel = {
    materialGroupId: 0,
    countryCode: country,
    materialGroupDescription: '',
    materialGroupName: '',
    materialGroupNumber: undefined,
    materialGroupTitle: '',
  };

  if (materialGroup !== undefined) {
    sidebarFormikInitialValue = {
      materialGroupId: materialGroup.id ?? 0,
      countryCode: country,
      materialGroupDescription: materialGroup.materialGroupDescription ?? '',
      materialGroupName: materialGroup.materialGroupName ?? '',
      materialGroupNumber: String(
        materialGroup.materialGroupName?.substring(materialGroup.materialGroupName.length - 3)
      ),
      materialGroupTitle: materialGroup.materialGroupTitle ?? '',
    };
  }

  const updateMaterialGroup = async (values: MaterialGroupSidebarModel) => {
    if (country && materialGroup?.id && values) {
      try {
        if (canEditMaterialGroup) {
          await api.materialGroupService.materialGroupServiceMaterialGroupUpdate(country, values.materialGroupId, {
            materialGroupName: `${values.countryCode}-${productGroupName}${values.materialGroupNumber ?? ''}`,
            materialGroupDescription: values.materialGroupDescription,
            productTitle: values.materialGroupTitle,
          });
        } else {
          await api.materialGroupService.materialGroupServiceMaterialGroupTitleUpdate(
            values.countryCode,
            values.materialGroupId,
            {
              title: values.materialGroupTitle,
            }
          );
        }

        notification.success(t('Warengruppe erfolgreich aktualisiert'));
        if (handleOnClose) handleOnClose();
      } catch {
        notification.warning(t('Speichern fehlgeschlagen'));
      }
    }
  };

  async function handleDelete() {
    setShowDeleteDialog(false);
    if (materialGroup?.countryCode && materialGroup?.id) {
      try {
        await api.materialGroupService.materialGroupServiceMaterialGroupDelete(
          materialGroup?.countryCode,
          materialGroup?.id
        );

        notification.success(t('Die Warengruppe wurde erfolgreich gelöscht.'));
        if (handleOnClose) handleOnClose();
      } catch {
        notification.error(t('Der Löschvorgang ist fehlgeschlagen'));
      }
    }
  }

  const validationSchema = Yup.object().shape({
    materialGroupDescription: Yup.string().min(0, t('zu kurz')).max(50, t('zu lang')).required(t('Feld erforderlich')),
    materialGroupNumber: Yup.string()
      .matches(RegExp('^\\d+$'), t('Nur Ziffern erlaubt'))
      .length(materialGroupNameLength, t('Anzahl stimmt nicht', { fieldLength: materialGroupNameLength }))
      .required(t('Feld erforderlich')),
  });

  return (
    <PIMFormikSidebar
      title={props.mode === SidebarMode.Create ? t('Neue Materialgruppe') : t('Warengruppe bearbeiten')}
      cancelButtonText={t('Abbrechen')}
      formikInitialValues={sidebarFormikInitialValue}
      validationSchema={validationSchema}
      handleOnSubmit={onHandleOnSubmit}
      handleOnCancel={handleOnCancel}>
      <>
        <PIMFormikTextField
          name='materialGroupNumber'
          id='filled-basic'
          label={t('Warengruppencode')}
          variant='filled'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                {props.mode === SidebarMode.Create ? country : materialGroup?.countryCode}-{productGroupName}
              </InputAdornment>
            ),
          }}
          type='text'
          placeholder='XXX'
          fullWidth
          className={classes.MaterialGroupNumber}
          disabled={props.mode === SidebarMode.Update && (materialGroup?.erpImport || !canEditMaterialGroup)}
        />
        <PIMFormikTextField
          name='materialGroupDescription'
          id='filled-basic'
          label={t('Warengruppenbezeichnung')}
          variant='filled'
          type='text'
          fullWidth
          disabled={props.mode === SidebarMode.Update && !canEditMaterialGroup}
        />
        {props.mode === SidebarMode.Update && (
          <>
            <PIMFormikTextField
              label={t('Warengruppenbezeichnung')
                .concat(' ')
                .concat(props.countryName ?? '')}
              name='materialGroupTitle'
              fullWidth
              disabled={!canEditMaterialGroupTitle}
            />
            <hr className={classes.separatorLine} />
            {canDeleteMaterialGroup && (
              <PIMButton
                color='warning'
                icon='trash'
                disabled={materialGroup?.countryCode !== country}
                onClick={() => {
                  setShowDeleteDialog(true);
                }}>
                {t('Warengruppe löschen')}
              </PIMButton>
            )}
          </>
        )}
        <MessageDialog
          show={showDeleteDialog}
          title={t('Wollen Sie diese Warengruppe löschen?')}
          messageText={t('HinweisWarengruppeLöschenFreigegebenLänder')}
          confirmColor='warning'
          confirmIcon='trash'
          confirmLabel={t('Löschen')}
          onClose={() => setShowDeleteDialog(false)}
          onCancel={() => {
            setShowDeleteDialog(false);
          }}
          onConfirm={() => handleDelete()}
        />
      </>
    </PIMFormikSidebar>
  );
}

const useStyles = makeStyles(() => ({
  separatorLine: {
    margin: '32px 0',
  },
  MaterialGroupNumber: {
    '& .MuiInputAdornment-root': {
      marginRight: '4px',
      color: 'black',
    },
  },
}));

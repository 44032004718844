import React, { ReactElement, useContext, useState } from 'react';
import { createStyles, makeStyles, Grid, MenuItem } from '@material-ui/core';
import { useAsync } from 'react-async-hook';
import { useTranslation } from 'react-i18next';
import { CircleLoader } from '../../../utils/CircleLoader';
import { AdminBreadCrumbs } from '../../../layouts/AdminBreadCrumbs/AdminBreadCrumbs';
import PIMTextField from '../../../shared/Components/PIMTextField';
import PIMButton from '../../../shared/Components/PIMButton';
import PIMSelect from '../../../shared/Components/PIMSelect';
import { ExpansionTrackingAccordion } from '../../../shared/Components/Accordion';
import PIMAccordionSummary from '../../../shared/Components/PIMAccordionSummary';
import PIMAccordionDetails from '../../../shared/Components/PIMAccordionDetails';
import MessageDialog from '../../../shared/Components/MessageDialog';
import {
  CountryResponse,
  GlobalPermission,
  MailResponse,
  MailingListResponse,
  useMurexinPimContextApi,
} from '../../../api';
import { NotificationContext } from '../../../contexts';
import { AuthorizationContext } from '../../../auth';
import { MailingListSidebar } from './MailingListSidebar';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import PIMIconButton from '../../../shared/Components/PIMIconButton';
import ListView from '../../../shared/Components/ListView';

const NULL_SELECTED_COUNRY_FILTER = null;
const EMPTY_SELECTED_COUNRY_FILTER = '';

export const COUNTRY_NAME_FOR_GENERAL_ENTRY = 'GENERAL';
export function GetMailingListFromCountries(
  countries: CountryResponse[],
  translation: (text: string) => string
): CountryResponse[] {
  return [
    { code: COUNTRY_NAME_FOR_GENERAL_ENTRY, name: translation('Allgemein'), order: 0 } as CountryResponse,
    ...countries,
  ];
}

export function MailingList(): ReactElement {
  const classes = useStyles();
  const api = useMurexinPimContextApi();
  const [searchText, setSearchText] = useState<string>('');
  const [selectedFilterCountryCode, setSelectedFilterCountryCode] = useState<string>(EMPTY_SELECTED_COUNRY_FILTER);
  const [selectedEmail, setSelectedEmail] = useState<MailResponse | undefined>(undefined);
  const [selectedEmailForDelete, setSelectedEmailForDelete] = useState<MailResponse | undefined>(undefined);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const notification = React.useContext(NotificationContext);
  const { t } = useTranslation();
  const { hasGlobalPermission } = useContext(AuthorizationContext);
  const isAuthorizedToWriteGlobal = hasGlobalPermission(GlobalPermission.AdministrationWrite);

  const countries = useAsync(async () => {
    const response = await api.countryService.countryServiceList();
    const data = response.data ?? [];
    return GetMailingListFromCountries(data, t);
  }, []);

  const mailingListResult = useAsync(async () => {
    const response = await api.mail.mailList();
    return response.data ?? [];
  }, []);

  const handleOnDeleteEmail = async () => {
    try {
      if (selectedEmailForDelete?.id) {
        await api.mail.mailDelete(selectedEmailForDelete?.id);
        notification.success(t('Löschen erfolgreich'));
        if (selectedEmail?.id === selectedEmailForDelete.id) setSelectedEmail(undefined);
        setSelectedEmailForDelete(undefined);
        setShowDeleteDialog(false);
        await mailingListResult.execute();
      }
    } catch (error) {
      notification.error(t('Löschen fehlgeschlagen'));
      throw error;
    }
  };

  const handleOnSubmit = async () => {
    setSelectedEmail(undefined);
    await mailingListResult.execute();
  };

  const handleOnDelete = (deleteMail: MailResponse) => {
    setSelectedEmailForDelete(deleteMail);
    setShowDeleteDialog(true);
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: '', hide: true },
    {
      field: 'eMail',
      headerName: t('E-Mail Adresse'),
      flex: 1,
      // eslint-disable-next-line react/display-name
      renderCell: (params: GridRenderCellParams) => <div className={classes.EmailColumn}>{params.value}</div>,
    },
    {
      field: '',
      headerName: '',
      sortable: false,
      filterable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      align: 'center',
      width: 80,
      // eslint-disable-next-line react/display-name
      renderCell: (params: GridRenderCellParams) => (
        <PIMIconButton
          className={classes.DeleteButton}
          size='small'
          onClick={(e) => {
            e.stopPropagation();
            setSelectedEmailForDelete(params.row as MailResponse);
            setShowDeleteDialog(true);
          }}
          iconName='trash'
        />
      ),
    },
  ];

  const getFilteredMailingList = (mailingList: MailingListResponse[]) => {
    const resutlFilterdMailingList = new Array<MailingListResponse>();

    mailingList.forEach((countryMailingList: MailingListResponse) => {
      if (
        selectedFilterCountryCode === EMPTY_SELECTED_COUNRY_FILTER ||
        selectedFilterCountryCode === countryMailingList.countryCode ||
        (selectedFilterCountryCode === COUNTRY_NAME_FOR_GENERAL_ENTRY && countryMailingList.countryCode === null)
      ) {
        let filterdMailList: MailResponse[] = [];
        if (searchText !== '') {
          if (countryMailingList?.mailingList) {
            filterdMailList = countryMailingList.mailingList.filter((mail: MailResponse) =>
              mail?.eMail.toLowerCase().includes(searchText.toLowerCase())
            );
          }
        } else {
          filterdMailList = countryMailingList?.mailingList ?? [];
        }

        resutlFilterdMailingList.push({
          countryCode: countryMailingList.countryCode,
          mailingList: filterdMailList,
        } as MailingListResponse);
      }
    });

    return resutlFilterdMailingList;
  };

  const filterdMailingList = getFilteredMailingList(mailingListResult?.result ?? []);

  const isSearching = !!selectedFilterCountryCode || searchText.length > 0;

  return (
    <>
      <CircleLoader open={countries.loading || mailingListResult.loading} />
      <AdminBreadCrumbs site={t('Info Mail')} />
      <Grid container className={classes.containerBase}>
        <Grid container>
          <Grid item xs={8} className={classes.searchArea}>
            <div className={classes.Title}>{t('Info Mail')}</div>
            <Grid container item xs={12} className={classes.FilterArea}>
              <PIMSelect
                value={selectedFilterCountryCode}
                label={t('E-Mail Verteiler')}
                removeSelectionText={t('Alle')}
                className={classes.CountrySelect}
                onChange={(e) => {
                  setSelectedEmail(undefined);
                  setSelectedFilterCountryCode(e.target.value as string);
                }}>
                {countries.result?.map((country) => (
                  <MenuItem key={country.name} value={country.code ?? undefined}>
                    {country.name}
                  </MenuItem>
                ))}
              </PIMSelect>
              <PIMTextField
                value={searchText}
                label={t('Filter')}
                className={classes.SearchTextField}
                onChange={(e) => setSearchText(e.target.value)}
              />
              <PIMButton
                className={classes.AddButton}
                icon='add'
                color='primary'
                disabled={!isAuthorizedToWriteGlobal}
                onClick={() => setSelectedEmail({} as MailResponse)}>
                {t('E-Mail hinzufügen')}
              </PIMButton>
            </Grid>
            <Grid item xs={12}>
              {countries.result?.map((country: CountryResponse) => {
                const countryMailList = filterdMailingList?.find((mailingList: MailingListResponse) =>
                  country.code === COUNTRY_NAME_FOR_GENERAL_ENTRY
                    ? mailingList.countryCode === NULL_SELECTED_COUNRY_FILTER
                    : mailingList.countryCode === country.code
                );
                const countryMailingList = countryMailList?.mailingList ?? [];

                const isExpanded = isSearching ? true : undefined;
                const isHidden = isSearching && countryMailingList.length <= 0;

                return (
                  <ExpansionTrackingAccordion
                    key={country.code}
                    hidden={isHidden}
                    expanded={isExpanded}
                    disabled={countryMailingList.length === 0}>
                    <PIMAccordionSummary>{`${country.name as string} (${
                      countryMailingList.length
                    })`}</PIMAccordionSummary>
                    <PIMAccordionDetails>
                      <ListView
                        hideFooter
                        disabledPagination
                        data={countryMailingList}
                        columns={columns}
                        selectedItem={selectedEmail}
                        onChangeSelection={(item) => {
                          if (selectedEmail?.id !== item?.id) {
                            setSelectedEmail(item);
                            setSelectedEmailForDelete(item);
                          } else {
                            setSelectedEmail(undefined);
                          }
                        }}
                      />
                    </PIMAccordionDetails>
                  </ExpansionTrackingAccordion>
                );
              })}
            </Grid>
          </Grid>
          {selectedEmail && (
            <Grid item xs={4} className={classes.Sidebar}>
              <MailingListSidebar
                mail={selectedEmail}
                countries={countries.result?.filter((c) => c.code) ?? []}
                isAuthorizedToWrite={isAuthorizedToWriteGlobal}
                actionDelete={handleOnDelete}
                actionCancel={() => setSelectedEmail(undefined)}
                actionSubmit={handleOnSubmit}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <MessageDialog
        show={showDeleteDialog}
        title={t(`FrageEmailLöschen`, { email: selectedEmailForDelete?.eMail })}
        messageText={t('HinweisEmailLöschen')}
        confirmColor='warning'
        confirmIcon='trash'
        confirmLabel={t('Löschen')}
        onClose={() => setShowDeleteDialog(false)}
        onCancel={() => setShowDeleteDialog(false)}
        onConfirm={handleOnDeleteEmail}
      />
    </>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    containerBase: {
      paddingLeft: '20px',
    },
    searchArea: {
      marginTop: '24px',
      paddingRight: '16px',
    },
    FilterArea: {
      marginBottom: '16px',
    },
    Title: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '36px',
      marginBottom: '24px',
    },
    SearchTextField: {
      flex: '1',
      marginLeft: '16px',
      marginRight: '16px',
    },
    CountrySelect: {
      width: '250px',
    },
    Sidebar: {
      marginTop: '-135px',
      minHeight: '100%',
      backgroundColor: 'white',
    },
    AddButton: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    '& .MuiDataGrid-footerContainer': {
      minHeight: 0,
      height: 0,
    },
    EmailColumn: {
      marginLeft: '8px',
    },
    DeleteButton: {
      marginRight: '2px',
    },
  })
);

import React, { useContext, useState } from 'react';
import { SubstrateRequest, SubstrateResponse, useMurexinPimContextApi } from '../../../../api';
import { useAsync } from 'react-async-hook';
import { useTranslation } from 'react-i18next';
import { Grid, makeStyles, MenuItem } from '@material-ui/core';
import { CircleLoader } from '../../../../utils/CircleLoader';
import { FieldArray, FieldArrayRenderProps, Form, Formik } from 'formik';
import { NotificationContext } from '../../../../contexts';
import { AdminBreadCrumbs } from '../../../../layouts/AdminBreadCrumbs/AdminBreadCrumbs';
import PIMButton from '../../../../shared/Components/PIMButton';
import PIMTextField from '../../../../shared/Components/PIMTextField';
import PIMSelect from '../../../../shared/Components/PIMSelect';
import { TemplateItem } from '../TemplateItem';
import { SaveChangesDialog } from '../../../../utils/SaveChangesDialog';

export function Substrate(): JSX.Element {
  const api = useMurexinPimContextApi();
  const classes = useStyles();
  const notification = useContext(NotificationContext);

  const { t } = useTranslation();
  const header = t('Untergrundvorlagen');

  const [searchText, setSearchText] = useState('');
  const [currentCountryCode, setCurrentCountryCode] = useState('AT');
  const [selectedCountryCode, setSelectedCountryCode] = useState('');
  const [showSaveDialog, setShowSaveDialog] = useState(false);
  const [temporaryId, setTemporaryId] = useState(-1);

  const countriesResponse = useAsync(async () => {
    const countryListResponse = await api.countryService.countryServiceList();
    return countryListResponse.data ?? [];
  }, []);

  const substrates = useAsync(async () => {
    const result = await api.admin.adminSubstrateDetail(currentCountryCode);
    return result?.data ?? [];
  }, [currentCountryCode]);

  const handleChangeCountry = (countryCode: string, formIsDirty: boolean) => {
    if (formIsDirty) {
      setSelectedCountryCode(countryCode);
      setShowSaveDialog(true);
    } else {
      setCurrentCountryCode(countryCode);
    }
  };

  const filterSearchResults = (values: SubstrateResponse[]) => {
    return searchText === ''
      ? values
      : values?.filter((substrateResponse) => {
          return (
            substrateResponse.title?.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) ||
            substrateResponse.content?.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
          );
        });
  };

  const updateSubstrates = async (substrateValues: SubstrateRequest[]) => {
    const titleValidationError = validateTitle(substrateValues);

    if (titleValidationError != null) {
      notification.error(titleValidationError);
      return false;
    }

    try {
      const request = substrateValues.map((substrate: SubstrateRequest) => ({
        ...substrate,
        id: substrate.id !== undefined && substrate.id >= 0 ? substrate.id : 0,
      }));

      await api.admin.adminSubstrateUpdate(currentCountryCode, request);
      notification.success(t('Speichern erfolgreich'));
      await substrates.execute();
      return true;
    } catch {
      notification.error(t('Speichern fehlgeschlagen'));
      return false;
    }
  };

  const validateTitle = (values: SubstrateRequest[]) => {
    if (values.some((substrate) => substrate.title === '' || substrate.title === undefined)) {
      return t('Titelfeld erforderlich');
    } else if (values.some((substrate) => substrate.title && substrate.title.length > 250)) {
      return t('Titelfeld zu lang');
    } else if (new Set(values.map((s) => s.title)).size !== values.length) {
      return t('Titelfeld nicht eindeutig');
    } else {
      return null;
    }
  };

  const findSubstrateIndex = (values: SubstrateResponse[], substrate: SubstrateResponse) =>
    values.findIndex((p: SubstrateResponse) => p.id === substrate.id);

  const getUniqueTitle = (title: string, suffix: string, values: SubstrateResponse[]) => {
    const titleUnique = (s: string) => s === '' || !values.some((value) => value.title === s);

    let newTitle = `${title}${suffix}`;

    if (!titleUnique(newTitle)) {
      let index = 1;
      while (!titleUnique(newTitle.concat(` (${index})`))) {
        index += 1;
      }

      newTitle = newTitle.concat(` (${index})`);
    }

    return newTitle;
  };

  const addSubstrate = (arrayHelpers: FieldArrayRenderProps) => {
    arrayHelpers.insert(0, {
      id: temporaryId,
      title: '',
      content: '',
    } as SubstrateResponse);

    setTemporaryId(temporaryId - 1);
  };

  const duplicateSubstrate = (
    arrayHelpers: FieldArrayRenderProps,
    substrate: SubstrateResponse,
    values: SubstrateResponse[]
  ) => {
    arrayHelpers.insert(0, {
      id: temporaryId,
      title: getUniqueTitle(substrate.title ?? '', ` ${t('Kopie')}`, values),
      content: substrate.content,
    } as SubstrateResponse);

    setTemporaryId(temporaryId - 1);
  };

  const deleteSubstrate = (
    arrayHelpers: FieldArrayRenderProps,
    substrate: SubstrateResponse,
    values: SubstrateResponse[]
  ) => {
    if (values) {
      const index = findSubstrateIndex(values, substrate);

      if (index >= 0) {
        arrayHelpers.remove(index);
      }
    }
  };

  function renderSubstrates(arrayHelpers: FieldArrayRenderProps, values: SubstrateResponse[]): React.ReactNode {
    const filteredSubstrates = filterSearchResults(values);

    return (
      <>
        {filteredSubstrates?.map((substrate: SubstrateResponse) => {
          const textFieldPrefix = `substrates[${findSubstrateIndex(values, substrate)}]`;
          return (
            <TemplateItem
              key={substrate.id}
              duplicateItem={() => duplicateSubstrate(arrayHelpers, substrate, values)}
              deleteItem={() => deleteSubstrate(arrayHelpers, substrate, values)}
              textFieldPrefix={textFieldPrefix}
            />
          );
        })}
      </>
    );
  }

  return (
    <>
      <AdminBreadCrumbs site={header} />
      <CircleLoader open={countriesResponse.loading || substrates.loading} />
      <Formik
        initialValues={{ substrates: substrates.result ?? [] }}
        onSubmit={async (formikValues: { substrates: SubstrateResponse[] }) => {
          await updateSubstrates(formikValues.substrates);
        }}
        enableReinitialize={true}>
        {(formik) => (
          <Form>
            <Grid container className={classes.Container}>
              <FieldArray
                name='substrates'
                render={(arrayHelpers) => {
                  return (
                    <>
                      <Grid container item xs={12}>
                        <Grid item xs={6}>
                          <div className={classes.Title}>{header}</div>
                        </Grid>
                        <Grid container item xs={6} className={classes.ActionButtonRow}>
                          <PIMButton
                            className={classes.TopButtons}
                            icon='close'
                            type='reset'
                            disabled={!formik.dirty}
                            onClick={() => {
                              formik.resetForm();
                              notification.info(t('Daten zurückgesetzt'));
                            }}>
                            {t('Abbrechen')}
                          </PIMButton>
                          <PIMButton
                            color='primary'
                            icon='save'
                            className={classes.TopButtons}
                            busy={formik.isSubmitting}
                            disabled={!formik.dirty}
                            type='submit'>
                            {t('Speichern')}
                          </PIMButton>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <PIMSelect
                          className={classes.Field}
                          label={countriesResponse?.result?.length === 0 ? t('Keine Länder vorhanden') : t('Land')}
                          value={currentCountryCode}
                          hideRemoveSelection
                          disabled={countriesResponse?.result?.length === 0}
                          onChange={(e) => {
                            handleChangeCountry(e.target.value as string, formik.dirty);
                          }}>
                          {countriesResponse?.result?.map((country) => (
                            <MenuItem key={country.code} value={country.code ?? ''}>
                              {country.name}
                            </MenuItem>
                          ))}
                        </PIMSelect>
                        <PIMButton
                          icon='add'
                          color='primary'
                          onClick={() => addSubstrate(arrayHelpers)}
                          className={classes.Field}>
                          {t('Hinzufügen')}
                        </PIMButton>
                      </Grid>
                      <Grid container>
                        <PIMTextField
                          label={t('Suche')}
                          className={classes.SearchTextField}
                          fullWidth
                          onChange={(event) => {
                            setSearchText(event.target.value ?? '');
                          }}
                        />
                      </Grid>
                      <Grid container item xs={12} className={classes.Panel} spacing={3}>
                        {renderSubstrates(arrayHelpers, formik.values.substrates)}
                      </Grid>
                    </>
                  );
                }}
              />
            </Grid>
            <SaveChangesDialog
              handleClose={() => {
                setShowSaveDialog(false);
                setCurrentCountryCode(selectedCountryCode);
              }}
              handleCancel={() => setShowSaveDialog(false)}
              handleSaveAndClose={async () => {
                const success = await updateSubstrates(formik.values.substrates);
                setShowSaveDialog(false);
                if (success) setCurrentCountryCode(selectedCountryCode);
              }}
              open={showSaveDialog}
              title={t('Wollen Sie die vorgenommenen Änderungen speichern?')}
              saveText={t('Nicht gespeicherte Änderungen gehen verloren')}
            />
          </Form>
        )}
      </Formik>
    </>
  );
}

const useStyles = makeStyles(() => ({
  Container: {
    marginTop: '40px',
    paddingLeft: '16px',
    paddingRight: '24px',
  },
  Title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '36px',
    marginBottom: '20px',
  },
  ActionButtonRow: {
    flexWrap: 'nowrap',
    justifyContent: 'flex-end',
    paddingBottom: '32px',
  },
  TopButtons: {
    marginLeft: '24px',
  },
  Panel: {
    background: 'white',
    padding: '16px 24px',
    margin: '16px 0px',
  },
  SearchTextField: {
    maxWidth: '600px',
    margin: '16px 24px 16px 0px',
  },
  Field: {
    margin: '16px 24px 16px 0px',
  },
}));

import React, { useContext } from 'react';
import { GeneralHintRequest, useMurexinPimContextApi } from '../../../../api';
import { useAsync } from 'react-async-hook';
import { useTranslation } from 'react-i18next';
import { Grid, makeStyles } from '@material-ui/core';
import PIMFormikTextField from '../../../../shared/Components/PIMFormikTextField';
import { CircleLoader } from '../../../../utils/CircleLoader';
import { AdminBreadCrumbs } from '../../../../layouts/AdminBreadCrumbs/AdminBreadCrumbs';
import { Form, Formik } from 'formik';
import PIMButton from '../../../../shared/Components/PIMButton';
import { NotificationContext } from '../../../../contexts';

export function GeneralHint(): JSX.Element {
  const { t } = useTranslation();
  const api = useMurexinPimContextApi();
  const classes = useStyles();
  const header = t('Allgemeine Hinweise');
  const notification = useContext(NotificationContext);

  const generalHints = useAsync(async () => {
    const result = await api.admin.adminGeneralHintList();
    return result?.data ?? [];
  }, []);

  const updateGeneralHints = async (values: { generalHints: GeneralHintRequest[] }) => {
    try {
      await api.admin.adminGeneralHintUpdate(values.generalHints);
      notification.success(t('Speichern erfolgreich'));
      await generalHints.execute();
    } catch {
      notification.error(t('Speichern fehlgeschlagen'));
    }
  };

  function renderGeneralHints(): React.ReactNode {
    const hintsList = [];

    if (generalHints.result) {
      for (let index = 0; index < generalHints.result.length; index++) {
        const hint = generalHints.result[index];
        hintsList.push(
          <>
            <Grid item xs={1}>
              <div className={classes.Country}>{hint.countryName ?? hint.countryCode}</div>
            </Grid>
            <Grid item xs={11}>
              <PIMFormikTextField
                multiline
                name={`generalHints[${index}].content`}
                label={t('Inhalt')}
                variant='filled'
                type='text'
                fullWidth
              />
            </Grid>
          </>
        );
      }
    }

    return hintsList;
  }

  if (generalHints.loading) return <CircleLoader open={true} />;
  else
    return (
      <>
        <AdminBreadCrumbs site={header} />
        <Formik
          initialValues={{ generalHints: generalHints.result ?? [] }}
          onSubmit={async (values) => await updateGeneralHints(values)}
          enableReinitialize={true}>
          {(formik) => (
            <Form>
              <Grid container className={classes.Container}>
                <Grid container item xs={12}>
                  <Grid item xs={6}>
                    <div className={classes.Title}>{header}</div>
                  </Grid>
                  <Grid container item xs={6} className={classes.ActionButtonRow}>
                    <PIMButton
                      className={classes.TopButtons}
                      icon='close'
                      type='reset'
                      disabled={!formik.dirty}
                      onClick={() => {
                        formik.resetForm();
                        notification.info(t('Daten zurückgesetzt'));
                      }}>
                      {t('Abbrechen')}
                    </PIMButton>
                    <PIMButton
                      color='primary'
                      icon='save'
                      className={classes.TopButtons}
                      busy={formik.isSubmitting}
                      disabled={!formik.dirty}
                      type='submit'>
                      {t('Speichern')}
                    </PIMButton>
                  </Grid>
                </Grid>
                <Grid container className={classes.Panel} spacing={2}>
                  {renderGeneralHints()}
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </>
    );
}

const useStyles = makeStyles(() => ({
  Country: {
    fontSize: '20px',
    lineHeight: '56px',
    paddingTop: '16px',
  },
  Container: {
    marginTop: '40px',
    paddingLeft: '16px',
    paddingRight: '24px',
  },
  Title: {
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '36px',
    marginBottom: '20px',
  },
  ActionButtonRow: {
    flexWrap: 'nowrap',
    justifyContent: 'flex-end',
    paddingBottom: '32px',
  },
  TopButtons: {
    marginLeft: '24px',
  },
  Panel: {
    background: 'white',
    padding: '16px 24px',
    margin: '16px 0px',
  },
  SearchTextField: {
    maxWidth: '600px',
    margin: '16px 24px 16px 0px',
  },
  Field: {
    margin: '16px 24px 16px 0px',
  },
}));

import { FormikProps } from 'formik';
import { RefObject, useRef } from 'react';

export default function useFormikSaveAndClose<T>(
  saveOnCloseRef: React.RefObject<{
    handleSave: () => Promise<void>;
  }>
): RefObject<FormikProps<T>> {
  const formikRef = useRef<FormikProps<T>>(null);

  const handleSave = async (): Promise<void> => {
    const formik = formikRef?.current;
    if (formik !== null) {
      await formik.submitForm();
    }
  };

  if (saveOnCloseRef !== null && saveOnCloseRef.current !== null) {
    saveOnCloseRef.current.handleSave = handleSave;
  }
  return formikRef;
}

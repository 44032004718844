import * as React from 'react';
import { Grid, makeStyles, TextField, useMediaQuery, useTheme } from '@material-ui/core';
import { CommentResponse } from '../../api';
import { DeleteDialog } from '../../utils/DeleteDialog';
import Utility from '../../shared/Utility';
import { useTranslation } from 'react-i18next';

interface IProps {
  isAuthorizedToWrite: boolean;
  comments: CommentResponse[] | null;
  deleteComment: (id: number) => void;
}

export function CommentGrid(props: IProps): JSX.Element {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
  const [commentToDeleteId, setCommentToDeleteId] = React.useState(0);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const { t } = useTranslation();
  const handleDeleteComment = (deleteId?: number) => {
    setIsDeleteDialogOpen(true);
    setCommentToDeleteId(deleteId ?? 0);
  };

  const handleClose = () => {
    setIsDeleteDialogOpen(false);
  };

  function deleteComment() {
    props.deleteComment(commentToDeleteId);
    setIsDeleteDialogOpen(false);
    setCommentToDeleteId(0);
  }

  return (
    <>
      {props.comments
        ? props.comments.map((comment) => (
            <div key={comment.id} className={classes.center}>
              <Grid container spacing={4}>
                <Grid item xs={2} />
                <Grid item xs={2}>
                  <div className={classes.rightSide}>
                    <span className={classes.float}>
                      {comment.realName ? comment.realName : t('Benutzer nicht gefunden')}
                    </span>
                    <div>
                      <span className={classes.floatWithText}>
                        {comment.dateTime ? Utility.formatDateTimeUtc(new Date(comment.dateTime)) : t('Datum fehlt')}
                      </span>
                    </div>
                    <br />
                    {props.isAuthorizedToWrite && (
                      <div className={classes.deleteButton} onClick={() => handleDeleteComment(comment.id)}>
                        {t('Löschen')}
                      </div>
                    )}
                  </div>
                </Grid>
                <Grid item xs={4} className={classes.item4}>
                  <TextField
                    id='standard-secondary'
                    className={classes.paragraphSize}
                    color='secondary'
                    disabled
                    defaultValue={comment.commentText}
                    multiline
                  />
                </Grid>
                <Grid item xs={4} />
              </Grid>
            </div>
          ))
        : null}
      <DeleteDialog
        handleClose={handleClose}
        open={isDeleteDialogOpen}
        fullScreen={fullScreen}
        delete={deleteComment}
        saveText={t('FrageKommentarLöschen')}
        title={t('Kommentar löschen')}
      />
    </>
  );
}

const useStyles = makeStyles(() => ({
  center: {
    marginTop: '20px',
    marginLeft: '50px',
    marginRight: '50px',
    height: 'auto',
    display: 'flex',
  },
  item4: {
    paddingTop: '10px !important',
    paddingLeft: '0px !important',
    paddingBottom: '10px !important',
    paddingRight: '0px !important',
  },
  paragraphSize: {
    float: 'left',
    width: '100%',
    display: 'contents',
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'black',
      width: '100%',
    },
    '& .MuiInput-underline.Mui-disabled:before': {
      borderBottomStyle: 'none',
    },
  },
  rightSide: {
    float: 'right',
    paddingRight: '25px',
  },
  float: {
    float: 'right',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '120%',
    textAlign: 'right',
    marginBottom: '3px',
  },
  floatWithText: {
    float: 'right',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '120%',
    textAlign: 'right',
    opacity: '50%',
  },
  deleteButton: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '120%',
    textAlign: 'right',
    color: '#DB1F35',
    float: 'right',
    marginTop: '8px',
    marginBottom: '16px',
    cursor: 'pointer',
  },
}));

import React, { ReactElement } from 'react';
import { makeStyles, Theme, Select, SelectProps, InputLabel, FormControl, MenuItem } from '@material-ui/core';

interface Props {
  label?: string;
  hideRemoveSelection?: boolean;
  removeSelectionText?: string;
  // set to true when no dropdown item selected - fixes bug in mui which doesn't reset shrunk label on deselection
  shrinkLabel?: boolean;
}

export function PIMSelect(props: Props & SelectProps): ReactElement {
  const classes = useStyles();
  const { className = '', hideRemoveSelection, shrinkLabel, removeSelectionText, label, children, ...rest } = props;
  const defaultProps = {
    style: { minWidth: '150px' },
  };

  return (
    <FormControl className={`${classes.selectField} ${className}`}>
      {label && (
        <InputLabel shrink={shrinkLabel} className={classes.Label}>
          {label}
        </InputLabel>
      )}
      <Select variant='filled' {...defaultProps} {...rest}>
        {hideRemoveSelection !== true && (
          <MenuItem style={{ minHeight: '30px' }} value=''>
            <em>{removeSelectionText}</em>
          </MenuItem>
        )}
        {children}
      </Select>
    </FormControl>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  selectField: {
    '& .MuiFormHelperText-root': { fontSize: 12 },
    '& .MuiFormLabel-root': {
      color: '#676767',
      fontSize: 16,
      zIndex: 1,
      transform: 'translate(12px, 20px)',
    },
    '& .MuiFormLabel-root.MuiInputLabel-shrink': {
      color: 'rgba(0, 0, 0, 0.6)',
      fontSize: 12,
      zIndex: 1,
      transform: 'translate(12px, 10px)',
    },
    '& .MuiSelect-icon': {
      color: '#6F6F6F',
    },
    '& .MuiFormLabel-root.Mui-focused': { color: '#000000' },
    '& .MuiSelect-root': { fontSize: 16, background: '#F2F2F2', boxShadow: 'inset 0px -1px 0px #8C8C8C' },
    '& .MuiSelect-root:focus': { boxShadow: 'inset 0px -1px 0px #000000' },
    '& .MuiSelect-root.Mui-disabled': { background: '#FAFAFA', boxShadow: 'inset 0px -1px 0px #E0E0E0' },
    '& .MuiSelect-root.Mui-disabled:focus': { boxShadow: 'inset 0px -1px 0px #E0E0E0' },
    '& .MuiFilledInput-underline:after': { borderColor: '#000000' },
    '& .MuiFilledInput-underline.Mui-error:after': { borderColor: theme.palette.error.main },
    '& .MuiFilledInput-underline.Mui-disabled:before': { borderBottomStyle: 'none' },
  },
  Label: {
    pointerEvents: 'none',
  },
}));

import * as React from 'react';
import { PictogramResponse, SealOfApprovalResponse, useMurexinPimContextApi } from '../../../api/MurexinPimApi';
import { Grid, makeStyles } from '@material-ui/core';
import { useAsync } from 'react-async-hook';
import { useParams } from 'react-router-dom';
import { CircleLoader } from '../../../utils/CircleLoader';
import { ApiImageUrlsBuilder } from '../../../utils/apiImageUrls';
import { useTranslation } from 'react-i18next';

interface IParams {
  country: string;
  materialId: string;
}

interface IProps {
  imageStoreMaterialGroupId: number | null | undefined;
}

export function ArticleSideBar(props: IProps): JSX.Element {
  const classes = useStyles();
  const api = useMurexinPimContextApi();
  const { country, materialId } = useParams<IParams>();
  const { imageStoreMaterialGroupId } = props;
  const { t } = useTranslation();

  const pictogramsResult = useAsync(async () => {
    const response = await api.pictogram.pictogramMaterialGroupDetail(country, Number(materialId));
    return response.data ?? [];
  }, [country, materialId]);

  const sealOfApprovalAndNormResult = useAsync(
    () => api.sealOfApprovalAndNorm.sealOfApprovalAndNormMaterialGroupDetail(country, Number(materialId)),
    [country, materialId]
  );

  if (pictogramsResult.loading || sealOfApprovalAndNormResult.loading) {
    return <CircleLoader open />;
  } else {
    return (
      <div>
        <Grid container className={classes.whiteBackground}>
          <Grid item xs={12}>
            <div className={classes.imageWrapper}>
              {imageStoreMaterialGroupId !== null && imageStoreMaterialGroupId !== undefined && (
                <img
                  className={classes.image}
                  src={ApiImageUrlsBuilder.buildMaterialGroupImageUrl(country, imageStoreMaterialGroupId)}
                />
              )}
            </div>
          </Grid>
          <Grid item xs={10} className={classes.margin}>
            <span className={classes.normalFont}>{t('Piktogramme')}</span>
            {pictogramsResult?.result ? (
              <div className={classes.distanz}>
                {pictogramsResult.result.map((pictogram: PictogramResponse, index: number) => {
                  return (
                    <img
                      className={classes.PictogramsAndSealOfApprovalImage}
                      key={index}
                      src={ApiImageUrlsBuilder.buildPictogramImageUrl(country, pictogram.fileName as string)}
                    />
                  );
                })}
              </div>
            ) : null}
          </Grid>
          <Grid item xs={10} className={classes.margin}>
            <span className={classes.normalFont}>{t('Prüfsiegel')}</span>
            <div className={classes.sealOfApprovalImageList}>
              {sealOfApprovalAndNormResult.result !== undefined &&
              sealOfApprovalAndNormResult.result.data !== undefined &&
              sealOfApprovalAndNormResult.result.data !== null &&
              sealOfApprovalAndNormResult.result.data.sealOfApprovals !== undefined &&
              sealOfApprovalAndNormResult.result.data.sealOfApprovals !== null
                ? sealOfApprovalAndNormResult.result.data.sealOfApprovals.map(
                    (sealOfApproval: SealOfApprovalResponse, index: number) => {
                      return (
                        <img
                          className={classes.PictogramsAndSealOfApprovalImage}
                          key={index}
                          src={ApiImageUrlsBuilder.buildSealOfApprovalAndNormImageUrl(country, sealOfApproval.fullName)}
                        />
                      );
                    }
                  )
                : null}
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const useStyles = makeStyles(() => ({
  margin: {
    marginTop: '8px',
  },
  whiteBackground: {
    backgroundColor: 'white',
    paddingLeft: '40px',
    paddingTop: '72px',
    paddingBottom: '20px',
  },
  normalFont: {
    fontSize: '24px',
    lineHeight: '120%',
    color: 'black',
  },
  distanz: {
    marginTop: '16px',
  },
  imageWrapper: {
    paddingBottom: '16px',
    width: '130px',
    height: '130px',
  },
  image: {
    maxWidth: '130px',
    maxHeight: '130px',
    borderRadius: '3px',
  },
  sealOfApprovalImageList: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: '16px',
    minHeight: '64px',
  },
  PictogramsAndSealOfApprovalImage: {
    padding: '0px 25.67px 16px 0px',
    maxWidth: '80px',
    maxHeight: '80px',
  },
}));
